import { axiosInstance } from 'utils/configAPI';
import { ChecksumType, IAppConfig, IConfigRepo, IDataResponse } from './types';
import axios from 'axios';

export class ConfigRepo implements IConfigRepo {
  async getNewChecksum(dateStamp: string): Promise<IDataResponse<ChecksumType>> {
    return await axiosInstance.get(`/checksum.json?v=${dateStamp}`);
  }

  async getAppConfig(): Promise<IDataResponse<IAppConfig>> {
    return await axiosInstance.get('application.json');
  }

  async getAppResource<T>(url: string): Promise<IDataResponse<T>> {
    return await axios.get(url);
  }
}
