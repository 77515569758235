import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { Pagination, Table, UnderConstructionModal } from 'components';
import Diagram from './Diagram';
import SubHeader from './components/SubHeader';
import { Params } from './types';
import { IColumn } from 'components/Table/types';
import { orderProcessing } from 'helpers/orderProcessing';
import { useIntersectionObserverEffect } from 'hooks/useIntersectionObserverEffect';
import { numberWithSpaces } from 'helpers/numberWithComma';
import Header from 'view/Header';
import { ClickEvent } from 'utils/types';
import classes from './Analytics.module.scss';

const ProjectAnalytics = observer(() => {
  const { state } = useLocation();
  const { analyticsStore, userStore } = useStore();
  const [show, setShow] = useState<boolean>(false);
  const [analyticsParam, setAnalyticsParam] = useState<Params>({
    lists: state as number[],
    order: [],
    page: 1
  });
  const [isMoreAnalyticsLoading] = useState<boolean>(false);

  const observableDiv = useRef<HTMLDivElement | null>(null);

  const closeModal = useCallback(() => setShow(false), []);
  const openModal = useCallback(() => setShow(true), []);

  const columns: IColumn[] = [
    {
      field: 'property',
      headerName: 'Details',
      sort: true
    },
    {
      field: 'buildingPermit',
      headerName: 'Type',
      sort: true
    },
    {
      field: 'value',
      headerName: 'Project Value',
      sort: true
    }
  ];

  useEffect(() => {
    (async () => {
      await initFavoriteAnalytics();
      await initFavoriteAnalyticsData();
    })();
    return () => {
      analyticsStore.diagramData = null;
    };
  }, []);

  useEffect(() => {
    if (analyticsStore.analyticsData.data.length) {
      (async () => {
        await initFavoriteAnalyticsData();
      })();
    }
  }, [analyticsParam]);

  const initFavoriteAnalytics = async (): Promise<void> => {
    if (!userStore.user) return;
    await analyticsStore.getFavouriteAnalytics(userStore.user.id, state as number[]);
  };

  const initFavoriteAnalyticsData = async (): Promise<void> => {
    if (!userStore.user) return;
    await analyticsStore.getFavoriteAnalyticsData(userStore.user.id, analyticsParam);
  };

  const handleOrder = (field: string): void => {
    setAnalyticsParam({
      ...analyticsParam,
      order: orderProcessing(field, analyticsParam.order),
      page: 1
    });
  };

  useIntersectionObserverEffect(
    observableDiv,
    (entry: IntersectionObserverEntry) => {
      setAnalyticsParam((prev) => {
        if (
          entry.isIntersecting &&
          prev.page < analyticsStore.analyticsData.totalPages &&
          !isMoreAnalyticsLoading
        ) {
          return {
            ...prev,
            page: prev.page + 1
          };
        }
        return prev;
      });
    },
    [observableDiv.current]
  );

  const handlePageClick = (event: ClickEvent) => {
    setAnalyticsParam((prev) => {
      return {
        ...prev,
        page: event.selected + 1
      };
    });
  };

  return (
    <div className="bg-light-300">
      <Header>
        <SubHeader totalValue={analyticsStore.totalProjectValue} onExport={openModal} />
      </Header>
      <div className="flex justify-between mb-4 mt-6 text-dark px-6">
        <div>
          <span className="font-kraftig">Total Project Value</span>
        </div>
        <div>
          <span className="font-kraftig">
            Total Project Value: ${numberWithSpaces(analyticsStore.totalProjectValue)}
          </span>
        </div>
      </div>
      <div className="w-full flex justify-between px-6">
        <div className="flex items-center justify-center mr-4 bg-white py-3 shadow-default">
          {analyticsStore.diagramData ? (
            <Diagram
              diagramData={analyticsStore.diagramData}
              totalValue={analyticsStore.totalProjectValue}
            />
          ) : null}
        </div>
        <div className="w-full">
          <div className="shadow-default">
            <Table
              columns={columns}
              rows={analyticsStore.analyticsData.data}
              orders={analyticsParam.order}
              orderAction={handleOrder}
              cellClass="inline-block"
              tableClass={classes.table}
            />
          </div>
          <div className="flex">
            <Pagination
              onPageClick={handlePageClick}
              currentPage={analyticsParam.page}
              totalPages={analyticsStore.analyticsData.totalPages}
            />
          </div>
        </div>
      </div>

      <UnderConstructionModal
        show={show}
        closeModal={closeModal}
        title="Favorite project"
        subTitle=""
      />
    </div>
  );
});

export default ProjectAnalytics;
