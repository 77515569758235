import { IAxiosResponse, Location } from 'utils/types';
import { IRow } from 'components/Table/types';

export type Product = {
  id: number;
  name: string;
  title: string;
  description: string;
  period: number;
  discount: number;
  price: number;
  currency: string;
  unlimited: boolean;
};

export type PaymentCustomer = {
  id: number;
  locationId: number[];
  period: number;
  clientSecret: string;
};

export interface PaymentData extends Product {
  locations: ShortLocationForPayment[] | null;
}

export type Price = {
  id: number;
  amount: number;
  currency: string;
};

export type UserSubscription = {
  id: number;
  gateway: number;
  status: number;
  period: Plans;
  name: string;
  title: string;
  location: Location;
  description: string;
  price: Price;
  startAt: string;
  endsAt: string;
  unlimited: boolean;
};

export type ShortLocationForPayment = {
  id: number;
  title: string;
};

export interface ISubscriptionRow extends IRow {
  status: SubscriptionStatus;
}

export type StoreUserSubscriptionData = {
  data: UserSubscription[];
  tableData: UserSubscriptionData;
};

export type UserSubscriptionData = {
  month: ISubscriptionRow[];
  year: ISubscriptionRow[];
};

export type QuestionsAndAnswers = {
  question: string;
  answer?: string;
  custom?: string;
};

export type CreateSubscriptionToLocationBody = {
  locationId: number[];
  paymentMethod?: string;
};

export type SubscriptionAction = 'upgrade' | 'renew';

export const SUBSCRIPTION_PLANS_REPO = 'SUBSCRIPTION_PLANS_REPO';
export interface ISubscriptionAndPlansRepo {
  getProductsByLocation(locations: number[]): Promise<IAxiosResponse<Product[]>>;
  getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<IAxiosResponse<Product>>;
  createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<IAxiosResponse<PaymentCustomer>>;
  upgradeSubscriptionToLocation(
    userId: number,
    subscriptionId: number
  ): Promise<IAxiosResponse<PaymentCustomer>>;
  getUserSubscriptions(userId: number): Promise<IAxiosResponse<UserSubscription[]>>;
  unsubscription(
    subscriptionId: number,
    userId: number,
    questions: QuestionsAndAnswers[]
  ): Promise<void>;
  checkHasSubscriptionToLocation(locations: number[]): Promise<IAxiosResponse<Location[]>>;
  removeIncompleteSubscription(subscriptionId: number): Promise<void>;
}

export const SUBSCRIPTION_PLANS_SERVICE = 'SUBSCRIPTION_PLANS_SERVICE';
export interface ISubscriptionAndPlansService {
  getProductsByLocation(locations: ShortLocationForPayment[]): Promise<Product[]>;
  getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<Product>;
  createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<PaymentCustomer>;
  upgradeSubscriptionToLocation(userId: number, subscriptionId: number): Promise<PaymentCustomer>;
  getUserSubscriptions(userId: number): Promise<StoreUserSubscriptionData>;
  unsubscription(
    subscriptionId: number,
    userId: number,
    questions: QuestionsAndAnswers[]
  ): Promise<void>;
  checkHasSubscriptionToLocation(locations: ShortLocationForPayment[]): Promise<boolean>;
  removeIncompleteSubscription(subscriptionId: number): Promise<void>;
}

export interface ISubscriptionAndPlansStore {
  getProductsByLocation(locations: ShortLocationForPayment[]): Promise<void>;
  getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<void>;
  createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<PaymentCustomer>;
  upgradeSubscriptionToLocation(userId: number, subscriptionId: number): Promise<PaymentCustomer>;
  getUserSubscriptions(userId: number): Promise<void>;
  unsubscription(subscriptionId: number, userId: number): Promise<void>;
  checkHasSubscriptionToLocation(locations: ShortLocationForPayment[]): Promise<boolean>;
  removeIncompleteSubscription(subscriptionId: number): Promise<void>;
}

export enum Plans {
  MONTH = 10,
  YEAR = 20
}

export enum SubscriptionStatus {
  UNDEFINED,
  ACTIVE = 10,
  PAST_DUE = 20,
  UNPAID = 30,
  CANCELED = 40,
  INCOMPLETE = 50,
  INCOMPLETE_EXPIRED = 60,
  TRIALING = 70,
  RENEW_INCOMPLETE = 80
}

export enum PaymentType {
  NEW,
  UPGRADE,
  RENEW
}
