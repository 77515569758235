import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'view/Header';
import Sidebar from 'modules/Sidebar';
import { IProps as PrivateProps } from 'router/Private';

interface IProps {
  Private: FC<PrivateProps>;
}

export const MainLayout: FC<IProps> = ({ Private }) => {
  return (
    <Private>
      <div className="flex min-h-[100vh] bg-[#F7F8FA]">
        <Sidebar />
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </Private>
  );
};
