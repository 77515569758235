import {
  ANALYTICS_SERVICE,
  DataAnalyticsDiagram,
  IAnalyticsService,
  IAnalyticsStore,
  Params
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { IFavoriteAnalyticsDataRes, IFavoriteDataRes } from '../types';
import { IRow } from 'components/Table/types';
import { ObjectValues } from 'utils/types';

export class AnalyticsStore implements IAnalyticsStore {
  private _analyticsService: IAnalyticsService = injector.get<IAnalyticsService>(ANALYTICS_SERVICE);

  diagramData: DataAnalyticsDiagram | null = null;
  totalProjectValue = 0;
  analyticsData: IFavoriteAnalyticsDataRes<IRow> = {
    count: 0,
    currentPage: 0,
    limit: 0,
    totalPages: 0,
    data: []
  };
  colorTypes: ObjectValues<string> = {};

  constructor() {
    makeAutoObservable<AnalyticsStore>(this);
  }

  setDiagramData(diagramData: DataAnalyticsDiagram | null): void {
    this.diagramData = diagramData;
  }

  setTotalProjectValue(totalProjectValue: number): void {
    this.totalProjectValue = totalProjectValue;
  }

  setAnalyticsData(analyticsData: IFavoriteAnalyticsDataRes<IRow>): void {
    this.analyticsData = analyticsData;
  }

  setColorTypes(colorTypes: { [key: string]: string }): void {
    this.colorTypes = colorTypes;
  }

  async getFavouriteAnalytics(userId: number, lists: number[]): Promise<void> {
    this.setDiagramData(await this._analyticsService.getFavouriteAnalytics(userId, lists));
    this.calcTotalProjectValue();
    this.createColorTypes();
  }

  async getFavoriteAnalyticsData(userId: number, params: Params): Promise<void> {
    const res = await this._analyticsService.getFavoriteAnalyticsData(
      userId,
      params,
      this.colorTypes
    );
    this.setAnalyticsData(res);
  }

  calcTotalProjectValue(): void {
    this.setTotalProjectValue(
      this.diagramData && this.diagramData.values.length
        ? this.diagramData?.values.reduce((a, b) => a + b)
        : 0
    );
  }

  createColorTypes(): void {
    if (this.diagramData) {
      const data = this.diagramData;
      const colorTypes: ObjectValues<string> = {};
      data.labels.forEach((label, idx) => {
        colorTypes[label] = data.colors[idx];
      });
      this.setColorTypes(colorTypes);
    }
  }
}
