import React, { FC, ReactNode } from 'react';
import { ReactComponent as TickIcon } from 'assets/icons/tick-white.svg';
import { ReactComponent as CloseWhiteIcon } from 'assets/icons/close-white.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ERROR, SUCCESS } from 'utils/constants';

interface Props {
  text: string | ReactNode;
  type: string;
  closeAlert?: () => void;
}

export const Alert: FC<Props> = ({ text, type, closeAlert }) => {
  return (
    <div
      className="flex items-center m-auto mb-2 px-4 py-3 w-max bg-white rounded-sm text-dark shadow-overlay text-sm"
      role="alert">
      {type === SUCCESS && (
        <div className="w-6 h-6 rounded-full mr-3 relative bg-green-100">
          <span className="absolute top-2/4 right-2/4 -translate-y-2/4 translate-x-2/4">
            <TickIcon style={{ width: '14px' }} />
          </span>
        </div>
      )}
      {type === ERROR && (
        <div className="w-6 h-6 rounded-full mr-3 relative bg-red-200">
          {type === ERROR && (
            <span className="absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2">
              <CloseWhiteIcon style={{ width: '22px' }} />
            </span>
          )}
        </div>
      )}
      <p className="mb-1">{text}</p>
      <CloseIcon
        className="relative translate-x-[10px] -translate-y-[12px] ml-3"
        onClick={closeAlert}
        role="button"
      />
    </div>
  );
};
