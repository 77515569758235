import { ISearchParams } from 'modules/Filter/types';
import { DetailInfo } from 'view/SearchProjects/components/Map/types';
import { FoundLocation } from 'view/Search/types';
import { IAxiosResponse, SearchType } from 'utils/types';

export interface IModalData {
  title: string;
  subtitle: string;
  negativeAction: string;
  positiveAction: string;
}

export interface ISavedSearch {
  id: number;
  name: string;
  filters: ISearchParams;
  viewedAt: string;
  newProjects: number;
  description: string;
  searchType: SearchType;
  isShared: boolean;
}

export enum Status {
  PENDING = 'pending',
  DONE = 'done'
}

export type DataForLink = {
  userId: number;
  type: string;
  hash: string;
  extension: string;
};

export type ExportData = {
  media: DataForLink | null;
  status: Status.PENDING | Status.DONE | null;
};

export type HasSubscriptionLocation = {
  id: number;
  title: string;
};

export enum Error {
  EDIT = 10,
  SHARE
}

export interface ISavedSearchStore {
  newProjectsCount: number;
  saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<ISavedSearch>;
  getSavedSearches(id: number): Promise<void>;
  updateTitle(userId: number, searchId: number, name: string): Promise<void>;
  clone(userId: number, searchId: number): Promise<void>;
  delete(userId: number, searchId: number): Promise<void>;
  deleteShareSavedSearch(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number, searchType: SearchType): Promise<void>;
  savedSearches: ISavedSearch[];
  setSavedSearch: (searches: ISavedSearch[]) => void;
  getLocationsInfo(locationIds: number[]): Promise<FoundLocation[]>;
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<void>;
  hasSubscription(locations: number[]): Promise<void>;
}

export const SAVED_SEARCH_SERVICE = 'SAVED_SEARCH_SERVICE';
export interface ISavedSearchService {
  saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<ISavedSearch>;
  getSavedSearches(id: number): Promise<ISavedSearch[]>;
  updateTitle(userId: number, searchId: number, name: string): Promise<ISavedSearch>;
  clone(userId: number, searchId: number): Promise<ISavedSearch>;
  delete(userId: number, searchId: number): Promise<void>;
  deleteShareSavedSearch(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number, searchType: SearchType): Promise<number>;
  getLocationsInfo(locationIds: number[]): Promise<FoundLocation[]>;
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<ExportData>;
  hasSubscription(locations: number[]): Promise<HasSubscriptionLocation[]>;
}

export const SAVED_SEARCH_REPO = 'SAVED_SEARCH_REPO';
export interface ISavedSearchRepo {
  saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<IAxiosResponse<ISavedSearch>>;
  getSavedSearches(id: number): Promise<IAxiosResponse<ISavedSearch[]>>;
  updateTitle(
    userId: number,
    searchId: number,
    name: string
  ): Promise<IAxiosResponse<ISavedSearch>>;
  clone(userId: number, searchId: number): Promise<IAxiosResponse<ISavedSearch>>;
  delete(userId: number, searchId: number): Promise<void>;
  deleteShareSavedSearch(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number, searchType: SearchType): Promise<IAxiosResponse<number>>;
  getLocationsInfo(locationIds: number[]): Promise<IAxiosResponse<DetailInfo[]>>;
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<IAxiosResponse<ExportData>>;
  hasSubscription(locations: number[]): Promise<IAxiosResponse<HasSubscriptionLocation[]>>;
}
