import React, { FC, useMemo } from 'react';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button } from 'components';
import Filter from 'modules/Filter';
import { FilterState } from 'modules/Filter/types';

interface Props {
  onSaveSearch: (name: string) => void;
  onShowPlan: () => void;
}

const SubHeader: FC<Props> = observer(({ onSaveSearch, onShowPlan }) => {
  const { t } = useTranslation();

  const { searchStore, projectSearchStore } = useStore();

  const showPurchase = useMemo<boolean>(() => {
    return (
      searchStore.locationForMap.length === 1 && !projectSearchStore.projectsData.hasSubscription
    );
  }, [searchStore.locationForMap, projectSearchStore.projectsData]);

  return (
    <div className="text-dark relative z-[5]">
      <div className="flex items-center py-1">
        <Filter type={FilterState.SEARCH} onSaveSearch={onSaveSearch} />
        {showPurchase && (
          <Button
            type="primary"
            data-test-element="header-purchase-btn"
            background={'#00C47D'}
            className="h-8 text-xs uppercase px-5 ml-2 hover:bg-green-400 hover:bg-opacity-10"
            disabled={projectSearchStore.isValueLoading}
            onClick={onShowPlan}>
            <span className="font-halbfett">{t('components.header.purchase')}</span>
          </Button>
        )}
      </div>
    </div>
  );
});

export default SubHeader;
