import React, { FC, MouseEvent, useState } from 'react';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import AddToFavoritesModal, { IAddToFavoritesProps } from './AddToFavoritesModal';

interface IProps extends IAddToFavoritesProps {
  isFavorite: boolean;
  isModalShown: boolean;
  onHeartClick: (e: MouseEvent) => void;
}
const AddToFavorites: FC<IProps> = ({
  onHeartClick,
  isFavorite,
  isModalShown,
  entity,
  closeModal,
  toggleCallback
}) => {
  const [favoriteIcon, setFavoriteIcon] = useState<SVGSVGElement | null>(null);

  const handleRef = (el: SVGSVGElement | null): void => {
    setFavoriteIcon(el);
  };

  return (
    <>
      <FavoriteIcon
        ref={handleRef}
        onClick={onHeartClick}
        className="cursor-pointer hover:fill-grey-100 transition ease-linear"
        data-test-element="favorite-icon"
        fill={isFavorite ? '#E35656' : '#FFFFFF'}
        stroke={isFavorite ? '#E35656' : '#000000'}
      />

      {isModalShown && favoriteIcon && entity && (
        <AddToFavoritesModal
          entity={{ ...entity, coordinates: favoriteIcon.getBoundingClientRect() }}
          closeModal={closeModal}
          toggleCallback={toggleCallback}
        />
      )}
    </>
  );
};

export default AddToFavorites;
