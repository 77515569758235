import {
  ISearchProjectsRepo,
  IKeyword,
  IProjectsData,
  RelatedProjectKey,
  IRecentProjectsData,
  IProjectListResponse,
  IProjectListResults
} from './types';
import { axiosInstance } from 'utils/API';
import { IFullProject } from 'modules/ProjectInfoModal/types';
import { IAxiosResponse, IContact } from 'utils/types';
import { ISearchParams } from 'modules/Filter/types';

export class SearchProjectsRepo implements ISearchProjectsRepo {
  async getKeywords(keyword: string): Promise<IAxiosResponse<IKeyword[]>> {
    return axiosInstance.get(`/keywords?title=${keyword}`);
  }

  async getProjects(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectsData>> {
    return axiosInstance.post(
      '/projects',
      params,
      controller
        ? {
            signal: controller.signal
          }
        : {}
    );
  }

  // TODO: remove after testing
  // async getStatistics(
  //   params: ISearchParams,
  //   controller?: AbortController
  // ): Promise<IAxiosResponse<Statistics>> {
  //   return axiosInstance.post(
  //     `projects/statistics`,
  //     params,
  //     controller
  //       ? {
  //           signal: controller.signal
  //         }
  //       : {}
  //   );
  // }

  async getContacts(id: number): Promise<IAxiosResponse<IContact[]>> {
    return axiosInstance.get(`projects/${id}/contacts`);
  }

  // async getCompany(
  //   projectId: number,
  //   role: string,
  //   contactId: number
  // ): Promise<IAxiosResponse<ICompanyResponse>> {
  //   return await axiosInstance.get(`/projects/${projectId}/${role}/company/${contactId}`);
  // }

  async getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>> {
    return await axiosInstance.get(`/contacts/${contactType}/${contactId}/projects`, {
      params: { page }
    });
  }

  async getAllProjectInfo(projectId: number): Promise<IAxiosResponse<IFullProject>> {
    return await axiosInstance.get(`projects/${projectId}`);
  }

  async getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>> {
    return await axiosInstance.get(`projects/${projectId}/other/${type}`, {
      params: { page }
    });
  }

  async getProjectByListView(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectListResponse<IProjectListResults[]>>> {
    return axiosInstance.post(
      'projects/list',
      params,
      controller
        ? {
            signal: controller.signal
          }
        : {}
    );
  }

  async validateSharedToken(
    token: string,
    projectId: number
  ): Promise<IAxiosResponse<IFullProject>> {
    return axiosInstance.get(`projects/${projectId}/share/${token}`);
  }
}
