import React, { useCallback, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import { AnimatedInput, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { IResetData } from '../types';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ResetSchema } from 'utils/Formik/validation';
import backIcon from 'assets/icons/back-arrow.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';

const Reset = () => {
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();
  const { authStore, alertStore } = useStore();

  const [success, setSuccess] = useState<boolean>(false);

  const sendPassword = useCallback(async (values: IResetData) => {
    await authStore.sendResetCode(values.email);
    alertStore.successAlert(`${t('auth.resetEmailSent')} ${formik.values.email}`);
    setSuccess(true);
  }, []);

  const formik = useFormik<IResetData>({
    initialValues: {
      email: ''
    },
    onSubmit: sendPassword,
    validationSchema: ResetSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const openLogin = useCallback(() => setParams('auth=sign-in'), []);

  if (success) {
    return (
      <div className="rounded-sm shadow-form max-w-146 bg-white py-7.5">
        <div className="flex items-center flex-col">
          <h1 className="text-dark text-3xl m-0">{t('auth.resetSuccessTitle')}</h1>
          <p className="text-base text-grey-500 font-normal w-71 mb-4 text-center">
            {t('auth.resetSuccessSubTitle')} {formik.values.email}
          </p>
          <div className="px-4 w-full flex justify-center items-center mt-25">
            <span>{t('auth.didntReceive')}</span>
            <Button
              type="light"
              onClick={() => sendPassword({ email: formik.values.email })}
              className={clsx('!text-primary', classes.forgot, 'py-0', '!m-0', '!ml-2')}>
              <span className="font-kraftig">{t('auth.resend')}</span>
            </Button>
          </div>
          <div className="flex justify-center pt-4">
            <Button
              type="light"
              onClick={openLogin}
              className={clsx(
                '!text-primary',
                classes.forgot,
                'py-0',
                'm-0',
                'flex',
                'items-center'
              )}>
              <div className="mr-2 flex items-center">
                <img src={backIcon} alt="" />
              </div>
              <span className="font-kraftig">{t('auth.backLogin')}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      action="#"
      className="rounded-sm shadow-form max-w-146 bg-white mb-4 py-7.5">
      <div className="flex items-center flex-col">
        <h1 className="text-dark text-2xl m-0">{t('auth.resetTitle')}</h1>
        <p className="text-base text-grey-500 font-normal mb-4">{t('auth.resetSubTitle')}</p>
        <div className="px-10 w-full mb-5">
          <AnimatedInput
            type="text"
            name="email"
            value={formik.values.email}
            onInput={onInputChange}
            isError={!!formik.errors.email}
            isSuccess={!formik.errors.email && formik.touched.email}
            errorText={formik.errors.email}
            dataTestElement="send-reset-email-input">
            <span className="flex items-center">
              <div className="mr-2 w-4">
                <EmailIcon className="-ml-[2.5px]" />
              </div>
              {t('form.emailAddress')}
            </span>
          </AnimatedInput>
        </div>

        <div className="px-10 w-full">
          <Button
            type="primary"
            className="w-full font-kraftig px-2 py-[11px]"
            data-test-element="send-reset-code-button">
            <span>{t('auth.reset')}</span>
          </Button>
        </div>
        <div className="flex justify-center pt-4">
          <Button
            type="light"
            onClick={openLogin}
            className={clsx(
              '!text-primary',
              classes.forgot,
              'py-0',
              'm-0',
              'flex',
              'items-center'
            )}>
            <div className="mr-2 flex items-center">
              <img src={backIcon} alt="" />
            </div>
            <span className="font-kraftig">{t('auth.backLogin')}</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Reset;
