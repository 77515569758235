import { ISort, ObjectValues } from 'utils/types';

const getOrdersParams = (orders: ISort[]): ObjectValues<string> => {
  const orderParams: ObjectValues<string> = {};
  orders.forEach((item, index) => {
    const keyField = `order[${index}][field]`;
    const keyOrder = `order[${index}][order]`;
    // TODO: need an update on the BE part (change property to class)
    orderParams[keyField] = item.field === 'class' ? 'property' : item.field;
    orderParams[keyOrder] = item.order;
  });
  return orderParams;
};

export default getOrdersParams;
