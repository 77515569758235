import React, { FC, useState } from 'react';
import classes from './ShareModal.module.scss';
import { clsx } from 'utils/clsx';
import { ILocation, SearchType } from 'utils/types';
import { Button, Input, Modal } from 'components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { SharedEntityType, ShareType } from './types';

interface Props {
  isShowWithId: number | null;
  closeModal: () => void;
  entityType: SharedEntityType;
  location?: ILocation;
  searchType?: SearchType;
}

const ShareModal: FC<Props> = ({ isShowWithId, closeModal, entityType, location, searchType }) => {
  const { shareStore, userStore, alertStore } = useStore();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [loadToken, setLoadToken] = useState<boolean>(false);
  const onStrChange = (name: string, str: string): void => {
    setEmail(str);
  };

  const createShareToken = async (type: ShareType): Promise<void> => {
    if (userStore.user && isShowWithId) {
      try {
        const sharedByEmail: boolean = type === ShareType.EMAIL;
        const shareRequestByType: { [key in SharedEntityType]: () => Promise<void> } = {
          [SharedEntityType.SAVED_SEARCH]: () =>
            shareStore.createSavedSearchToken(
              userStore.user!.id,
              isShowWithId,
              sharedByEmail ? email : null
            ),
          [SharedEntityType.SINGLE_PERMIT]: () =>
            shareStore.createSinglePermitToken(isShowWithId, sharedByEmail ? email : null),
          [SharedEntityType.FAVORITE_LIST]: () =>
            shareStore.createFavoriteListToken(
              userStore.user!.id,
              isShowWithId,
              sharedByEmail ? email : null
            )
        };
        setLoadToken(true);
        await shareRequestByType[entityType]();
        if (sharedByEmail) {
          alertStore.successAlert(t('share.sendToEmail'));
          setEmail('');
        } else {
          const copyByTypeUrl: { [key in SharedEntityType]: string } = {
            [SharedEntityType.SAVED_SEARCH]: `${window.location.href}/share/${
              searchType === SearchType.PROJECT ? 'project' : 'company'
            }/${shareStore.shareTokens[entityType]}`,
            [SharedEntityType.SINGLE_PERMIT]: `${window.location.origin}/search-projects/${location?.state.stateCode}/${location?.county.canonicalTitle}/share/${shareStore.shareTokens[entityType]}/${isShowWithId}`,
            [SharedEntityType.FAVORITE_LIST]: `${window.location.origin}/favorites/share/${shareStore.shareTokens[entityType]}`
          };
          navigator.clipboard
            .writeText(copyByTypeUrl[entityType])
            .then(() => alertStore.successAlert(t('share.copied')))
            .catch(() => alertStore.errorAlert('share.errorCopy'));
        }
      } catch (e) {
        console.log('Share error: ', e);
      } finally {
        setLoadToken(false);
      }
    }
  };

  return (
    <Modal
      show={!!isShowWithId}
      title="Share"
      deleteBtn={false}
      hideFooter={true}
      closeModal={() => closeModal()}>
      <div className="flex items-end">
        <Input
          type="email"
          value={email}
          onInput={onStrChange}
          name=""
          label="Share with people"
          placeholder="Email"
        />
        <Button
          type="primary"
          className="h-10 px-4 ml-2 font-kraftig"
          onClick={() => createShareToken(ShareType.EMAIL)}
          disabled={loadToken || !email.length}>
          {loadToken && (
            <div className="flex items-center">
              <div className="loader p-0">
                <div />
              </div>
            </div>
          )}
          {!loadToken && 'Share'}
        </Button>
      </div>
      <div className="py-5">
        <div className={clsx('divider', 'relative')}>
          <span className="or-use text-center mb-4 absolute">
            <b className="px-3 font-kraftig">{t('components.modal.or')}</b>
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center font-kraftig">
        <Button
          type="primary"
          className={clsx(classes.shareBtn, 'px-4')}
          onClick={() => createShareToken(ShareType.lINK)}
          disabled={loadToken}>
          {loadToken && (
            <div className="flex items-center">
              <div className="loader p-0">
                <div />
              </div>
            </div>
          )}
          {!loadToken && 'Copy link'}
        </Button>
      </div>
    </Modal>
  );
};

export default ShareModal;
