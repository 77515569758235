import React, { FC } from 'react';

const Error404: FC = () => {
  return (
    <div className="h-full flex justify-center items-center">
      <h1 className="text-5xl">Error 404</h1>
    </div>
  );
};

export default Error404;
