import {
  AUTH_REPO,
  IAuthRepo,
  IAuthService,
  IConfirmData,
  ILoginData,
  IOpenIdLogin,
  IRegisterData,
  IRegisterReducedData,
  IRegisterResponse,
  IResetPasswordData
} from './types';
import { injector } from 'utils/injector';

export class AuthService implements IAuthService {
  private _authRepo: IAuthRepo = injector.get<IAuthRepo>(AUTH_REPO);

  async signIn(data: ILoginData): Promise<string> {
    return (await this._authRepo.signIn(data)).data.token;
  }
  async signUp({
    firstName,
    lastName,
    email,
    password,
    socialUserToken
  }: IRegisterData): Promise<IRegisterResponse> {
    const reducedData: IRegisterReducedData = {
      firstName,
      lastName,
      email,
      password,
      socialUserToken
    };
    return await this._authRepo.signUp(reducedData);
  }
  async openIdLogin(token: string, provider: string): Promise<IOpenIdLogin> {
    return (await this._authRepo.openIdLogin(token, provider)).data;
  }
  async confirmAccount(data: IConfirmData): Promise<void> {
    return this._authRepo.confirmAccount(data);
  }

  async sendResetCode(email: string): Promise<boolean> {
    return (await this._authRepo.sendResetCode(email)).data.result;
  }

  async resetPassword(data: IResetPasswordData): Promise<boolean> {
    return (await this._authRepo.resetPassword(data)).data.result;
  }
}
