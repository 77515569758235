import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import classes from '../Auth.module.scss';
import { Button, Select, AnimatedInput } from 'components';
import { clsx } from 'utils/clsx';
import { IAxiosError } from 'utils/types';
import { IRegisterData, IValidationErrorResponse, PreRegistrationUserData } from '../types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SignupSchema } from 'utils/Formik/validation';
import AuthFooter from './AuthFooter';
import Checkbox from 'components/Checkbox';
import { configToOption } from './helpers/configToOption';
import { IOption } from 'components/Select/types';
import { observer } from 'mobx-react';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-contacts.svg';

export const SignUp: FC = observer(() => {
  const { t } = useTranslation();
  const { authStore, alertStore, configStore, userStore } = useStore();
  const [params] = useSearchParams();
  const { navigateAfterLogin } = useNavigateAfterLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateState = location.state as PreRegistrationUserData;
  const isExtended = !!params.get('extended');

  const signUp = useCallback(async (values: IRegisterData) => {
    try {
      setIsLoading(true);
      await authStore.signUp(values);
      await authStore.signIn(values);
      await userStore.getCurrentUser();
      alertStore.successAlert(t('auth.successRegister'));
      navigateAfterLogin();
    } catch (e) {
      const response: IValidationErrorResponse = (e as IAxiosError<IValidationErrorResponse>)
        .response.data;
      if (response.validation) {
        formik.setErrors(response.validation);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const formik = useFormik<IRegisterData>({
    initialValues: {
      firstName: navigateState?.fullName.split(' ')[0] || '',
      lastName: navigateState?.fullName.split(' ')[1] || '',
      email: navigateState?.email || '',
      password: '',
      type: '',
      agree: false,
      socialUserToken: navigateState?.token || ''
    },
    onSubmit: signUp,
    validationSchema: SignupSchema
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const onCheckboxChange = useCallback((id: number, checked: boolean): void => {
    formik.setFieldTouched('agree');
    formik.handleChange({ target: { value: !checked, name: 'agree' } });
  }, []);

  const onSelectChange = useCallback(({ text }: IOption, name: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value: text, name } });
  }, []);

  const selectOptions = useMemo<IOption[]>(
    () => configToOption(configStore.enums?.propertyClasses),
    []
  );

  const selectedType = useMemo<IOption | null>(() => {
    return selectOptions.find((option) => option.text === formik.values.type) || null;
  }, [formik.values]);

  useEffect(() => {
    if (userStore.user) {
      navigate(location.pathname, { replace: true });
    }
  }, [userStore.user]);

  useEffect(() => {
    if (navigateState?.token) {
      formik.setValues({
        firstName: navigateState.fullName.split(' ')[0],
        lastName: navigateState.fullName.split(' ')[1],
        email: navigateState.email || '',
        password: '',
        type: '',
        agree: false,
        socialUserToken: navigateState.token
      });
    }
  }, [navigateState]);

  return (
    <div className={clsx(isExtended && 'flex', isExtended && 'items-stretch')}>
      <form
        action="#"
        className={clsx(
          'rounded-sm shadow-form bg-white py-7.5',
          isExtended ? 'w-[536px]' : 'w-full'
        )}
        onSubmit={formik.handleSubmit}>
        <div>
          <div className="flex items-center flex-col">
            <h1 className="text-dark text-2xl m-0 mb-4 pb-2">{t('auth.signUpTitle')}</h1>
            <div className="w-full px-10">
              <div className="mb-4 flex justify-between">
                <div className="grow mr-3">
                  <AnimatedInput
                    type="text"
                    name="firstName"
                    value={formik.values.firstName}
                    onInput={onInputChange}
                    isError={!!formik.touched.firstName && !!formik.errors.firstName?.length}
                    isSuccess={!formik.errors.firstName && formik.values.firstName.length > 0}
                    errorText={formik.errors.firstName}
                    dataTestElement="register-name-input">
                    <span className="flex items-center">
                      <div className="mr-2 w-4">
                        <UserIcon className="-ml-[2.5px]" />
                      </div>
                      {t('form.firstName')}
                    </span>
                  </AnimatedInput>
                </div>
                <div className="grow">
                  <AnimatedInput
                    type="text"
                    name="lastName"
                    value={formik.values.lastName}
                    onInput={onInputChange}
                    isError={!!formik.touched.lastName && !!formik.errors.lastName?.length}
                    isSuccess={!formik.errors.lastName && formik.values.lastName.length > 0}
                    errorText={formik.errors.lastName}
                    dataTestElement="register-name-input">
                    <span className="flex items-center">
                      <div className="mr-2 w-4">
                        <UserIcon className="-ml-[2.5px]" />
                      </div>
                      {t('form.lastName')}
                    </span>
                  </AnimatedInput>
                </div>
              </div>
              <div className="mb-4">
                <AnimatedInput
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onInput={onInputChange}
                  isError={!!formik.touched.email && !!formik.errors.email?.length}
                  isSuccess={!formik.errors.email && formik.values.email.length > 0}
                  errorText={formik.errors.email}
                  dataTestElement="register-email-input">
                  <span className="flex items-center">
                    <div className="mr-2 w-4">
                      <EmailIcon className="-ml-[2.5px]" />
                    </div>
                    {t('form.emailAddress')}
                  </span>
                </AnimatedInput>
              </div>
              <div className="mb-4">
                <AnimatedInput
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onInput={onInputChange}
                  isError={!!formik.touched.password && !!formik.errors.password?.length}
                  isSuccess={!formik.errors.password && formik.values.password.length > 0}
                  errorText={formik.errors.password}
                  dataTestElement="register-password-input">
                  <span className="flex items-center">
                    <div className="mr-2 w-4">
                      <KeyIcon />
                    </div>
                    {t('form.password')}
                  </span>
                </AnimatedInput>
              </div>
              <div className="mb-4 relative">
                <Select
                  placeholder="What types of projects are you interested in?"
                  placeholderClassName="pr-0 text-grey-500 text-sm"
                  className="text-grey-500 shadow-none h-12 px-3"
                  options={selectOptions}
                  value={selectedType}
                  onChange={onSelectChange}
                  name="type"
                />
                {!!formik.touched.type && formik.errors.type && (
                  <div className="bg-white text-red-100 text-xs translate-y-[2px] top-full w-full pt-1">
                    {formik.errors.type}
                  </div>
                )}
              </div>

              <div className="mb-4 pb-3">
                <div className="flex items-center">
                  <Checkbox id={1} checked={formik.values.agree} onChange={onCheckboxChange}>
                    <div className={classes.checkboxText}>
                      <span>
                        {t('auth.agree')}&nbsp;
                        <span className={clsx(classes.link, classes.privacy)}>
                          {t('auth.terms')}
                        </span>
                        &nbsp;
                        {t('auth.and')}&nbsp;
                        <span className={clsx(classes.link, classes.privacy)}>
                          {t('auth.privacy')}
                        </span>
                      </span>
                    </div>
                  </Checkbox>
                </div>
                {!!formik.touched.agree && formik.errors.agree && (
                  <div className="bg-white text-red-100 text-xs translate-y-[2px] top-full right-0 w-full pt-1">
                    {formik.errors.agree}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full px-10">
              <Button
                type="primary"
                className="w-full font-kraftig px-2 py-[11px]"
                isLoading={isLoading}
                data-test-element="register-submit-button">
                {t('auth.signUp')}
              </Button>
            </div>
          </div>
          <AuthFooter step="sign-up" />
        </div>
      </form>
      {isExtended && (
        <div className={clsx(classes.extendedWrapper, 'relative')}>
          <div className="relative z-1">
            <h1 className={clsx('text-center', 'text-white', 'mb-5', classes.extendedTitle)}>
              {t('auth.extendedRegister')}
            </h1>
            <ul className={clsx('ms-auto', classes.extendedList)}>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.unlockMarket')}</li>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.getInfo')}</li>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.winBetter')}</li>
              <li className={clsx('text-white')}>{t('auth.research')}</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
});
