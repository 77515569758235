import { INote, INoteService, INoteStore, NOTE_SERVICE } from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { FavoriteTabSystemType } from '../Favorites/types';

export class NoteStore implements INoteStore {
  private _noteService: INoteService = injector.get<INoteService>(NOTE_SERVICE);

  constructor() {
    makeAutoObservable<NoteStore>(this);
  }

  async getNote(projectId: number, type: FavoriteTabSystemType): Promise<INote | null> {
    return await this._noteService.getNote(projectId, type);
  }

  async addNote(projectId: number, type: FavoriteTabSystemType, content: string): Promise<void> {
    await this._noteService.addNote(projectId, type, content);
  }
}
