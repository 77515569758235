import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import CompanyTable from './components/Table';
import Header from 'view/Header';
import SubHeader from './components/Table/SubHeader';
import { useStore } from 'storesProvider/storeContext';
import { setLocal, getLocal } from 'utils/localStorageOperation';
import { FoundLocation } from 'view/Search/types';
import ContactInfo from 'modules/ContactInfoModal';
import { useFiltersActions } from 'hooks/useFiltersActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination } from 'components';
import { AllEntityTypes, ClickEvent, SearchType } from 'utils/types';
import { useTranslation } from 'react-i18next';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { ISearchParams } from 'modules/Filter/types';
import { COMPANY_LOCATIONS, COMPANY_SEARCH_FILTER } from 'utils/constants';
import { ShortLocationForPayment } from 'view/SubscriptionsAndPlans/types';
import { CompanyData } from './types';
import { orderProcessing } from 'helpers/orderProcessing';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';
import ProjectInfoModal from 'modules/ProjectInfoModal/Modal';
import { runInAction } from 'mobx';
// import { usePrevious } from 'hooks/usePrevious';

const CompanySearch: FC = observer(() => {
  const {
    companySearchStore,
    filtersStore,
    searchStore,
    userStore,
    alertStore,
    savedSearchesStore
  } = useStore();
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  // const prevSelectedLocations = usePrevious<FoundLocation[] | null>(
  //   searchStore.selectedLocationsInSearchList
  // );
  const { setFilters, removeFilters } = useFiltersActions();
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(false);
  const {
    progress: companiesProgress,
    visible: companiesVisible,
    reload: companiesReloadProgress
  } = useProgress(isCompaniesLoading);
  // const [locationIsDone, setLocationIsDone] = useState<boolean>(false);
  const controller = new AbortController();

  const details = params.get('details');
  const contacts = params.get('contacts');

  useEffect(() => {
    const localLocations = getLocal<FoundLocation[]>(COMPANY_LOCATIONS);
    const localFilters = getLocal<ISearchParams>(COMPANY_SEARCH_FILTER);
    if (localFilters) {
      runInAction(() => {
        setFilters(localFilters);
      });
    }
    if (localLocations && localLocations.length) {
      searchStore.setSelectedLocation(localLocations);
      searchStore.setLocationForMap(localLocations);
    }
    if (!localLocations || !localLocations.length) {
      (async () => {
        await searchStore.lookupLocation([], []);
        // setLocationIsDone(true);
      })();
    }
    return () => {
      searchStore.setSelectedLocation([]);
      searchStore.setLocationForMap([]);
      removeFilters();
    };
  }, []);

  useEffect(() => {
    // if (!locationIsDone) return;
    if (!searchStore.selectedLocationsInSearchList.length) return;
    if (searchStore.selectedLocationsInSearchList.length) {
      filtersStore.setSearchParams(
        'locations',
        searchStore.selectedLocationsInSearchList.map((location) => location.id)
      );
      setLocal(COMPANY_LOCATIONS, searchStore.selectedLocationsInSearchList);
    }
    searchStore.setLocationForMap(searchStore.selectedLocationsInSearchList);
    // if (prevSelectedLocations?.length && !searchStore.selectedLocationsInSearchList.length) {
    //   navigate('/saved-search');
    // }
  }, [searchStore.selectedLocationsInSearchList]);

  useEffect(() => {
    (async () => await getCompanies(controller))();
    setLocal(COMPANY_SEARCH_FILTER, filtersStore.searchParams);
    return () => {
      controller.abort();
    };
  }, [filtersStore.searchParams]);

  const getCompanies = useCallback(
    async (controller?: AbortController) => {
      setIsCompaniesLoading(true);
      await companySearchStore.getCompaniesListView(filtersStore.searchParams, controller);
      setIsCompaniesLoading(false);
    },
    [filtersStore.searchParams]
  );

  const handleOrder = useCallback(
    (field: string): void => {
      if (filtersStore.searchParams.order) {
        companiesReloadProgress();
        filtersStore.setSearchParams(
          'order',
          orderProcessing(field, filtersStore.searchParams.order)
        );
      }
    },
    [companiesReloadProgress, filtersStore.searchParams]
  );

  const openContacts = (data: CompanyData) => {
    setParams(`contacts=${data.id}&type=${AllEntityTypes.Company}`);
  };

  const handleUpdateFavorite = (id: number, isActive: boolean) => {
    companySearchStore.updateFavorite(id, isActive);
  };

  const handlePageClick = useCallback(
    (event: ClickEvent) => {
      filtersStore.setSearchParams('page', event.selected + 1);
    },
    [filtersStore.searchParams]
  );

  const handleSaveSearch = useCallback(
    async (name: string) => {
      if (!userStore.user) return;
      const params = { ...filtersStore.searchParams };
      delete params.area;
      const result = await savedSearchesStore.saveSearch(
        userStore.user.id,
        name,
        params,
        SearchType.COMPANY
      );
      alertStore.successAlert(
        <>
          <span className="font-kraftig">{result.name}</span> {t('searchProject.listCreated')}{' '}
          <span className="font-kraftig">{result.description}</span> {t('searchProject.savedTo')}{' '}
          <Link to={`/saved-search/${result.id}`} className="font-kraftig !text-primary">
            {result.name}
          </Link>
          .
        </>
      );
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    },
    [filtersStore.searchParams]
  );

  const showCurrentLocations = useMemo((): string => {
    const locationStrings = searchStore.locationForMap.map((location) => {
      if (location.title.split(',')[1]) return location.title;
      return `${location.title}, ${location.stateCode}`;
    });
    return locationStrings.join(' | ');
  }, [searchStore.locationForMap]);

  const getUnsubscribedLocations = useCallback((): ShortLocationForPayment[] | null => {
    // TODO: update this method after fix select unsubscribed location in search list
    if (
      !companySearchStore.companies?.locations.length &&
      searchStore.selectedLocationsInSearchList.length === 1
    ) {
      return searchStore.selectedLocationsInSearchList.map((location) => ({
        id: location.id,
        title: location.title
      }));
    }
    return (
      companySearchStore.companies?.locations
        .filter((location) => !location.hasSubscription)
        .map((unsubscribedLocations) => ({
          id: unsubscribedLocations.id,
          title: unsubscribedLocations.title
        })) || null
    );
  }, [companySearchStore.companies, searchStore.selectedLocationsInSearchList]);

  return (
    <div>
      <Header>
        <SubHeader onSaveSearch={handleSaveSearch} />
      </Header>

      <div className="mx-6">
        <div className="mt-6 mb-7">
          <span className="font-halbfett text-xl text-dark block select-text w-80 overflow-hidden text-ellipsis whitespace-nowrap">
            {showCurrentLocations}
          </span>
        </div>
        {isCompaniesLoading && <ProgressBar progress={companiesProgress} thin />}
        {companySearchStore.companies && !!companySearchStore.companies.data.length && (
          <div>
            <CompanyTable
              data={companySearchStore.companies}
              onUpdateFavorite={handleUpdateFavorite}
              onDetails={openContacts}
              orders={filtersStore.searchParams.order!}
              orderAction={handleOrder}
              hasSubscription={companySearchStore.companies.hasSubscription}
              onHandleUnlock={() => setShowPlan(true)}
            />
            <div className="flex">
              <Pagination
                onPageClick={handlePageClick}
                currentPage={companySearchStore.companies.currentPage}
                totalPages={companySearchStore.companies.totalPages}
              />
            </div>
          </div>
        )}
        {!companySearchStore.companies?.data.length && (
          <div className="p-4 bg-white">
            <h4 className="text-center text-dark">{t('companySearch.noResults')}</h4>
          </div>
        )}
      </div>

      <ChoosePlanModal
        show={showPlan}
        locations={getUnsubscribedLocations()}
        closeModal={() => setShowPlan(false)}
        onUpdate={getCompanies}
      />

      {contacts && <ContactInfo onUpdateFavorite={handleUpdateFavorite} />}
      {details && <ProjectInfoModal onHandleUnlock={() => setShowPlan(true)} />}
    </div>
  );
});

export default CompanySearch;
