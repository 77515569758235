import React, { FC } from 'react';
import arrowIcon from 'assets/icons/arrow.svg';
import { Button } from 'components';
import exportIcon from 'assets/icons/export.svg';
import { useNavigate } from 'react-router-dom';
import { numberWithSpaces } from 'helpers/numberWithComma';

interface Props {
  totalValue: number;
  onExport: () => void;
}

const SubHeader: FC<Props> = ({ totalValue, onExport }) => {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  return (
    <div>
      <div className="py-1 w-full flex justify-between items-center">
        <div className="flex text-dark text-lg">
          <img role="button" src={arrowIcon} alt="" className="-rotate-90 mr-2" onClick={back} />
          <span className="font-kraftig">Project Favorites Analytics</span>
        </div>
        <Button type="secondary" onClick={onExport} className="h-8 px-3 py-1 flex items-center">
          <span className="text-dark text-sm font-kraftig">Export</span>
          <img src={exportIcon} alt="" className="ml-2 -translate-y-px" />
        </Button>
      </div>
    </div>
  );
};

export default SubHeader;
