import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'This value is too short.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  lastName: Yup.string()
    .min(3, 'This value is too short.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  email: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required'),
  password: Yup.string()
    .min(6, 'It should have 6 characters or more.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  type: Yup.string().required('This field is Required'),
  agree: Yup.boolean().isTrue('Please accept the terms of service and privacy policy')
});

export const PreRegisterSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, 'This value is too short.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  email: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required')
});

export const ResetSchema = Yup.object().shape({
  email: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required')
});

export const ResetConfirmSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'It should have 8 characters or more.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  repeat: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match")
});

export const EditEmailSchema = Yup.object().shape({
  current: Yup.string()
    .min(8, 'It should have 8 characters or more.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  new: Yup.string()
    .min(8, 'It should have 8 characters or more.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  confirm: Yup.string().oneOf([Yup.ref('new'), null], "New password doesn't match")
});
const phoneRegExp =
  /^(\+\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'This value is too short.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  lastName: Yup.string()
    .min(3, 'This value is too short.')
    .max(50, 'This value is too long')
    .required('This field is Required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required'),
  password: Yup.string()
    .min(6, 'It should have 6 characters or more.')
    .max(50, 'This value is too long')
    .required('This field is Required')
});

export const ChangePasswordSchema = Yup.object().shape({
  current: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required'),
  new: Yup.string()
    .email('This value is not a valid email address.')
    .required('This field is Required')
});
