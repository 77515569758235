import { INotificationRepo, INotification } from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class NotificationRepo implements INotificationRepo {
  async getNotifications(): Promise<IAxiosResponse<INotification[]>> {
    return await axiosInstance.get(`/notifications`);
  }

  async markAsRead(): Promise<void> {
    return await axiosInstance.post(`/notifications/read`);
  }
  async clearNotifications(): Promise<void> {
    return await axiosInstance.post(`/notifications/clear`);
  }
}
