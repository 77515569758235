import { ERROR_COLOR, GREY_COLOR, TEXT_COLOR } from 'utils/constants';

export const options = {
  style: {
    base: {
      color: TEXT_COLOR,
      '::placeholder': {
        color: GREY_COLOR
      }
    },
    invalid: {
      color: ERROR_COLOR
    }
  }
};
