import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { IColumn, IRow, ICell, IAction, ICellContact } from './types';
import { ColumnHeader } from './components/ColumnHeader';
import { Cell } from './components/Cell';
import { iconActiveList, iconList } from 'utils/icons';
import { clsx } from 'utils/clsx';
import classes from './Table.module.scss';
import { DOCUMENT, DOCUMENT_GREY, FAVORITE } from 'utils/constants';
import { ISort } from 'utils/types';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

interface Props {
  columns: IColumn[];
  rows: IRow[];
  orders?: ISort[];
  actions?: IAction[];
  orderAction?: (field: string) => void;
  onScroll?: () => void;
  children?: JSX.Element;
  tableClass?: string;
  cellClass?: string;
  onTitleClick?: (id: number) => void;
  hasSubscription?: boolean;
  onHandleUnlock?: () => void;
  page?: number;
  onContact?: (contactId: number, projectId: number) => void;
  onTitleContact?: (id: number) => void;
}

const Table: FC<Props> = ({
  columns,
  rows,
  orders,
  actions,
  orderAction,
  onScroll,
  children,
  tableClass,
  cellClass,
  onTitleClick,
  onTitleContact,
  hasSubscription = true,
  onHandleUnlock,
  page,
  onContact
}) => {
  const tableRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    tableRef.current.scrollTo({ top: 0 });
  }, [page]);

  const getIcon = useCallback((icon: string, row: IRow): string => {
    if (icon === FAVORITE && row.favorite) {
      return iconActiveList[icon];
    }
    return iconList[icon];
  }, []);

  return (
    <div className="relative">
      <div
        className={clsx(tableClass, 'overflow-auto', classes.wrapperTable)}
        onScroll={onScroll}
        ref={tableRef}>
        <table className="table table-borderless mb-0 bg-white rounded-sm text-dark shadow-table w-full">
          <thead className="sticky top-0 shadow-tableHead z-[1]">
            <tr>
              {columns.map((column: IColumn, i: number) => (
                <th
                  scope="col"
                  key={`table-column-${i}`}
                  className={clsx(
                    classes.tableHeadCell,
                    'h-10',
                    'bg-white',
                    'text-sm',
                    'px-2',
                    'font-normal',
                    'first:pl-6 first:text-left',
                    'last:pr-6 last:text-right'
                  )}>
                  <ColumnHeader column={column} orders={orders} sortAction={orderAction} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: IRow, idx: number) => (
              <tr key={`table-row-${row.id}`} className="border-t border-light-400 relative">
                {row.fields.map((fieldItem: ICell, index: number) => (
                  <td key={`cell-${index}`} className={`${classes.cell} py-6 px-2 align-top`}>
                    {!index && row.newItem && (
                      <div className="rounded-full absolute new-notification"></div>
                    )}
                    <Cell
                      data={fieldItem}
                      cellClass={cellClass}
                      onTitleClick={
                        fieldItem.isTitle && onTitleClick
                          ? () => onTitleClick(Number(row.id))
                          : null
                      }
                      onTitleContact={() => onTitleContact && onTitleContact(Number(row.id))}
                      hasSubscription={hasSubscription}
                      onHandleUnlock={onHandleUnlock}
                      onContact={(id) => onContact && row.id && onContact(id, Number(row.id))}
                    />
                  </td>
                ))}
                {actions && !!actions.length ? (
                  <td className="min-w-max py-4 flex justify-end mr-6">
                    {actions.map((action: IAction, index: number) => (
                      <span
                        className={clsx('relative', classes.actionButton)}
                        key={`action-icon-${index}`}>
                        <img
                          src={getIcon(action.icon, row)}
                          id={`table-action-${action.icon}-${row.id}`}
                          alt=""
                          className={'relative'}
                          onClick={() => action.callback(Number(row.id))}
                          role="button"
                          data-test-element="favorite-icon"
                        />
                        {(action.icon === DOCUMENT || action.icon === DOCUMENT_GREY) &&
                          row.hasNote && (
                            <span className="absolute -top-1 left-0 rounded-full -translate-x-2/4 w-2 h-2 bg-primary" />
                          )}
                      </span>
                    ))}
                  </td>
                ) : (
                  <td></td>
                )}
                {!hasSubscription && idx > 2 && onHandleUnlock && (
                  <td
                    role="button"
                    onClick={onHandleUnlock}
                    className={clsx(
                      'absolute',
                      'top-0',
                      'start-0',
                      'bottom-0',
                      'end-0',
                      'flex',
                      'justify-center',
                      'items-center',
                      classes.lockedWrapper
                    )}>
                    <div className={classes.lockIcon}>
                      <LockIcon className="w-full h-auto" />
                    </div>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={columns.length}>{children}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*<div className={classes.shadow}></div>*/}
    </div>
  );
};

export default Table;
