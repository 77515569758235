import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ReactComponent as FavoritesIcon } from 'assets/icons/sidebar-favorites.svg';
import { ReactComponent as SavedSearchIcon } from 'assets/icons/sidebar-saved-search.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/sidebar-search.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/sidebar-search-companies.svg';
import { ReactComponent as Arrow } from 'assets/icons/dropdown-arrow.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as ImportHistoryIcon } from 'assets/icons/import-history.svg';
import { clsx } from 'utils/clsx';
import classes from './Sidebar.module.scss';
import Logo from 'assets/icons/logo-header.svg';
import { Link, useLocation } from 'react-router-dom';
import useIsAuthorization from 'hooks/useIsAuthorization';
import moment from 'moment';

const Sidebar: FC = () => {
  const location = useLocation();
  const researchPaths = ['/search-projects', '/saved-search', '/favorites', '/company-search'];
  const leadIntelligencePaths = ['/new-import', '/import-history'];
  const isAuthorization = useIsAuthorization();
  const refs = useRef<[() => void] | []>([]);
  const toggleRefs = useRef<HTMLButtonElement[]>([]);

  const navigation = useMemo(
    () => [
      {
        name: 'Research',
        current: researchPaths.some((path) => location.pathname.includes(path)),
        children: [
          {
            name: 'Search Projects',
            icon: SearchIcon,
            path: '/search-projects',
            current: location.pathname.includes(researchPaths[0])
          },
          {
            name: 'Search Companies',
            icon: CompanyIcon,
            path: isAuthorization ? '/company-search' : '?auth=sign-up&extended=true',
            current: location.pathname.includes(researchPaths[3])
          },
          {
            name: 'Saved Searches',
            icon: SavedSearchIcon,
            path: isAuthorization ? '/saved-search' : '?auth=sign-up&extended=true',
            current: location.pathname.includes(researchPaths[1])
          },
          {
            name: 'Favorites',
            icon: FavoritesIcon,
            path: isAuthorization ? '/favorites/all/project' : '?auth=sign-up&extended=true',
            current: location.pathname.includes(researchPaths[2])
          }
        ]
      },
      {
        name: 'Lead Intelligence',
        current: leadIntelligencePaths.some((path) => location.pathname.includes(path)),
        children: [
          {
            name: 'New Import',
            icon: UploadIcon,
            path: '/new-import',
            current: location.pathname.includes(leadIntelligencePaths[0])
          },
          {
            name: 'Import History',
            icon: ImportHistoryIcon,
            path: '/import-history',
            current: location.pathname.includes(leadIntelligencePaths[1])
          }
        ]
      }
    ],
    [location.pathname, isAuthorization]
  );

  const handleClick = (index: number) => {
    refs.current.map((closeFunction, refIndex) => {
      if (refIndex !== index) {
        closeFunction();
      }
    });
  };

  useEffect(() => {
    // toggle lists when navigate
    navigation.forEach((nav, idx) => {
      if (!nav.current && refs.current[idx]) refs.current[idx]();
      if (nav.current && toggleRefs.current[idx]) toggleRefs.current[idx].click();
    });
    const activeIdx = navigation.findIndex((nav) => nav.current);
    toggleRefs.current[activeIdx]?.parentElement?.focus();
  }, [location.pathname]);

  return (
    <div className="flex-[0_0_200px] bg-shadesBlue-300">
      <div className="flex h-16 items-center justify-center">
        <Link
          className="focus:outline-0"
          to={isAuthorization ? '/saved-search' : '/'}
          tabIndex={-1}>
          <img src={Logo} alt="close" className="relative" role="button" />
        </Link>
      </div>
      <nav className="flex flex-1 flex-col font-halbfett">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className=" space-y-1">
              {navigation.map((item, index) => (
                <li key={item.name}>
                  {!item.children ? (
                    <span
                      className={clsx(
                        item.current ? 'bg-black' : 'hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-white'
                      )}>
                      {item.name}
                    </span>
                  ) : (
                    <Disclosure as="div" defaultOpen={item.current} key={item.name}>
                      {({ open, close }) => (
                        <>
                          <Disclosure.Button
                            className={clsx(
                              item.current
                                ? 'text-white bg-black bg-opacity-20'
                                : 'text-shadesBlue-200 hover:bg-gray-50',
                              'flex items-center w-full text-left px-4 gap-x-3 text-sm py-6 leading-6 uppercase !outline-0 focus:text-white'
                            )}>
                            {item.name}
                            <button
                              onClick={() => close()}
                              className="hidden"
                              ref={(el) => {
                                if (!el) return;
                                toggleRefs.current[index] = el;
                              }}>
                              close
                            </button>
                            <Arrow
                              className={clsx(
                                !open ? classes.arrowInactive : `${classes.arrowActive} rotate-180`,
                                'ml-auto h-5 w-5 shrink-0 duration-200'
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-250 ease-in-out"
                            enterFrom="transform -translate-y-4 opacity-0"
                            enterTo="transform translate-y-0 opacity-100"
                            leave="transition duration-50 ease-out"
                            leaveFrom="transform translate-y-0 opacity-100"
                            leaveTo="transform -translate-y-4 opacity-0">
                            <Disclosure.Panel
                              as="ul"
                              className="m-0 p-0 pb-4 bg-black bg-opacity-20">
                              {item.children.map((subItem) => (
                                <li
                                  key={subItem.name}
                                  className={clsx(
                                    subItem.current && 'bg-[#0085F3]',
                                    '!outline-none'
                                  )}>
                                  <Link
                                    ref={() => {
                                      refs.current[index] = close;
                                    }}
                                    onClick={() => handleClick(index)}
                                    to={subItem.path}
                                    className={clsx(
                                      subItem.current && '!text-white',
                                      'group flex py-4 px-4 text-sm leading-6 text-white relative !outline-0 font-inter focus:text-[#0085F3] hover:text-[#0085F3]'
                                    )}>
                                    <subItem.icon
                                      className={clsx(
                                        'stroke-white',
                                        !subItem.current && 'group-hover:sidebar-active-icon',
                                        'h-6 w-6 shrink-0 mr-3'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {subItem.name}
                                  </Link>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
