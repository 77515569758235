import { useStore } from 'storesProvider/storeContext';
import { ITreeOption, TreeOptionType } from '../view/SearchProjects/components/SubHeader/types';
import { ConfigType } from 'store/ConfigStore/types';

export const mapPropertyTypesToOptions = (
  activeClasses: number[] = [],
  activeTypes: number[] = [],
  activeSubtypes: number[] = []
): ITreeOption[] => {
  const { configStore } = useStore();
  return configStore.enums
    ? configStore.enums.propertyClasses.sort(alfSort).map((propClass) => ({
        label: propClass.name,
        value: propClass.id,
        type: TreeOptionType.Class,
        checked: activeClasses.includes(propClass.id),
        children: configStore.enums
          ? configStore.enums.propertyTypes
              .filter((propType) => propType.classId === propClass.id)
              .sort(alfSort)
              .map((propType) => ({
                label:
                  propType.name.toLowerCase() === 'other'
                    ? `${propType.name} (${propClass.name} class)`
                    : propType.name,
                value: propType.id,
                type: TreeOptionType.Type,
                checked: activeTypes.includes(propType.id),
                children: configStore.enums
                  ? configStore.enums.propertySubTypes
                      .filter((subType) => subType.typeId === propType.id)
                      .sort(alfSort)
                      .map((subType) => ({
                        label:
                          subType.name.toLowerCase() === 'other'
                            ? `${subType.name} (${propType.name} type)`
                            : subType.name,
                        value: subType.id,
                        checked: activeSubtypes.includes(subType.id),
                        type: TreeOptionType.Subtype
                      }))
                  : []
              }))
          : []
      }))
    : [];
};

export const mapPermitTypesToOptions = (activeTypes: number[] = []): ITreeOption[] => {
  const { configStore } = useStore();
  return configStore.enums
    ? configStore.enums.buildingPermitTypes.map((permitType) => ({
        label: permitType.name,
        value: permitType.id,
        type: TreeOptionType.PermitType,
        checked: activeTypes.includes(permitType.id)
      }))
    : [];
};

const alfSort = (a: ConfigType, b: ConfigType) => {
  if (a.name.toLowerCase().includes('other')) return 1;
  if (b.name.toLowerCase().includes('other')) return -1;
  return a.name.localeCompare(b.name);
};
