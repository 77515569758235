import { FoundLocation } from 'view/Search/types';

export const getLocationParamsFromSelectedLocation = (
  selectedLocations: FoundLocation[]
): string => {
  const stateCodes = String(selectedLocations.map((location: FoundLocation) => location.stateCode));
  const canonicalTitles = String(
    selectedLocations.map((location: FoundLocation) => location.canonicalTitle)
  );
  return `${stateCodes}/${canonicalTitles}`;
};
