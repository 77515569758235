import { IProjectListResponse, IProjectsData, IRecentProjectsData } from './types';
import { IRow } from 'components/Table/types';
import { AllRoles, EntityType, IContact, PersonDetail } from '../../utils/types';

export const initProjectsData: IProjectsData = {
  currentPage: 0,
  data: [],
  limit: 0,
  hasMore: false,
  hasSubscription: false,
  showCount: 0,
  showTotal: 0,
  value: 0,
  totalPages: 0,
  emptyCounties: []
};

export const initProjectsListData: IProjectListResponse<IRow[]> = {
  currentPage: 1,
  totalPages: 1,
  limit: 20,
  hasMore: false,
  hasSubscription: false,
  showCount: 0,
  showTotal: 0,
  value: 0,
  data: [],
  dataTable: [],
  emptyCounties: []
};

export const initRecentProjectsData: IRecentProjectsData = {
  count: 0,
  currentPage: 0,
  data: [],
  limit: 0,
  totalPages: 0,
  value: 0,
  hasSubscription: false
};

export const mockUsers: IContact<PersonDetail>[] = [
  {
    id: 1,
    type: 10,
    name: 'Mock User 1',
    details: [],
    role: 10,
    favourite: false
  },
  {
    id: 2,
    type: 20,
    name: 'Mock User 2',
    details: [],
    role: 20,
    favourite: false
  }
];
