import axios from 'axios';
import { ACCESS_TOKEN } from 'utils/constants';
import { useStore } from 'storesProvider/storeContext';
import { IValidationErrorResponse } from 'view/Auth/types';
import { IAxiosError } from 'utils/types';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { alertStore, authStore, userStore } = useStore();

    if (error.response && error.response.status === 401) {
      if (error.config.url === '/auth/login') {
        alertStore.errorAlert(error.response.data.message);
        throw error;
      }
      authStore.logout();
      userStore.setUser(null);
      authStore.setIsRedirected(true);
      throw error;
    }

    const err = error as IAxiosError<IValidationErrorResponse>;
    if (err.response && err.response.data && err.response.data.validation) {
      Object.values(err.response.data.validation).forEach((item) => {
        alertStore.errorAlert(item);
      });
    } else {
      if (err.response) {
        alertStore.errorAlert(err.response.data.message);
      }
    }
    if (error.name !== 'CanceledError') {
      throw error;
    }
  }
);

export { axiosInstance };
