import {
  IFilterStore,
  ISearchParamValue,
  ISearchParamKey,
  IInputChangeItem,
  ICustomDate,
  ITreeOption
} from './types';
import { makeAutoObservable } from 'mobx';
import { ISearchParams, formInputChangeEventType } from './types';
import { dateOptions, initialSearchParams } from './mock';
import { IOption } from 'components/Select/types';
import { getDate } from 'helpers/getDate';
import { mapPermitTypesToOptions, mapPropertyTypesToOptions } from 'helpers/mapConfigsToOptions';

export class FilterStore implements IFilterStore {
  constructor() {
    makeAutoObservable<FilterStore>(this);
  }

  originalFilters: ISearchParams | null = null;
  searchParams: ISearchParams = initialSearchParams;
  selectedDate: IOption | null = null;
  customDate: ICustomDate | null = null;
  categories: ITreeOption[] = [];
  permitTypes: ITreeOption[] = [];

  setCategories(categories: ITreeOption[]): void {
    this.categories = categories;
  }

  setPermitTypes(permitTypes: ITreeOption[]): void {
    this.permitTypes = permitTypes;
  }

  resetCategories(): void {
    this.categories = mapPropertyTypesToOptions();
  }

  resetPermitTypes(): void {
    this.permitTypes = mapPermitTypesToOptions();
  }

  setAllSearchParams(searchParams: ISearchParams): void {
    this.searchParams = searchParams;
  }

  setSearchParams(name: ISearchParamKey, param: ISearchParamValue): void {
    const prev: ISearchParamValue | undefined = this.searchParams[name];
    if (JSON.stringify(prev) === JSON.stringify(param)) return;
    this.searchParams = { ...this.searchParams, page: 1, [name]: param };
  }

  setCustomDate = (date: ICustomDate | null) => {
    this.customDate = date;
  };

  setSelectedDate(date: IOption | null) {
    this.selectedDate = date;
  }

  resetSearchParams(): void {
    if (
      JSON.stringify(this.searchParams) ===
      JSON.stringify({ ...initialSearchParams, location: this.searchParams.locations })
    ) {
      return;
    }
    this.searchParams = {
      ...initialSearchParams,
      locations: this.searchParams.locations,
      area: this.searchParams.area
    };
  }

  onSelectChange = (option: IOption, field: string) => {
    if (field === 'date') {
      if (option.value === 'custom') return;
      const dayInMs = 1000 * 60 * 60 * 24;
      const now = new Date(Date.now());
      const from = new Date(Date.now() - dayInMs * Number(option.value));
      const dateFrom = getDate(from);
      const dateTo = getDate(now);
      this.setSearchParams(field, { max: dateTo, min: dateFrom });
      this.setSelectedDate(option);
      this.setCustomDate(null);
      return;
    }
    this.setSearchParams(field as ISearchParamKey, option ? [option.id] : []);
  };

  onInputChange = (inputChange: IInputChangeItem, field: string): void => {
    const { min, max } = inputChange;
    this.setSearchParams(field as formInputChangeEventType, { max, min });
  };

  onCustomDateChange = (date: ICustomDate) => {
    const from = new Date(date.from);
    const to = new Date(date.to);
    const dateFrom = getDate(from);
    const dateTo = getDate(to);
    this.setSearchParams('date', { max: dateTo, min: dateFrom });
    this.setCustomDate(date);
    this.setSelectedDate(dateOptions.find((option) => option.value === 'custom') || null);
  };

  onSelectReset = (field: string) => {
    if (field === 'date') {
      this.setSelectedDate(null);
    }
    this.setSearchParams(field as ISearchParamKey, []);
  };

  handleReset = () => {
    this.resetSearchParams();
    this.setSelectedDate(null);
    this.setCustomDate(null);
    this.resetCategories();
    this.resetPermitTypes();
  };

  onCategoryChange(classes: number[], types: number[], subtypes: number[]) {
    this.setSearchParams('classes', classes);
    this.setSearchParams('types', types);
    this.setSearchParams('subTypes', subtypes);
  }

  onPermitsChange(permitTypes: number[]) {
    this.setSearchParams('permitTypes', permitTypes);
  }

  isActiveFilters(): boolean {
    const {
      permitTypes,
      statuses,
      classes,
      types,
      subTypes,
      value,
      levels,
      units,
      date,
      keywords,
      companyName,
      companyAddress,
      companyType
    } = this.searchParams;
    const arrayFilters = [permitTypes, statuses, classes, types, subTypes];
    const objectFilters = [value, levels, units, date];
    let isActive = false;
    arrayFilters.forEach((filter) => {
      if (filter?.length) {
        isActive = true;
      }
    });
    if (!isActive) {
      objectFilters.forEach((filter) => {
        for (const key in filter) {
          const k = key as keyof IInputChangeItem;
          if (filter[k] !== null) {
            isActive = true;
          }
        }
      });
      if (
        (keywords && keywords.length) ||
        (companyName && companyName.length) ||
        (companyAddress && companyAddress.length) ||
        companyType
      ) {
        isActive = true;
      }
    }
    return isActive;
  }

  createOriginalFilters(): void {
    this.originalFilters = JSON.parse(JSON.stringify(this.searchParams));
  }
}
