import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, Modal, Select } from 'components';
import { IModalActions } from 'components/Modal/types';
import { IExtendedOption } from 'components/Select/types';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
interface IProps {
  show: boolean;
  closeModal: () => void;
  id: number | null;
}

const extendedOptions: IExtendedOption[] = [
  {
    category: 'SYSTEM PROJECT LIST',
    options: [
      { value: 'projects', text: 'Projects', id: 1 },
      { value: 'companies', text: 'Companies', id: 2 }
    ],
    selected: 1
  },
  {
    category: 'PERSONAL PROJECT LIST',
    options: [
      { value: 'projects 1', text: 'My Projects 1', id: 1 },
      { value: 'projects 2', text: 'My Projects 2', id: 2 }
    ],
    selected: null
  }
];
const createListOption: IExtendedOption = {
  category: 'PERSONAL PROJECT LIST',
  options: [{ value: '', text: 'Create personal Project list', id: 1 }],
  selected: 1
};
const actions: IModalActions = {
  positive: 'Save',
  negative: 'Cancel'
};

const AddToFavoriteModal: FC<IProps> = ({ show, closeModal, id }) => {
  const { alertStore } = useStore();
  const { t } = useTranslation();

  const [selected, setSelected] = useState<IExtendedOption | null>(extendedOptions[0]);
  const [listName, setListName] = useState<string>('');
  const [isCreatingList, setIsCreatingList] = useState<boolean>(false);

  const onSelectChange = useCallback((selected: IExtendedOption) => {
    setSelected(selected);
    setIsCreatingList(false);
  }, []);
  const onInput = useCallback((name: string, value: string) => setListName(value), []);
  const onCreateList = useCallback(() => {
    setSelected(createListOption);
    setIsCreatingList(true);
  }, []);
  const onClose = useCallback(() => {
    setSelected(extendedOptions[0]);
    setListName('');
    setIsCreatingList(false);
    closeModal();
  }, []);

  const onAddToFavorites = useCallback(() => {
    alertStore.successAlert(t('dashboard.successAddedToFavorite'));
    onClose();
  }, []);

  useEffect(() => {
    if (!id) return;
    console.log('modal add to favorite project - ' + id);
  }, [id]);

  return (
    <Modal
      show={show}
      title="Add to Favorites"
      closeModal={onClose}
      actions={actions}
      deleteBtn={false}
      onSubmit={onAddToFavorites}>
      <Select<IExtendedOption>
        name=""
        options={extendedOptions}
        label="Select List"
        extended={true}
        value={selected}
        onChange={onSelectChange}
        onCreateList={onCreateList}
      />
      <div className={clsx('mt-3', !isCreatingList && 'opacity-0 select-none cursor-default')}>
        <Input
          type="text"
          value={listName}
          onInput={onInput}
          name=""
          placeholder="eg My Project list 1"
          label="Name your personal Project list"
          inputClass={clsx(!isCreatingList && 'opacity-0 select-none cursor-default')}
        />
      </div>
    </Modal>
  );
};

export default AddToFavoriteModal;
