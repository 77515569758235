import { useStore } from 'storesProvider/storeContext';
import { ACCESS_TOKEN } from 'utils/constants';

export const useAppInit = async () => {
  const { configStore, userStore, notificationStore, authStore } = useStore();
  await configStore.updateConfigs();
  const token: string | null = localStorage.getItem(ACCESS_TOKEN);
  if (!token) {
    userStore.setIsUserLoading(false);
    return;
  }
  try {
    await userStore.getCurrentUser();
    if (!userStore.user) return;
    await notificationStore.getNotifications();
  } catch (e) {
    authStore.logout();
    // TODO: testing
    // localStorage.removeItem(ACCESS_TOKEN);
  }
};
