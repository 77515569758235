import LeafletMap from 'map/LeafletMap';
import { makeAutoObservable } from 'mobx';
import L, { DivIcon } from 'leaflet';
import MarkerBlue from 'assets/icons/marker-blue.svg';

class MapWithMarkerStore {
  leafletMap: LeafletMap;
  constructor() {
    makeAutoObservable<MapWithMarkerStore>(this);
    this.leafletMap = new LeafletMap();
  }

  getMarkerRed(): DivIcon {
    return L.icon({
      iconUrl: MarkerBlue,
      className: 'large-marker'
    });
  }

  addMarker(coordinate: [number, number]): void {
    if (this.leafletMap.map) {
      L.marker([coordinate[1], coordinate[0]], {
        icon: this.getMarkerRed()
      }).addTo(this.leafletMap.map);
    }
  }
}

export default MapWithMarkerStore;
