import {
  IMedia,
  IChangeEmailData,
  IChangePasswordData,
  IUserRepo,
  IUserService,
  USER_REPO,
  UserData,
  IChangeEmailResponse,
  NotificationSettings
} from './types';
import { injector } from 'utils/injector';
import { IEditProfileData } from './Settings/types';

export class UserService implements IUserService {
  private _userRepo: IUserRepo = injector.get<IUserRepo>(USER_REPO);

  async editUserPassword(data: IChangePasswordData): Promise<void> {
    return await this._userRepo.editUserPassword(data);
  }

  async getCurrentUser(): Promise<UserData> {
    return (await this._userRepo.getCurrentUser()).data.result;
  }

  async editUserInfo(data: IEditProfileData): Promise<UserData> {
    return (await this._userRepo.editUserInfo(data)).data.result;
  }
  async setAvatar(avatar: File): Promise<IMedia> {
    const formData = new FormData();
    formData.append('type', 'avatar');
    formData.append('file', avatar);
    return (await this._userRepo.setAvatar(formData)).data.result;
  }

  async editUserEmail(data: IChangeEmailData): Promise<void> {
    return await this._userRepo.editUserEmail(data);
  }

  async confirmEmailToken(token: string): Promise<IChangeEmailResponse> {
    return (await this._userRepo.confirmEmailToken(token))?.data?.result;
  }

  async getNotificationSettings(): Promise<NotificationSettings> {
    return (await this._userRepo.getNotificationSettings()).data.result;
  }

  async updateNotificationSettings(
    notificationSettings: NotificationSettings
  ): Promise<NotificationSettings> {
    // TODO: remove this code after fixed BE
    const notificationSettingsBody = { ...notificationSettings };
    delete notificationSettingsBody.id;
    delete notificationSettingsBody.user_id;

    return (await this._userRepo.updateNotificationSettings(notificationSettingsBody)).data.result;
  }
}
