import { ASC, DESC } from 'utils/constants';
import { ISort } from 'utils/types';

export const orderProcessing = (field: string, orders: ISort[]): ISort[] => {
  if (orders.length) {
    const currentOrder = orders.find((order) => order.field === field);
    if (currentOrder && currentOrder.order === DESC) {
      return orders.filter((order) => order.field !== field);
    }
    if (currentOrder) {
      return orders.map((item) => {
        if (item.field === field) {
          if (item.order === ASC) {
            return {
              field: item.field,
              order: DESC
            };
          }
        }
        return item;
      });
    }
    return addNewOrder(field, orders);
  }
  return addNewOrder(field, orders);
};

const addNewOrder = (field: string, orders: ISort[]): ISort[] => {
  const currentOrder: ISort = {
    field,
    order: ASC
  };
  return [...orders, currentOrder];
};

export const singleSortProcessing = (field: string, sort: ISort[]): ISort[] => {
  if (sort.length && sort[0].field === field) {
    if (sort[0].order === ASC) {
      return [
        {
          ...sort[0],
          order: DESC
        }
      ];
    } else {
      return [];
    }
  } else {
    return [
      {
        field,
        order: ASC
      }
    ];
  }
};
