import { IFavoriteFilters } from './types';

export const initialFavoriteFilters: IFavoriteFilters = {
  classes: [],
  types: [],
  subTypes: [],
  date: {
    min: null,
    max: null
  },
  projectValue: {
    min: null,
    max: null
  },
  location: [],
  projectCount: {
    min: null,
    max: null
  },
  totalAmount: {
    min: null,
    max: null
  }
};
