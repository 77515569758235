import React, { FC, useCallback, useState } from 'react';
import { ICell, ICellContact, IListIcon } from '../types';
import classes from '../Table.module.scss';
import { clsx } from 'utils/clsx';
import { iconListHoverByNum } from 'utils/icons';
import { SourceLink } from 'utils/types';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

interface Props {
  data: ICell;
  onTitleClick: (() => void) | null;
  onTitleContact?: () => void;
  cellClass?: string;
  hasSubscription?: boolean;
  onHandleUnlock?: () => void;
  onContact?: (id: number) => void;
}

export const Cell: FC<Props> = ({
  data,
  onTitleClick,
  onTitleContact,
  cellClass,
  hasSubscription = true,
  onHandleUnlock,
  onContact
}) => {
  const [hoverKey, setHoverKey] = useState<string | null>(null);
  const { alertStore } = useStore();
  const { t } = useTranslation();

  const currentIcon = useCallback(
    (iconItem: IListIcon, currentHoverKey: string): string => {
      return hoverKey === currentHoverKey
        ? iconListHoverByNum[iconItem.type]
        : (iconItem.icon as string);
    },
    [hoverKey]
  );

  const handleIconClick = (iconItem: IListIcon): void => {
    if (iconItem.type === SourceLink.LINKEDIN || iconItem.type === SourceLink.WEB) {
      window.open(iconItem.label, '_blank');
      return;
    }
    if (iconItem.type === SourceLink.EMAIL) {
      window.open(`mailto:${iconItem.label}`, '_blank');
      return;
    }
    navigator.clipboard
      .writeText(iconItem.label)
      .then(() => alertStore.successAlert(t('savedSearch.copied')))
      .catch(() => alertStore.errorAlert('savedSearch.errorCopy'));
  };

  return (
    <div className={clsx(classes.cellWidth, cellClass)}>
      {data.label && (
        <label className="absolute text-xxs uppercase top-2 text-grey-300">{data.label}</label>
      )}
      <div className={data.colorType ? 'flex' : 'block'}>
        {data.colorType && (
          <div
            className="w-3 h-3 rounded-full mr-2 mt-1"
            style={{ backgroundColor: data.colorType }}></div>
        )}
        {data.value && (
          <h6
            className={clsx(
              'm-0',
              (data.field === 'property' || data.field === 'details') && 'select-text'
            )}
            role={onTitleClick || onTitleContact ? 'button' : 'note'}
            onClick={onTitleClick ? onTitleClick : onTitleContact ? onTitleContact : () => null}>
            {data.value}
          </h6>
        )}
      </div>
      {data.status && (
        <span
          style={{ color: data.status.color }}
          className={clsx(
            classes['status' + data.status],
            'block',
            'py-1',
            'px-2',
            'w-fit',
            'border',
            'border-light-600',
            'rounded-sm',
            'uppercase',
            'text-xs'
          )}>
          {data.status.name}
        </span>
      )}
      {data.additionalValue && (
        <span
          className={clsx(
            'text-sm',
            'text-grey-500',
            (data.field === 'property' || data.field === 'details') && classes.description,
            (data.field === 'property' || data.field === 'details') && 'mb-4'
          )}>
          {data.additionalValue}
        </span>
      )}
      <>
        <div className="flex flex-wrap -mb-6">
          {data.values &&
            data.values.map((contactItem: ICellContact, valueIdx: number) => (
              <div key={`contact-${valueIdx}`} className="w-full xl:w-1/2 2xl:w-1/3 mb-4 relative">
                {contactItem.label && (
                  <span className="absolute font-kraftig text-xxs text-grey-300 -top-[8px]">
                    {contactItem.label}
                  </span>
                )}
                <h6
                  className="m-0 cursor-pointer"
                  onClick={() => onContact && contactItem.id && onContact(contactItem.id)}>
                  {contactItem.value}
                </h6>
                {contactItem.phone && <span className="text-sm">{contactItem.phone}</span>}
                <div className="flex">
                  {contactItem.iconItems?.map((iconItem: IListIcon, iconIdx: number) => (
                    <div
                      className="relative group flex flex-col items-center justify-center"
                      key={`icon-contact-${iconIdx}`}>
                      <img
                        src={currentIcon(iconItem, `${valueIdx}-${iconIdx}`)}
                        alt=""
                        className={clsx(classes.contactIcon, 'relative', 'mr-1')}
                        onClick={() => handleIconClick(iconItem)}
                        onMouseEnter={() => setHoverKey(`${valueIdx}-${iconIdx}`)}
                        onMouseLeave={() => setHoverKey(null)}
                      />
                      <div className="absolute top-6 mb-6 mr-1 rounded text-dark z-1 hidden group-hover:block">
                        <div className="flex max-w-xs flex-col items-center">
                          <div className="clip-bottom h-2 w-4 bg-white"></div>
                          <div className="w-max rounded bg-white shadow-overlay px-6 py-4 text-base">
                            <p className="select-text">{iconItem.label}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/*TODO: remove after testing*/}
                {/*{!hasSubscription && onHandleUnlock && (*/}
                {/*  <div*/}
                {/*    role="button"*/}
                {/*    onClick={onHandleUnlock}*/}
                {/*    className={clsx(*/}
                {/*      'position-absolute',*/}
                {/*      'top-0',*/}
                {/*      'start-0',*/}
                {/*      'bottom-0',*/}
                {/*      'end-0',*/}
                {/*      'd-flex',*/}
                {/*      'justify-content-center',*/}
                {/*      'align-items-center',*/}
                {/*      classes.lockedWrapper*/}
                {/*    )}>*/}
                {/*    <div className={classes.lockIcon}>*/}
                {/*      <LockIcon className="w-100 h-auto" />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            ))}
        </div>
      </>
    </div>
  );
};
