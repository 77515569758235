import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import { ReactComponent as SortIcon } from 'assets/icons/arrow.svg';
import { clsx } from 'utils/clsx';
import classes from './Table.module.scss';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ASC, DESC } from 'utils/constants';
import { ISort } from 'utils/types';

interface Props {
  table: Table<unknown>;
  orders?: ISort[];
  orderAction?: (field: string) => void;
  onTableScroll?: () => void;
  hasSubscription?: boolean;
  onHandleUnlock?: () => void;
  tableClass?: string;
}
const NewTable: FC<Props> = ({
  table,
  orders,
  orderAction,
  onTableScroll,
  hasSubscription,
  onHandleUnlock,
  tableClass
}) => {
  const tableRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    tableRef.current.scrollTo({ top: 0 });
  }, [table.getRowModel().rows]);

  // Sort
  const sortingColumn = useCallback(
    (field: string): boolean => {
      return !!orders?.find((order) => order.field === field);
    },
    [orders]
  );

  const sortIconPosition = useCallback(
    (field: string): string => {
      const currentOrder = orders?.find((order) => order.field === field);
      if (currentOrder && currentOrder.order === ASC) return 'rotate-0';
      if (currentOrder && currentOrder.order === DESC) return 'rotate-180';
      return classes.sortIconUp;
    },
    [orders]
  );
  // Sort end

  const showLock = (idx: number): boolean => !hasSubscription && idx > 2 && !!onHandleUnlock;

  return (
    <div className="relative">
      <div
        className={clsx('overflow-auto', classes.wrapperTable, tableClass)}
        ref={tableRef}
        onScroll={onTableScroll}>
        <table className="table table-borderless mb-0 bg-white rounded-sm text-dark shadow-table w-full">
          <thead className="sticky top-0 shadow-tableHead z-[1]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={clsx(
                      classes.tableHeadCell,
                      'h-10',
                      'bg-white',
                      'text-sm',
                      'px-2',
                      'font-normal',
                      'first:pl-6 first:text-left',
                      'last:pr-6 last:text-right',
                      'text-left'
                    )}>
                    <div className={clsx('flex', 'items-center', classes.cellHeader)}>
                      <span className="font-kraftig">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </span>
                      {header.column.getCanSort() && orders && (
                        <SortIcon
                          className={clsx(
                            sortingColumn(header.id) ? 'fill-red-100' : 'fill-dark',
                            sortIconPosition(header.id),
                            'ml-2',
                            'cursor-pointer'
                          )}
                          onClick={() => orderAction && orderAction(header.id)}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border-t border-light-400 relative font-kraftig">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={clsx(
                      'relative',
                      'py-6',
                      'px-2',
                      'first:pl-6',
                      'first:text-left',
                      'last-of-type:pr-6',
                      'last-of-type:text-right',
                      showLock(Number(row.id)) &&
                        cell.column.columnDef.header === 'Actions' &&
                        'pr-6',
                      'align-top'
                    )}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                {showLock(Number(row.id)) && (
                  <td
                    role="button"
                    onClick={onHandleUnlock}
                    className={clsx(
                      'absolute',
                      'top-0',
                      'start-0',
                      'bottom-0',
                      'end-0',
                      'flex',
                      'justify-center',
                      'items-center',
                      'backdrop-blur-sm'
                    )}>
                    <div className={classes.lockIcon}>
                      <LockIcon className="w-full h-auto" />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewTable;
