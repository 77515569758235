import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MainLayout } from 'view/Layout/MainLayout';
import { Components } from 'view/ComponentsPage/Components';
import { Settings, Subscription, Notifications } from 'view/Profile';
import Private from './Private';
import ProfileLayout from 'view/Layout/ProfileLayout';
import SavedSearches from 'view/SavedSearches';
import Favorites from 'view/Favorites';
import Error404 from 'view/Error404';
import UnderConstruction from 'view/UnderConstruction';
import Analytics from 'view/Favorites/Analytics';
import SearchProjects from 'view/SearchProjects';
import SavedSearchResults from 'view/SavedSearches/components/SavedSearchResults';
import Home from 'view/Home';
import AuthContainer from 'view/Auth/AuthContainer';
import CompanySearch from 'view/CompanySearch';
import ShareFavorite from 'view/Favorites/components/ShareFavorite';
// import ListUploadPage from 'view/ListUpload';
import NewImport from 'view/ListUpload/NewImport';
import ImportHistory from 'view/ListUpload/ImportHistory';
// import SearchProjectsLayout from 'view/Layout/SearchProjectsLayout';

const ReactLinks: FC = () => {
  return (
    <Routes>
      <Route element={<AuthContainer />} path="/">
        <Route element={<Home />} path="/" />
        <Route element={<MainLayout Private={Private} />}>
          <Route element={<ProfileLayout />} path="/profile">
            <Route element={<Settings />} path="settings" />
            <Route element={<Subscription />} path="subscription" />
            <Route element={<Notifications />} path="notifications" />
            <Route element={<UnderConstruction />} path="company" />
            <Route element={<UnderConstruction />} path="notifications" />
          </Route>
          <Route element={<SavedSearches />} path="/saved-search" />
          <Route element={<SavedSearchResults />} path="/saved-search/:id" />
          <Route element={<SavedSearchResults />} path="/saved-search/share/:entityType/:token" />
          <Route element={<CompanySearch />} path="/company-search" />
          <Route element={<Navigate to="/favorites/:id/:pathTab" replace />} path="/favorites" />
          <Route
            element={<Navigate to="/favorites/:id/:pathTab" replace />}
            path="/favorites/:id"
          />
          <Route element={<Favorites />} path="/favorites/:id/:pathTab" />
          <Route element={<ShareFavorite />} path="/favorites/share/:token" />
          <Route element={<Analytics />} path="/favorites/analytics" />
          <Route path="search-projects">
            <Route index element={<SearchProjects />} />
            <Route element={<SearchProjects />} path=":code/:canonicalTitle" />
            <Route
              element={<SearchProjects />}
              path=":code/:canonicalTitle/share/:token/:projectId"
            />
          </Route>
          <Route element={<NewImport />} path="/new-import" />
          <Route element={<ImportHistory />} path="/import-history" />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route element={<Components />} path="/components" />
      </Route>
    </Routes>
  );
};

export default ReactLinks;
