import { Chart } from 'chart.js';
import { numberWithSpaces } from 'helpers/numberWithComma';

export const centerText = (title: string, totalValue: number) => ({
  id: 'centerText',
  afterDatasetsDraw(chart: Chart) {
    const {
      ctx,
      chartArea: { width, height }
    } = chart;
    ctx.save();
    ctx.textAlign = 'center';
    ctx.font = 'normal 40px Söhne-Kraftig, Söhne-Buch, Arial';
    ctx.fillStyle = '#2F3139';
    ctx.fillText(`$${numberWithSpaces(totalValue)}`, width / 2, height / 2);
  }
});
