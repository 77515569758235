import React, { FC, useState, useCallback, useEffect } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Filter.module.scss';
import { Input } from 'components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Checkbox from 'components/Checkbox';
import { ExcludesKeys, FilterState, KeywordExcludes } from '../types';

interface Props {
  keywords: string[];
  selectedExcludes: ExcludesKeys[];
  onSetKeyword: (val: string) => void;
  onRemoveKeyword: (idx: number) => void;
  onSetExclude: (keys: ExcludesKeys[]) => void;
  type?: FilterState;
}

const Keywords: FC<Props> = ({
  keywords,
  selectedExcludes,
  onSetKeyword,
  onRemoveKeyword,
  onSetExclude,
  type
}) => {
  const [keywordValue, setKeywordValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    console.log('selectedExcludes: ', selectedExcludes);
  }, [selectedExcludes]);

  const keywordParams: KeywordExcludes[] = [
    {
      id: 1,
      key: 'address',
      name: 'Exclude Addresses'
    },
    {
      id: 2,
      key: 'companyName',
      name: 'Exclude Company Names'
    },
    {
      id: 3,
      key: 'description',
      name: 'Exclude Descriptions'
    }
  ];

  const handleKeywordChange = (name: string, str: string): void => {
    setKeywordValue(str);
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      if (keywordValue.length) {
        onSetKeyword(keywordValue);
        setKeywordValue('');
      }
    }
  };

  const handleChecked = useCallback(
    (key: ExcludesKeys) => {
      if (!selectedExcludes.includes(key) && selectedExcludes.length === 2) {
        setError(true);
        return;
      }
      if (!selectedExcludes.length || !selectedExcludes.includes(key)) {
        onSetExclude(selectedExcludes.concat([key]));
        setError(false);
        return;
      }
      onSetExclude(selectedExcludes.filter((paramId) => paramId !== key));
      setError(false);
    },
    [selectedExcludes]
  );

  const disabledValue = useCallback(
    (key: ExcludesKeys): boolean => {
      return !selectedExcludes.includes(key) && selectedExcludes.length === 2;
    },
    [selectedExcludes]
  );

  const constantValue = useCallback(
    (key: ExcludesKeys): boolean => {
      return !!(type && type === FilterState.COMPANY_SEARCH && key === 'companyName');
    },
    [selectedExcludes]
  );

  return (
    <div className="w-58">
      <Input
        type="text"
        value={keywordValue}
        onInput={handleKeywordChange}
        onKeyDown={handleKeyDown}
        name="keywords"
        inputClass="text-dark h-12 bg-light-200 border-0 w-58 focus:shadow-field ml-auto"
        placeholder="Add a keyword…"
      />
      <div>
        {keywordParams.map((param) => (
          <div className="mt-4.5 text-dark text-sm" key={param.id}>
            <Checkbox
              checked={selectedExcludes.includes(param.key)}
              id={param.id}
              onChange={() => handleChecked(param.key)}
              disabled={disabledValue(param.key)}
              constantValue={constantValue(param.key)}>
              {param.name}
            </Checkbox>
          </div>
        ))}
        {error && <p className="text-red-100">You can select two maximum</p>}
      </div>
      <div className={clsx(classes.keywordList, 'flex', 'flex-wrap', 'mb-2', 'max-h-14')}>
        {keywords.map((keyword: string, index) => (
          <div
            key={index}
            className={clsx(
              classes.keywordItem,
              'text-dark',
              'p-2',
              'mt-2',
              'mr-2',
              'flex',
              'items-center',
              'cursor-default'
            )}>
            <span>{keyword}</span>
            <CloseIcon onClick={() => onRemoveKeyword(index)} className="ml-1 cursor-pointer" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Keywords;
