import { IExportDataRepo, ExportData } from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class ExportDataRepo implements IExportDataRepo {
  async runExport(userId: number, searchId: number): Promise<void> {
    await axiosInstance.get(`users/${userId}/saved-searches/${searchId}/export/run`);
  }
  async getExportFile(userId: number, searchId: number): Promise<IAxiosResponse<ExportData>> {
    return await axiosInstance.get(`users/${userId}/saved-searches/${searchId}/export`);
  }
  // async createToken(
  //   userId: number,
  //   searchId: number,
  //   email?: string
  // ): Promise<IAxiosResponse<any>> {
  //   return await axiosInstance.post(`users/${userId}/saved-searches/${searchId}/share`, {
  //     email
  //   });
  // }
}
