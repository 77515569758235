import {
  INotificationService,
  INotificationStore,
  INotification,
  NOTIFICATION_SERVICE
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';

export class NotificationStore implements INotificationStore {
  private _notificationService: INotificationService =
    injector.get<INotificationService>(NOTIFICATION_SERVICE);

  constructor() {
    makeAutoObservable<NotificationStore>(this);
  }

  notifications: INotification[] = [];

  setNotifications(notifications: INotification[]) {
    this.notifications = notifications;
  }

  async getNotifications(): Promise<void> {
    this.setNotifications(await this._notificationService.getNotifications());
  }

  async markAsRead(): Promise<void> {
    await this._notificationService.markAsRead();
    this.setNotifications(this.notifications.map((not) => ({ ...not, seenAt: new Date() })));
  }

  async clearNotifications(): Promise<void> {
    await this._notificationService.clearNotifications();
    this.setNotifications([]);
  }
}
