import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import useIsMount from 'hooks/useIsMount';
import { Button } from 'components';
import { FilterState } from 'modules/Filter/types';
import Filter from 'modules/Filter';
import { SearchType } from 'utils/types';

interface Props {
  onSaveSearch: () => void;
  onUpdate: () => void;
  type: SearchType;
  savedSearchId: number | null;
}

const Filters: FC<Props> = observer(({ onSaveSearch, onUpdate, type, savedSearchId }) => {
  const { t } = useTranslation();

  const { filtersStore } = useStore();
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      onUpdate();
    }
  }, [filtersStore.searchParams]);
  const filterType = useMemo((): FilterState => {
    return type === 10 ? FilterState.SAVED_SEARCH : FilterState.SAVED_SEARCH_COMPANY;
  }, [type]);

  return (
    <div className="text-dark relative z-[5] border-t border-t-light-400 mx-2 py-3">
      <div className="flex justify-between py-1">
        <Filter type={filterType} onSaveSearch={onSaveSearch} savedSearchId={savedSearchId} />
        <Button
          tabIndex={0}
          type="primary"
          onClick={onSaveSearch}
          className="flex items-center h-8 py-0 px-[15px] text-sm z-[1] whitespace-nowrap"
          data-test-element="search-project-save-button">
          <span className="font-kraftig">{t('searchProject.saveSearch')}</span>
        </Button>
      </div>
    </div>
  );
});

export default Filters;
