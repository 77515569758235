import { INote, INoteRepo, INoteService, NOTE_REPO } from './types';
import { injector } from 'utils/injector';
import { FavoriteTabSystemType } from '../Favorites/types';

export class NoteService implements INoteService {
  private _noteRepo: INoteRepo = injector.get<INoteRepo>(NOTE_REPO);

  async getNote(projectId: number, type: FavoriteTabSystemType): Promise<INote | null> {
    return (await this._noteRepo.getNote(projectId, type)).data.result || null;
  }
  async addNote(projectId: number, type: FavoriteTabSystemType, content: string): Promise<INote> {
    return (await this._noteRepo.addNote(projectId, type, content)).data.result;
  }
}
