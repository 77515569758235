import React, { FC } from 'react';
import { PaymentType } from '../SubscriptionsAndPlans/types';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import { loadStripe } from '@stripe/stripe-js';

interface IProps {
  direction: 'row' | 'column';
  type: PaymentType;
  onPurchase: () => void;
}

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY));

const Payment: FC<IProps> = ({ direction, type, onPurchase }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm direction={direction} type={type} onPurchase={onPurchase} />
    </Elements>
  );
};

export default Payment;
