import { IAxiosResponse } from 'utils/types';

export const SUBSCRIPTION_LOCATION_DROPDOWN_REPO = 'SUBSCRIPTION_LOCATION_DROPDOWN_REPO';
export const SUBSCRIPTION_LOCATION_DROPDOWN_SERVICE = 'SUBSCRIPTION_LOCATION_DROPDOWN_SERVICE';

export interface ISubscriptionLocationDropdownRepo {
  getSubscribedLocations(
    page: number,
    pageSize: number,
    searchId?: number | null
  ): Promise<IAxiosResponse<SubscribedLocationsResponse>>;
}

export interface ISubscriptionLocationDropdownService {
  getSubscribedLocations(
    page: number,
    pageSize: number,
    searchId?: number | null
  ): Promise<SubscribedLocationsResponse>;
}

export interface ISubscriptionLocationDropdownStore {
  currentPage: number;
  pageSize: number;
  data: SubscribedLocationsResponse;
  selected: ShortLocation[];
  getSubscribedLocations(searchId?: number | null): Promise<void>;
}

export type SubscribedLocationsResponse = {
  limit: number;
  page: number;
  hasMore: boolean;
  total: number;
  data: ShortLocation[];
};

export type ShortLocation = {
  id: number;
  title: string;
  targetTitle: string;
};
