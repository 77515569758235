import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AUTH_SERVICE, IAuthService } from '../types';
import { injector } from 'utils/injector';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';

const ConfirmAccount = () => {
  const { t } = useTranslation();
  const { navigateAfterLogin } = useNavigateAfterLogin();
  const authService: IAuthService = injector.get<IAuthService>(AUTH_SERVICE);
  const { alertStore } = useStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  if (!token || !email) {
    alertStore.errorAlert(t('auth.incorrectLink'));
  }
  const onLoad = useCallback(async () => {
    if (!token || !email) return;
    try {
      await authService.confirmAccount({
        token: token.replaceAll(' ', '+'),
        email
      });
      alertStore.successAlert(t('auth.successVerify'));
    } finally {
      navigateAfterLogin();
    }
  }, [searchParams]);

  useEffect(() => {
    onLoad().then();
  }, []);

  return <></>;
};

export default ConfirmAccount;
