import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { SearchProjects } from 'view/Search/types';

interface params {
  searchResults: SearchProjects | null;
  setSearchResults: Dispatch<SetStateAction<SearchProjects | null>>;
  setValueWithoutFetching: (value: string) => void;
  lastInputValue: string;
  setShowSearchList: Dispatch<SetStateAction<boolean>>;
  setLastInputValue: Dispatch<SetStateAction<string>>;
}

export const useKeyboardCallbacks = ({
  searchResults,
  setSearchResults,
  lastInputValue,
  setLastInputValue,
  setValueWithoutFetching,
  setShowSearchList
}: params) => {
  const navigate = useNavigate();
  const { filtersStore } = useStore();

  const handleKeyUp = useCallback(() => {
    setSearchResults((prev) => {
      if (!prev) return prev;
      const activeSubscription = prev.subscriptionData.data.find((item) => item.focused);
      const activeResult = prev.data.find((item) => item.focused);
      const focused = !!activeSubscription || !!activeResult;
      if (!focused) return prev;
      if (activeSubscription) {
        const prevIndex = prev.subscriptionData.data.findIndex((item) => item.focused) - 1;
        if (prev.subscriptionData.data[prevIndex]) {
          setValueWithoutFetching(prev.subscriptionData.data[prevIndex].title || '');
          return {
            ...prev,
            subscriptionData: {
              data: prev.subscriptionData.data.map((item, index) => ({
                ...item,
                focused: index === prevIndex
              }))
            }
          };
        } else {
          setLastInputValue((prev) => {
            setValueWithoutFetching(prev);
            return prev;
          });
          return {
            ...prev,
            subscriptionData: {
              data: prev.subscriptionData.data.map((item) => ({ ...item, focused: false }))
            },
            data: prev.data.map((item) => ({ ...item, focused: false }))
          };
        }
      }
      if (activeResult) {
        const prevIndex = prev.data.findIndex((item) => item.focused) - 1;
        if (prev.data[prevIndex]) {
          setValueWithoutFetching(prev.data[prevIndex].title || '');
          return {
            ...prev,
            data: prev.data.map((item, index) => ({
              ...item,
              focused: index === prevIndex
            }))
          };
        } else {
          if (!prev.subscriptionData.data.length) {
            handleKeyEscape();
            return prev;
          }
          setValueWithoutFetching(
            prev.subscriptionData.data[prev.subscriptionData.data.length - 1].title
          );
          return {
            ...prev,
            data: prev.data.map((item) => ({
              ...item,
              focused: false
            })),
            subscriptionData: {
              data: prev.subscriptionData.data.map((item, index) => ({
                ...item,
                focused: index === prev.subscriptionData.data.length - 1
              }))
            }
          };
        }
      }
      return prev;
    });
  }, [searchResults, setSearchResults, setValueWithoutFetching, lastInputValue]);

  const handleKeyDown = useCallback(() => {
    setSearchResults((prev) => {
      if (!prev) return prev;
      const activeSubscription = prev.subscriptionData.data.find((item) => item.focused);
      const activeResult = prev.data.find((item) => item.focused);
      const focused = !!activeSubscription || !!activeResult;
      if (!focused) {
        if (prev.subscriptionData.data.length >= 1) {
          setValueWithoutFetching(prev.subscriptionData.data[0].title || '');
          return {
            ...prev,
            subscriptionData: {
              data: prev.subscriptionData.data.map((item, index) => ({
                ...item,
                focused: index === 0
              }))
            }
          };
        } else {
          setValueWithoutFetching(prev.data[0].title || '');
          return {
            ...prev,
            data: prev.data.map((item, index) => ({ ...item, focused: index === 0 }))
          };
        }
      }
      if (activeSubscription) {
        const nextIndex = prev.subscriptionData.data.findIndex((item) => item.focused) + 1;
        if (prev.subscriptionData.data[nextIndex]) {
          setValueWithoutFetching(prev.subscriptionData.data[nextIndex].title || '');
          return {
            ...prev,
            subscriptionData: {
              data: prev.subscriptionData.data.map((item, index) => ({
                ...item,
                focused: index === nextIndex
              }))
            }
          };
        } else {
          if (!prev.data[0]) return prev;
          setValueWithoutFetching(prev.data[0].title || '');
          return {
            ...prev,
            subscriptionData: {
              data: prev.subscriptionData.data.map((item) => ({
                ...item,
                focused: false
              }))
            },
            data: prev.data.map((item, index) => ({ ...item, focused: index === 0 }))
          };
        }
      }
      if (activeResult) {
        const nextIndex = prev.data.findIndex((item) => item.focused) + 1;
        if (prev.data[nextIndex]) {
          setValueWithoutFetching(prev.data[nextIndex].title || '');
          return {
            ...prev,
            data: prev.data.map((item, index) => ({
              ...item,
              focused: index === nextIndex
            }))
          };
        }
      }
      return prev;
    });
  }, [searchResults, setSearchResults, setValueWithoutFetching, lastInputValue]);

  const handleKeyEnter = useCallback(() => {
    setSearchResults((prev) => {
      //TODO: check why it doesnt show correct value without setState
      if (!prev) return prev;
      const allLocations = [...prev.subscriptionData.data, ...prev.data];
      const focused = allLocations.find((item) => item.focused);
      if (focused) {
        setShowSearchList(false);
        filtersStore.setSearchParams('area', []);
        navigate(`/search-projects/${focused.stateCode.toLowerCase()}/${focused.canonicalTitle}`);
      }
      return prev;
    });
  }, [searchResults]);

  const handleKeyEscape = useCallback(() => {
    //TODO: check why it doesnt show correct value without setState
    setLastInputValue((prev) => {
      setValueWithoutFetching(prev);
      return prev;
    });
    setSearchResults((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        subscriptionData: {
          data: prev.subscriptionData.data.map((item) => ({ ...item, focused: false }))
        },
        data: prev.data.map((item) => ({ ...item, focused: false }))
      };
    });
  }, [lastInputValue]);

  return { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape };
};
