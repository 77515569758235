import { ConfigType } from 'store/ConfigStore/types';
import { IOption } from 'components/Select/types';

export const configToOption = (configs: ConfigType[] | undefined): IOption[] =>
  configs
    ? configs.map((config) => ({
        value: String(config.id),
        text: config.name,
        id: config.id
      }))
    : [];
