import React, { FC } from 'react';
import { Modal } from 'components';
import { IModalActions } from 'components/Modal/types';

interface IProps {
  show: boolean;
  closeModal: () => void;
  title: string;
  subTitle: string;
  [key: string]: unknown;
}

const modalActions: IModalActions = {
  negative: 'Cancel',
  positive: 'Update'
};

const UnderConstructionModal: FC<IProps> = ({ show, title, subTitle, closeModal, ...rest }) => {
  return (
    <Modal
      closeModal={closeModal}
      title={title}
      subTitle={subTitle}
      actions={modalActions}
      deleteBtn={false}
      show={show}
      onSubmit={() => console.log('click!')}
      {...rest}>
      This action is currently under construction
    </Modal>
  );
};

export default UnderConstructionModal;
