import React, { useCallback, FC } from 'react';
import { IEditProfileData } from 'view/Profile/Settings/types';
import { Input, Button } from 'components';
import { UserData } from 'view/Profile/types';
import { useFormik } from 'formik';
import { EditProfileSchema } from 'utils/Formik/validation';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

interface IProps {
  user: UserData;
}

const EditProfileForm: FC<IProps> = observer(({ user }: IProps) => {
  const { userStore, alertStore } = useStore();
  const { t } = useTranslation();

  const handleEditProfile = useCallback(async (data: IEditProfileData) => {
    await userStore.editUserInfo(data);
    alertStore.successAlert(t('profile.successInfoChanged'));
  }, []);

  const formik = useFormik<IEditProfileData>({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone || ''
    },
    onSubmit: handleEditProfile,
    validationSchema: EditProfileSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex items-center mb-4">
        <div className="grow mr-3">
          <Input
            type="text"
            value={formik.values.firstName}
            isError={!!formik.errors.firstName}
            errorText={formik.errors.firstName}
            onInput={onInputChange}
            name="firstName"
            label={t('profile.firstName')}
            data-test-element="edit-name-input"
          />
        </div>
        <div className="grow">
          <Input
            type="text"
            value={formik.values.lastName}
            isError={!!formik.errors.lastName}
            errorText={formik.errors.lastName}
            onInput={onInputChange}
            name="lastName"
            label={t('profile.lastName')}
            data-test-element="edit-name-input"
          />
        </div>
      </div>
      <div className="mb-4">
        <Input
          type="tel"
          value={formik.values.phone}
          isError={!!formik.errors.phone}
          errorText={formik.errors.phone}
          onInput={onInputChange}
          name="phone"
          label={t('profile.phone')}
          data-test-element="edit-phone-input"
          placeholder="+1(650) 251-52-21"
        />
      </div>
      <Button type="primary" className="px-5" data-test-element="edit-profile-submit-button">
        <span className="font-kraftig">{t('profile.save')}</span>
      </Button>
    </form>
  );
});

export default EditProfileForm;
