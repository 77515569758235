import {
  IImportHistory,
  IListUploadRepo,
  MatchesResponseData,
  MatchOperationData,
  UploadResult
} from './types';
import { IAxiosResponse, ObjectValues } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class ListUploadRepo implements IListUploadRepo {
  async uploadFile(file: FormData): Promise<IAxiosResponse<UploadResult>> {
    return await axiosInstance.post('/import/cross-referencing/upload', file);
  }

  async getStatusLastUploadedFile(): Promise<IAxiosResponse<UploadResult>> {
    return await axiosInstance.get('/import/cross-referencing/upload');
  }

  async abortUploadedFileProcess(): Promise<IAxiosResponse<UploadResult>> {
    return await axiosInstance.post('/import/cross-referencing/abort');
  }

  async retrieveContactsListByImportId(
    importFileId: number,
    page: number
  ): Promise<IAxiosResponse<MatchesResponseData>> {
    return await axiosInstance.get(`/import/${importFileId}/contacts/list`, { params: { page } });
  }

  async mergeContactFromFileImport(data: MatchOperationData[]): Promise<IAxiosResponse<void>> {
    return await axiosInstance.patch('/import/contact/merge', data);
  }

  async getImportHistoryData(
    page: number,
    orderParams: ObjectValues<string>,
    perPage?: number
  ): Promise<IAxiosResponse<IImportHistory>> {
    return await axiosInstance.get('/import/lists', {
      params: {
        pageSize: perPage || 20,
        page,
        ...orderParams
      }
    });
  }
}
