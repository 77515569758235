import React, { FC, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from 'components/Button';
import { HasSubscriptionLocation } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  locations: number[] | null;
  partialSubscription: boolean;
  hasSubscriptionLocations: HasSubscriptionLocation[];
  onClose: () => void;
  onAccept: () => void;
  onRefuse: () => void;
}

const WarningModal: FC<Props> = ({
  show,
  locations,
  partialSubscription,
  hasSubscriptionLocations,
  onClose,
  onAccept,
  onRefuse
}) => {
  const { t } = useTranslation();

  const refuseBtnContent = useCallback((): string => {
    // TODO: remove after test
    // if (
    //   locations &&
    //   hasSubscriptionLocations.length > 0 &&
    //   locations.length > hasSubscriptionLocations.length
    // ) {
    //   return t('savedSearch.modal.removeResult');
    // }
    // return t('savedSearch.modal.neverMind');
    return partialSubscription
      ? t('savedSearch.modal.removeResult')
      : t('savedSearch.modal.neverMind');
  }, [locations, hasSubscriptionLocations]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white px-4 pb-4 pt-5 text-left shadow-overlay transition-all sm:my-8 sm:w-full sm:max-w-[420px] sm:px-4 sm:pt-5 sm:pb-4">
                <div className="absolute right-0 top-0 hidden pr-3 pt-3 sm:block">
                  <CloseIcon
                    className="relative -translate-y-0.5"
                    onClick={onClose}
                    role="button"
                  />
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-[25px] w-[25px] flex-shrink-0 items-center font-kraftig text-sm text-white justify-center rounded-full bg-info sm:mx-0">
                    !
                  </div>
                  <div className="mt-3 text-center pr-3 sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="text-sm text-dark">
                      <p>{t('savedSearch.modal.countiesWithoutSubscribe')}</p>
                      <p className="font-kraftig pt-2">
                        {hasSubscriptionLocations.map((location) => location.title).join(', ')}
                      </p>
                    </div>
                    <div className="mt-4 text-sm sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3">
                      <Button type="secondary" className="h-8 !p-0" onClick={onRefuse}>
                        <span className="font-kraftig">{refuseBtnContent()}</span>
                      </Button>
                      <Button type="primary" className="h-8 !p-0 mr-3" onClick={onAccept}>
                        <span className="font-kraftig">{t('savedSearch.modal.subscribe')}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WarningModal;
