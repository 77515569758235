import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { clsx } from 'utils/clsx';
import classes from 'components/Select/Select.module.scss';
import styles from '../Filter.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'components';
import { getConfigs } from '../helpers/getConfigs';
import { CompanyType, FilterState, IOptionsConfig, keywordKeys } from 'modules/Filter/types';
import { ExcludesKeys, IMinMax, IMoreValues } from '../types';
import { companyTypeOptions, maxOptions, minOptions } from '../mock';
import { IOption } from 'components/Select/types';
import { getInitialMoreValues } from 'modules/Filter/helpers/getInitialMoreValues';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helpers/childOf';
import Keywords from './Keywords';
import CompanyNameKeywords from './CompanyNameKeywords';
import CompanyAddressKeywords from './CompanyAddressKeywords';

interface Props {
  type?: FilterState;
}

const MoreDropdown: FC<Props> = observer(({ type }) => {
  const { filtersStore, userStore } = useStore();
  const { t } = useTranslation();
  const configs: IOptionsConfig = getConfigs();
  const initValues = getInitialMoreValues();
  const [, setParams] = useSearchParams();
  const { pathname } = useLocation();

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IMoreValues>(initValues);

  const [selectedExcludes, setSelectedExcludes] = useState<ExcludesKeys[]>(
    type === FilterState.COMPANY_SEARCH ? ['companyName'] : []
  );

  const selectRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const params = filtersStore.searchParams;
    setFormValues({
      units: {
        min: minOptions.find((option) => option.value === String(params.units?.min)) || null,
        max: minOptions.find((option) => option.value === String(params.units?.max)) || null
      },
      levels: {
        min: minOptions.find((option) => option.value === String(params.levels?.min)) || null,
        max: minOptions.find((option) => option.value === String(params.levels?.max)) || null
      },
      status:
        configs.projectStatuses.find((status) => {
          return params.statuses?.length > 0 ? status.id === params.statuses[0] : false;
        }) || null,
      keywords: params.keywords,
      excludes: params.excludes,
      companyName: params.companyName || [],
      companyAddress: params.companyAddress || [],
      companyType: params.companyType
        ? {
            id: 0,
            value: params.companyType,
            text: params.companyType.charAt(0).toUpperCase() + params.companyType.slice(1)
          }
        : null
    });
  }, [filtersStore.searchParams]);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(selectRef.current as EventTarget)) {
      setIsOpened(false);
    }
  }, []);

  const toggleSelect = useCallback((e: React.MouseEvent): void => {
    if (e.nativeEvent.composedPath().includes(formRef.current as EventTarget)) return;
    setIsOpened((prev) => !prev);
  }, []);

  const handleFormChange = useCallback(
    (option: IOption, name: string) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      const [key, subKey]: string[] = name.split('-');
      if (!subKey) setFormValues((prev) => ({ ...prev, [key]: option }));
      if (subKey)
        setFormValues((prev) => ({
          ...prev,
          [key]: {
            ...prev[key as keyof Omit<IMoreValues, 'companyType'>],
            [subKey]: option
          }
        }));
    },
    [pathname]
  );

  const handleSubmit = useCallback(() => {
    for (const key in formValues) {
      switch (key) {
        case 'keywords':
          filtersStore.setSearchParams(key, formValues[key]);
          break;
        case 'excludes':
          filtersStore.setSearchParams(key, formValues[key]);
          break;
        case 'status':
          filtersStore.onSelectChange(formValues[key] as IOption, 'statuses');
          break;
        case 'companyName':
          type === FilterState.COMPANY_SEARCH && filtersStore.setSearchParams(key, formValues[key]);
          break;
        case 'companyAddress':
          type === FilterState.COMPANY_SEARCH && filtersStore.setSearchParams(key, formValues[key]);
          break;
        case 'companyType':
          type === FilterState.COMPANY_SEARCH &&
            filtersStore.setSearchParams(key, formValues[key]?.value as CompanyType);
          break;
        default: {
          const { min, max } = formValues[key as keyof IMoreValues] as IMinMax;
          filtersStore.onInputChange(
            {
              min: min?.value ? Number(min?.value) : null,
              max: max?.value ? Number(max?.value) : null
            },
            key
          );
        }
      }
    }
    setIsOpened(false);
  }, [formValues]);

  const handleResetField = useCallback((name: string) => {
    const [key, subKey]: string[] = name.split('-');
    if (!subKey) setFormValues((prev) => ({ ...prev, [key]: null }));
    if (subKey)
      setFormValues((prev) => ({
        ...prev,
        [key]: { ...prev[key as keyof Omit<IMoreValues, 'companyType'>], [subKey]: null }
      }));
  }, []);

  const keyPress = useCallback((e: KeyboardEvent): void => {
    if (e.code === ENTER) {
      if (
        !childOf(document.activeElement, selectRef.current) &&
        document.activeElement !== selectRef.current
      )
        setIsOpened(false);
      if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
    }
  }, []);

  const isActive = useMemo<boolean>(() => {
    return (
      !!filtersStore.searchParams.statuses?.length ||
      !!filtersStore.searchParams.units?.min ||
      !!filtersStore.searchParams.units?.max ||
      !!filtersStore.searchParams.levels?.max ||
      !!filtersStore.searchParams.levels?.min ||
      !!filtersStore.searchParams.keywords?.length ||
      !!filtersStore.searchParams.companyName?.length ||
      !!filtersStore.searchParams.companyAddress?.length ||
      !!filtersStore.searchParams.companyType
    );
  }, [filtersStore.searchParams]);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    if (!isOpened)
      return () => {
        window.removeEventListener('keydown', keyPress);
      };
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, [isOpened]);

  const handleSetKeyword = (
    name: 'keywords' | 'companyName' | 'companyAddress',
    keywordValue: string
  ): void => {
    setFormValues((prev) => ({
      ...prev,
      [name]: [...prev[name], keywordValue]
    }));
  };

  const handleRemoveKeyword = (name: keywordKeys, idx: number): void => {
    setFormValues((prev) => ({
      ...prev,
      [name]: prev[name].filter((keyword, i) => i !== idx)
    }));
  };

  const handleSetExclude = (keys: ExcludesKeys[]) => {
    setFormValues((prev) => ({
      ...prev,
      excludes: {
        address: keys.includes('address'),
        companyName: keys.includes('companyName'),
        description: keys.includes('description')
      }
    }));
  };

  useEffect(() => {
    const excludes: ExcludesKeys[] = [];
    for (const key in formValues.excludes) {
      const k = key as ExcludesKeys;
      if (Object.hasOwn(formValues.excludes, k) && formValues.excludes[k]) {
        excludes.push(k);
      }
    }
    setSelectedExcludes(excludes);
  }, [formValues.excludes]);

  return (
    <div
      role="button"
      className={clsx(
        classes.select,
        'relative',
        'flex',
        'items-center',
        'justify-between',
        'border-2',
        'border-grey-200',
        'rounded-sm',
        'text-sm',
        'h-8',
        'py-0.5',
        'pr-8',
        'pl-4',
        'mr-2',
        'select-none',
        'hover:bg-grey-100',
        isOpened && 'bg-primary text-white border-primary hover:bg-primary',
        styles.searchSelect,
        isActive && 'border-2 border-primary'
      )}
      tabIndex={0}
      data-test-element="search-projects-valuation-dropdown"
      onClick={toggleSelect}
      ref={selectRef}>
      <>
        <span role="button" className={clsx('block w-full', styles.text, 'font-kraftig')}>
          {t('searchProject.more')}
        </span>
        {isOpened && (
          <div
            className={clsx(
              classes.optionWrapper,
              'absolute w-[410px] max-h-fit max-h-fit overflow-visible overflow-y-scroll bg-white'
            )}
            ref={formRef}>
            <div
              className={clsx(
                type === FilterState.SEARCH || type === FilterState.SAVED_SEARCH
                  ? 'h-96'
                  : 'h-[530px]',
                'py-4 overflow-y-scroll custom-scroll-bar'
              )}>
              <div className="flex px-4 mb-4">
                <h3 className="m-0 text-dark text-sm whitespace-nowrap grow pt-1">
                  {t('searchProject.numberUnits')}
                </h3>
                <Select
                  options={minOptions}
                  value={formValues.units.min}
                  disableMore={formValues.units.max?.value}
                  onChange={handleFormChange}
                  placeholder="No Min"
                  placeholderClassName={styles.placeholder}
                  name="units-min"
                  className="text-dark w-full max-w-25 h-12 !border-0"
                  bgColor="bg-light-200"
                  data-test-element="search-projects-minUnits-select"
                />
                <div className="bg-dark h-0.5 w-3 my-0 mx-2.5 self-center" />
                <Select
                  options={maxOptions}
                  value={formValues.units.max}
                  disableLess={formValues.units.min?.value}
                  onChange={handleFormChange}
                  placeholder="No Max"
                  placeholderClassName={styles.placeholder}
                  name="units-max"
                  className="text-dark w-full max-w-25 h-12 bg-light-200 !border-0"
                  bgColor="bg-light-200"
                  onReset={handleResetField}
                  data-test-element="search-projects-maxUnits-select"
                />
              </div>
              <div className="flex px-4 mb-4">
                <h3 className="text-dark text-sm whitespace-nowrap grow m-0 pt-1">
                  {t('searchProject.levels')}
                </h3>
                <Select
                  options={minOptions}
                  value={formValues.levels.min}
                  disableMore={formValues.levels.max?.value}
                  onChange={handleFormChange}
                  placeholder="No Min"
                  placeholderClassName={styles.placeholder}
                  name="levels-min"
                  className="text-dark w-full max-w-25 h-12 !border-0"
                  bgColor="bg-light-200"
                  onReset={handleResetField}
                  data-test-element="search-projects-minLevels-select"
                />
                <div className="bg-dark h-0.5 w-3 my-0 mx-2.5 self-center" />
                <Select
                  options={maxOptions}
                  value={formValues.levels.max}
                  disableLess={formValues.levels.min?.value}
                  onChange={handleFormChange}
                  placeholder="No Max"
                  placeholderClassName={styles.placeholder}
                  name="levels-max"
                  className="text-dark w-full max-w-25 h-12 !border-0"
                  bgColor="bg-light-200"
                  onReset={handleResetField}
                  data-test-element="search-projects-maxLevels-select"
                />
              </div>
              <div className="flex px-4 mb-4">
                <h3 className="text-dark text-sm whitespace-nowrap grow m-0 pt-1">
                  {t('searchProject.status')}
                </h3>
                <Select
                  options={configs.projectStatuses}
                  value={formValues.status}
                  onChange={handleFormChange}
                  data-test-element="search-project-status-select"
                  placeholder="Any"
                  placeholderClassName="text-grey-500"
                  name="status"
                  className="text-dark w-full max-w-[232px] h-12 !border-0"
                  bgColor="bg-light-200"
                  onReset={handleResetField}
                />
              </div>
              <div className="flex px-4">
                <h3 className="m-0 text-dark text-sm whitespace-nowrap grow pt-1">
                  {t('searchProject.keywords')}
                </h3>
                <Keywords
                  keywords={formValues.keywords}
                  selectedExcludes={selectedExcludes}
                  onSetKeyword={(val) => handleSetKeyword('keywords', val)}
                  onRemoveKeyword={(idx) => handleRemoveKeyword('keywords', idx)}
                  onSetExclude={handleSetExclude}
                  type={type}
                />
              </div>
              {type && type === FilterState.COMPANY_SEARCH && (
                <div className="mt-4">
                  <div className="flex px-4 mb-4">
                    <h3 className="text-dark text-sm whitespace-nowrap grow m-0 pt-1">
                      Company Name
                    </h3>
                    <div className="w-58">
                      <CompanyNameKeywords
                        keywords={formValues.companyName || []}
                        onSetKeyword={(val) => handleSetKeyword('companyName', val)}
                        onRemoveKeyword={(idx) => handleRemoveKeyword('companyName', idx)}
                      />
                    </div>
                  </div>
                  <div className="flex px-4 mb-4">
                    <h3 className="m-0 text-dark text-sm whitespace-nowrap grow pt-1">
                      Company Address
                    </h3>
                    <div className="w-58">
                      <CompanyAddressKeywords
                        keywords={formValues.companyAddress || []}
                        onSetKeyword={(val) => handleSetKeyword('companyAddress', val)}
                        onRemoveKeyword={(idx) => handleRemoveKeyword('companyAddress', idx)}
                      />
                    </div>
                  </div>
                  <div className="flex px-4 mb-4">
                    <h3 className="m-0 text-dark text-sm whitespace-nowrap grow pt-1">
                      Company Type
                    </h3>
                    <div className="w-58 flex">
                      <Select
                        options={companyTypeOptions}
                        value={formValues.companyType}
                        onChange={handleFormChange}
                        placeholder="Select Company Type"
                        placeholderClassName="text-grey-500"
                        name="companyType"
                        className="text-dark w-full max-w-[232px] !h-12 !border-0"
                        bgColor="bg-light-200"
                        onReset={handleResetField}
                        data-test-element="company-type-select"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-light-400 h-px" />
            <div className="flex justify-between p-4">
              <Button type="primary" className="px-4 text-sm py-[5px]" onClick={handleSubmit}>
                <span className="font-kraftig">{t('searchProject.done')}</span>
              </Button>
            </div>
          </div>
        )}
      </>
      <div
        className={clsx(
          classes.icon,
          'absolute',
          'top-1/2',
          'flex',
          'items-center',
          '-translate-y-[45%]',
          'right-3',
          isOpened && classes.active
        )}
        role="button">
        <ArrowIcon />
      </div>
    </div>
  );
});

export default MoreDropdown;
