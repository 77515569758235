import React, { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as DefaultUserIcon } from 'assets/icons/default-avatar.svg';
import { Button } from 'components';
import { useStore } from 'storesProvider/storeContext';
import classes from './Profile.module.scss';
import { clsx } from 'utils/clsx';
import { EditEmailForm, EditPasswordForm, EditProfileForm } from './components';
import { useTranslation } from 'react-i18next';
import { BLUE_COLOR } from 'utils/constants';

const Profile: FC = observer(() => {
  const { userStore, configStore } = useStore();
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangePhoto = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (!files) return;
    const file = files[0];
    if (!file) return;
    try {
      setIsLoading(true);
      await userStore.setAvatar(file);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const pathToAvatar = useMemo<string>(() => {
    if (!userStore.user || !userStore.user?.avatar) return '';
    return (
      configStore.appConfig?.media.avatar.xs
        .replace('{userId}', String(userStore.user.avatar.userId))
        .replace('{mediaType}', userStore.user.avatar.type)
        .replace('{hash}', userStore.user.avatar.hash)
        .replace('{extension}', userStore.user.avatar.extension) || ''
    );
  }, [userStore.user?.avatar]);

  const avatarContent = useMemo<JSX.Element>(() => {
    if (isLoading) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <div className="loader">
            <div />
          </div>
        </div>
      );
    }

    if (userStore.user?.avatar) {
      return <img src={pathToAvatar} className="rounded-full" alt="" />;
    }
    return <DefaultUserIcon />;
  }, [userStore.user, isLoading]);

  if (!userStore.user) return <></>;

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="top-0 left-0 w-0 h-0 -z-10"
        onChange={handleFileChange}
      />
      <div className="w-full bg-white h-full -mt-6">
        <div className="flex">
          <div className="w-1/3 p-8 border-b border-b-light-600 border-r border-r-light-600 last:border-r-0 col-12 col-lg-4 col-sm-6">
            <h1 className="text-dark text-2xl mb-10">{t('profile.generalInformation')}</h1>
            <div className="flex flex-col items-center mb-6">
              <div
                className={clsx(
                  classes.cover,
                  'rounded-full',
                  'flex',
                  'justify-center',
                  'items-center',
                  'mb-3'
                )}>
                {avatarContent}
              </div>
              <Button
                type="light"
                onClick={handleChangePhoto}
                color={BLUE_COLOR}
                className="mb-3"
                data-test-element="change-photo-button">
                {t('profile.changePhoto')}
              </Button>
              <h2 className={clsx(classes.name, 'm-0')}>
                {userStore.user.firstName} {userStore.user.lastName}
              </h2>
              <p className={classes.email}>{userStore.user.email}</p>
            </div>
            <EditProfileForm user={userStore.user} />
          </div>
          <div className="w-1/3 p-8 border-b border-b-light-600 border-r border-r-light-600 last:border-r-0 col-12 col-lg-4 col-sm-6">
            <EditPasswordForm titleClassName="text-dark text-2xl mb-10" />
          </div>
          <div className="w-1/3 p-8 border-b border-b-light-600 border-r border-r-light-600 last:border-r-0 col-12 col-lg-4 col-sm-6">
            <EditEmailForm user={userStore.user} titleClassName="text-dark text-2xl mb-10" />
          </div>
        </div>
      </div>
    </>
  );
});

export default Profile;
