import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Pagination } from 'components';
import MatchTable from './MatchTable';
import { MatchOperationData, UploadStatusCode } from '../types';
import { Button } from 'components';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { ClickEvent } from 'utils/types';
import ProgressBar from 'components/ProgressBar';
import { useProgress } from 'hooks/useProgress';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { defaultMatchesData } from '../mock';

const modalStyle = {
  modal: 'w-[914px] !bg-light-200 max-h-[728px] px-6 pb-4',
  title: 'pt-4 !text-3xl',
  subTitle: 'text-grey-500 leading-5 mt-2.5'
};

const MatchModal: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { listUploadStore } = useStore();
  const [page, setPage] = useState<number>(1);
  const [isMatchesDataLoading, setIsMatchesDataLoading] = useState<boolean>(false);
  const { progress: matchesProgress } = useProgress(isMatchesDataLoading);

  const matchId = params.get('matchId');
  const searchId = params.get('searchId');

  useEffect(() => {
    return () => {
      listUploadStore.setMatchesData(defaultMatchesData);
      listUploadStore.setMatchOperationData(null);
    };
  }, []);

  useEffect(() => {
    (async () => {
      setIsMatchesDataLoading(true);
      await listUploadStore.retrieveContactsListByImportId(Number(matchId), page);
      setIsMatchesDataLoading(false);
    })();
  }, [matchId, page]);

  const handleMatchOperation = (data: MatchOperationData) => {
    listUploadStore.setMatchOperationData(data);
  };

  const onConfirm = useCallback(async () => {
    await listUploadStore.mergeContactFromFileImport();
    await listUploadStore.getImportHistoryData();
    if (
      listUploadStore.uploadData?.status === UploadStatusCode.START ||
      listUploadStore.uploadData?.status === UploadStatusCode.DONE
    ) {
      await listUploadStore.getStatusLastUploadedFile();
    }
    setParams('');
  }, [page, listUploadStore.uploadData]);

  const handlePageClick = (event: ClickEvent) => {
    setPage(event.selected + 1);
  };

  const showReviewLaterBtn = useMemo(() => {
    return listUploadStore.uploadData && !!listUploadStore.uploadData.found;
  }, [listUploadStore.uploadData]);

  return (
    <Modal
      show={true}
      closeModal={() => setParams('')}
      title={`Review ${listUploadStore.matchesData.count} Possible Matches`}
      subTitle={t('listUpload.createdListPermits')}
      classModal={modalStyle}
      hideFooter={true}>
      <div className="relative">
        <div className="absolute -top-1 inset-x-0">
          {isMatchesDataLoading && <ProgressBar progress={matchesProgress} thin />}
        </div>
        {!!listUploadStore.matchesData.data.length && (
          <MatchTable
            data={listUploadStore.matchesData.data}
            onMatchOperation={handleMatchOperation}
            matchData={listUploadStore.matchOperationData}
          />
        )}
      </div>
      <div className="flex justify-between mt-5">
        <div className="-mt-2.5">
          <Pagination
            onPageClick={handlePageClick}
            currentPage={listUploadStore.matchesData.currentPage}
            totalPages={listUploadStore.matchesData.totalPages}
          />
        </div>
        <div>
          <div className="flex">
            {showReviewLaterBtn && (
              <Button
                type="secondary"
                className="w-40 h-12 border-primary text-primary mr-4"
                onClick={() => navigate(`/saved-search/${Number(searchId)}`)}>
                {t('listUpload.reviewLater')}
              </Button>
            )}
            <Button
              type="primary"
              disabled={isMatchesDataLoading || !listUploadStore.matchOperationData.length}
              className="w-40 h-12"
              onClick={onConfirm}>
              {t('listUpload.confirm')}
            </Button>
          </div>
          <p className="mt-4 text-right text-grey-500 text-sm">{t('listUpload.actionUndone')}</p>
        </div>
      </div>
    </Modal>
  );
});

export default MatchModal;
