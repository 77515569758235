import React, { FC, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import closeIcon from 'assets/icons/close.svg';
import Button from '../Button';
import { IModalActions } from './types';
import { clsx } from 'utils/clsx';

interface Props {
  show: boolean;
  title: string;
  subTitle?: string;
  closeModal: () => void;
  onSubmit?: () => void;
  hideFooter?: boolean;
  actions?: IModalActions;
  modalClass?: string;
  bodyClass?: string;
  [key: string]: unknown;
}

const ConfirmModal: FC<Props> = ({
  show,
  title,
  subTitle,
  actions,
  closeModal,
  onSubmit,
  hideFooter,
  modalClass,
  bodyClass,
  ...rest
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
        {...rest}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto bg-shadesBlue-400 bg-opacity-20">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={clsx(
                  modalClass,
                  'relative',
                  'transform',
                  'overflow-hidden',
                  'rounded-sm',
                  'bg-white',
                  'px-4',
                  'pb-4',
                  'text-left',
                  'shadow-overlay',
                  'transition-all',
                  'sm:w-full',
                  'sm:max-w-lg'
                )}>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="modal-header border-0 pl-4 pr-2 w-full pb-0">
                      <div className="w-full flex justify-end">
                        <img
                          src={closeIcon}
                          alt="close"
                          className="relative"
                          onClick={closeModal}
                          role="button"
                        />
                      </div>
                    </div>
                    <Dialog.Title as="h2" className="text-dark font-medium leading-6 font-kraftig">
                      {title}
                    </Dialog.Title>
                    <div className={clsx(bodyClass, 'modal-body px-4 m-auto pt-2.5')}>
                      {subTitle && (
                        <p className="m-0 text-base text-grey-500 leading-5">{subTitle}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex mt-10 justify-center">
                  {actions && (
                    <>
                      {actions.negative && (
                        <Button type="secondary" className="px-6 mr-3" onClick={closeModal}>
                          <span className="font-kraftig">{actions.negative}</span>
                        </Button>
                      )}
                      {actions.positive && (
                        <Button type="primary" className="px-6" onClick={onSubmit}>
                          <span className="font-kraftig">{actions.positive}</span>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmModal;
