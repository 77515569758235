import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import { Switch } from '@headlessui/react';

interface Props {
  enabled: boolean;
  onEnabled: (enabled: boolean) => void;
  label: string;
}

const SwitchItem: FC<Props> = ({ enabled, onEnabled, label }) => {
  return (
    <div className="flex">
      <Switch
        checked={enabled}
        onChange={onEnabled}
        className={clsx(
          enabled ? 'bg-primary' : 'bg-light-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}>
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <label className="pl-4">{label}</label>
    </div>
  );
};

export default SwitchItem;
