import {
  ISavedSearchRepo,
  ISavedSearchService,
  SAVED_SEARCH_REPO,
  ISavedSearch,
  ExportData,
  HasSubscriptionLocation
} from './types';
import { ISearchParams } from 'modules/Filter/types';
import { injector } from 'utils/injector';
import { FoundLocation } from '../Search/types';
import { DetailInfo } from '../SearchProjects/components/Map/types';
import filterForRequest from 'helpers/filtersForRequest';
import { SearchType } from 'utils/types';

export class SavedSearchManageService implements ISavedSearchService {
  private _savedSearchRepo: ISavedSearchRepo = injector.get<ISavedSearchRepo>(SAVED_SEARCH_REPO);

  async saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<ISavedSearch> {
    return (await this._savedSearchRepo.saveSearch(id, name, filterForRequest(filters), searchType))
      .data.result;
  }

  async getSavedSearches(id: number): Promise<ISavedSearch[]> {
    return (await this._savedSearchRepo.getSavedSearches(id)).data.result;
  }
  async updateTitle(userId: number, searchId: number, name: string): Promise<ISavedSearch> {
    return (await this._savedSearchRepo.updateTitle(userId, searchId, name)).data.result;
  }

  async clone(userId: number, searchId: number): Promise<ISavedSearch> {
    return (await this._savedSearchRepo.clone(userId, searchId)).data.result;
  }

  async delete(userId: number, searchId: number): Promise<void> {
    return await this._savedSearchRepo.delete(userId, searchId);
  }

  async deleteShareSavedSearch(userId: number, searchId: number): Promise<void> {
    return await this._savedSearchRepo.deleteShareSavedSearch(userId, searchId);
  }

  async getNewProjectCount(userId: number, searchType: SearchType): Promise<number> {
    return (await this._savedSearchRepo.getNewProjectCount(userId, searchType)).data.result;
  }

  async getLocationsInfo(locationIds: number[]): Promise<FoundLocation[]> {
    const res = (await this._savedSearchRepo.getLocationsInfo(locationIds)).data.result;
    return res.map((item: DetailInfo) => ({
      id: item.id,
      title: item.title,
      type: item.type,
      canonicalTitle: item.canonicalTitle,
      stateCode: item.stateCode,
      subscription: false
    }));
  }

  async runExport(userId: number, searchId: number): Promise<void> {
    await this._savedSearchRepo.runExport(userId, searchId);
  }

  async getExportFile(userId: number, searchId: number): Promise<ExportData> {
    return (await this._savedSearchRepo.getExportFile(userId, searchId)).data.result;
  }

  async hasSubscription(locations: number[]): Promise<HasSubscriptionLocation[]> {
    return (await this._savedSearchRepo.hasSubscription(locations)).data.result;
  }
}
