import {
  AnalyticsDataRes,
  FavoriteAnalytics,
  IAnalyticsRepo,
  Params,
  ParamsForPost
} from './types';
import { IAxiosResponse, ITableData, ObjectValues } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class AnalyticsRepo implements IAnalyticsRepo {
  async getFavouriteAnalytics(
    userId: number,
    lists: number[]
  ): Promise<IAxiosResponse<FavoriteAnalytics[]>> {
    return await axiosInstance.get(`users/${userId}/favourites/analytics`, { params: { lists } });
  }

  async getFavoriteAnalyticsData(
    userId: number,
    params: ParamsForPost | Params,
    lists: ObjectValues<number>
  ): Promise<IAxiosResponse<ITableData<AnalyticsDataRes>>> {
    return await axiosInstance.get(`users/${userId}/favourites/analytics/items`, {
      params: {
        page: params.page,
        ...lists,
        ...params.order
      }
    });
  }
}
