import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, Location, Navigate } from 'react-router-dom';
import { Nav } from 'components';
import { INavItems } from 'components/Nav/types';
import Header from 'view/Header';

const ProfileLayout: FC = () => {
  const location: Location = useLocation();
  const [tabs, setTabs] = useState<INavItems[]>([
    {
      name: 'Profile settings',
      link: '/profile/settings',
      active: true
    },
    {
      name: 'Subscription',
      link: '/profile/subscription',
      active: false
    },
    {
      name: 'Notifications',
      link: '/profile/notifications',
      active: false
    }
  ]);

  useEffect(() => {
    setTabs((tabs) =>
      tabs.map((tab) => ({ ...tab, active: location.pathname.includes(tab.link) }))
    );
  }, [location]);

  if (location.pathname === '/profile') {
    return <Navigate to="settings" />;
  }

  return (
    <>
      <Header>
        <Nav items={tabs} mode="light" />
      </Header>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default ProfileLayout;
