import { ContactById, ICompanyResponse, IContactInfoRepo } from './types';
import { axiosInstance } from 'utils/API';
import { entityTypesValues, IAxiosResponse } from 'utils/types';

export class ContactInfoRepo implements IContactInfoRepo {
  async getContactById(id: number, type: entityTypesValues): Promise<IAxiosResponse<ContactById>> {
    return await axiosInstance.get(`contacts/${type}/${id}`);
  }

  async getCompany(
    projectId: number,
    role: string,
    contactId: number
  ): Promise<IAxiosResponse<ICompanyResponse>> {
    return await axiosInstance.get(`/projects/${projectId}/${role}/company/${contactId}`);
  }

  async getCompanyContactDetailsById(contactId: number): Promise<IAxiosResponse<ICompanyResponse>> {
    return await axiosInstance.get(`contacts/${contactId}/companyDetails`);
  }
}
