import webHoverIcon from 'assets/icons/web-blue.svg';
import favoriteIcon from 'assets/icons/favorite.svg';
import favoriteActiveIcon from 'assets/icons/favorite-active.svg';
import documentIcon from 'assets/icons/document.svg';
import shareIcon from 'assets/icons/share.svg';
import trashIcon from 'assets/icons/trash.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import phoneIcon from 'assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import emailIcon from 'assets/icons/email.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-contact.svg';
import linkedinIcon from 'assets/icons/linkedin-contact.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import webIcon from 'assets/icons/website.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/makers.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-contacts.svg';
import userIcon from 'assets/icons/user-contacts.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-grey.svg';
import locationIcon from 'assets/icons/location-grey.svg';
import { ReactComponent as WebIcon } from 'assets/icons/web-grey.svg';
import phoneHoverIcon from 'assets/icons/phone-blue.svg';
import linkedinHoverIcon from 'assets/icons/linkedin-blue.svg';
import locationHoverIcon from 'assets/icons/location-blue.svg';
import { IIconConfig } from 'utils/types';
import emailHoverIcon from 'assets/icons/email-blue.svg';
import userHoverIcon from 'assets/icons/user-blue.svg';
import favoriteGreyIcon from 'assets/icons/favorite-grey.svg';
import documentGreyIcon from 'assets/icons/document-grey.svg';
import shareGreyIcon from 'assets/icons/share-grey.svg';
import { FunctionComponent } from 'react';

export const iconList: { [key: string]: string } = {
  favorite: favoriteIcon,
  favoriteGrey: favoriteGreyIcon,
  document: documentIcon,
  documentGrey: documentGreyIcon,
  share: shareIcon,
  shareGrey: shareGreyIcon,
  delete: trashIcon
};

export const iconActiveList: { [key: string]: string } = {
  favorite: favoriteActiveIcon,
  document: documentIcon,
  share: shareIcon,
  delete: trashIcon
};

export const detailIcons: IIconConfig = {
  10: PhoneIcon,
  20: EmailIcon,
  30: LinkedinIcon,
  40: WebsiteIcon,
  50: GroupIcon,
  60: UserIcon
};

export const iconListByNum: { [key: number]: FunctionComponent } = {
  10: PhoneIcon,
  20: EmailIcon,
  30: LinkedinIcon,
  40: WebIcon,
  50: LocationIcon,
  60: UserIcon
};

export const iconSrcListByNum: { [key: number]: string } = {
  10: phoneIcon,
  20: emailIcon,
  30: linkedinIcon,
  40: webIcon,
  50: locationIcon,
  60: userIcon
};

export const iconListHoverByNum: { [key: number]: string } = {
  10: phoneHoverIcon,
  20: emailHoverIcon,
  30: linkedinHoverIcon,
  40: webHoverIcon,
  50: locationHoverIcon,
  60: userHoverIcon
};
