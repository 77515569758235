import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import closeIcon from 'assets/icons/close-dark.svg';
import { Tab } from 'components';
import { ITabsItems } from 'components/Tab/types';
import { GeneralInfo } from 'components';
import { useStore } from 'storesProvider/storeContext';
import RecentProjects from 'view/SearchProjects/components/RecentProjects';
import { IFavoriteEntity } from 'view/Favorites/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NA, COMPANY } from 'utils/constants';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { EntityType, entityTypes, AllEntityTypes, AllRoles } from 'utils/types';

interface Props {
  onUpdateFavorite?: (id: number, isActive: boolean) => void;
}

const ContactInfo: FC<Props> = observer(({ onUpdateFavorite }) => {
  const { projectSearchStore, configStore, userStore, contactInfoStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
  const [isRecentLoading, setIsRecentLoading] = useState<boolean>(false);
  const [tabItems, setTabItems] = useState<ITabsItems[]>([]);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const contactId = params.get('contacts');
  const projectId = params.get('project');
  const contactType = Number(params.get('type')) as EntityType;
  const contactRole = params.get('role') ? (Number(params.get('role')) as AllRoles) : null;

  //TODO: contactRole and contactType === 10 | 20 | 30
  useEffect(() => {
    // contactRole
    if (contactId && contactType) {
      const type = entityTypes[contactType];
      (async () =>
        await contactInfoStore.getContactById(
          Number(contactId),
          type,
          contactRole || AllRoles.Contractor
        ))();
      return;
    }
    setParams('');
  }, [contactId]);

  useEffect(() => {
    if (contactInfoStore.contact) {
      setIsFavorite(contactInfoStore.contact.favourite);
      if (contactInfoStore.contact.favourite) {
        const contactForFavorite = {
          id: contactInfoStore.contact.id,
          role: contactInfoStore.contact.role,
          type: contactInfoStore.contact.type
        };
        //TODO: need refactoring favorite system
        //In this code favoriteContacts can have duplicate
        projectSearchStore.setFavoriteContacts(
          projectSearchStore.favoriteContacts.concat([contactForFavorite])
        );
      }
    }
  }, [contactInfoStore.contact]);

  const closeFavorite = useCallback(() => {
    projectSearchStore.setActiveFavorite(null);
  }, []);

  const handleFavorite = useCallback(
    (isActive: boolean, id: number): void => {
      if (contactInfoStore.contact) {
        setIsFavorite(isActive);
        projectSearchStore.updateFavoriteContacts(
          isActive,
          id,
          contactInfoStore.contact?.type,
          contactInfoStore.contact?.role
        );
        if (onUpdateFavorite) {
          onUpdateFavorite(id, isActive);
        }
      }
    },
    [contactInfoStore.contact]
  );

  const handleTitleClick = useCallback(() => {
    setActiveTab(1);
  }, []);

  const getContactType = useCallback(
    (id: number): string => {
      return configStore.enums?.contactTypes.find((type) => type.id === id)?.name || '';
    },
    [configStore.enums]
  );

  const role = useMemo(() => {
    const projectContact = contactInfoStore.contact;
    if (!projectContact || getContactType(projectContact.type).toLowerCase() !== COMPANY)
      return null;
    return configStore.enums?.projectRoles.find((role) => role.id === projectContact.role)?.value;
  }, [configStore.enums, contactInfoStore.contact]);

  const entity = useMemo<IFavoriteEntity | null>(() => {
    const projectContact = contactInfoStore.contact;
    if (!projectContact) return null;
    const entity: IFavoriteEntity = {
      id: projectContact.id,
      coordinates: {} as DOMRect,
      type: projectContact.type,
      role: projectContact.role,
      key: 'sidebar',
      name: projectContact ? projectContact.name : 'Contact'
    };
    return entity;
  }, [contactInfoStore.contact]);

  const openModal = useCallback(
    (e: MouseEvent): void => {
      if (!userStore.user) {
        navigate('?auth=sign-in');
        return;
      }
      const thisModal = document.getElementById(
        `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
      );
      if (!entity) return;
      projectSearchStore.setActiveFavorite(thisModal ? null : { id: entity.id, type: 'sidebar' });
      e.stopPropagation();
    },
    [entity]
  );

  const isModalShown = useMemo<boolean>(() => {
    return (
      projectSearchStore.activeFavorite?.id === entity?.id &&
      projectSearchStore.activeFavorite?.type === 'sidebar'
    );
  }, [entity, projectSearchStore.activeFavorite]);

  const handleUpdateProjects = async (): Promise<void> => {
    await getRecentProjects(1);
  };

  useEffect(() => {
    const projectContact = contactInfoStore.contact;
    (async () => {
      if (contactType === AllEntityTypes.Company && !projectId) {
        try {
          setIsCompanyLoading(true);
          await contactInfoStore.getCompanyContactDetailsById(Number(contactId));
          setAllTab();
        } finally {
          setIsCompanyLoading(false);
        }
        return;
      }
      if (projectContact && projectId && role && contactType === AllEntityTypes.Company) {
        try {
          setIsCompanyLoading(true);
          await contactInfoStore.getCompany(Number(projectId), role, projectContact.id);
          setAllTab();
        } finally {
          setIsCompanyLoading(false);
        }
        return;
      }
      contactInfoStore.setCompany(null);
      setActiveTab(1);
      setTabItems([
        {
          name: t('searchProject.recentProjects'),
          active: activeTab === 1,
          tabId: 1
        }
      ]);
    })();
  }, [contactInfoStore.contact, role]);

  const setAllTab = () => {
    setTabItems([
      {
        name: t('searchProject.generalInfo'),
        active: activeTab === 1,
        tabId: 1
      },
      {
        name: t('searchProject.recentProjects'),
        active: activeTab === 2,
        tabId: 2
      }
    ]);
  };

  useEffect(() => {
    setTabItems((prev) => {
      return prev.map((tab) => {
        return {
          ...tab,
          active: activeTab === tab.tabId
        };
      });
    });
  }, [activeTab]);

  useEffect(() => {
    (async () => await getRecentProjects(1))();
  }, [contactInfoStore.contact]);

  const getRecentProjects = useCallback(
    async (page: number): Promise<void> => {
      const projectContact = contactInfoStore.contact;
      if (projectContact) {
        try {
          setIsRecentLoading(true);
          await projectSearchStore.getRecentProjects(
            getContactType(projectContact.type).toLowerCase(),
            projectContact.id,
            page
          );
        } finally {
          setIsRecentLoading(false);
        }
      }
    },
    [contactInfoStore.contact]
  );

  const clickOutside = (e: MouseEvent): void => {
    const favoriteModals = document.querySelector('#modal-root')?.children.length;
    if (!favoriteModals) e.stopPropagation();
    if (e.target === e.currentTarget) {
      e.stopPropagation();
      projectSearchStore.setActiveFavorite(null);
      setActiveTab(1);
      contactInfoStore.setContact(null);
      setParams('');
    }
  };

  const getContactName = useCallback((): string => {
    const projectContact = contactInfoStore.contact;
    return projectContact ? projectContact.name : '';
  }, [contactInfoStore.contact]);

  const tabConditions = useCallback(
    (tab: number): boolean => {
      const projectContact = contactInfoStore.contact;
      if (projectContact && projectContact.type === AllEntityTypes.Company) {
        return activeTab === tab;
      }
      return true;
    },
    [contactInfoStore.contact, activeTab]
  );

  const handleTabAction = (tabId: number): void => setActiveTab(tabId);

  const getRecentProjectsValue = useCallback((): string => {
    return projectSearchStore.recentProjects.value
      ? `$${numberWithSpaces(projectSearchStore.recentProjects.value)}`
      : NA;
  }, [projectSearchStore.recentProjects]);

  const back = () => navigate(-1);

  const close = () => {
    contactInfoStore.setContact(null);
    setParams('');
  };

  return (
    <div className="absolute inset-0 z-10 overflow-hidden bg-shadesBlue-400 bg-opacity-[.21]">
      <div onClick={clickOutside} className="flex justify-end h-screen text-dark">
        <div className="w-[656px] bg-white z-[11]">
          {/*TODO: create RightSidebarHeader*/}
          <div className="p-4 bg-[#F3F5F8]">
            <div className="flex justify-between items-center mb-4">
              <Arrow className="-rotate-90 mr-2" onClick={back} />
              <h5 className="m-0 text-lg">{getContactName()}</h5>
              <div className="flex items-center">
                {entity && (
                  <span className="mr-4">
                    <AddToFavorites
                      onHeartClick={openModal}
                      closeModal={closeFavorite}
                      entity={entity}
                      toggleCallback={handleFavorite}
                      isFavorite={isFavorite}
                      isModalShown={isModalShown}
                    />
                  </span>
                )}
                <img
                  src={closeIcon}
                  alt="close"
                  className="relative left-[9px]"
                  onClick={close}
                  role="button"
                />
              </div>
            </div>
            <span className="mb-2 font-kraftig">
              {t('searchProject.analyticsForTheLastMonths')}
            </span>
            <div className="flex justify-between mt-3 mb-2">
              <span>{t('searchProject.totalProjects')}</span>
              <span className="font-kraftig">{projectSearchStore.recentProjects.count}</span>
            </div>
            <div className="flex justify-between">
              <span>{t('searchProject.totalAmount')}</span>
              <span className="font-kraftig">{getRecentProjectsValue()}</span>
            </div>
          </div>
          {!!tabItems.length && (
            <div>
              <Tab
                items={tabItems}
                mode="light"
                navClass="py-0 px-6 border-t border-t-light-600 border-b border-b-light-600"
                action={handleTabAction}
                stopPropagation
              />
              {tabConditions(1) && (
                <GeneralInfo isLoading={isCompanyLoading} company={contactInfoStore.company} />
              )}
              {tabConditions(2) && (
                <div>
                  <RecentProjects
                    isLoading={isRecentLoading}
                    closeFavorite={closeFavorite}
                    moreProjects={getRecentProjects}
                    onLoadProjectInfo={handleTitleClick}
                    handleUpdateProjects={handleUpdateProjects}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ContactInfo;
