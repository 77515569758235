import {
  IContactInfoService,
  CONTACT_INFO_REPO,
  IContactInfoRepo,
  ContactById,
  ICompanyResponse
} from './types';
import { injector } from 'utils/injector';
import { entityTypesValues } from 'utils/types';
import { iconListByNum } from 'utils/icons';

const addIconsForPersonAndDetails = (data: ICompanyResponse): ICompanyResponse => {
  return {
    ...data,
    details: data.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] })),
    projectPersons: data.projectPersons.map((person) => ({
      ...person,
      details: person.details.map((detail) => ({
        ...detail,
        icon: iconListByNum[detail.type]
      }))
    })),
    otherPersons: data.otherPersons.map((person) => ({
      ...person,
      details: person.details.map((detail) => ({
        ...detail,
        icon: iconListByNum[detail.type]
      }))
    }))
  };
};

export class ContactInfoService implements IContactInfoService {
  private _contactInfoRepo: IContactInfoRepo = injector.get<IContactInfoRepo>(CONTACT_INFO_REPO);

  async getContactById(id: number, type: entityTypesValues): Promise<ContactById> {
    return (await this._contactInfoRepo.getContactById(id, type)).data.result;
  }

  async getCompany(projectId: number, role: string, contactId: number): Promise<ICompanyResponse> {
    const res = (await this._contactInfoRepo.getCompany(projectId, role, contactId)).data.result;
    return addIconsForPersonAndDetails(res);
  }

  async getCompanyContactDetailsById(contactId: number): Promise<ICompanyResponse> {
    const res = (await this._contactInfoRepo.getCompanyContactDetailsById(contactId)).data.result;
    return addIconsForPersonAndDetails(res);
  }
}
