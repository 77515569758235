import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { ClickEvent } from 'utils/types';

interface Props {
  onPageClick: (event: ClickEvent) => void;
  currentPage: number;
  totalPages: number;
}

const Pagination: FC<Props> = ({ onPageClick, currentPage, totalPages }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<ArrowIcon data-direction="left" />}
      previousLabel={<ArrowIcon data-direction="right" />}
      onPageChange={onPageClick}
      forcePage={currentPage - 1}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      pageCount={totalPages}
      containerClassName="pagination flex mb-2 justify-center"
      activeClassName="active"
      activeLinkClassName="active"
      breakClassName="page-item"
      breakLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
      pageClassName="page-item mx-1"
      pageLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
      previousClassName="page-item mx-1"
      previousLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
      nextClassName="page-item mx-1"
      nextLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
    />
  );
};

export default Pagination;
