import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components';
import SubHeader from './components/SubHeader';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isNaN } from 'formik';
import { orderProcessing } from 'helpers/orderProcessing';
import { IFavoriteState, FavoriteTabSystemType } from 'view/Favorites/types';
import ProjectInfoModal from 'modules/ProjectInfoModal/Modal';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';
import {
  AllEntityTypes,
  AllRoles,
  ClickEvent,
  EntityType,
  IAxiosError,
  IAxiosErrorResponse,
  ISort,
  SearchStringType,
  SearchType
} from 'utils/types';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import Header from 'view/Header';
import classes from './SavedSearchResults.module.scss';
import Filters from './components/Filters';
import { useFiltersActions } from 'hooks/useFiltersActions';
import { clsx } from 'utils/clsx';
import { getLocationParamsFromSelectedLocation } from 'helpers/createLocationParams';
import { ISavedSearch } from 'view/SavedSearches/types';
import InfoModal from '../InfoModal';
import { COMPANY, HIDE_RED_DOTS_DELAY, PROJECT } from 'utils/constants';
import ContactInfo from 'modules/ContactInfoModal';
import { ISearchParams } from 'modules/Filter/types';
import CompanyTable from 'view/CompanySearch/components/Table';
import { CompanyData } from 'view/CompanySearch/types';
import Table from './components/SavedSearchTable';

interface IState {
  edit: boolean;
}

const SavedSearchResults: FC = observer(() => {
  const { id, token, entityType } = useParams();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const {
    savedSearchesStore,
    savedSearchResultsStore,
    userStore,
    shareStore,
    searchStore,
    alertStore,
    filtersStore
  } = useStore();
  const searchId = id ? Number(id) : null;
  const sharedEntityType: SearchStringType | null = (entityType as SearchStringType) ?? null;
  const [params, setParams] = useSearchParams();
  const details = params.get('details');
  const contacts = params.get('contacts');
  const [page, setPage] = useState<number>(1);
  const [isSearchesLoading, setIsSearchesLoading] = useState<boolean>(false);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<ISort[]>([]);
  const [favoriteState, setFavoriteState] = useState<IFavoriteState>({
    entity: null,
    showAddToFavorite: false,
    favoriteId: null
  });
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [editFilters, setEditFilters] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [currentSavedSearches, setCurrentSavedSearches] = useState<ISavedSearch | null>(null);
  const { setFilters, createOriginalFilters, filterComparison } = useFiltersActions();
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [crossReferencingType, setCrossReferencingType] = useState<SearchStringType>('project');
  // const [filterIdByToken, setFilterIdByToken] = useState<number | null>(null);
  const {
    progress: projectsProgress,
    visible: projectsVisible,
    reload: projectsReloadProgress
  } = useProgress(isProjectsLoading);
  const { progress: searchesProgress, visible: searchesVisible } = useProgress(isSearchesLoading);

  const isUnsubscribedLocations = useMemo((): boolean => {
    return !!savedSearchResultsStore.unsubscribedLocations.length;
  }, [savedSearchResultsStore.unsubscribedLocations]);

  const toggleReferenceType = () => {
    setCrossReferencingType((prev) => (prev === 'project' ? 'company' : 'project'));
  };

  const handleOrder = useCallback(
    (field: string): void => {
      projectsReloadProgress();
      setOrders(orderProcessing(field, orders));
    },
    [projectsReloadProgress, orders]
  );

  const handleOpenProjectInfo = useCallback(
    (id: number) => {
      setParams(`details=${id}`);
    },
    [pathname]
  );

  const navigate = useNavigate();

  // TODO: remove after test
  // const onSearchChange = useCallback(
  //   (id: number) => {
  //     projectsReloadProgress();
  //     const path = isNaN(id) ? 'all' : String(id);
  //     setPage(1);
  //     navigate('/saved-search/' + path, { replace: true });
  //   },
  //   [projectsReloadProgress]
  // );

  const loadSearches = useCallback(async () => {
    if (!userStore.user) return;
    try {
      setIsSearchesLoading(true);
      await savedSearchesStore.getSavedSearches(userStore.user.id);
      const savedSearch = savedSearchesStore.getSavedSearchById(searchId);
      setCurrentSavedSearches(savedSearch);
    } finally {
      setIsSearchesLoading(false);
    }
  }, [searchId, currentSavedSearches]);

  const loadProjects = useCallback(async () => {
    if (!userStore.user) return;
    try {
      setIsProjectsLoading(true);
      // TODO: move to separate useEffect
      if (token) {
        sharedEntityType === 'project'
          ? await savedSearchResultsStore.getShareProjectSavedSearch(token, page, orders)
          : await savedSearchResultsStore.getShareCompanySavedSearch(token, page, orders);
        if (!savedSearchResultsStore.shareSearchFilter) return;
        return navigate(`/saved-search/${savedSearchResultsStore.shareSearchFilter.id}`, {
          replace: true
        });
      }
      // end
      if (searchId) {
        const ids = isNaN(searchId)
          ? savedSearchesStore.savedSearches.map((search) => search.id)
          : [searchId];
        if (currentSavedSearches?.searchType === SearchType.CROSS_REFERENCING_BOTH) {
          await savedSearchResultsStore.getCrossReferencingSavedSearches(
            userStore.user.id,
            searchId,
            page,
            orders,
            crossReferencingType
          );
        }
        if (currentSavedSearches?.searchType === SearchType.PROJECT) {
          await savedSearchResultsStore.getSavedSearchesProjectsBySearchType(
            userStore.user.id,
            ids,
            page,
            orders
          );
        }
        if (currentSavedSearches?.searchType === SearchType.COMPANY) {
          await savedSearchResultsStore.getSavedSearchesCompaniesBySearchType(
            userStore.user.id,
            ids,
            page,
            orders
          );
        }
      }
    } catch (e) {
      navigate('/saved-search', { replace: true });
    } finally {
      setIsProjectsLoading(false);
      if (currentSavedSearches) {
        await savedSearchesStore.getNewProjectCount(
          userStore.user.id,
          currentSavedSearches.searchType
        );
      }
    }
  }, [
    searchId,
    token,
    orders,
    shareStore.shareTokens,
    page,
    currentSavedSearches,
    crossReferencingType
  ]);

  const handleUpdateNote = useCallback(
    (id: number, hasNote: boolean, type: FavoriteTabSystemType) => {
      type === 'project'
        ? savedSearchResultsStore.setProjectNoteStatus(id, hasNote)
        : savedSearchResultsStore.setCompanyNoteStatus(id, hasNote);
    },
    []
  );

  useLayoutEffect(() => {
    if (token) {
      setPage(1);
    }
  }, [token]);

  useEffect(() => {
    (async () => await loadProjects())();
  }, [page, searchId, orders, currentSavedSearches, crossReferencingType]);

  useEffect(() => {
    setPage(1);
  }, [currentSavedSearches]);

  useEffect(() => {
    if (token) {
      return;
    }
    if (isNaN(Number(id)) && id !== 'all') {
      navigate('/saved-search', { replace: true });
    }
    (async () => await loadSearches())();
    if (state && (state as IState).edit) {
      setEditFilters(true);
    }
    setTimeout(() => {
      savedSearchResultsStore.hideRedDots();
    }, HIDE_RED_DOTS_DELAY);
    return () => {
      // savedSearchResultsStore.setTableRows([]);
      savedSearchResultsStore.setProjects(null);
      savedSearchResultsStore.setUnsubscribedLocations([]);
    };
  }, [token]);

  useEffect(() => {
    (async () => await filtersProcessing())();
  }, [currentSavedSearches]);

  const filtersProcessing = useCallback(async () => {
    if (currentSavedSearches) {
      setFiltersReady(false);
      if (currentSavedSearches.filters.locations.length) {
        const locations = await savedSearchesStore.getLocationsInfo(
          currentSavedSearches.filters.locations
        );
        searchStore.setSelectedLocation(locations);
      }
      setFilters(currentSavedSearches.filters);
      createOriginalFilters();
      setFiltersReady(true);
    }
  }, [currentSavedSearches]);

  useEffect(() => {
    if (savedSearchResultsStore.unsubscribedLocations.length) {
      setShowPlan(!!savedSearchResultsStore.unsubscribedLocations.length);
    }
  }, [savedSearchResultsStore.unsubscribedLocations]);

  useEffect(() => {
    if (savedSearchResultsStore.shareSearchFilter) {
      savedSearchesStore.setSharedSavedSearches(savedSearchResultsStore.shareSearchFilter);
      // setCurrentSavedSearches(savedSearchResultsStore.shareSearchFilter);
    }
  }, [savedSearchResultsStore.shareSearchFilter]);

  const favoriteCallback = useCallback(
    (id: number, isActive: boolean) => {
      // if (favoriteState.favoriteId) {
      //   savedSearchResultsStore.updateFavoriteRow(favoriteState.favoriteId, isActive);
      // }
      savedSearchResultsStore.updateFavoriteRow(id, isActive);
    },
    [favoriteState.favoriteId]
  );

  const handleUpdateAfterPayment = (): void => {
    (async () => await loadProjects())();
  };

  const handlePageClick = (event: ClickEvent) => {
    setPage(event.selected + 1);
  };

  // const totalPage = useMemo((): number => {
  //   const pages =
  //     currentSavedSearches?.searchType === SearchType.PROJECT
  //       ? savedSearchResultsStore.totalPages
  //       : savedSearchResultsStore.companies?.totalPages;
  //   return pages || 1;
  // }, [currentSavedSearches]);

  const openMap = async (): Promise<void> => {
    if (id === 'all') return;
    if (currentSavedSearches) {
      // removeFilters();
      const locations = await savedSearchesStore.getLocationsInfo(
        currentSavedSearches.filters.locations
      );
      // searchStore.setSelectedLocation(locations);
      // setFilters(currentSavedSearches.filters);
      navigate(`/search-projects/${getLocationParamsFromSelectedLocation(locations)}`, {
        replace: true,
        state: {
          useFilters: true
        }
      });
    }
  };

  const handleEditFilters = () => {
    setEditFilters((prev) => {
      if (prev && !filterComparison()) {
        setShowInfoModal(true);
        return prev;
      }
      return !prev;
    });
  };

  const handleSaveTitle = useCallback(
    async (newName: string) => {
      if (!userStore.user || !currentSavedSearches) return;
      try {
        await savedSearchesStore.updateTitle(userStore.user.id, currentSavedSearches.id, newName);
        setCurrentSavedSearches(savedSearchesStore.getSavedSearchById(searchId));
        alertStore.successAlert(t('savedSearch.editSuccess'));
      } catch (e) {
        const errorMessage: string = (e as IAxiosError<IAxiosErrorResponse>).response.data.message;
        alertStore.errorAlert(errorMessage);
      }
    },
    [currentSavedSearches]
  );

  let controller: AbortController | null = null;
  const updateFilters = useCallback(
    async (filters?: ISearchParams) => {
      if (
        !userStore.user ||
        !searchId ||
        !(currentSavedSearches && currentSavedSearches.searchType)
      )
        return;
      if (isSearchesLoading) {
        controller?.abort();
      }
      setIsProjectsLoading(true);
      controller = new AbortController();
      const crossRefType =
        crossReferencingType === PROJECT
          ? SearchType.CROSS_REFERENCING_PROJECT
          : SearchType.CROSS_REFERENCING_COMPANY;
      await savedSearchResultsStore.updateFilters(
        userStore.user.id,
        searchId,
        filters || filtersStore.searchParams,
        isSearchCrossReferencing ? crossRefType : currentSavedSearches.searchType,
        controller
      );
      setIsProjectsLoading(false);
    },
    [isSearchesLoading, currentSavedSearches, crossReferencingType]
  );

  const saveSearch = async () => {
    setEditFilters(false);
    setShowInfoModal(false);
    if (!userStore.user) return;
    try {
      setIsSearchesLoading(true);
      await savedSearchesStore.getSavedSearches(userStore.user.id);
      setCurrentSavedSearches(savedSearchesStore.getSavedSearchById(searchId));
    } finally {
      setIsSearchesLoading(false);
    }
  };

  const undoChanges = async () => {
    setEditFilters(false);
    setShowInfoModal(false);
    if (filtersStore.originalFilters) {
      await updateFilters(filtersStore.originalFilters);
    }
  };

  const handleContact = (id: number, type: EntityType, role: AllRoles): void => {
    setParams(`contacts=${id}&type=${type}&role=${role}`);
  };

  const showNoResult = useMemo((): boolean => {
    if (
      currentSavedSearches?.searchType === SearchType.PROJECT ||
      (currentSavedSearches?.searchType === SearchType.CROSS_REFERENCING_BOTH &&
        crossReferencingType === PROJECT)
    ) {
      return !savedSearchResultsStore.projects?.data.length;
    }
    if (
      currentSavedSearches?.searchType === SearchType.COMPANY ||
      (currentSavedSearches?.searchType === SearchType.CROSS_REFERENCING_BOTH &&
        crossReferencingType === COMPANY)
    ) {
      return !savedSearchResultsStore.companies?.data.length;
    }
    return false;
  }, [
    currentSavedSearches,
    savedSearchResultsStore.projects?.data,
    savedSearchResultsStore.companies,
    crossReferencingType
  ]);

  const handleUpdateFavorite = (id: number, isActive: boolean) => {
    // if (!isUnsubscribedLocations) {
    //   setShowPlan(true);
    //   return;
    // }
    savedSearchResultsStore.updateCompanyFavorite(id, isActive);
  };

  const openContacts = (data: CompanyData) => {
    setParams(`contacts=${data.id}&type=${AllEntityTypes.Company}`);
  };

  const isSearchCrossReferencing = useMemo<boolean>(() => {
    if (!currentSavedSearches) return false;
    return savedSearchResultsStore.isSearchCrossReferencing(currentSavedSearches.searchType);
  }, [currentSavedSearches?.searchType]);

  if (!searchesVisible)
    return (
      <div className="relative -top-1">
        <ProgressBar progress={searchesProgress} />
      </div>
    );

  return (
    <div>
      <Header>
        {(currentSavedSearches || savedSearchResultsStore.shareSearchFilter) && (
          <SubHeader
            searchId={searchId}
            all={id === 'all'}
            token={!!token}
            edit={editFilters}
            owner={!currentSavedSearches?.isShared}
            onEditFilters={handleEditFilters}
            onLoadSearch={loadSearches}
            onMoveToMap={openMap}
            onSaveTitle={handleSaveTitle}
            activeType={crossReferencingType}
            onToggleType={toggleReferenceType}
            isSearchCrossReferencing={isSearchCrossReferencing}
            currentSavedSearches={
              currentSavedSearches || savedSearchResultsStore.shareSearchFilter!
            }
          />
        )}
        {editFilters && filtersReady && currentSavedSearches && (
          <Filters
            onUpdate={updateFilters}
            onSaveSearch={saveSearch}
            type={currentSavedSearches.searchType}
            savedSearchId={
              currentSavedSearches.searchType === SearchType.CROSS_REFERENCING_BOTH
                ? currentSavedSearches.id
                : null
            }
          />
        )}
      </Header>
      <div className={clsx(editFilters ? 'mt-24' : 'mt-8', 'rounded-sm w-full')}>
        {!projectsVisible && <ProgressBar progress={projectsProgress} thin />}
        {isProjectsLoading && projectsVisible && <ProgressBar progress={projectsProgress} thin />}
        {projectsVisible && (
          <>
            {(token ||
              currentSavedSearches?.searchType === SearchType.PROJECT ||
              (isSearchCrossReferencing && crossReferencingType === 'project')) &&
              !!savedSearchResultsStore.projects?.data.length && (
                <div className="mx-6">
                  <Table
                    data={savedSearchResultsStore.projects.data}
                    onContact={handleContact}
                    onDetails={handleOpenProjectInfo}
                    onUpdateFavorite={favoriteCallback}
                    onUpdateNode={(...pars) => handleUpdateNote(...pars, 'project')}
                    orderAction={handleOrder}
                    orders={orders}
                    hasSubscription={!isUnsubscribedLocations}
                    onHandleUnlock={() => setShowPlan(true)}
                    tableClass={editFilters ? classes.tableWithFilters : classes.table}
                  />
                  <div className="flex">
                    <Pagination
                      onPageClick={handlePageClick}
                      currentPage={page}
                      totalPages={savedSearchResultsStore.projects.totalPages}
                    />
                  </div>
                </div>
              )}
            {(token ||
              currentSavedSearches?.searchType === SearchType.COMPANY ||
              (isSearchCrossReferencing && crossReferencingType === 'company')) &&
              !!savedSearchResultsStore.companies?.data.length && (
                <div className="mx-6" style={{ maxWidth: `calc(100vw - 248px)` }}>
                  <CompanyTable
                    data={savedSearchResultsStore.companies}
                    onDetails={openContacts}
                    orders={orders}
                    orderAction={handleOrder}
                    onUpdateFavorite={handleUpdateFavorite}
                    onUpdateNode={(...pars) => handleUpdateNote(...pars, 'company')}
                    hasSubscription={!isUnsubscribedLocations}
                    onHandleUnlock={() => setShowPlan(true)}
                    tableClass={editFilters ? classes.tableWithFilters : classes.table}
                  />
                  <div className="flex">
                    <Pagination
                      onPageClick={handlePageClick}
                      currentPage={page}
                      totalPages={savedSearchResultsStore.companies.totalPages || 1}
                    />
                  </div>
                </div>
              )}
            {!isProjectsLoading && showNoResult && (
              <div className="p-4 bg-white">
                {crossReferencingType === PROJECT && (
                  <h4 className="text-center text-dark">{t('savedSearch.noResults')}</h4>
                )}
                {crossReferencingType === COMPANY && (
                  <h4 className="text-center text-dark">{t('savedSearch.noCompanyResults')}</h4>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {details && <ProjectInfoModal onFavorite={favoriteCallback} />}

      <ChoosePlanModal
        show={showPlan}
        locations={savedSearchResultsStore.unsubscribedLocations}
        closeModal={() => setShowPlan(false)}
        onUpdate={handleUpdateAfterPayment}
      />

      <InfoModal
        show={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        onAccept={saveSearch}
        onRefuse={undoChanges}
      />

      {contacts && <ContactInfo />}
    </div>
  );
});

export default SavedSearchResults;
