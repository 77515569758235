type StateCodesAndCanonicalTitles = {
  stateCodes: string[];
  canonicalTitles: string[];
};

export const getStateCodesAndCanonicalTitlesFromUrl = (): StateCodesAndCanonicalTitles => {
  const url = new URL(window.location.href);
  const path = url.pathname.split('/');
  return {
    stateCodes: path[2].split(','),
    canonicalTitles: path[3].split(',')
  };
};
