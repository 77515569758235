import { ICompaniesListView, ICompanySearchRepo } from './types';
import { IAxiosResponse, ObjectValues } from 'utils/types';
import { axiosInstance } from 'utils/API';
import { ISearchParams } from 'modules/Filter/types';

export class CompanySearchRepo implements ICompanySearchRepo {
  async getCompaniesListView(
    params: ISearchParams,
    // orderParams: ObjectValues<string>,
    controller?: AbortController
  ): Promise<IAxiosResponse<ICompaniesListView>> {
    return await axiosInstance.post(
      '/company/list',
      { ...params, pageSize: 20 },
      controller
        ? {
            signal: controller.signal
          }
        : {}
    );
  }
}
