import { useStore } from 'storesProvider/storeContext';
import { mapPermitTypesToOptions, mapPropertyTypesToOptions } from 'helpers/mapConfigsToOptions';
import { FILTERS } from 'utils/constants';
import { ISearchParams } from 'modules/Filter/types';
// import { initialSearchParams } from 'modules/Filter/mock';

export const useFiltersActions = () => {
  const { filtersStore } = useStore();

  const setFilters = (filters: ISearchParams) => {
    filtersStore.setAllSearchParams({
      ...filters,
      page: 1,
      pageSize: filters.locations.length > 1 ? 20 : 250,
      keywords: filters.keywords || []
    });
    const localCategories = mapPropertyTypesToOptions(
      filters.classes,
      filters.types,
      filters.subTypes
    );
    const localPermitTypes = mapPermitTypesToOptions(filters.permitTypes);
    filtersStore.setCategories(localCategories);
    filtersStore.setPermitTypes(localPermitTypes);
    if (filters.date && filters.date.min && filters.date.max) {
      const from = new Date(filters.date.min);
      const to = new Date(filters.date.max);
      filtersStore.setCustomDate({
        from: from.getTime(),
        to: to.getTime()
      });
      filtersStore.setSelectedDate({
        id: 5,
        text: 'Custom',
        value: 'custom'
      });
    }
  };

  const createOriginalFilters = () => {
    filtersStore.createOriginalFilters();
  };

  const removeFilters = (clearLocalFilters?: boolean) => {
    // filtersStore.setAllSearchParams(initialSearchParams);
    filtersStore.handleReset();
    filtersStore.setSearchParams('locations', []);
    filtersStore.setSearchParams('order', []);
    if (clearLocalFilters) {
      localStorage.removeItem(FILTERS);
    }
  };

  const filterComparison = (): boolean => {
    return (
      JSON.stringify(filtersStore.originalFilters) === JSON.stringify(filtersStore.searchParams)
    );
  };

  return { setFilters, createOriginalFilters, removeFilters, filterComparison };
};
