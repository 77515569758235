import { INotificationRepo, INotificationService, INotification, NOTIFICATION_REPO } from './types';
import { injector } from 'utils/injector';

export class NotificationService implements INotificationService {
  private _notificationRepo: INotificationRepo = injector.get<INotificationRepo>(NOTIFICATION_REPO);

  async getNotifications(): Promise<INotification[]> {
    return (await this._notificationRepo.getNotifications()).data.result;
  }

  async markAsRead(): Promise<void> {
    return await this._notificationRepo.markAsRead();
  }
  async clearNotifications(): Promise<void> {
    return await this._notificationRepo.clearNotifications();
  }
}
