import L, { Map } from 'leaflet';
import { makeAutoObservable } from 'mobx';

class LeafletMap {
  map: Map | null = null;
  viewArea: [number, number][] = [];

  constructor() {
    makeAutoObservable<LeafletMap>(this);
  }

  setMap(map: Map): void {
    this.map = map;
  }

  setViewArea(area: [number, number][]): void {
    this.viewArea = area;
  }

  initMapForSearchProject(element: HTMLElement): void {
    const map = L.map(element, {
      zoom: 13,
      preferCanvas: true
    });
    map.on('zoomend', () => this.getViewPort());
    map.on('moveend', () => this.getViewPort());
    const osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution:
        '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    map.addLayer(osm);
    this.setMap(map);
  }

  initMapForProjectInfo(element: HTMLElement, center: [number, number]): void {
    const map = L.map(element, {
      dragging: false,
      zoomControl: false,
      doubleClickZoom: false,
      boxZoom: false,
      scrollWheelZoom: false
    }).setView([center[1], center[0]], 16);
    const osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    map.addLayer(osm);
    this.setMap(map);
  }

  getViewPort(): void {
    if (this.map) {
      this.setViewArea([
        [this.map.getBounds().getNorthWest().lng, this.map.getBounds().getNorthWest().lat],
        [this.map.getBounds().getNorthEast().lng, this.map.getBounds().getNorthEast().lat],
        [this.map.getBounds().getSouthEast().lng, this.map.getBounds().getSouthEast().lat],
        [this.map.getBounds().getSouthWest().lng, this.map.getBounds().getSouthWest().lat],
        [this.map.getBounds().getNorthWest().lng, this.map.getBounds().getNorthWest().lat]
      ]);
    }
  }
}

export default LeafletMap;
