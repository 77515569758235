import React, {
  useMemo,
  ReactNode,
  useCallback,
  MutableRefObject,
  useEffect,
  PropsWithChildren,
  useRef,
  useState,
  FunctionComponent
} from 'react';

import classes from './Dropdown.module.scss';
import { clsx } from 'utils/clsx';
import { dropdown, Float, IDropdownOption } from './types';
import tickIcon from 'assets/icons/tick.svg';
import searchIcon from 'assets/icons/search.svg';
import { Input, FavoriteList } from '../index';
import { IExtendedOption, IOption } from '../Select/types';
import { useTranslation } from 'react-i18next';

interface IProps<DropdownType> {
  title: string | ReactNode;
  changeIconColor?: boolean;
  options: DropdownType[];
  onSelect: (value: DropdownType) => void;
  float?: Float;
  activeClass?: string;
  extended?: boolean;
  value?: DropdownType | undefined;
  arrowIconColor?: string;
  titleClass?: string;
  isFiltering?: boolean;
  width?: number;
  extendedPosition?: 'start' | 'end';
  children?: JSX.Element;
  showArrow?: boolean;
  [key: string]: unknown;
}

const Dropdown = <T extends dropdown = IDropdownOption>({
  title,
  options,
  onSelect,
  changeIconColor,
  float,
  activeClass,
  extended,
  value,
  arrowIconColor = '#2F3139',
  titleClass,
  isFiltering = true,
  width,
  extendedPosition,
  children,
  showArrow = true,
  ...rest
}: PropsWithChildren<IProps<T>>) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;

  const toggleOpened = useCallback((): void => {
    setIsOpened((prev) => !prev);
  }, []);

  const selectHandler = useCallback(
    (item: T) => {
      setIsOpened(false);
      onSelect(item);
    },
    [onSelect]
  );
  useEffect(() => {
    if (isOpened) {
      window.addEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [isOpened]);

  const handleExtendedSelect = useCallback((item: T, option: IOption): void => {
    setIsOpened(false);
    onSelect({ ...item, selected: option.id });
  }, []);

  const onInput = (name: string, value: string) => setFilterString(value);

  const clickOutside = (e: MouseEvent): void => {
    if (!e.composedPath().includes(dropdownRef.current)) {
      setIsOpened(false);
    }
  };

  const filteredOptions = useMemo<IExtendedOption[]>(() => {
    if (!extended) return [] as IExtendedOption[];

    return (options as IExtendedOption[]).map((item) => ({
      ...item,
      options: item.options.filter((option) =>
        option.value?.toLowerCase().includes(filterString.toLowerCase())
      )
    }));
  }, [filterString]);

  const renderIcon = useCallback((Icon: FunctionComponent | undefined) => {
    if (!Icon) return <></>;
    return <Icon />;
  }, []);

  return (
    <div
      className={clsx('inline-block relative', changeIconColor && classes.hoverColored)}
      ref={dropdownRef}>
      <div
        className="relative flex text-dark"
        role="button"
        ref={dropdownRef}
        onClick={toggleOpened}
        {...rest}>
        <div
          className={clsx(
            'me-2',
            'overflow-hidden',
            'max-w-7.5',
            'select-none',
            'whitespace-nowrap',
            'text-ellipsis',
            isOpened && activeClass,
            titleClass,
            classes.title
          )}>
          <span className="font-kraftig">{title}</span>
        </div>
        {showArrow && (
          <div className={clsx(classes.icon, isOpened && classes.active, 'flex items-center')}>
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                className={clsx(changeIconColor && isOpened && 'stroke-primary')}
                d="M1 0.5L6 5.5L11 0.5"
                stroke={arrowIconColor}
                strokeWidth="1.4"
              />
            </svg>
          </div>
        )}
      </div>
      {isOpened &&
        (extended ? (
          <div
            className={clsx(
              classes.extendedWrapper,
              'absolute',
              'w-92',
              extendedPosition ? `${extendedPosition}-0` : 'end-0'
            )}
            style={{ width: `${width}px` }}>
            <FavoriteList<T>
              list={filteredOptions}
              value={value || null}
              childrenAlignment="top"
              onChange={handleExtendedSelect}>
              {isFiltering && (
                <div className="p-2">
                  <Input
                    type="text"
                    value={filterString}
                    onInput={onInput}
                    name=""
                    data-test-element="dropdown-input">
                    <div className="w-full h-full flex items-center pl-2 text-grey-500 bg-light-200 duration-200 ease-linear hover:bg-grey-100">
                      <img src={searchIcon} alt="" className="mr-2" />
                      <span>{t('form.search')}</span>
                    </div>
                  </Input>
                </div>
              )}
            </FavoriteList>
          </div>
        ) : (
          <div
            className={clsx(
              classes.wrapper,
              'absolute',
              'w-54',
              'top-full',
              'bg-light-100',
              extendedPosition ? `${extendedPosition}-0` : 'end-0'
            )}
            style={{ width: `${width}px` }}
            role="button">
            <div
              className={clsx(
                classes.optionsWrapper,
                `${float}-0`,
                'text-dark',
                'w-54',
                'max-h-60',
                'overflow-y-scroll',
                'select-none'
              )}
              data-scrollable={options.length > 6}>
              {options.map((option) => (
                <div
                  className="py-2 px-3 relative flex items-center justify-between hover:bg-grey-100"
                  data-test-element={(option as IDropdownOption).attr}
                  key={(option as IDropdownOption).id}
                  onClick={() => selectHandler(option)}>
                  <span className="max-w-full whitespace-nowrap text-ellipsis pr-2 overflow-hidden flex text-sm items-center">
                    {(option as IDropdownOption).icon && (
                      <span className="w-7.5 flex justify-center items-center">
                        <span className="mr-2">{renderIcon((option as IDropdownOption).icon)}</span>
                      </span>
                    )}
                    {(option as IDropdownOption).content}
                  </span>
                  {option.selected && <img src={tickIcon} alt="" />}
                </div>
              ))}
            </div>
            {children ? <div onClick={() => setIsOpened(false)}>{children}</div> : null}
          </div>
        ))}
    </div>
  );
};

export default Dropdown;
