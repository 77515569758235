import React, { FC } from 'react';
import { observer } from 'mobx-react';
import SearchLocationInput from 'modules/SearchLocationInput';
import DateDropdown from './DateDropdown';
import TreeDropdown from './TreeDropdown';
import ValuationDropdown from './ValuationDropdown';
import MoreDropdown from './MoreDropdown';
import SaveSearchButton from './SaveSearchButton';
import { useStore } from 'storesProvider/storeContext';
import { IOption } from 'components/Select/types';
import { useTranslation } from 'react-i18next';
import { FilterState, IDropdownEvent, IInputChangeItem } from '../types';
import { Button } from 'components';
import SubscriptionLocationDropdown from 'modules/SubscriptionLocationDropdown';

interface Props {
  type: FilterState;
  onDateChange: (option: IOption, field: string) => void;
  onResetDate: () => void;
  onCategoryChange: ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => void;
  onPropertyChangeChange: ({ activePermits }: IDropdownEvent) => void;
  onResetValue: () => void;
  onSaveValue: (value: IInputChangeItem) => void;
  onSaveSearch: () => void;
  onHideSaveSearch: () => void;
  onSearchNameInput: (name: string, value: string) => void;
  onSaveClick: () => void;
  saveSearchVisible: boolean;
  newSearchName: string;
  onReset: () => void;
  savedSearchId?: number | null;
}

const FilterForCompanySearch: FC<Props> = observer(
  ({
    type,
    onDateChange,
    onResetDate,
    onCategoryChange,
    onPropertyChangeChange,
    onResetValue,
    onSaveValue,
    onSaveSearch,
    onHideSaveSearch,
    onSearchNameInput,
    onSaveClick,
    saveSearchVisible,
    newSearchName,
    onReset,
    savedSearchId
  }) => {
    const { t } = useTranslation();
    const { filtersStore, userStore } = useStore();

    return (
      <div className="flex items-center">
        <div className="mr-2">
          {type === FilterState.COMPANY_SEARCH && (
            <SearchLocationInput savedSearchId={savedSearchId} />
          )}
          {type === FilterState.SAVED_SEARCH_COMPANY && (
            <SubscriptionLocationDropdown savedSearchId={savedSearchId} />
          )}
        </div>
        <DateDropdown
          customValue={filtersStore.customDate}
          onChange={onDateChange}
          onCustomChange={filtersStore.onCustomDateChange}
          onReset={onResetDate}
          value={filtersStore.selectedDate}
        />
        <TreeDropdown
          data={filtersStore.categories}
          onChange={onCategoryChange}
          divClass="property"
          placeholder={t('components.dropdown.placeholder')}
        />
        <TreeDropdown
          data={filtersStore.permitTypes}
          placeholder={t('components.dropdown.placeholderPermitType')}
          onChange={onPropertyChangeChange}
          dropdownClass="permit-types"
        />
        <ValuationDropdown
          onReset={onResetValue}
          onSave={onSaveValue}
          value={filtersStore.searchParams.value}
          placeholder={t('searchProject.valuation')}
        />
        <MoreDropdown type={FilterState.COMPANY_SEARCH} />
        {type === FilterState.COMPANY_SEARCH && (
          <>
            {userStore.user && (
              <SaveSearchButton
                handleSaveSearch={onSaveSearch}
                hideSaveSearch={onHideSaveSearch}
                onInput={onSearchNameInput}
                onSaveClick={onSaveClick}
                saveSearchVisible={saveSearchVisible}
                value={newSearchName}
              />
            )}
            {filtersStore.isActiveFilters() && (
              <Button type="light" color="#0087FF" className="ml-2 !py-1" onClick={onReset}>
                <span className="font-kraftig text-xs">{t('searchProject.resetAll')}</span>
              </Button>
            )}
          </>
        )}
      </div>
    );
  }
);

export default FilterForCompanySearch;
