import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../LeftSidebar.module.scss';
import { Select } from 'components';
import { sortOptions, sorts } from '../mock';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { IOption } from 'components/Select/types';
import { SortKey } from '../types';
import { observer } from 'mobx-react';
import { NA } from 'utils/constants';
import { valueFormatter } from 'helpers/valueFormatter';
import { createProjectCountString } from 'utils/createProjectCountString';

const LeftSidebarHeader = observer(() => {
  const { projectSearchStore, filtersStore, searchStore } = useStore();
  const { t } = useTranslation();
  const [selectedSort, setSelectedSort] = useState<IOption>(sortOptions[0]);

  const onSortChange = useCallback((option: IOption) => {
    setSelectedSort(option);
    filtersStore.setSearchParams('order', [sorts[option.value as SortKey]]);
  }, []);

  useEffect(() => {
    const key = Object.entries(sorts).find((item) => {
      const [, value] = item;
      if (!filtersStore.searchParams.order || !filtersStore.searchParams.order.length) return;
      return (
        filtersStore.searchParams.order[0].field === value.field &&
        filtersStore.searchParams.order[0].order === value.order
      );
    });
    const selectedOption = key
      ? sortOptions.find((option) => option.value === key[0])
      : sortOptions[0];
    if (!selectedOption) return;
    setSelectedSort(selectedOption);
    filtersStore.setSearchParams('order', [sorts[selectedOption.value as SortKey]]);
  }, [filtersStore.searchParams.order]);

  const getProjectCount = useMemo(
    (): string =>
      createProjectCountString(projectSearchStore.projectsData, t('searchProject.projects')),
    [projectSearchStore.projectsData]
  );

  return (
    <div>
      <div className="justify-between py-3 py-0 pr-[13px] pl-6 max-w-full items-end border-b border-b-grey-200 3xl:flex">
        <div className="w-full mb-2.5 3xl:w-[55%] 3xl:mb-0">
          <div className="text-dark text-xl select-text">
            <span className="font-halbfett block max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
              {searchStore.locationForMap.length > 0 && searchStore.locationForMap[0]?.title}
            </span>
          </div>
          <div className="flex items-center whitespace-nowrap text-sm">
            {getProjectCount} | {t('searchProject.projectValue')}:
            {projectSearchStore.isValueLoading ? (
              <div className={clsx(classes.loader, 'ml-2')}>
                <div></div>
              </div>
            ) : (
              <span>
                &nbsp;
                {projectSearchStore.projectsData.value > 0
                  ? `$${valueFormatter(projectSearchStore.projectsData.value)}`
                  : NA}
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-2 font-halbfett text-dark uppercase text-xs">
            {t('searchProject.sortBy')}:
          </span>
          <Select
            name="sort"
            onChange={onSortChange}
            options={sortOptions}
            value={selectedSort}
            className="text-dark text-xs h-8 pr-8"
            data-test-element="search-projects-sort-select"
            optionWidth={120}
          />
        </div>
      </div>
    </div>
  );
});

export default LeftSidebarHeader;
