import { IAxiosResponse } from 'utils/types';

export type INotification = {
  id: number;
  type: NotificationTypes;
  data: SavedSearchNotification | ProjectUpdateNotification;
  seenAt?: Date;
  createdAt: Date;
};

export enum NotificationTypes {
  NEW_PROJECTS = 10,
  SHARE_ACCEPTED = 20,
  SHARED_SAVED_SEARCH = 30,
  FAV_PROJECT_VALUE_UPDATED = 40,
  FAV_PROJECT_STATUS_UPDATED = 50,
  FAV_PROJECT_SHARED = 60,
  PROJECT_SHARED = 70,
  CROSS_IMPORT_PROCESSED = 80
}

export type SavedSearchNotification = {
  savedSearchId: number;
  savedSearchName: string;
  newProjectsCount: number;
};

export type ProjectUpdateNotification = {
  message: string;
};

export const NOTIFICATION_REPO = 'NOTIFICATION_REPO';
export interface INotificationRepo {
  getNotifications(): Promise<IAxiosResponse<INotification[]>>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}

export const NOTIFICATION_SERVICE = 'NOTIFICATION_SERVICE';
export interface INotificationService {
  getNotifications(): Promise<INotification[]>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}

export interface INotificationStore {
  getNotifications(): Promise<void>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}
