import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import classes from './AnimatedInput.module.scss';
import { clsx } from 'utils/clsx';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
interface IProps {
  type: string;
  value: string;
  placeholder?: string;
  onInput: (name: string, val: string) => void;
  isError?: boolean;
  isSuccess?: boolean;
  errorText?: string;
  name: string;
  dataTestElement: string;
  children: ReactNode;
}

const AnimatedInput: FC<IProps> = ({
  type,
  value,
  onInput,
  isError,
  errorText,
  name,
  dataTestElement,
  isSuccess,
  children
}) => {
  const [inputType, setInputType] = useState<string>(type);
  const inputRef = useRef({} as HTMLInputElement);

  const toggleType = useCallback((): void => {
    setInputType((prev: string) => (prev === 'password' ? 'text' : 'password'));
    handleInputFocus();
  }, []);

  const handleInputFocus = useCallback((): void => {
    inputRef.current.focus();
  }, []);

  const onInputChange = useCallback((event: React.SyntheticEvent): void => {
    onInput(name, (event.target as HTMLInputElement).value);
  }, []);

  return (
    <div>
      <div className="relative">
        <input
          ref={inputRef}
          type={inputType}
          className={clsx(
            classes.animatedInput,
            isError && classes.inputError,
            isSuccess && classes.inputSuccess,
            'w-full',
            'block',
            'relative',
            'py-2',
            'h-12',
            'border-0',
            'bg-light-200',
            'rounded-sm',
            'text-dark',
            'text-base',
            'shadow-1sm',
            'hover:bg-grey-100'
          )}
          aria-describedby="passwordHelpBlock"
          value={value}
          onInput={onInputChange}
          role="button"
          name={name}
          data-test-element={dataTestElement}
          autoComplete={type === 'password' ? 'on' : 'off'}
        />
        <div
          role="button"
          className={clsx(
            classes.animatedLabel,
            'absolute',
            'top-1/2',
            '-translate-y-1/2',
            'text-sm',
            'text-grey-500',
            'px-1',
            'left-2'
          )}
          onClick={handleInputFocus}>
          {children}
        </div>

        {type === 'password' && (
          <div
            role="button"
            className={clsx(
              inputType === 'text' && classes.filledIcon,
              'absolute',
              'top-1/4',
              'right-3'
            )}
            onClick={toggleType}>
            <EyeIcon />
          </div>
        )}
      </div>
      {isError && (
        <span className="w-full block bg-white text-xs text-red-200 translate-y-0.5 pt-1">
          {errorText}
        </span>
      )}
    </div>
  );
};

export default AnimatedInput;
