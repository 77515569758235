import React, { FC, useCallback, MouseEvent, useState, useEffect } from 'react';
import classes from './CancelSubscriptionModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import List from './components/List';
import closeIcon from 'assets/icons/close.svg';
import Survey from './components/Survey';
import UpgradePlan from './components/UpgradePlan';
import { CustomAnswer, SurveyList } from './types';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { Plans } from '../types';

interface IProps {
  show: boolean;
  closeModal: () => void;
  onCancelSubscription: () => void;
  userName: string;
  subscriptionId: number | null;
  onUpgrade: (subscriptionId: number) => void;
}

const CancelSubscriptionModal: FC<IProps> = observer(
  ({ show, closeModal, onCancelSubscription, userName, subscriptionId, onUpgrade }) => {
    const { t } = useTranslation();
    const { subscriptionAndPlansStore, alertStore } = useStore();
    const [answer, setAnswer] = useState<SurveyList | null>(null);
    // const [showUpgradeBtn, setShowUpgradeBtn] = useState<boolean>(false);

    useEffect(() => {
      // TODO: remove after review
      // if (show && subscriptionId) {
      //   const subscription = subscriptionAndPlansStore.getSubscriptionById(subscriptionId);
      //   if (subscription) {
      //     setShowUpgradeBtn(subscription.period === Plans.MONTH);
      //   }
      // }
      if (!show) {
        setAnswer(null);
        subscriptionAndPlansStore.clearAnswers();
      }
    }, [show]);

    const handleCommentChange = useCallback(
      (answer: CustomAnswer) =>
        subscriptionAndPlansStore.setAnswer(answer.index, 'custom', answer.text),
      []
    );

    const clickOutside = useCallback((e: MouseEvent) => {
      if (e.target === e.currentTarget) closeModal();
    }, []);

    const handleAnswer = useCallback((answer: SurveyList): void => {
      setAnswer(answer);
      subscriptionAndPlansStore.setAnswer(0, 'answer', answer.label);
    }, []);

    const cancelSubscription = (): void => {
      const requireAnswers = subscriptionAndPlansStore.questionsAndAnswers[0];
      if (
        (requireAnswers.answer?.length && requireAnswers.answer !== 'Other') ||
        (requireAnswers.answer?.length &&
          requireAnswers.answer === 'Other' &&
          requireAnswers.custom?.length)
      ) {
        onCancelSubscription();
      } else {
        alertStore.errorAlert(t('cancelSubscription.pleaseAnswer'));
      }
    };

    const handleUpgrade = useCallback((): void => {
      if (subscriptionId) {
        closeModal();
        onUpgrade(subscriptionId);
      }
    }, [subscriptionId]);

    const getPeriodSubscription = useCallback((): number => {
      if (!subscriptionId) {
        return 0;
      }
      const currentSubscription = subscriptionAndPlansStore.getSubscriptionById(subscriptionId);
      return currentSubscription ? currentSubscription.period : 0;
    }, [subscriptionId]);

    return (
      <div
        className={clsx(
          'fixed',
          'inset-0',
          'flex',
          'items-center',
          'justify-center',
          'z-50',
          show ? 'block' : 'hidden',
          classes.modal
        )}
        onClick={clickOutside}>
        <div className={clsx(classes.body, 'p-14', 'relative', 'max-h-[700px]', '2xl:max-h-full')}>
          <div className={clsx(classes.close, 'absolute')} role="button" onClick={closeModal}>
            <img src={closeIcon} alt="" />
          </div>
          <h2 className={clsx(classes.title, 'mb-2', 'text-center')}>
            {`${userName}, ${t('cancelSubscription.title')}`}
          </h2>
          <p className={clsx(classes.subTitle, 'mb-2', 'text-center')}>
            {t('cancelSubscription.subTitle')}
          </p>
          <div className="flex justify-between my-10">
            <List fullWidth={getPeriodSubscription() === Plans.YEAR} />
            {getPeriodSubscription() !== Plans.YEAR && <UpgradePlan onUpgrade={handleUpgrade} />}
          </div>
          {/*TODO: remove after review*/}
          {/*{showUpgradeBtn && (*/}
          {/*  <div className={clsx(classes.btnWrapper, 'm-auto')}>*/}
          {/*    <Button type="primary" className={clsx('w-full h-full')} onClick={closeModal}>*/}
          {/*      <span className="fw-normal font-kraftig">*/}
          {/*        {t('cancelSubscription.projectUpdateBtn')}*/}
          {/*      </span>*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className={clsx(classes.btnWrapper, 'm-auto')}>
            <Button type="primary" className={clsx('w-full h-full')} onClick={closeModal}>
              <span className="fw-normal font-kraftig">
                {t('cancelSubscription.projectUpdateBtn')}
              </span>
            </Button>
          </div>
          <div className="mt-10">
            <Survey
              onCheckAnswer={handleAnswer}
              checkedAnswer={answer}
              onCommentChange={handleCommentChange}
              answers={subscriptionAndPlansStore.questionsAndAnswers}
            />
          </div>
          <div className="mt-6 flex justify-center">
            <Button type="light" color="#EF2F3C" onClick={cancelSubscription}>
              <span className="fw-normal font-kraftig">{t('cancelSubscription.cancel')}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default CancelSubscriptionModal;
