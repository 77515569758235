import { IAxiosResponse } from 'utils/types';
import { FavoriteTabSystemType } from '../Favorites/types';

export interface INoteEntity {
  coordinates: DOMRect | null;
  name: string | null;
  description: string | null;
  id: number | null;
  type: FavoriteTabSystemType | null;
}

export interface INoteState {
  entity: INoteEntity;
  showAddNote: boolean;
}
export const NOTE_REPO = 'NOTE_REPO';
export interface INoteRepo {
  getNote(projectId: number, type: FavoriteTabSystemType): Promise<IAxiosResponse<INote>>;
  addNote(
    projectId: number,
    type: FavoriteTabSystemType,
    content: string
  ): Promise<IAxiosResponse<INote>>;
}

export const NOTE_SERVICE = 'NOTE_SERVICE';
export interface INoteService {
  getNote(projectId: number, type: FavoriteTabSystemType): Promise<INote | null>;
  addNote(projectId: number, type: FavoriteTabSystemType, content: string): Promise<INote>;
}

export interface INoteStore {
  getNote(projectId: number, type: FavoriteTabSystemType): Promise<INote | null>;
  addNote(projectId: number, type: FavoriteTabSystemType, content: string): Promise<void>;
}

export interface INote {
  id: number;
  content: string;
  createdAt: Date;
  updatedAt: Date;
}
