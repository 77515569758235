import {
  IContact,
  IAxiosResponse,
  ILocation,
  ISort,
  EntityType,
  AllRole,
  AllRoles,
  Detail
} from 'utils/types';
import { IInputChangeItem } from '../SearchProjects/components/SubHeader/types';
import { IOption } from 'components/Select/types';
import { IRow } from 'components/Table/types';

export const FAVORITES_REPO = 'FAVORITES_REPO';
export interface IFavoritesRepo {
  createFavoritesList(userId: number, name: string): Promise<IAxiosResponse<FavoritesList>>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(
    userId: number,
    type: string,
    entityId: number,
    role: string
  ): Promise<IAxiosResponse<FavoritesList[]>>;
  getFavoritesList(userId: number): Promise<IAxiosResponse<FavoritesList[]>>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<IAxiosResponse<number>>;
  getFavoritesData<T>(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IAxiosResponse<IFavoriteDataRes<T>>>;
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(
    userId: number,
    listId: number,
    name: string
  ): Promise<IAxiosResponse<FavoritesList>>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    body: IFavoriteLocationBody
  ): Promise<IAxiosResponse<FavoriteLocation[]>>;

  validateSharedToken(token: string): Promise<IAxiosResponse<FavoritesList>>;
}

export const FAVORITES_SERVICE = 'FAVORITES_SERVICE';
export interface IFavoritesService {
  createFavoritesList(userId: number, name: string): Promise<FavoritesList>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(userId: number, type: string, entityId: number, role: string): Promise<number[]>;
  getFavoritesList(userId: number): Promise<FavoritesList[]>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<number>;
  getFavoritesData<T>(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IFavoriteDataRes<T>>; //IRowFavorite
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(userId: number, listId: number, name: string): Promise<FavoritesList>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]>;
  validateSharedToken(token: string): Promise<FavoritesList>;
}

export interface IFavoritesStore {
  list: FavoritesList[];
  totalValue: number;
  totalPages: number;
  createFavoritesList(userId: number, name: string): Promise<FavoritesList>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(userId: number, type: string, entityId: number, role: string): Promise<number[]>;
  getFavoritesList(userId: number): Promise<void>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<void>;
  getFavoritesData(userId: number, systemType: string, body: IFavoriteDataBody): Promise<void>;
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(userId: number, listId: number, name: string): Promise<void>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]>;
  validateSharedToken(token: string): Promise<void>;
  updateNoteStatus(id: number, hasNote: boolean, systemType: string): void;
}

export interface IFavoriteEntity {
  id: number;
  coordinates: DOMRect;
  type: EntityType;
  role: AllRole;
  key: IFavoriteKey;
  name?: string;
}

export enum FavoriteSubTypes {
  recent = 'recent',
  regular = 'regular'
}

export type IFavoriteKey =
  | 'projects'
  | 'table'
  | 'contacts'
  | 'recentContacts'
  | 'sidebar'
  | 'recentProjects'
  | 'openProjects'
  | 'fullInfoModal'
  | 'closedProjects';

export type FavoritesOptions = {
  id: number;
  selected: boolean;
  shared: boolean;
  content: string;
};

export type FavoritesList = {
  id: number;
  name: string;
  shared: boolean;
};

export interface IFavoriteData {
  entityType: EntityType;
  entityId: number;
  lists: number[];
  role: number;
}

export enum ModalType {
  CREATE,
  EDIT
}

export interface IFavoriteDataBody extends IFavoriteFilters {
  lists: number[];
  order: ISort[];
  search?: string;
  page: number;
}

export interface IFavoriteDataRes<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  // dataTable: T[];
  data: T[];
}

export interface IFavoriteAnalyticsDataRes<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: T[];
}

// export type FavoriteData = ProjectData | CompanyData | PersonData;

export type Status = {
  id: number;
  name: string;
  color: string;
};

export type DefaultTypeObj = {
  id: number;
  name: string;
};

export type Location = {
  id: number;
  title: string;
  fullTitle: string;
  type: number;
  coordinates: [number, number];
  shape: [number, number][][][];
};

export type ProjectData = {
  hasNotes: boolean;
  hasSubscription: boolean;
  id: number;
  class: DefaultTypeObj;
  type: DefaultTypeObj;
  subType: DefaultTypeObj;
  description: string;
  status: Status;
  updatedAt: string;
  createdAt: string;
  value: number;
  location: ILocation;
  contacts: IContact[];
  // role: AllRole;
  // favoritesList: number[];
};

export type Persons = {
  id: number;
  details: Detail[];
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
};

export type CompanyData = {
  hasNotes: boolean;
  hasSubscription: boolean;
  id: number;
  description: string;
  location: Location;
  name: string;
  persons: Persons[];
  projectsCount: number;
  projectsValue: number;
  role: AllRole;
  // favoritesList: number[];
};

export type PersonData = {
  hasNotes: boolean;
  hasSubscription: boolean;
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
  projectsCount: number;
  projectsValue: number;
  type: { [key: string]: string };
  role: AllRole;
  // favoritesList: number[];
};

export interface IFavoriteFilters {
  classes: number[];
  types: number[];
  subTypes: number[];
  projectValue?: IInputChangeItem;
  date?: IInputChangeItem;
  location?: number[];
  projectCount?: IInputChangeItem;
  totalAmount?: IInputChangeItem;
}

export type FavoriteLocation = {
  id: number;
  title: string;
};

export interface IFavoriteLocationBody extends IFavoriteFilters {
  lists: number[];
  search?: string;
}

export type FavoriteFormValue = IInputChangeItem | number[] | null | { min: string; max: string };

export type FavoriteTabSystemType = 'project' | 'company' | 'person';

export interface IRowFavorite extends IRow {
  role: AllRoles;
}

export interface IFavoriteState {
  entity: IFavoriteEntity | null;
  showAddToFavorite: boolean;
  favoriteId: number | null;
}
