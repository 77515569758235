import React, { FC } from 'react';
import classes from './MapProjectStatistics.module.scss';
import { clsx } from 'utils/clsx';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { NA } from '../../../../../utils/constants';

const MapProjectStatistics: FC = observer(() => {
  const { projectSearchStore } = useStore();
  return (
    <div className={clsx('absolute py-1 px-4 text-sm text-white', classes.statisticContainer)}>
      <span>
        Showing{' '}
        {projectSearchStore.projectsData.showCount
          ? numberWithSpaces(projectSearchStore.projectsData.showCount)
          : NA}{' '}
        of{' '}
        {projectSearchStore.projectsData.showTotal
          ? numberWithSpaces(projectSearchStore.projectsData.showTotal)
          : NA}{' '}
        results in this area
      </span>
    </div>
  );
});

export default MapProjectStatistics;
