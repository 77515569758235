import {
  ISavedSearchData,
  ISavedSearchResultsService,
  ISavedSearchResultsStore,
  SAVED_SEARCH_RESULTS_SERVICE
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { ISort, SearchStringType, SearchType } from 'utils/types';
import { ShortLocationForPayment } from 'view/SubscriptionsAndPlans/types';
import { ISavedSearch } from 'view/SavedSearches/types';
import { ISearchParams } from 'modules/Filter/types';
import { ICompaniesListView } from 'view/CompanySearch/types';

export class SavedSearchResultsStore implements ISavedSearchResultsStore {
  private _savedSearchResultsService: ISavedSearchResultsService =
    injector.get<ISavedSearchResultsService>(SAVED_SEARCH_RESULTS_SERVICE);

  constructor() {
    makeAutoObservable<SavedSearchResultsStore>(this);
  }

  projects: ISavedSearchData | null = null;
  unsubscribedLocations: ShortLocationForPayment[] = [];
  shareSearchFilter: ISavedSearch | null = null;

  companies: ICompaniesListView | null = null;

  setProjects(projects: ISavedSearchData | null): void {
    this.projects = projects;
  }

  setUnsubscribedLocations(locations: ShortLocationForPayment[]): void {
    this.unsubscribedLocations = locations;
  }

  setShareSearchFilter(filter: ISavedSearch): void {
    this.shareSearchFilter = filter;
  }

  setCompanies(companies: ICompaniesListView | null): void {
    this.companies = companies;
  }

  setProjectNoteStatus(id: number, hasNote: boolean): void {
    if (!this.projects) return;
    this.projects = {
      ...this.projects,
      data: this.projects.data.map((data) => ({
        ...data,
        hasNotes: data.id === id ? hasNote : data.hasNotes
      }))
    };
  }

  setCompanyNoteStatus(id: number, hasNote: boolean): void {
    if (!this.companies) return;
    this.companies = {
      ...this.companies,
      data: this.companies.data.map((data) => ({
        ...data,
        hasNotes: data.id === id ? hasNote : data.hasNotes
      }))
    };
  }

  async getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orders: ISort[],
    type: SearchStringType
  ): Promise<void> {
    const res = await this._savedSearchResultsService.getCrossReferencingSavedSearches(
      userId,
      id,
      page,
      orders,
      type
    );
    if (type === 'project') {
      this.setProjects(res as ISavedSearchData);
    } else {
      this.setCompanies(res as ICompaniesListView);
    }
  }

  async getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<void> {
    const res = await this._savedSearchResultsService.getSavedSearchesProjectsBySearchType(
      userId,
      ids,
      page,
      orders
    );
    const unsubscribedLocations = res.locations.filter((location) => !location.hasSubscription);
    this.setUnsubscribedLocations(
      unsubscribedLocations.map((location) => ({
        id: location.id,
        title: location.title
      }))
    );
    this.setProjects(res);
  }

  async getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<void> {
    const res = await this._savedSearchResultsService.getSavedSearchesCompaniesBySearchType(
      userId,
      ids,
      page,
      orders
    );
    const unsubscribedLocations = res.locations.filter((location) => !location.hasSubscription);
    this.setUnsubscribedLocations(
      unsubscribedLocations.map((location) => ({
        id: location.id,
        title: location.title
      }))
    );
    this.setCompanies(res);
    // this.setProjects(res.data);
    // this.setTableRows([...res.dataTable]);
    // this.setTotalPages(res.totalPages);
  }

  async getShareProjectSavedSearch(token: string, page: number, orders: ISort[]): Promise<void> {
    const res = await this._savedSearchResultsService.getShareProjectSavedSearch(
      token,
      page,
      orders
    );
    this.setShareSearchFilter({ ...res.savedSearchData, isShared: true });
    if (!this.isSearchCrossReferencing(res.searchType)) {
      const unsubscribedLocations = res.locations.filter((location) => !location.hasSubscription);
      this.setUnsubscribedLocations(
        unsubscribedLocations.map((location) => ({
          id: location.id,
          title: location.title
        }))
      );
    }
    // TODO: rm data handling or continue working with token(based on BE decision)
    this.setProjects(res.data);
    // this.setShareSearchFilter(res.savedSearchData);
  }

  async getShareCompanySavedSearch(token: string, page: number, orders: ISort[]): Promise<void> {
    const res = await this._savedSearchResultsService.getShareCompanySavedSearch(
      token,
      page,
      orders
    );
    this.setShareSearchFilter({ ...res.savedSearchData, isShared: true });
    if (!this.isSearchCrossReferencing(res.searchType)) {
      const unsubscribedLocations = res.locations.filter((location) => !location.hasSubscription);
      this.setUnsubscribedLocations(
        unsubscribedLocations.map((location) => ({
          id: location.id,
          title: location.title
        }))
      );
    }
    // TODO: rm data handling or continue working with token(based on BE decision)
    this.setCompanies(res.data);
  }

  isSearchCrossReferencing(searchType: SearchType) {
    const crossRefTypes = [
      SearchType.CROSS_REFERENCING_PROJECT,
      SearchType.CROSS_REFERENCING_COMPANY,
      SearchType.CROSS_REFERENCING_BOTH
    ];
    return crossRefTypes.includes(searchType);
  }

  async updateFilters(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<void> {
    if (searchType === SearchType.PROJECT || searchType === SearchType.CROSS_REFERENCING_PROJECT) {
      const res = await this._savedSearchResultsService.updateFilters<ISavedSearchData>(
        userId,
        searchId,
        filters,
        searchType,
        controller
      );
      this.setProjects(res);
    }

    if (
      searchType === SearchType.COMPANY ||
      searchType === SearchType.CROSS_REFERENCING_COMPANY ||
      searchType === SearchType.CROSS_REFERENCING_BOTH
    ) {
      const res = await this._savedSearchResultsService.updateFilters<ICompaniesListView>(
        userId,
        searchId,
        filters,
        searchType,
        controller
      );
      this.setCompanies(res);
    }
  }

  // TODO: after refactoring the table make one method
  updateFavoriteRow(id: number, isActive: boolean): void {
    if (!this.projects) return;
    this.setProjects({
      ...this.projects,
      data: this.projects.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            favourite: item.favourite !== isActive ? !item.favourite : item.favourite
          };
        }
        return item;
      })
    });
  }

  updateCompanyFavorite(id: number, isActive: boolean): void {
    if (this.companies) {
      this.setCompanies({
        ...this.companies,
        data: this.companies?.data.map((company) => {
          if (company.id === id) {
            return {
              ...company,
              favorite: company.favorite !== isActive ? !company.favorite : company.favorite
            };
          }
          return company;
        })
      });
    }
  }

  hideRedDots() {
    if (!this.projects) return;
    this.setProjects({
      ...this.projects,
      data: this.projects.data.map((item) => ({
        ...item,
        new: false
      }))
    });
  }
}
