import { injector } from 'utils/injector';
import { coordinateShift } from './helper';
import { DetailInfo, IMapRepo, IMapService, MAP_REPO } from './types';

export class MapService implements IMapService {
  private _mapRepo: IMapRepo = injector.get<IMapRepo>(MAP_REPO);

  async getLocationInfo(getLocationInfo: number): Promise<DetailInfo> {
    const detailedInfo = (await this._mapRepo.getLocationInfo(getLocationInfo)).data.result[0];
    return {
      ...detailedInfo,
      shape: coordinateShift(detailedInfo.shape)
    };
  }

  async getNeighbourLocations(locationId: number): Promise<DetailInfo[]> {
    const detailedInfo = (await this._mapRepo.getNeighbourLocations(locationId)).data.result;
    return detailedInfo.map((item) => {
      return {
        ...item,
        shape: coordinateShift(item.shape)
      };
    });
  }
}
