import React, { FC, useCallback } from 'react';
import { clsx } from 'utils/clsx';
import classes from './PersonItem.module.scss';
import avatar from 'assets/icons/default-avatar.svg';
import { IContact, SourceLink, PersonDetail } from 'utils/types';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';

//TODO: move to SearchProjects service and mutation persons array
const personRole: { [key: number]: string } = {
  10: 'Architect',
  20: 'Owner',
  30: 'Contractor'
};

interface Props {
  person: IContact<PersonDetail>;
  onContacts?: (contact: IContact<PersonDetail>) => void;
}

const PersonItem: FC<Props> = ({ person, onContacts }) => {
  const { alertStore } = useStore();
  const { t } = useTranslation();

  const copy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alertStore.successAlert(t('savedSearch.copied')))
      .catch(() => alertStore.errorAlert('savedSearch.errorCopy'));
  };

  const getDetail = useCallback((detail: PersonDetail) => {
    if (detail.type === SourceLink.LINKEDIN || detail.type === SourceLink.WEB) {
      return (
        <a
          href={detail.value}
          target={'_blank'}
          rel="noreferrer"
          className="no-underline text-dark">
          {detail.type === SourceLink.LINKEDIN
            ? detail.value.split('linkedin.com/')[1]
            : detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.PHONE) {
      return (
        <a href={`tel:${detail.value}`} className="no-underline text-dark">
          {detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.EMAIL) {
      return (
        <a href={`mailto:${detail.value}`} className="no-underline text-dark">
          {detail.value}
        </a>
      );
    }
    return (
      <div className="flex group relative">
        <span
          onClick={() => copy(detail.value)}
          className="cursor-pointer w-40 overflow-hidden whitespace-nowrap text-ellipsis">
          {detail.value}
        </span>
        <div className="absolute bottom-0 mb-6 px-4 py-2 shadow-overlay origin-bottom transform rounded bg-white text-dark text-sm hidden transition-all duration-300 group-hover:block">
          <p>{detail.value}</p>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={clsx(classes.personItem, 'w-1/2', 'mb-5', 'flex')}>
      <div className="mr-3">
        <div className="rounded-full flex items-center justify-center w-14 h-14 bg-light-500">
          <img src={avatar} alt="avatar" />
        </div>
      </div>
      <div className={clsx(classes.personInfo)}>
        <span className="mb-2 block uppercase font-kraftig text-xs text-grey-300">
          {personRole[person.role as number]}
        </span>
        {/*className="cursor-pointer" onClick={onContacts && onContacts(person.id)}*/}
        <h6 className="cursor-pointer" onClick={() => onContacts && onContacts(person)}>
          {person.name}
        </h6>
        <ul className="p-0">
          {person.details.map((detail, idx: number) => (
            <li
              className="flex mb-2 break-inside-avoid-page break-inside-avoid"
              key={`detail-item-${idx}`}>
              <span className="mr-2">
                <detail.icon />
              </span>
              {getDetail(detail)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PersonItem;
