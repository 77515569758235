import React, { FC, useCallback, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  Table as RTable
} from '@tanstack/react-table';
import { CompanyData, ICompaniesListView } from '../../types';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { NA } from 'utils/constants';
import { IFavoriteEntity } from 'view/Favorites/types';
import AddNoteModal from 'view/AddNoteModal';
import { INoteEntity, INoteState } from 'view/AddNoteModal/types';
import { AllEntityTypes, AllRoles, ISort } from 'utils/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';

import { NewTable } from 'components';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';

interface Props {
  data: ICompaniesListView;
  onUpdateFavorite: (id: number, isActive: boolean) => void;
  onUpdateNode?: (id: number, hasNote: boolean) => void;
  onDetails: (data: CompanyData) => void;
  orders: ISort[];
  orderAction: (field: string) => void;
  hasSubscription?: boolean;
  onHandleUnlock?: () => void;
  tableClass?: string;
}

const Table: FC<Props> = ({
  data,
  onUpdateFavorite,
  onDetails,
  orders,
  orderAction,
  hasSubscription,
  onHandleUnlock,
  tableClass,
  onUpdateNode
}) => {
  const [favoriteShowId, setFavoriteShowId] = useState<number | null>(null);
  const columnHelper = createColumnHelper<CompanyData>();

  const [noteState, setNoteState] = useState<INoteState>({
    entity: {
      coordinates: null,
      name: null,
      description: null,
      id: null,
      type: null
    },
    showAddNote: false
  });

  const showAddNoteModal = useCallback((id: number) => {
    const thisModal = document.getElementById(`note-${id}`);
    setNoteState((prev) => ({ ...prev, showAddNote: !thisModal }));
    setFavoriteShowId(null);
  }, []);

  const noteEntity = (row: CompanyData) => {
    const coordinates: DOMRect | null =
      document.getElementById(`document-icon-${row.id}`)?.getBoundingClientRect() || null;
    const entity: INoteEntity = {
      coordinates,
      name: null,
      description: '',
      id: row.id,
      type: 'company'
    };
    setNoteState((prev) => ({ ...prev, entity }));
    showAddNoteModal(row.id);
  };

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Details',
      cell: (info) => {
        return (
          <span onClick={() => onDetails(info.row.original)} className="block cursor-pointer w-96">
            {info.getValue()}
          </span>
        );
      },
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('roleType', {
      header: () => 'Company Type',
      cell: (info) => info.renderValue(),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('projectsCount', {
      header: () => 'Total Projects',
      cell: (info) => numberWithSpaces(info.getValue()),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('projectsProgress', {
      header: () => 'Projects in Progress',
      cell: (info) => numberWithSpaces(info.getValue()),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('projectsValue', {
      header: () => 'Total Permit Value',
      cell: (info) => (info.getValue() > 0 ? `$${numberWithSpaces(info.getValue())}` : NA),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('locationTitle', {
      header: () => 'Active Area',
      cell: (info) => <div className="w-24">{info.getValue()}</div>,
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: (info) => {
        return (
          <div className="flex align-top justify-end relative">
            <AddToFavorites
              onHeartClick={() => showFavoriteModal(info.row.original)}
              closeModal={closeAddFavoriteModal}
              entity={getEntity(info.row.original)}
              toggleCallback={favoriteCallback}
              isFavorite={info.row.original.favorite}
              isModalShown={favoriteShowId === info.row.original.id}
            />
            {onUpdateNode && (
              <DocumentIcon
                id={`document-icon-${info.row.original.id}`}
                className="stroke-[#BABCC1] ml-4"
                onClick={() => noteEntity(info.row.original)}
              />
            )}
          </div>
        );
      },
      enableSorting: false,
      footer: (info) => info.column.id
    })
  ];

  const table = useReactTable({
    data: data.data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  // Work with actions
  const showFavoriteModal = (row: CompanyData) => {
    if (!hasSubscription && onHandleUnlock) {
      onHandleUnlock();
      return;
    }
    showAddFavoriteModal(getEntity(row));
  };

  const getEntity = useCallback((row: CompanyData): IFavoriteEntity => {
    return {
      id: row.id,
      coordinates: {} as DOMRect,
      type: AllEntityTypes.Company,
      role: AllRoles.Contractor,
      key: 'table',
      name: row.name
    };
  }, []);

  const closeNoteModal = useCallback(
    () => setNoteState((prev) => ({ ...prev, showAddNote: false })),
    []
  );

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    setFavoriteShowId((prev) => (prev === entity.id ? null : entity.id));
  };

  const favoriteCallback = useCallback((isActive: boolean, id: number) => {
    onUpdateFavorite(id, isActive);
  }, []);

  const closeAddFavoriteModal = (): void => {
    setFavoriteShowId(null);
  };
  // Work with actions end

  const handleTableScroll = useCallback((): void => {
    closeAddFavoriteModal();
  }, []);

  return (
    <div>
      <NewTable
        table={table as RTable<unknown>}
        orders={orders}
        orderAction={orderAction}
        onTableScroll={handleTableScroll}
        hasSubscription={hasSubscription}
        onHandleUnlock={onHandleUnlock}
        tableClass={tableClass}
      />
      {onUpdateNode && noteState.showAddNote && noteState.entity && (
        <AddNoteModal
          entity={noteState.entity}
          closeModal={closeNoteModal}
          onNoteChange={onUpdateNode}
        />
      )}
    </div>
  );
};

export default Table;
