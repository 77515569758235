import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  useEffect,
  MouseEvent,
  MutableRefObject
} from 'react';
import { IProject, ProjectType, IListRef } from '../../types';
import classes from './ProjectInfo.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { Location, AllRoles, AllEntityTypes } from 'utils/types';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { IFavoriteEntity } from 'view/Favorites/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';
import { observer } from 'mobx-react';
import { NA } from 'utils/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { numberWithSpaces } from 'helpers/numberWithComma';

interface IProps {
  project: IProject;
  active: boolean;
  onSelectItem?: (id: number) => void;
  style?: {
    [key: string]: string;
  };
  index?: number;
  onTitleClick?: () => void;
  listRef: MutableRefObject<IListRef>;
  type: ProjectType;
  isLocked: boolean;
  hideContacts?: boolean;
  handleUnlock: ((location: Location | null) => void) | null;
}
const ProjectInfo: FC<IProps> = observer(
  ({
    project,
    active,
    onSelectItem,
    style,
    index,
    onTitleClick,
    listRef,
    isLocked,
    handleUnlock,
    hideContacts,
    type
  }) => {
    const { projectSearchStore, userStore } = useStore();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [, setParams] = useSearchParams();

    const [isFavorite, setIsFavorite] = useState<boolean>(
      type === 'recentProjects'
        ? projectSearchStore.checkRecentProjectIsFavorite(project.id)
        : projectSearchStore.checkProjectIsFavorite(project.id)
    );

    // TODO: check why this component re-render after click contact
    useEffect(() => {
      if (type == 'recentProjects') {
        setIsFavorite(projectSearchStore.checkRecentProjectIsFavorite(project.id));
      } else {
        setIsFavorite(projectSearchStore.checkProjectIsFavorite(project.id));
      }
    }, [projectSearchStore.favoriteProjects]);

    const entity = useMemo<IFavoriteEntity>(
      () => ({
        id: project.id,
        coordinates: {} as DOMRect,
        type: AllEntityTypes.Project,
        role: AllRoles.Project,
        key: type,
        name: `${project.class.name} - ${project.type.name}`
      }),
      [project]
    );

    const handleFavorite = useCallback((e: MouseEvent): void => {
      if (!userStore.user) {
        navigate('?auth=sign-in');
        return;
      }
      const thisModal = document.getElementById(
        `favorite-${entity.type}-${entity.role}-${entity.id}-${entity.key}`
      );
      if (thisModal) e.stopPropagation();
      projectSearchStore.setActiveFavorite(thisModal ? null : { id: entity.id, type });
    }, []);

    const closeFavorite = useCallback(() => {
      projectSearchStore.setActiveFavorite(null);
    }, []);

    const favoriteCallback = useCallback((isActive: boolean, id: number) => {
      projectSearchStore.updateFavoriteProjects(isActive, id);
      projectSearchStore.updateFavoriteRecentProjects(isActive, id);
    }, []);

    const handleClick = useCallback(() => onSelectItem && onSelectItem(project.id), []);
    const handleTitleClick = useCallback(() => {
      setParams(`details=${project.id}`);
      if (onTitleClick) {
        onTitleClick();
      }
    }, []);

    const selectProject = useMemo<string>(() => {
      if (
        projectSearchStore.selectedProjectOnTheMap !== null &&
        projectSearchStore.selectedProjectOnTheMap === index
      ) {
        return classes.selectProject;
      }
      return '';
    }, [projectSearchStore.selectedProjectOnTheMap]);

    const handleUnlockClick = useCallback(() => {
      if (handleUnlock) {
        handleUnlock(project.locations.county);
      }
    }, []);

    const isModalShown = useMemo<boolean>(() => {
      return (
        projectSearchStore.activeFavorite?.id === entity.id &&
        projectSearchStore.activeFavorite?.type === type
      );
    }, [entity, projectSearchStore.activeFavorite]);

    useEffect(() => {
      if (!listRef.current?._outerRef) return;
      listRef.current._outerRef.addEventListener('scroll', closeFavorite);
      return () => {
        if (!listRef.current?._outerRef) return;
        listRef.current._outerRef.removeEventListener('scroll', closeFavorite);
      };
    }, []);

    return (
      <div
        style={style}
        className={clsx(
          'py-6 pl-6 pr-4 border-t border-t-grey-200 bg-white select-text transition-colors duration-200 first-of-type:border-t-0 last-of-type:border-b last-of-type:border-b-grey-200',
          active && 'bg-light-500',
          selectProject
        )}>
        <div className="flex justify-between items-center mb-3">
          <div className={classes.status} style={{ color: project.status.color }}>
            {project.status.name}
          </div>
          <AddToFavorites
            onHeartClick={handleUnlock ? handleUnlockClick : handleFavorite}
            closeModal={closeFavorite}
            entity={entity}
            toggleCallback={favoriteCallback}
            isFavorite={isFavorite}
            isModalShown={isModalShown}
          />
        </div>
        <span className="flex justify-between items-center mb-2 font-kraftig text-base text-dark">
          <span
            className="pr-2 whitespace-nowrap text-ellipsis max-w-full overflow-hidden text-dark"
            role="button"
            onClick={handleUnlock ? handleUnlockClick : handleTitleClick}>
            {project.class.name} - {project.type.name}
          </span>
          <div className="relative">
            <span>{project.value ? `$${numberWithSpaces(project.value)}` : NA}</span>
            {project.estimatedValue && (
              <span className="absolute bottom-full right-0 text-xxs text-grey-500 translate-y-[3px]">
                EST.
              </span>
            )}
          </div>
        </span>
        <div className="flex justify-between items-center mb-2 text-grey-500 text-base font-normal">
          <span className="whitespace-nowrap text-ellipsis max-w-full overflow-hidden text-dark pr-2 text-base text-grey-500">
            {project.permitType?.name || project.description}
          </span>
          <span>{project.effectiveDate}</span>
        </div>

        {!hideContacts && (
          <div className="flex justify-between items-center">
            <span className="flex items-center whitespace-nowrap text-ellipsis max-w-full overflow-hidden text-dark">
              <span className="mr-2 flex items-center">
                <LocationIcon />
              </span>
              <span>{project.locationToShow}</span>
            </span>

            <button
              onClick={handleClick}
              className={clsx(classes.button, active && classes.active)}
              data-test-element="search-project-contacts-button">
              {t('searchProject.contacts')}
            </button>
          </div>
        )}

        {isLocked && handleUnlock && (
          <div
            role="button"
            onClick={handleUnlockClick}
            className="absolute inset-0 flex justify-center items-center backdrop-blur-sm">
            <div className={classes.lockIcon}>
              <LockIcon className="w-full h-auto" />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ProjectInfo;
