import { getLocal, removeLocal } from 'utils/localStorageOperation';
import { FoundLocation } from 'view/Search/types';
import { CURRENT_LOCATION, SHARE_TOKEN } from 'utils/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getLocationParamsFromSelectedLocation } from 'helpers/createLocationParams';
import { useStore } from 'storesProvider/storeContext';
import { SharedEntityType } from '../modules/ShareModal/types';

export const useNavigateAfterLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { pathname, location } = useLocation();
  const [params, setSearchParams] = useSearchParams();

  const { shareStore } = useStore();

  const navigateAfterLogin = () => {
    if (location.pathname.split('/').includes('search-projects')) {
      params.delete('auth');
      params.delete('extended');
      setSearchParams(params);
      return;
    }
    if (location.pathname === '/') {
      const shareToken = getLocal(SHARE_TOKEN);
      if (shareStore.shareTokens[SharedEntityType.SAVED_SEARCH].length && shareToken) {
        removeLocal(SHARE_TOKEN);
        return navigate(
          `/saved-search/share/${
            shareStore.shareTokens[SharedEntityType.SAVED_SEARCH]
          }/${shareToken}`,
          { replace: true }
        );
      }

      const localLocations = getLocal<FoundLocation[]>(CURRENT_LOCATION);
      if (localLocations) {
        navigate(`/search-projects/${getLocationParamsFromSelectedLocation(localLocations)}`, {
          replace: true
        });
        return;
      }
    }
    navigate('/saved-search', { replace: true });
  };

  return { navigateAfterLogin };
};
