import React, { FC } from 'react';
import { ISimpleBlock } from './types';
import { clsx } from 'utils/clsx';

interface IProps extends ISimpleBlock {
  background: string;
  isReversed: boolean;
}

const SimpleBlock: FC<IProps> = ({
  verticalPaddings,
  background,
  isReversed,
  subTitle,
  title,
  img
}) => {
  return (
    <div style={{ padding: `${verticalPaddings}px 0`, backgroundColor: background }}>
      <div
        className={clsx(
          'container',
          'm-auto',
          'flex',
          'items-center',
          'justify-between',
          isReversed && 'flex-row-reverse'
        )}>
        <img src={img} alt="" />
        <div className="max-w-[42%]">
          <h2 className={clsx('text-dark', 'text-5xl', subTitle && 'mb-3')}>{title}</h2>
          {subTitle && <p className="text-grey-500 text-lg">{subTitle}</p>}
        </div>
      </div>
    </div>
  );
};

export default SimpleBlock;
