import LeafletMap from 'map/LeafletMap';
import {
  DetailInfo,
  IMapService,
  IMapStore,
  IObserverMarkerChange,
  MAP_SERVICE,
  MarkerData,
  SelectMarker,
  Shape
} from './types';
import { makeAutoObservable, observe } from 'mobx';
import L, {
  DivIcon,
  LatLngBoundsLiteral,
  LayerGroup,
  LeafletMouseEvent,
  Marker,
  MarkerClusterGroup,
  Polygon
} from 'leaflet';
import { IProject } from '../../types';
import { valueFormatter } from 'helpers/valueFormatter';
import { injector } from 'utils/injector';
import { FoundLocation } from 'view/Search/types';
import { BLUE_COLOR, GREY_COLOR, NA, PURPLE_COLOR } from 'utils/constants';
import { ConfigType } from 'store/ConfigStore/types';
import { clusterSizeByCount, getTooltipPosition, markerSizeByPrice } from './helper';
import MarkerRed from 'assets/icons/marker-red.svg';
import MarkerBlue from 'assets/icons/marker-blue.svg';
import { getProjectById } from 'view/SearchProjects/helpers/getProjectById';
import { numberWithSpaces } from 'helpers/numberWithComma';

export class MapStore implements IMapStore {
  private _mapService: IMapService = injector.get<IMapService>(MAP_SERVICE);

  constructor() {
    makeAutoObservable<MapStore>(this);
    observe(this, 'selectMarker', (change): void => {
      if (change.oldValue) {
        const { event, data } = change.oldValue as IObserverMarkerChange;
        this.setPrevSelectMarker(event, data);
      }
    });
    this.leafletMap = new LeafletMap();
  }

  leafletMap: LeafletMap;
  cluster: MarkerClusterGroup | null = null;
  layer: Marker[] = [];
  markerData: MarkerData[] = [];
  currentLocation: FoundLocation | null = null;
  layerGroup: LayerGroup | null = null;
  allLocations: DetailInfo[] = [];
  selectMarker: SelectMarker | null = null;
  prevSelectMarker: SelectMarker | null = null;
  selectedLocation: FoundLocation | null = null;
  useFitBounds = true;
  loader = false;

  setCluster(cluster: MarkerClusterGroup): void {
    this.cluster = cluster;
  }

  setLayer(layer: Marker): void {
    this.layer.push(layer);
  }

  setMarkerData(markers: MarkerData[]): void {
    this.markerData = markers;
  }

  setCurrentLocation(location: FoundLocation | null): void {
    this.currentLocation = location;
  }

  setLayerGroup(layerGroup: LayerGroup): void {
    this.layerGroup = layerGroup;
  }

  // TODO: Remove after testing
  // Заменить на setLocations(location: DetailInfo[]): void
  // setLocations(location: DetailInfo[]): void {
  //   this.allLocations = [];
  //   if (location.length === 1) {
  //     this.allLocations = this.allLocations.concat(location);
  //     return;
  //   }
  //   this.allLocations = location;
  //   // if (location.length > 1) {
  //   //   this.allLocations = this.allLocations.concat(location);
  //   //   return;
  //   // }
  //   // this.allLocations = location;
  // }

  setAllLocations(location: DetailInfo[]): void {
    this.allLocations = this.allLocations.concat(location);
  }

  setUpdateLocations(location: DetailInfo[]): void {
    this.allLocations = location;
  }

  setSelectMarker(e: LeafletMouseEvent, data: MarkerData): void {
    this.selectMarker = {
      event: e,
      data
    };
  }

  setPrevSelectMarker(e: LeafletMouseEvent, data: MarkerData): void {
    this.prevSelectMarker = {
      event: e,
      data
    };
  }

  setSelectedLocation(location: FoundLocation | null): void {
    this.selectedLocation = location;
  }

  setLoader(loader: boolean): void {
    this.loader = loader;
  }

  clearSelectMarker(): void {
    this.selectMarker = null;
  }

  initCluster(): void {
    this.setCluster(
      L.markerClusterGroup({
        chunkedLoading: true,
        iconCreateFunction: (cluster) => {
          return L.divIcon({
            className: `${clusterSizeByCount(
              cluster.getChildCount()
            )} custom-circle-cluster flex rounded-full justify-center items-center font-kraftig`,
            html: '<span>' + cluster.getChildCount() + '</span>'
          });
        }
      })
    );
    if (!this.cluster) return;
  }

  async initMarkers(projects: IProject[]): Promise<void> {
    if (projects.length) {
      this.generateMarkerData(projects);
      await this.addMarkersOnTheMap();
      return;
    }
    this.cluster?.clearLayers();
    if (this.layer) {
      this.layer.forEach((marker) => marker.remove());
    }
  }

  async initPolygons(): Promise<void> {
    this.setLoader(true);
    if (!this.locationOnTheList()) {
      await this.getPolygonById();
    }
    await this.getPolygonsNeighbour();
    this.deletedDuplicateLocations();
    this.setPolygons();
    this.setLoader(false);
    // TODO: Remove after testing
    // if (this.allLocations.length) {
    //   if (this.allLocations[0].type !== this.getCurrentType()) {
    //     this.setUpdateLocations([]);
    //     this.layerGroup?.remove();
    //   }
    //   if (!this.locationOnTheList()) {
    //     await this.getPolygonById();
    //   }
    //   await this.getPolygonsNeighbour();
    //   this.deletedDuplicateLocations();
    //   this.setPolygons();
    // } else {
    //   await this.getPolygonById();
    //   await this.getPolygonsNeighbour();
    //   this.setPolygons();
    // }
  }

  getCurrentType(): number {
    return typeof this.currentLocation?.type === 'number'
      ? this.currentLocation?.type
      : (this.currentLocation?.type as ConfigType).id;
  }

  locationOnTheList(): boolean {
    return !!this.allLocations.find((location) => location.id === this.currentLocation?.id);
  }

  // TODO: probably move to helpers
  deletedDuplicateLocations(): void {
    const used: { [key: number]: number } = {};
    this.setUpdateLocations(
      this.allLocations.filter((obj) => {
        return obj.id in used ? 0 : (used[obj.id] = 1);
      })
    );
  }

  customMarker(value: number): DivIcon {
    if (this.leafletMap.map && this.leafletMap.map.getZoom() === 18) {
      return this.getMarkerMax(value);
    }
    return this.getMarkerRed(value);
  }

  //TODO: Update to class in the future
  getMarkerRed(value: number): DivIcon {
    return L.icon({
      iconUrl: MarkerRed,
      className: `${markerSizeByPrice(value)}`
    });
  }

  //TODO: Update to class in the future
  getMarkerBlue(value: number): DivIcon {
    return L.icon({
      iconUrl: MarkerBlue,
      className: `${markerSizeByPrice(value)}`
    });
  }

  //TODO: Update to class in the future
  getMarkerMax(value: number): DivIcon {
    return L.divIcon({
      className: 'custom-marker-max font-kraftig',
      html: `${valueFormatter(value)}`
    });
  }

  generateMarkerData(projects: IProject[]): void {
    this.setMarkerData(
      projects.map((project) => ({
        projectId: project.id,
        location: {
          ...project.locations.address,
          coordinates: [...project.locations.address.coordinates].reverse() as [number, number]
        },
        value: project.value,
        status: project.status.name
      }))
    );
  }

  markersCoordinate(): [number, number][] {
    return this.markerData.map((data) => data.location.coordinates);
  }

  async addMarkersOnTheMap(): Promise<void> {
    if (this.markerData.length && this.leafletMap.map && this.cluster) {
      this.cluster.clearLayers();
      if (this.layer) {
        this.layer.forEach((marker) => marker.remove());
      }
      this.markersCreation();
      if (this.leafletMap.map.getZoom() === 18) {
        this.leafletMap.map.addLayer(this.cluster);
      }
      return;
    }
  }

  markersCreation(): void {
    this.markerData.forEach((data) => {
      const project = getProjectById(data.projectId);
      const marker = L.marker(data.location.coordinates, {
        icon: this.customMarker(data.value)
      })
        .on('click', (e) => {
          this.setSelectMarker(e, data);
          this.updateIconByClick();
        })
        .on('mouseover', (e) => {
          if (this.leafletMap.map?.getZoom() === 18) {
            e.target.getElement().classList.add('select-marker-max');
          } else {
            e.target.setIcon(this.getMarkerBlue(data.value));
          }
          this.addTooltipOnMarker(marker, project, data, e);
        })
        .on('mouseout', (e) => {
          if (this.leafletMap.map?.getZoom() === 18) {
            if (e.target.getElement() !== this.selectMarker?.event.target.getElement()) {
              e.target.getElement().classList.remove('select-marker-max');
            }
          } else {
            if (e.target.getElement() !== this.selectMarker?.event.target.getElement()) {
              e.target.setIcon(this.getMarkerRed(data.value));
            }
          }
        });
      if (this.leafletMap.map?.getZoom() === 18) {
        this.cluster?.addLayer(marker);
      } else {
        if (this.leafletMap.map) {
          const layer = marker.addTo(this.leafletMap.map);
          layer.addTo(this.leafletMap.map);
          this.setLayer(layer);
        }
      }
    });
  }

  addTooltipOnMarker(
    marker: Marker,
    project: IProject | null,
    data: MarkerData,
    e: LeafletMouseEvent
  ): void {
    marker
      .bindTooltip(
        `<div>
          <h1 class="tooltip-value font-kraftig mb-2">${
            project?.value ? `$${numberWithSpaces(project.value)}` : NA
          }</h1>
            <h2 class="tooltip-title font-kraftig">${project?.class.name} - ${
          project?.type.name
        }</h2>
          <p class="tooltip-description">${
            project?.permitType?.name || project?.description || ''
          }</p>
        </div>`,
        {
          direction: e.containerPoint.y >= 110 ? 'top' : 'bottom',
          opacity: 1,
          className: `${
            e.containerPoint.y >= 110 ? 'custom-top-tooltip' : 'custom-bottom-tooltip'
          } ${
            this.leafletMap.map?.getZoom() !== 18
              ? getTooltipPosition(data.value)
              : 'tooltip-for-marker-max'
          }`
        }
      )
      .openTooltip();
  }

  updateIconByClick(): void {
    const currentMarker = this.layer.find(
      (marker) => marker.getElement() === this.selectMarker?.event.target.getElement()
    );
    const prevMarker = this.layer.find(
      (marker) => marker.getElement() === this.prevSelectMarker?.event.target.getElement()
    );
    if (this.leafletMap.map && this.leafletMap.map.getZoom() === 18) {
      this.selectMarker?.event.target.getElement().classList.add('select-marker-max');
      this.prevSelectMarker?.event.target.getElement().classList.remove('select-marker-max');
    } else {
      if (currentMarker) {
        currentMarker.setIcon(this.getMarkerBlue(this.selectMarker?.data.value || 0));
      }
      if (prevMarker) {
        prevMarker.setIcon(this.getMarkerRed(this.prevSelectMarker?.data.value || 0));
      }
    }
  }

  async getPolygonById(): Promise<void> {
    if (this.currentLocation) {
      try {
        this.setAllLocations([await this._mapService.getLocationInfo(this.currentLocation.id)]);
        // this.setLocations([res]);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async getPolygonsNeighbour(): Promise<void> {
    if (this.currentLocation) {
      try {
        this.setAllLocations(await this._mapService.getNeighbourLocations(this.currentLocation.id));
        // this.setLocations(await this._mapService.getNeighbourLocations(this.currentLocation.id));
      } catch (e) {
        console.log(e);
      }
    }
  }

  setPolygons(): void {
    if (this.layerGroup) {
      this.layerGroup.remove();
    }
    this.setLayerGroup(new L.FeatureGroup());
    if (this.layerGroup) {
      this.leafletMap.map?.addLayer(this.layerGroup);
    }
    for (const location of this.allLocations) {
      if (location.id === this.currentLocation?.id) {
        if (this.useFitBounds) {
          this.fitBoundsLocation(location.shape);
        }
        this.layerGroup?.addLayer(this.createPolygon(location, BLUE_COLOR));
      } else {
        this.layerGroup?.addLayer(this.createPolygon(location, GREY_COLOR));
      }
    }
    this.useFitBounds = true;
  }

  createPolygon(location: DetailInfo, color: string): Polygon {
    const layer = L.polygon(location.shape, { color, interactive: color !== BLUE_COLOR })
      .on('click', () => this.clickByPolygon(location))
      .bindTooltip(location.title, {
        permanent: false,
        direction: 'center',
        className: 'custom-label'
      })
      .openTooltip();
    layer.on('mouseover', () => {
      if (layer.options.color !== BLUE_COLOR) {
        layer.setStyle({
          color: PURPLE_COLOR
        });
      }
    });
    layer.on('mouseout', () => {
      layer.setStyle({
        color
      });
    });
    return layer;
  }

  clickByPolygon(location: DetailInfo): void {
    if (this.selectedLocation && this.selectedLocation.id === location.id) {
      return;
    }
    this.setSelectedLocation({
      id: location.id,
      title: location.fullTitle,
      type: location.type,
      canonicalTitle: location.canonicalTitle,
      stateCode: location.stateCode,
      subscription: true
    });
    this.useFitBounds = false;
  }

  fitBoundsLocation(shapes: Shape): void {
    //TODO: need refactoring in the future
    let allShapes: LatLngBoundsLiteral[] = [];
    for (const shape of shapes) {
      for (const points of shape) {
        if (Array.isArray((points as LatLngBoundsLiteral)[0])) {
          allShapes = allShapes.concat(shape as LatLngBoundsLiteral);
          continue;
        } else {
          allShapes = [(shapes as LatLngBoundsLiteral[])[0]];
        }
      }
    }
    const bigShape: number = allShapes.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0);
    this.leafletMap.map?.fitBounds(allShapes[bigShape]);
  }
}
