import { IOption } from 'components/Select/types';
import { Sort } from './types';

export const skeletonsToShow = 10;

export const sortOptions: IOption[] = [
  {
    text: 'Newest',
    value: 'new',
    id: 1
  },
  {
    text: 'Oldest',
    value: 'old',
    id: 2
  },
  {
    text: 'Price Asc',
    value: 'priceAsc',
    id: 3
  },
  {
    text: 'Price Des',
    value: 'priceDes',
    id: 4
  }
];

export const sorts: Sort = {
  new: {
    field: 'effectiveDate',
    order: 'desc'
  },
  old: {
    field: 'effectiveDate',
    order: 'asc'
  },
  priceAsc: {
    field: 'value',
    order: 'asc'
  },
  priceDes: {
    field: 'value',
    order: 'desc'
  }
};
