import React, { FC, useCallback, useState, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  Table as RTable
} from '@tanstack/react-table';
import classes from 'components/NewTable/Table.module.scss';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { NA } from 'utils/constants';
import { IFavoriteEntity } from 'view/Favorites/types';
import { AllEntityTypes, AllRoles, EntityType, ISort } from 'utils/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';
import { NewTable } from 'components';
import { useStore } from 'storesProvider/storeContext';
import { getDataFromConfig, getLocations } from 'helpers/getDataForTable';
import moment from 'moment';
import { clsx } from 'utils/clsx';
import ContactItem from 'components/NewTable/components/ContactItem';
import { INoteEntity, INoteState } from 'view/AddNoteModal/types';
import AddNoteModal from 'view/AddNoteModal';
import { Location } from 'utils/types';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ISavedSearchResultsProject } from '../types';

interface Props {
  data: ISavedSearchResultsProject[];
  onUpdateFavorite: (id: number, isActive: boolean, listId: number) => void;
  onDetails: (id: number) => void;
  onContact: (id: number, type: EntityType, role: AllRoles) => void;
  onUpdateNode: (id: number, hasNote: boolean) => void;
  orders: ISort[];
  orderAction: (field: string) => void;
  hasSubscription?: boolean;
  shared?: boolean;
  onHandleUnlock?: (location?: Location) => void;
  tableClass: string;
}

const SavedSearchTable: FC<Props> = ({
  data,
  onUpdateFavorite,
  onDetails,
  onContact,
  onUpdateNode,
  orders,
  orderAction,
  hasSubscription,
  shared,
  onHandleUnlock,
  tableClass
}) => {
  const { configStore } = useStore();
  const [favoriteShowId, setFavoriteShowId] = useState<number | null>(null);
  const columnHelper = createColumnHelper<ISavedSearchResultsProject>();
  const [noteState, setNoteState] = useState<INoteState>({
    entity: {
      coordinates: null,
      name: null,
      description: null,
      id: null,
      type: null
    },
    showAddNote: false
  });

  const columns = [
    columnHelper.accessor('class', {
      header: () => 'Details',
      cell: (info) => {
        return (
          <>
            {info.row.original.new && <div className="rounded-full absolute new-notification" />}
            <span onClick={() => onDetails(info.row.original.id)} className="cursor-pointer">
              {configStore.enums
                ? `${getDataFromConfig(configStore.enums.propertyClasses, info.getValue().id)} - ${
                    info.row.original.type.name
                  }`
                : ''}
            </span>
            <p className={clsx('text-sm', 'text-grey-500', 'mb-4', classes.description)}>
              {info.row.original.description}
            </p>
          </>
        );
      }
    }),
    columnHelper.accessor('effectiveDate', {
      header: () => 'Date',
      cell: (info) => (
        <span className={clsx('text-sm', 'text-grey-500', 'mb-4', classes.description)}>
          {moment(info.getValue()).format('MM/DD/YYYY')}
        </span>
      )
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      enableSorting: false,
      cell: (info) => {
        return (
          <span
            style={{ color: info.getValue().color }}
            className={clsx(
              classes['status' + info.getValue()],
              'block',
              'py-1',
              'px-2',
              'w-fit',
              'border',
              'border-light-600',
              'rounded-sm',
              'uppercase',
              'text-xs'
            )}>
            {info.getValue().name}
          </span>
        );
      }
    }),
    columnHelper.accessor('locations', {
      header: () => 'Location',
      enableSorting: false,
      cell: (info) => getLocations(info.getValue())
    }),
    columnHelper.accessor('contacts', {
      header: () => 'Contacts',
      enableSorting: false,
      cell: (info) => {
        return (
          <div className="flex flex-wrap -mb-6">
            {info.getValue().map((contact, idx) => (
              <ContactItem
                key={`contact-${idx}`}
                contact={contact}
                onContact={() => onContact(contact.id, contact.type, contact.role)}
              />
            ))}
          </div>
        );
      }
    }),
    columnHelper.accessor('value', {
      header: () => 'Value',
      cell: (info) => {
        return info.getValue() > 0 ? `$${numberWithSpaces(info.getValue())}` : NA;
      }
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: (info) => {
        if (shared) return;
        return (
          <div className={`flex align-top justify-end text-right`}>
            <AddToFavorites
              onHeartClick={() => openFavoriteModal(info.row.original)}
              closeModal={closeAddFavoriteModal}
              entity={getEntity(info.row.original)}
              toggleCallback={favoriteCallback}
              isFavorite={info.row.original.favourite}
              isModalShown={favoriteShowId === info.row.original.id}
            />
            <DocumentIcon
              id={`document-icon-${info.row.original.id}`}
              className="stroke-[#BABCC1] ml-4"
              onClick={() => noteEntity(info.row.original)}
            />
          </div>
        );
      },
      enableSorting: false
    })
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  // Work with actions
  const openFavoriteModal = (row: ISavedSearchResultsProject) => {
    showAddFavoriteModal(getEntity(row));
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  const getEntity = useCallback((row: ISavedSearchResultsProject): IFavoriteEntity => {
    return {
      id: row.id,
      coordinates: {} as DOMRect,
      type: AllEntityTypes.Project,
      role: AllRoles.Project,
      key: 'table',
      name: configStore.enums
        ? `${getDataFromConfig(configStore.enums.propertyClasses, row.class.id)} - ${row.type.name}`
        : ''
    };
  }, []);

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    setFavoriteShowId((prev) => (prev === entity.id ? null : entity.id));
  };

  const favoriteCallback = useCallback((isActive: boolean, id: number, listId: number) => {
    onUpdateFavorite(id, isActive, listId);
  }, []);

  const closeAddFavoriteModal = (): void => {
    setFavoriteShowId(null);
  };

  const noteEntity = (row: ISavedSearchResultsProject) => {
    const coordinates: DOMRect | null =
      document.getElementById(`document-icon-${row.id}`)?.getBoundingClientRect() || null;
    const entity: INoteEntity = {
      coordinates,
      name: configStore.enums
        ? getDataFromConfig(configStore.enums.propertyClasses, row.class.id)
        : null,
      description: row.description,
      id: row.id,
      type: 'project'
    };
    setNoteState((prev) => ({ ...prev, entity }));
    showAddNoteModal(row.id);
  };

  const showAddNoteModal = useCallback((id: number) => {
    const thisModal = document.getElementById(`note-${id}`);
    setNoteState((prev) => ({ ...prev, showAddNote: !thisModal }));
    setFavoriteShowId(null);
  }, []);

  const closeNoteModal = useCallback(
    () => setNoteState((prev) => ({ ...prev, showAddNote: false })),
    []
  );
  // Work with actions end

  const handleTableScroll = () => {
    setFavoriteShowId(null);
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  return (
    <div>
      <NewTable
        table={table as RTable<unknown>}
        orders={orders}
        orderAction={orderAction}
        onTableScroll={handleTableScroll}
        hasSubscription={hasSubscription}
        onHandleUnlock={onHandleUnlock}
        tableClass={tableClass}
      />
      {noteState.showAddNote && noteState.entity && (
        <AddNoteModal
          entity={noteState.entity}
          closeModal={closeNoteModal}
          onNoteChange={onUpdateNode}
        />
      )}
    </div>
  );
};

export default SavedSearchTable;
