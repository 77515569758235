import { valueFormatter } from 'helpers/valueFormatter';
import { IProjectListResponse, IProjectsData } from 'view/SearchProjects/types';
import { IRow } from 'components/Table/types';

export const createProjectCountString = (
  projectsData: IProjectsData | IProjectListResponse<IRow[]>,
  text: string
): string => {
  if (projectsData) {
    const value = valueFormatter(projectsData.showTotal);
    return (projectsData.showTotal > 1000 ? value + '+ ' : value + ' ') + text;
  }
  return '';
};
