import {
  FAVORITES_SERVICE,
  IFavoriteData,
  FavoritesList,
  IFavoritesService,
  IFavoritesStore,
  IFavoriteFilters,
  IFavoriteDataBody,
  IFavoriteDataRes,
  ProjectData,
  CompanyData,
  PersonData
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { IOption } from 'components/Select/types';
import { ITreeOption } from 'view/SearchProjects/components/SubHeader/types';
import { COMPANY, PERSON, PROJECT } from 'utils/constants';
import { mapPropertyTypesToOptions } from 'helpers/mapConfigsToOptions';

export class FavoritesStore implements IFavoritesStore {
  private _favoritesService: IFavoritesService = injector.get<IFavoritesService>(FAVORITES_SERVICE);

  constructor() {
    makeAutoObservable<FavoritesStore>(this);
  }

  list: FavoritesList[] = [];
  totalValue = 0;
  projects: IFavoriteDataRes<ProjectData> | null = null;
  companies: IFavoriteDataRes<CompanyData> | null = null;
  persons: IFavoriteDataRes<PersonData> | null = null;
  // dataTable: IRowFavorite[] = [];
  totalPages = 0;
  categories: ITreeOption[] = [];

  setCategories(categories: ITreeOption[]): void {
    this.categories = categories;
  }

  resetCategories(): void {
    this.categories = mapPropertyTypesToOptions();
  }

  setList(list: FavoritesList[]): void {
    this.list = list;
  }

  setTotalValue(value: number): void {
    this.totalValue = value;
  }

  setProjects(projects: IFavoriteDataRes<ProjectData> | null): void {
    this.projects = projects;
  }

  setCompanies(companies: IFavoriteDataRes<CompanyData> | null): void {
    this.companies = companies;
  }

  setPersons(persons: IFavoriteDataRes<PersonData> | null): void {
    this.persons = persons;
  }

  // setDataTable(data: IRowFavorite[]): void {
  //   this.dataTable = data;
  // }

  setTotalPages(pages: number): void {
    this.totalPages = pages;
  }

  onCategoryChange(classes: number[], types: number[], subtypes: number[]) {
    this.setCategories(mapPropertyTypesToOptions(classes, types, subtypes));
  }

  async createFavoritesList(id: number, name: string): Promise<FavoritesList> {
    const newList = await this._favoritesService.createFavoritesList(id, name);
    this.setList([...this.list, newList]);
    return newList;
  }

  async addToFavorites(id: number, data: IFavoriteData): Promise<void> {
    await this._favoritesService.addToFavorites(id, data);
  }

  async deleteFromFavorites(id: number, data: IFavoriteData): Promise<void> {
    await this._favoritesService.deleteFromFavorites(id, data);
  }

  async getListsByEntity(
    id: number,
    type: string,
    entityId: number,
    role: string
  ): Promise<number[]> {
    return await this._favoritesService.getListsByEntity(id, type, entityId, role);
  }

  async getFavoritesList(userId: number): Promise<void> {
    this.setList(await this._favoritesService.getFavoritesList(userId));
  }

  async getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<void> {
    this.setTotalValue(
      await this._favoritesService.getFavoritesProjectsTotalValue(userId, systemType, lists)
    );
  }

  async deleteFavoriteList(userId: number, listId: number): Promise<void> {
    await this._favoritesService.deleteFavoriteList(userId, listId);
    this.setList(this.list.filter((item) => item.id !== listId));
  }

  async editFavoriteList(userId: number, listId: number, name: string): Promise<void> {
    await this._favoritesService.editFavoriteList(userId, listId, name).then((res) =>
      this.setList(
        this.list.map((item) => {
          if (item.id === res.id) {
            return res;
          }
          return item;
        })
      )
    );
  }

  async getFavoritesData(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<void> {
    if (systemType === PROJECT) {
      this.setProjects(
        await this._favoritesService.getFavoritesData<ProjectData>(userId, systemType, body)
      );
    }
    if (systemType === COMPANY) {
      this.setCompanies(
        await this._favoritesService.getFavoritesData<CompanyData>(userId, systemType, body)
      );
    }
    if (systemType === PERSON) {
      this.setPersons(
        await this._favoritesService.getFavoritesData<PersonData>(userId, systemType, body)
      );
    }
    // this.setDataTable(response.dataTable);
    // this.setTotalPages(response.totalPages);
  }

  async getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]> {
    return await this._favoritesService.getFavoriteLocations(
      userId,
      systemType,
      listId,
      favoriteFilters
    );
  }

  // getFavoriteById(id: number): IRowFavorite | null {
  //   return this.dataTable.find((row) => row.id === id) || null;
  // }

  updateNoteStatus(id: number, hasNote: boolean, systemType: string): void {
    if (systemType === PROJECT && this.projects) {
      this.setProjects({
        ...this.projects,
        data: this.projects.data.map((item) => ({
          ...item,
          hasNotes: item.id === id ? hasNote : item.hasNotes
        }))
      });
    }
    if (systemType === COMPANY && this.companies) {
      this.setCompanies({
        ...this.companies,
        data: this.companies.data.map((item) => ({
          ...item,
          hasNotes: item.id === id ? hasNote : item.hasNotes
        }))
      });
    }
    if (systemType === PERSON && this.persons) {
      this.setPersons({
        ...this.persons,
        data: this.persons.data.map((item) => ({
          ...item,
          hasNotes: item.id === id ? hasNote : item.hasNotes
        }))
      });
    }
  }

  async validateSharedToken(token: string): Promise<void> {
    await this._favoritesService.validateSharedToken(token);
  }
}
