import { FunctionComponent } from 'react';

export interface IAxiosResponse<T> {
  data: {
    result: T;
  };
}

export interface IAxiosError<T> {
  code: string;
  message: string;
  name: string;
  response: {
    data: T;
    status: number;
  };
}

export interface IAxiosErrorResponse {
  message: string;
  code: number;
}

export interface ITableData<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: T[];
}

export interface IIconConfig {
  [key: string]: FunctionComponent;
}

// TODO: remove after refactoring table
export enum SourceLink {
  PHONE = 10,
  EMAIL = 20,
  LINKEDIN = 30,
  WEB = 40
}

export enum Icons {
  PHONE = 10,
  EMAIL = 20,
  LINKEDIN = 30,
  WEB = 40,
  GROUP = 50,
  USER = 60
}

export type ClickEvent = {
  index: number | null;
  selected: number;
  nextSelectedPage: number | undefined;
  event: object;
  isPrevious: boolean;
  isNext: boolean;
  isBreak: boolean;
  isActive: boolean;
};

export type ObjectValues<T> = { [key: string]: T };

export type Detail = {
  type: number;
  value: string;
};

export interface IContact<T = Detail> {
  id: number;
  type: EntityType;
  name: string;
  details: T[];
  role: AllRoles;
  favourite: boolean;
}

type LocationKey = 'address' | 'city' | 'county' | 'state' | 'zip';

export type Location = {
  id: number;
  title: string;
  type: number;
  stateCode: string;
  canonicalTitle: string;
  coordinates: [number, number];
};

export type ILocation = {
  [key in LocationKey]: Location;
};

export type Status = {
  id: number;
  name: string;
  color: string;
};

export interface ISort {
  field: string;
  order: 'asc' | 'desc';
}

export type EntityType = 10 | 20 | 30;
export type AllRole = 10 | 20 | 30 | 40;

export enum AllEntityTypes {
  Person = 10,
  Company = 20,
  Project = 30
}

export enum AllRoles {
  Architect = 10,
  Owner = 20,
  Contractor = 30,
  Project = 40
}

export const entityTypes: { [key: number]: string } = {
  10: 'person',
  20: 'company',
  30: 'project'
};

export type entityTypesValues = (typeof entityTypes)[AllEntityTypes];

export type PersonDetail = {
  type: number;
  value: string;
  icon: FunctionComponent;
};

export enum SearchType {
  PROJECT = 10,
  COMPANY = 20,
  CROSS_REFERENCING_PROJECT = 30,
  CROSS_REFERENCING_COMPANY = 40,
  CROSS_REFERENCING_BOTH = 50
}

export type SearchStringType = 'project' | 'company';

export type UsableLocation = {
  id: number;
  title: string;
  hasSubscription: boolean;
};
