import {
  IContactInfoStore,
  IContactInfoService,
  CONTACT_INFO_SERVICE,
  ICompanyResponse
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { AllRoles, EntityType, entityTypesValues, IContact } from 'utils/types';

export class ContactInfoStore implements IContactInfoStore {
  private _contactInfoService: IContactInfoService =
    injector.get<IContactInfoService>(CONTACT_INFO_SERVICE);

  constructor() {
    makeAutoObservable<ContactInfoStore>(this);
  }

  contact: IContact | null = null;
  company: ICompanyResponse | null = null;

  setContact(contact: IContact | null): void {
    this.contact = contact;
  }

  setCompany(company: ICompanyResponse | null): void {
    this.company = company;
  }

  async getContactById(id: number, type: entityTypesValues, role: AllRoles): Promise<void> {
    const res = await this._contactInfoService.getContactById(id, type);
    this.setContact({
      ...res,
      role
    });
  }

  async getCompany(projectId: number, role: string, contactId: number): Promise<void> {
    this.setCompany(await this._contactInfoService.getCompany(projectId, role, contactId));
  }

  async getCompanyContactDetailsById(contactId: number): Promise<void> {
    this.setCompany(await this._contactInfoService.getCompanyContactDetailsById(contactId));
  }
}
