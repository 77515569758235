import React, { FC, useEffect, useState } from 'react';
import 'leaflet.markercluster';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_ZERO_DELAY, VIEWPORT_DEBOUNCE_DELAY } from 'utils/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MapProjects: FC = observer(() => {
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const { mapStore, projectSearchStore, searchStore, filtersStore, userStore } = useStore();
  const [delay, setDelay] = useState<number>(VIEWPORT_DEBOUNCE_DELAY);

  const debouncedViewPort = useDebounce<[number, number][]>(mapStore.leafletMap.viewArea, delay);

  useEffect(() => {
    const container = document.getElementsByClassName('leaflet-container')[0];
    const mapElement: HTMLElement | null = document.getElementById('mapId');
    if (!container && mapElement) {
      mapStore.leafletMap.initMapForSearchProject(mapElement);
      mapStore.initCluster();
    }
    return () => {
      mapStore.setCurrentLocation(null);
      mapStore.setSelectedLocation(null);
      mapStore.setUpdateLocations([]);
      mapStore.clearSelectMarker();
      mapStore.leafletMap.setViewArea([]);
      mapStore.leafletMap.map?.remove();
    };
  }, []);

  useEffect(() => {
    (async () => await mapStore.initMarkers(projectSearchStore.projectsData.data))();
  }, [projectSearchStore.projectsData]);

  useEffect(() => {
    if (searchStore.locationForMap.length && searchStore.locationForMap.length === 1) {
      if (searchStore.locationForMap[0].id !== mapStore.currentLocation?.id) {
        setDelay(DEBOUNCE_ZERO_DELAY);
      }
      mapStore.setCurrentLocation(searchStore.locationForMap[0]);
      if (
        mapStore.selectedLocation &&
        mapStore.selectedLocation.id !== searchStore.locationForMap[0].id
      ) {
        mapStore.setSelectedLocation(searchStore.locationForMap[0]);
      }
      (async () => await mapStore.initPolygons())();
    }
  }, [searchStore.locationForMap]);

  useEffect(() => {
    if (mapStore.selectedLocation) {
      navigate(
        `/search-projects/${mapStore.selectedLocation.stateCode}/${mapStore.selectedLocation.canonicalTitle}`
      );
    }
  }, [mapStore.selectedLocation]);

  useEffect(() => {
    if (mapStore.selectMarker) {
      if (userStore.user) {
        const { data } = mapStore.selectMarker;
        projectSearchStore.selectProjectOnTheMap(data.projectId);
        setParams(`details=${data.projectId}`);
      } else {
        setParams('auth=sign-up&extended=true');
      }
    }
  }, [mapStore.selectMarker]);

  useEffect(() => {
    if (mapStore.leafletMap?.viewArea.length) {
      projectSearchStore.setActiveFavorite(null);
      filtersStore.setSearchParams('area', mapStore.leafletMap.viewArea);
      setDelay(VIEWPORT_DEBOUNCE_DELAY);
    }
  }, [debouncedViewPort]);

  return (
    <div className="w-full h-full relative">
      {mapStore.loader && (
        <div className="absolute bg-white inset-0 z-[5] flex items-center justify-center">
          <div className="loader p-0">
            <div />
          </div>
        </div>
      )}
      <div id="mapId"></div>
    </div>
  );
});

export default MapProjects;
