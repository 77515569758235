import { ConfigType } from 'store/ConfigStore/types';
import { IAxiosResponse } from 'utils/types';

export type FoundLocation = {
  id: number;
  title: string;
  targetTitle?: string;
  type?: number | ConfigType;
  canonicalTitle: string;
  stateCode: string;
  // focused?: boolean;
  subscription: boolean;
};

export type SearchProjects = {
  count: number;
  subscriptionCount: number;
  data: FoundLocation[];
  subscriptionData: {
    data: FoundLocation[];
  };
};

export interface ISearchStore {
  setFoundLocations(locations: SearchProjects | null): void;
  setSelectedLocation(location: FoundLocation[]): void;
  searchProjects(value: string, controller: AbortController, searchId?: number): Promise<void>;
  lookupLocation(states: string[], titles: string[]): Promise<void>;
}

export const SEARCH_SERVICE = 'SEARCH_SERVICE';
export interface ISearchService {
  searchProjects(
    value: string,
    controller: AbortController,
    searchId?: number
  ): Promise<SearchProjects>;
  lookupLocation(states: string[], titles: string[]): Promise<FoundLocation[]>;
}

export const SEARCH_REPO = 'SEARCH_REPO';
export interface ISearchRepo {
  searchProjects(
    value: string,
    controller: AbortController,
    searchId?: number
  ): Promise<IAxiosResponse<SearchProjects>>;
  lookupLocation(states: string[], titles: string[]): Promise<IAxiosResponse<FoundLocation[]>>;
}
