import {
  ISearchProjectService,
  IKeyword,
  ISearchProjectsRepo,
  SEARCH_PROJECTS_REPO,
  IProjectsData,
  RelatedProjectKey,
  IRecentProjectsData,
  IProjectListResponse,
  IDetail
} from './types';
import { injector } from 'utils/injector';
import { detailIcons, iconListByNum } from 'utils/icons';
import { IFullProject } from 'modules/ProjectInfoModal/types';
import moment from 'moment';
import { tableRowGeneration } from './components/ListView/TableRowGeneration';
import { IRow } from 'components/Table/types';
import { IContact } from 'utils/types';
import { ISearchParams } from 'modules/Filter/types';

const copyParams = (params: ISearchParams): ISearchParams => {
  let copy: ISearchParams = {} as ISearchParams;
  for (const key in params) {
    const k = key as keyof ISearchParams;
    if (!(key === 'keywords' && !params.keywords.length)) copy = { ...copy, [k]: params[k] };
  }
  return copy;
};

export class SearchProjectsService implements ISearchProjectService {
  private _searchRepo: ISearchProjectsRepo =
    injector.get<ISearchProjectsRepo>(SEARCH_PROJECTS_REPO);

  async getKeywords(keyword: string): Promise<IKeyword[]> {
    return (await this._searchRepo.getKeywords(keyword)).data.result;
  }
  async getProjects(
    params: ISearchParams,
    controller: AbortController
  ): Promise<IProjectsData | null> {
    const res = await this._searchRepo.getProjects(copyParams(params), controller);
    const data = res ? res.data.result : null;
    if (data) {
      return {
        ...data,
        data: data.data.map((project, index) => ({
          ...project,
          createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
          effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY'),
          locationToShow: `${project.locations.city ? project.locations.city.title : ''} ${
            project.locations.state.stateCode
          }`,
          isLocked: data.hasSubscription ? false : !(params.page === 1 && index < 3)
        }))
      };
    }
    return null;
  }

  // TODO: remove after testing
  // async getStatistics(params: ISearchParams, controller?: AbortController): Promise<Statistics> {
  //   return (await this._searchRepo.getStatistics(copyParams(params), controller)).data.result;
  // }

  async getContacts(id: number): Promise<IContact<IDetail>[]> {
    return (await this._searchRepo.getContacts(id)).data.result.map((contact) => ({
      ...contact,
      details: contact.details.map((detail) => ({
        ...detail,
        icon: detailIcons[String(detail.type)]
      }))
    }));
  }

  // async getCompany(projectId: number, role: string, contactId: number): Promise<ICompanyResponse> {
  //   const res = (await this._searchRepo.getCompany(projectId, role, contactId)).data.result;
  //   return {
  //     ...res,
  //     details: res.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] })),
  //     projectPersons: res.projectPersons.map((person) => ({
  //       ...person,
  //       details: person.details.map((detail) => ({
  //         ...detail,
  //         icon: iconListByNum[detail.type]
  //       }))
  //     })),
  //     otherPersons: res.otherPersons.map((person) => ({
  //       ...person,
  //       details: person.details.map((detail) => ({
  //         ...detail,
  //         icon: iconListByNum[detail.type]
  //       }))
  //     }))
  //   };
  // }

  async getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IRecentProjectsData> {
    const res = (await this._searchRepo.getRecentProjects(contactType, contactId, page)).data
      .result;
    return {
      ...res,
      data: res.data.map((project) => ({
        ...project,
        createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
        effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY'),
        locationToShow: `${project.locations.city ? project.locations.city.title : ''} ${
          project.locations.state.stateCode
        }`
      }))
    };
  }

  async getAllProjectInfo(projectId: number): Promise<IFullProject> {
    const response = (await this._searchRepo.getAllProjectInfo(projectId)).data.result;
    return {
      ...response,
      effectiveDate: moment(response.effectiveDate).format('MM/DD/YYYY'),
      contacts: response.contacts.map((person) => ({
        ...person,
        details: person.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] }))
      }))
    };
  }

  async getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IRecentProjectsData> {
    const res = (await this._searchRepo.getRelatedProjects(projectId, type, page)).data.result;
    return {
      ...res,
      data: res.data.map((project) => ({
        ...project,
        createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
        effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY')
      }))
    };
  }

  async getProjectByListView(
    params: ISearchParams,
    controller?: AbortController,
    page?: number
  ): Promise<IProjectListResponse<IRow[]>> {
    const newParams = copyParams(params);
    if (page) {
      newParams.page = page;
    }
    const res = (await this._searchRepo.getProjectByListView(newParams, controller)).data.result;
    return { ...res, dataTable: tableRowGeneration(res.data) };
  }

  async validateSharedToken(token: string, projectId: number): Promise<IFullProject> {
    const response = (await this._searchRepo.validateSharedToken(token, projectId)).data.result;
    return {
      ...response,
      effectiveDate: moment(response.effectiveDate).format('MM/DD/YYYY'),
      contacts: response.contacts.map((person) => ({
        ...person,
        details: person.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] }))
      }))
    };
  }
}
