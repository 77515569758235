import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';
import classes from './GeneralInfo.module.scss';
import { Skeleton, PersonItem } from 'components';
import { ICompanyResponse } from 'modules/ContactInfoModal/types';
import { skeletonsToShow } from './mock';
import { SourceLink, PersonDetail } from 'utils/types';

interface IProps {
  isLoading: boolean;
  company: ICompanyResponse | null;
}

const GeneralInfo: FC<IProps> = observer(({ isLoading, company }) => {
  const { t } = useTranslation();

  const getDetail = useCallback((detail: PersonDetail) => {
    if (detail.type === SourceLink.LINKEDIN || detail.type === SourceLink.WEB) {
      return (
        <a
          href={detail.value}
          target={'_blank'}
          rel="noreferrer"
          className="no-underline text-dark">
          {detail.type === SourceLink.LINKEDIN
            ? detail.value.split('linkedin.com/')[1]
            : detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.PHONE) {
      return (
        <a href={`tel:${detail.value}`} className="no-underline text-dark">
          {detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.EMAIL) {
      return (
        <a href={`mailto:${detail.value}`} className="no-underline text-dark">
          {detail.value}
        </a>
      );
    }
    return <span>{detail.value}</span>;
  }, []);

  if (isLoading) {
    return <Skeleton type="company" itemsToShow={skeletonsToShow} />;
  }

  return company ? (
    <div className={clsx(classes.generalInfo, 'p-4')}>
      {company.description && (
        <div className="mb-5">
          <h6>{t('searchProject.description')}</h6>
          <p className="select-text">{company.description}</p>
        </div>
      )}
      <div className="mb-5">
        <h6 className="mb-3">{t('searchProject.companyInfo')}</h6>
        <ul className="p-0 flex select-text h-25 flex-wrap">
          {company.details.map((detail, idx: number) => (
            <li
              className="mb-2 w-1/2 overflow-hidden whitespace-nowrap text-ellipsis break-inside-avoid-page break-inside-avoid"
              key={`detail-item-${idx}`}>
              <span className="mr-2">
                <detail.icon />
              </span>
              {getDetail(detail)}
            </li>
          ))}
        </ul>
      </div>
      {company.projectPersons.length > 0 && (
        <div>
          <h6 className="mb-4">{t('searchProject.projectContacts')}</h6>
          <div className="flex-wrap flex">
            {company.projectPersons.map((person, idx) => (
              <PersonItem key={`person-item-${person.id}-${idx}`} person={person} />
            ))}
          </div>
        </div>
      )}
      {company.otherPersons.length > 0 && (
        <div>
          <h6 className="mb-4">{t('searchProject.otherPeopleInThisCompany')}</h6>
          <div className="flex flex-wrap">
            {company.otherPersons.map((person) => (
              <PersonItem key={`person-item-${person.id}`} person={person} />
            ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
});

export default GeneralInfo;
