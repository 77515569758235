import React, { FC, useCallback, useMemo } from 'react';
import { Input, Button } from 'components';
import { IEditFieldData } from 'view/Profile/Settings/types';
import { useFormik } from 'formik';
import { EditEmailSchema } from 'utils/Formik/validation';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';

interface IProps {
  titleClassName: string;
}

const EditPasswordForm: FC<IProps> = ({ titleClassName }) => {
  const { userStore, alertStore } = useStore();
  const { t } = useTranslation();

  const initialValues = useMemo<IEditFieldData>(
    () => ({
      current: '',
      new: '',
      confirm: ''
    }),
    []
  );

  const handleEditPassword = useCallback(async (values: IEditFieldData) => {
    await userStore.editUserPassword({ currentPassword: values.current, newPassword: values.new });
    await formik.setValues(initialValues);
    await formik.setErrors(initialValues);
    alertStore.successAlert(t('profile.successPasswordChanged'));
  }, []);

  const formik = useFormik<IEditFieldData>({
    onSubmit: handleEditPassword,
    initialValues,
    validationSchema: EditEmailSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const isFormFilled = useMemo<boolean>(() => {
    return !!Object.values(formik.errors).length;
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className={titleClassName}>{t('profile.editPassword')}</h2>
      <div className="mb-4">
        <Input
          type="password"
          value={formik.values.current}
          isError={!!formik.errors.current}
          errorText={formik.errors.current}
          onInput={onInputChange}
          name="current"
          label={t('profile.currentPassword')}
          data-test-element="edit-current-password-input"
          autoComplete="current-password"
        />
      </div>
      <div className="mb-4">
        <Input
          type="password"
          value={formik.values.new}
          isError={!!formik.errors.new}
          errorText={formik.errors.new}
          onInput={onInputChange}
          name="new"
          label={t('profile.newPassword')}
          data-test-element="edit-new-password-input"
          autoComplete="new-password"
        />
      </div>

      <div>
        <Input
          type="password"
          value={formik.values.confirm}
          isError={!!formik.errors.confirm}
          errorText={formik.errors.confirm}
          onInput={onInputChange}
          name="confirm"
          label={t('profile.confirmPassword')}
          data-test-element="edit-confirm-password-input"
          autoComplete="confirm-new-password"
        />
      </div>
      <Button
        disabled={isFormFilled}
        type="primary"
        className="px-5 mt-8"
        data-test-element="edit-password-submit-button">
        <span className="font-kraftig">{t('profile.save')}</span>
      </Button>
    </form>
  );
};

export default EditPasswordForm;
