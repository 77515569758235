import {
  ISubscriptionAndPlansRepo,
  ISubscriptionAndPlansService,
  PaymentCustomer,
  Plans,
  Product,
  QuestionsAndAnswers,
  ShortLocationForPayment,
  StoreUserSubscriptionData,
  SUBSCRIPTION_PLANS_REPO,
  SubscriptionAction
} from './types';
import { injector } from 'utils/injector';
import { tableRowGeneration } from 'view/Profile/Subscription/TableRowGeneration';

export class SubscriptionAndPlansService implements ISubscriptionAndPlansService {
  private _subscriptionRepo: ISubscriptionAndPlansRepo =
    injector.get<ISubscriptionAndPlansRepo>(SUBSCRIPTION_PLANS_REPO);

  async getProductsByLocation(locations: ShortLocationForPayment[]): Promise<Product[]> {
    const locationsIds = locations.map((location) => location.id);
    return (await this._subscriptionRepo.getProductsByLocation(locationsIds)).data.result;
  }

  async getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<Product> {
    return (
      await this._subscriptionRepo.getSubscriptionPlanToLocation(
        subscriptionId,
        userId,
        subscriptionAction
      )
    ).data.result;
  }

  async createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<PaymentCustomer> {
    return (
      await this._subscriptionRepo.createSubscriptionToLocation(priceId, locationId, paymentMethod)
    ).data.result;
  }

  async upgradeSubscriptionToLocation(
    userId: number,
    subscriptionId: number
  ): Promise<PaymentCustomer> {
    return (await this._subscriptionRepo.upgradeSubscriptionToLocation(userId, subscriptionId)).data
      .result;
  }

  async getUserSubscriptions(userId: number): Promise<StoreUserSubscriptionData> {
    const data = (await this._subscriptionRepo.getUserSubscriptions(userId)).data.result;
    return {
      data,
      tableData: {
        month: tableRowGeneration(data.filter((item) => item.period === Plans.MONTH)),
        year: tableRowGeneration(data.filter((item) => item.period === Plans.YEAR))
      }
    };
  }

  async unsubscription(
    subscriptionId: number,
    userId: number,
    questions: QuestionsAndAnswers[]
  ): Promise<void> {
    return await this._subscriptionRepo.unsubscription(subscriptionId, userId, questions);
  }

  async checkHasSubscriptionToLocation(locations: ShortLocationForPayment[]): Promise<boolean> {
    const locationsIds = locations.map((location) => location.id);
    return !(await this._subscriptionRepo.checkHasSubscriptionToLocation(locationsIds)).data.result
      .length;
  }

  async removeIncompleteSubscription(subscriptionId: number): Promise<void> {
    await this._subscriptionRepo.removeIncompleteSubscription(subscriptionId);
  }
}
