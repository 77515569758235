import {
  IListUploadService,
  IListUploadStore,
  LIST_UPLOAD_SERVICE,
  MatchesResponseData,
  MatchOperationData,
  MAXIMUM_DELAY,
  PRIMARY_DELAY,
  SPECIAL_DELAY,
  UploadResult,
  UploadStatusCode,
  IImportHistory,
  ImportDataKeys,
  ImportData
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { ASC } from 'utils/constants';
import { defaultImportHistoryData, defaultUploadData } from './mock';

export class ListUploadStore implements IListUploadStore {
  private _listUploadService: IListUploadService =
    injector.get<IListUploadService>(LIST_UPLOAD_SERVICE);

  uploadData: UploadResult | null = null;
  matchesData: MatchesResponseData = {
    count: 0,
    currentPage: 1,
    limit: 20,
    totalPages: 1,
    data: []
  };
  matchOperationData: MatchOperationData[] = [];
  timer: ReturnType<typeof setTimeout> | null = null;
  importHistoryData: IImportHistory = defaultImportHistoryData;

  constructor() {
    makeAutoObservable<ListUploadStore>(this);
  }

  setUploadData(data: UploadResult): void {
    this.uploadData = data;
  }

  setMatchesData(data: MatchesResponseData): void {
    this.matchesData = data;
  }

  setMatchOperationData(data: MatchOperationData | null): void {
    if (!data) {
      this.matchOperationData = [];
      return;
    }
    if (!this.matchOperationData.length) {
      this.matchOperationData = [data];
      return;
    }
    if (this.matchOperationData.length) {
      const currentItem = this.matchOperationData.find(
        (item) => item.userContactId === data.userContactId
      );

      if (currentItem) {
        this.matchOperationData = this.matchOperationData.map((item) => {
          if (item.userContactId === data.userContactId) {
            return data;
          }
          return item;
        });
      } else {
        this.matchOperationData = this.matchOperationData.concat([data]);
      }
    }
  }

  setImportHistoryData(data: IImportHistory) {
    this.importHistoryData = data;
  }

  resetImportHistoryData() {
    this.importHistoryData = defaultImportHistoryData;
  }

  async uploadFile(file: File): Promise<void> {
    this.setUploadData(await this._listUploadService.uploadFile(file));
    await this.getStatusLastUploadedFile(SPECIAL_DELAY);
  }

  async getStatusLastUploadedFile(firstDelay?: number): Promise<void> {
    let attempt = 0;
    let delay = firstDelay || 0;
    let result: UploadResult = defaultUploadData;

    const checkLastUploadedFile = async (): Promise<UploadResult> => {
      if (attempt <= 60) {
        attempt += 1;
        await this.wait(delay);
        if (attempt === 1) {
          delay = PRIMARY_DELAY;
        }
        if (
          attempt > 1 &&
          delay < MAXIMUM_DELAY &&
          result.status === UploadStatusCode.IN_PROGRESS
        ) {
          delay *= 1.15;
        }
        result = await this._listUploadService.getStatusLastUploadedFile();

        if (
          result.status === UploadStatusCode.DONE ||
          result.status === UploadStatusCode.FAIL ||
          result.status === UploadStatusCode.ABORT
        ) {
          await this.getImportHistoryData();
          return result;
        }
        if (
          result.status === UploadStatusCode.NEW ||
          result.status === UploadStatusCode.PENDING ||
          result.status === UploadStatusCode.IN_PROGRESS
        ) {
          this.setUploadData(result);
          await checkLastUploadedFile();
        }
      }
      return result;
    };
    this.setUploadData(await checkLastUploadedFile());
  }

  async wait(delay: number) {
    return new Promise((resolve) => {
      this.timer = setTimeout(resolve, delay);
    });
  }

  async abortUploadedFileProcess(): Promise<void> {
    this.setUploadData(await this._listUploadService.abortUploadedFileProcess());
  }

  async retrieveContactsListByImportId(importFileId: number, page: number): Promise<void> {
    this.setMatchesData(
      await this._listUploadService.retrieveContactsListByImportId(importFileId, page)
    );
  }

  async mergeContactFromFileImport(): Promise<void> {
    await this._listUploadService.mergeContactFromFileImport(this.matchOperationData);
    this.setMatchOperationData(null);
  }

  resetUploadToDefault(): void {
    this.setUploadData(defaultUploadData);
  }

  async getImportHistoryData(): Promise<void> {
    const orders = this.importHistoryData.orders.map((order) => {
      if (order.field === 'createdAtFull') {
        return {
          ...order,
          field: 'createdAt'
        };
      }
      return order;
    });
    const res = await this._listUploadService.getImportHistoryData(
      this.importHistoryData.currentPage,
      orders,
      this.importHistoryData.limit
    );
    this.setImportHistoryData({
      ...this.importHistoryData,
      ...res
    });
  }

  localSortHistoryData() {
    let data: ImportData[] = [];
    if (!this.importHistoryData.orders.length) {
      data = [...this.importHistoryData.data].sort((a, b) => {
        return a.createdAtFull < b.createdAtFull ? 1 : -1;
      });
    }
    this.importHistoryData.orders.forEach((order) => {
      data = [...this.importHistoryData.data].sort((a, b) => {
        const field = order.field as ImportDataKeys;
        if (a[field] < b[field]) return -1 * (order.order === ASC ? 1 : -1);
        if (a[field] > b[field]) return 1 * (order.order === ASC ? 1 : -1);
        return 0;
      });
    });
    this.setImportHistoryData({
      ...this.importHistoryData,
      data
    });
  }
}
