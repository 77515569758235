import { IAxiosResponse } from 'utils/types';

export type ShareToken = {
  token: string;
};

export const SHARE_REPO = 'SHARE_REPO';

export enum SharedEntityType {
  SAVED_SEARCH = 'savedSearch',
  SINGLE_PERMIT = 'singlePermit',
  FAVORITE_LIST = 'favoriteList'
}

export interface IShareRepo {
  createSavedSearchToken(
    userId: number,
    searchId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>>;

  createSinglePermitToken(
    projectId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>>;
  // getShareSearchData<T>(token: string): Promise<IAxiosResponse<T>>;

  createFavoriteListToken(
    userId: number,
    listId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>>;
}

export const SHARE_SERVICE = 'SHARE_SERVICE';
export interface IShareService {
  createSavedSearchToken(
    userId: number,
    searchId: number,
    email: string | null
  ): Promise<ShareToken>;
  createSinglePermitToken(projectId: number, email: string | null): Promise<ShareToken>;
  createFavoriteListToken(
    userId: number,
    listId: number,
    email: string | null
  ): Promise<ShareToken>;
  // getShareSearchData<T>(token: string): Promise<T>;
}

export interface IShareStore {
  shareTokens: { [key in SharedEntityType]: string };
  createSavedSearchToken(userId: number, searchId: number, email: string | null): Promise<void>;
  createSinglePermitToken(projectId: number, email: string | null): Promise<void>;
  createFavoriteListToken(userId: number, listId: number, email: string | null): Promise<void>;
  // getShareSearchData<T>(token: string): Promise<T>;
}

export enum ShareType {
  EMAIL,
  lINK
}
