import React, { FC, useCallback } from 'react';
import { Modal, Button } from 'components';
import { SearchProjectView } from 'view/SearchProjects/components/SubHeader/types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';

const modalStyle = {
  modal: 'w-[434px]'
};

interface Props {
  show: boolean;
  view: SearchProjectView;
  close: () => void;
}

const DummyContentModal: FC<Props> = ({ show, view, close }) => {
  const { t } = useTranslation();
  const { projectSearchStore, searchStore } = useStore();

  const getEmptyLocations = useCallback((): string => {
    const emptyLocationIds =
      view === SearchProjectView.MAP
        ? projectSearchStore.projectsData.emptyCounties
        : view === SearchProjectView.LIST
        ? projectSearchStore.projectListData.emptyCounties
        : [];
    const emptyLocationsTitle = searchStore
      .getLocationsForMapById(emptyLocationIds)
      .map((location) => location.title);
    return emptyLocationsTitle.join(', ');
  }, [view]);

  return (
    <Modal show={show} title="" closeModal={close} classModal={modalStyle} hideFooter={true}>
      <div className="px-3.5 pb-3.5 text-center">
        <h2 className="text-dark text-2.4xl pb-2.5">
          {searchStore.locationForMap.length > 1
            ? t('searchProject.noMultiProjectsModalTitle')
            : t('searchProject.noProjectsModalTitle')}
        </h2>
        <p className="leading-5 text-grey-500 pb-1.5">
          {`${t('searchProject.noProjectsModalContentPartOne')} ${getEmptyLocations()}${t(
            'searchProject.noProjectsModalContentPartTwo'
          )}`}
        </p>
        <Button type="primary" className="px-6 m-auto" onClick={close}>
          <span className="font-kraftig">{t('searchProject.okay')}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default DummyContentModal;
