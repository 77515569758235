import {
  AUTH_SERVICE,
  IAuthService,
  IAuthStore,
  ILoginData,
  IOpenIdLogin,
  IRegisterData,
  IResetPasswordData
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { ACCESS_TOKEN } from 'utils/constants';
import { useFiltersActions } from 'hooks/useFiltersActions';

// const { removeFilters } = useFiltersActions();

export class AuthStore implements IAuthStore {
  private _authService: IAuthService = injector.get<IAuthService>(AUTH_SERVICE);

  constructor() {
    makeAutoObservable<AuthStore>(this);
  }

  accessToken = '';
  isRedirected = false;

  setIsRedirected(isRedirected: boolean): void {
    this.isRedirected = isRedirected;
  }

  setToken(accessToken: string): void {
    this.accessToken = accessToken;
    this.storeAccessToken();
  }

  storeAccessToken(): void {
    localStorage.setItem(ACCESS_TOKEN, this.accessToken);
  }

  async signIn(data: ILoginData): Promise<void> {
    const token = await this._authService.signIn(data);
    this.setToken(token);
  }

  async signUp(data: IRegisterData): Promise<void> {
    await this._authService.signUp(data);
  }

  async openIdLogin(token: string, provider: string): Promise<IOpenIdLogin> {
    return await this._authService.openIdLogin(token, provider);
  }

  logout(): void {
    this.accessToken = '';
    localStorage.removeItem(ACCESS_TOKEN);
    const { removeFilters } = useFiltersActions();
    removeFilters(true);
  }

  async sendResetCode(email: string): Promise<void> {
    await this._authService.sendResetCode(email);
  }

  async resetPassword(data: IResetPasswordData): Promise<void> {
    await this._authService.resetPassword(data);
  }
}
