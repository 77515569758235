import React, { FC } from 'react';
import { IReport } from '../types';
import reporterImage from 'assets/img/home/reporter.jpg';

const ReportItem: FC<IReport> = ({ name, photo, comment, position }) => {
  return (
    <>
      <img src={photo || reporterImage} alt="" className="m-auto block relative w-26 h-26 z-40" />
      <div className="pb-5">
        <div className="pt-26 px-22.5 pb-14 shadow-portal rounded-sm z-30 -mt-13 relative">
          <blockquote className="mb-4 text-center min-h-24 text-lg text-dark leading-8">
            {comment}
          </blockquote>
          <h2 className="mb-2 text-center text-dark text-2xl">{name}</h2>
          <p className="text-grey-500 text-lg text-center m-0">{position}</p>
        </div>
        <div className="bg-white w-[90%] h-4 mx-auto my-0 rounded-b-sm relative z-20 shadow-portal"></div>
        <div className="bg-white w-[82%] h-4 mx-auto my-0 rounded-b-sm relative z-10 shadow-portal"></div>
      </div>
    </>
  );
};

export default ReportItem;
