import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { clsx } from 'utils/clsx';
import closeIcon from 'assets/icons/close.svg';
import { isIconOnLeft } from 'view/Favorites/helpers/isIconOnLeft';

const modalRoot = document.getElementById('modal-root');

interface IProps {
  coordinates: DOMRect;
  closeModal: () => void;
  children: JSX.Element;
  closeOnClickOut?: boolean;
  width?: number;
}

const PortalModal: FC<IProps> = ({ coordinates, closeModal, children, closeOnClickOut, width }) => {
  const [arrowTop, setArrowTop] = useState<number>(15);
  const [modalHeight, setModalHeight] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const isIconOnLeftSide = useMemo<boolean>(() => {
    return isIconOnLeft(coordinates.left);
  }, [coordinates]);

  const top = useMemo<string>(() => {
    const screenHeight = document.body.offsetHeight;
    let y = coordinates.top - 30;
    if (y < 5) {
      y = 5;
    } else if (screenHeight - y < modalHeight) {
      y = screenHeight - modalHeight - 10;
    }
    return `${y}px`;
  }, [coordinates, modalHeight]);

  const left = useMemo<string>(() => {
    const favoriteModalWidth = (width ? width : 450) + 20;
    const x: number = isIconOnLeftSide
      ? coordinates.left + 60
      : coordinates.left - favoriteModalWidth;
    return `${x}px`;
  }, [coordinates]);

  const handleClose = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    closeModal();
  }, []);

  const clickOutside = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    const target = (
      (e.target as Element).tagName === 'path' ? (e.target as Element).parentNode : e.target
    ) as Element;
    const isTargetIcon =
      target.tagName === 'svg' || target.tagName === 'IMG' || target.tagName === 'H4';
    if (modalRef.current && !e.composedPath().includes(modalRef.current) && !isTargetIcon) {
      closeModal();
    }
  }, []);

  useLayoutEffect(() => {
    setModalHeight(Number(modalRef.current?.offsetHeight));
  }, [coordinates, children]);

  useLayoutEffect(() => {
    const screenHeight = document.body.offsetHeight;
    const y = coordinates.top - 30;
    const top = screenHeight - y < modalHeight ? y - (screenHeight - modalHeight - 30) : 15;
    setArrowTop(modalHeight - top < 30 ? modalHeight - 30 : top);
  }, [coordinates, modalHeight]);

  useLayoutEffect(() => {
    if (!closeOnClickOut) return;
    window.addEventListener('click', clickOutside);
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', closeModal);
    return () => {
      window.removeEventListener('resize', closeModal);
    };
  }, []);

  if (!modalRoot) return <></>;

  return createPortal(
    <div
      className="absolute bg-white z-99 w-112 shadow-portal"
      style={{ top, left, width: `${width}px` }}
      ref={modalRef}
      data-test-element="add-to-favorites-modal">
      <div
        className={clsx(
          'absolute',
          'bg-white',
          'w-6',
          'h-6',
          'top-6',
          '-z-[1]',
          isIconOnLeftSide
            ? 'right-full translate-x-3.125/4 rotate-45'
            : 'left-full -translate-x-3.125/4 rotate-45'
        )}
        style={{ top: `${arrowTop}px` }}
      />
      <img
        src={closeIcon}
        role="button"
        alt=""
        className="absolute top-4 right-4"
        onClick={handleClose}
      />
      {children}
    </div>,
    modalRoot
  );
};

export default PortalModal;
