import React, { useCallback, useEffect, useState } from 'react';
import SubHeader from './components/SubHeader';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import ProjectInfoModal from 'modules/ProjectInfoModal/Modal';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { usePrevious } from 'hooks/usePrevious';
import { SearchProjectView } from './components/SubHeader/types';
import { Location, SearchType } from 'utils/types';
import MapView from './components/MapView';
import ListView from './components/ListView';
import { getStateCodesAndCanonicalTitlesFromUrl } from 'helpers/getStateCodesAndCanonicalTitlesFromUrl';
import { initProjectsData } from './mock';
import { getLocal, setLocal } from 'utils/localStorageOperation';
import { CURRENT_LOCATION, FILTERS } from 'utils/constants';
import { useFiltersActions } from 'hooks/useFiltersActions';
import Header from 'view/Header';
import { UserData } from 'view/Profile/types';
import { getLocationParamsFromSelectedLocation } from 'helpers/createLocationParams';
import DummyContentModal from 'modules/DummyContentModal';
import { FoundLocation } from 'view/Search/types';
import ContactInfo from 'modules/ContactInfoModal';
import { ISearchParams } from 'modules/Filter/types';
import { SharedEntityType } from 'modules/ShareModal/types';
import useIsMount from 'hooks/useIsMount';

const SearchProjects = observer(() => {
  const {
    projectSearchStore,
    alertStore,
    savedSearchesStore,
    filtersStore,
    searchStore,
    userStore,
    shareStore
  } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = filtersStore.searchParams;
  const prevUser = usePrevious<UserData | null>(userStore.user);
  const [params, setParams] = useSearchParams();
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [showNoProjectsModal, setShowNoProjectsModal] = useState<boolean>(false);
  const [dummyContentAlreadyDisplayed, setDummyContentAlreadyDisplayed] = useState<boolean>(false);
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [planLocation, setPlanLocation] = useState<Location[] | null>(null);
  const [view, setView] = useState<SearchProjectView>(SearchProjectView.MAP);
  const { pathname, state } = useLocation();
  const prevPath = usePrevious<string>(pathname);
  const prevLocationInList = usePrevious<FoundLocation[]>(
    searchStore.selectedLocationsInSearchList
  );
  const { setFilters, removeFilters } = useFiltersActions();
  const { token, projectId } = useParams();
  const isMount = useIsMount();

  const details = params.get('details');
  const contacts = params.get('contacts');

  const loadSharedProject = useCallback(async () => {
    if (!token || !projectId) return;
    await projectSearchStore.validateSharedToken(token, Number(projectId));
    shareStore.setShareToken(token, SharedEntityType.SINGLE_PERMIT);
    if (!params.get('details')) {
      params.set('details', projectId);
      setParams(params);
    }

    if (!userStore.user) {
      params.set('auth', 'sign-up');
      params.set('extended', 'true-up');
      setParams(params);
    }
  }, [userStore.user, token, projectId, details]);

  useEffect(() => {
    setFiltersReady(false);
    if (!state || (state && !(state as { useFilters: boolean }).useFilters)) {
      const localFilters = getLocal<ISearchParams>(FILTERS);
      if (localFilters) {
        setFilters(localFilters);
      }
    }
    setFiltersReady(true);
    return () => {
      searchStore.setSelectedLocation([]);
      searchStore.setLocationForMap([]);
      projectSearchStore.setProjects(initProjectsData);
      setLocal(FILTERS, filtersStore.searchParams);
      removeFilters();
    };
  }, []);

  useEffect(() => {
    // TODO: move to separate hook
    const localState = getLocal<FoundLocation[]>(CURRENT_LOCATION) ?? [];
    if (pathname.split('/').length < 4 && localState.length) {
      searchStore.setSelectedLocation(localState);
      searchStore.setLocationForMap(localState);
      navigate(`/search-projects/${getLocationParamsFromSelectedLocation(localState)}`);
      return;
    }
    if (pathname.split('/').length < 4) {
      (async () => {
        await searchStore.lookupLocation([], []);
        searchStore.setSelectedLocation(searchStore.locationForMap);
        navigate(
          `/search-projects/${getLocationParamsFromSelectedLocation(searchStore.locationForMap)}`
        );
      })();
      return;
    }
    if (prevPath !== '/search-projects') {
      (async () => {
        try {
          await searchStore.lookupLocation(
            getStateCodesAndCanonicalTitlesFromUrl().stateCodes,
            getStateCodesAndCanonicalTitlesFromUrl().canonicalTitles
          );
        } catch (e) {
          navigate('/', { replace: true });
        }
      })();
    }
  }, [pathname]);

  useEffect(() => {
    if (searchStore.locationForMap.length) {
      filtersStore.setSearchParams(
        'locations',
        searchStore.locationForMap.map((location) => location.id)
      );
      setDummyContentAlreadyDisplayed(false);
      if (searchStore.locationForMap.length > 1) {
        setView(SearchProjectView.LIST);
      }
      setLocal(CURRENT_LOCATION, searchStore.locationForMap);
      searchStore.setSelectedLocation(searchStore.locationForMap);
    }
  }, [searchStore.locationForMap]);

  useEffect(() => {
    // TODO: need refactoring this code in the future
    if (!isMount) {
      // don't navigate if shared
      if (token && projectId && !prevLocationInList.length) {
        loadSharedProject();
        return;
      }
      if (searchStore.selectedLocationsInSearchList.length) {
        navigate(
          `/search-projects/${getLocationParamsFromSelectedLocation(
            searchStore.selectedLocationsInSearchList
          )}`
        );
      }
    }
  }, [searchStore.selectedLocationsInSearchList]);

  useEffect(() => {
    if (
      view === SearchProjectView.MAP &&
      projectSearchStore.projectsData.hasSubscription &&
      projectSearchStore.projectsData.emptyCounties.length
    ) {
      setShowNoProjectsModal(true);
    }
    if (
      view === SearchProjectView.LIST &&
      projectSearchStore.projectListData.hasSubscription &&
      projectSearchStore.projectListData.emptyCounties.length
    ) {
      setShowNoProjectsModal(true);
    }
  }, [projectSearchStore.projectsData, projectSearchStore.projectListData, view, userStore]);

  const handleSaveSearch = useCallback(
    async (name: string) => {
      if (!userStore.user) return;
      const params = { ...searchParams };
      delete params.area;
      const result = await savedSearchesStore.saveSearch(
        userStore.user.id,
        name,
        params,
        SearchType.PROJECT
      );
      alertStore.successAlert(
        <>
          <span className="font-kraftig">{result.name}</span> {t('searchProject.listCreated')}{' '}
          <span className="font-kraftig">{result.description}</span> {t('searchProject.savedTo')}{' '}
          <Link to={`/saved-search/${result.id}`} className="font-kraftig !text-primary">
            {result.name}
          </Link>
          .
        </>
      );
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    },
    [searchParams]
  );

  const handleUnlock = useCallback(
    (locations: Location[] | null) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      setShowPlan(true);
      setPlanLocation(locations);
      if (token && projectId) {
        void loadSharedProject();
      }
    },
    [token, projectId, pathname]
  );

  const handleUpdateProjects = useCallback(async (): Promise<void> => {
    if (view === SearchProjectView.MAP) {
      await projectSearchStore.getProjects(filtersStore.searchParams);
      return;
    }
    if (view === SearchProjectView.LIST) {
      await projectSearchStore.getProjectByListView(filtersStore.searchParams);
    }
  }, [view]);

  useEffect(() => {
    if (prevUser?.id !== userStore.user?.id) {
      (async () => {
        await handleUpdateProjects();
        void loadSharedProject();
      })();
    }
  }, [userStore.user]);

  const handleSelectView = useCallback(
    (view: SearchProjectView) => {
      setView(view);
    },
    [view]
  );

  const closeDummyContent = () => {
    setShowNoProjectsModal(false);
    setDummyContentAlreadyDisplayed(true);
  };

  const handleShowPlan = useCallback(() => {
    if (!userStore.user) {
      params.set('auth', 'sign-in');
      setParams(params);
      return;
    }
    setShowPlan(true);
  }, [userStore.user]);

  return (
    <div className="h-full">
      {filtersReady && (
        <Header>
          <SubHeader onSaveSearch={handleSaveSearch} onShowPlan={handleShowPlan} />
        </Header>
      )}
      {view === SearchProjectView.MAP && filtersReady && (
        <MapView onHandleUnlock={handleUnlock} onSelectView={handleSelectView} />
      )}
      {view === SearchProjectView.LIST && filtersReady && (
        <ListView onHandleUnlock={handleUnlock} onSelectView={handleSelectView} />
      )}

      <ChoosePlanModal
        show={showPlan}
        locations={planLocation || searchStore.locationForMap}
        closeModal={() => setShowPlan(false)}
        onUpdate={handleUpdateProjects}
      />

      <DummyContentModal
        show={showNoProjectsModal && !dummyContentAlreadyDisplayed}
        view={view}
        close={closeDummyContent}
      />

      {contacts && <ContactInfo />}

      {details && <ProjectInfoModal onHandleUnlock={handleUnlock} />}
    </div>
  );
});

export default SearchProjects;
