import {
  ICompanySearchStore,
  ICompanySearchService,
  COMPANY_SEARCH_SERVICE,
  ICompaniesListView
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { ISearchParams } from 'modules/Filter/types';
import { ISort } from 'utils/types';

export class CompanySearchStore implements ICompanySearchStore {
  private _companySearchService: ICompanySearchService =
    injector.get<ICompanySearchService>(COMPANY_SEARCH_SERVICE);

  constructor() {
    makeAutoObservable<CompanySearchStore>(this);
  }

  companies: ICompaniesListView | null = null;

  setCompanies(companies: ICompaniesListView | null): void {
    this.companies = companies;
  }

  async getCompaniesListView(
    params: ISearchParams,
    // orders: ISort[],
    controller?: AbortController
  ): Promise<void> {
    if (params.locations.length) {
      this.setCompanies(await this._companySearchService.getCompaniesListView(params, controller));
    }
  }

  updateFavorite(id: number, isActive: boolean): void {
    if (this.companies) {
      this.setCompanies({
        ...this.companies,
        data: this.companies?.data.map((company) => {
          if (company.id === id) {
            return {
              ...company,
              favorite: company.favorite !== isActive ? !company.favorite : company.favorite
            };
          }
          return company;
        })
      });
    }
  }
}
