import { ISkeletonBase } from './types';

export const skeletonBase: ISkeletonBase = {
  project: [
    {
      items: [
        {
          width: 70,
          height: 24
        },
        {
          width: 20,
          height: 20,
          isCircled: true
        }
      ],
      rowGap: 16,
      between: true
    },
    {
      items: [
        {
          width: 200,
          height: 20
        },
        {
          width: 50,
          height: 20
        }
      ],
      rowGap: 8,
      between: true
    },
    {
      items: [
        {
          width: 180,
          height: 24
        },
        {
          width: 80,
          height: 24
        }
      ],
      rowGap: 4,
      between: true
    },
    {
      items: [
        {
          width: 150,
          height: 24
        },
        {
          width: 80,
          height: 24
        }
      ],
      between: true
    }
  ],
  company: [
    {
      items: [
        {
          width: 120,
          height: 24
        }
      ],
      rowGap: 8
    },
    {
      items: [
        {
          width: 600,
          height: 54
        }
      ],
      rowGap: 26
    },
    {
      items: [
        {
          width: 120,
          height: 24
        }
      ],
      rowGap: 10
    },
    {
      items: [
        {
          width: 140,
          height: 20
        },
        {
          width: 140,
          height: 20,
          padStart: 100
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 160,
          height: 20
        },
        {
          width: 140,
          height: 20,
          padStart: 80
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 120,
          height: 20
        }
      ],
      rowGap: 36
    },
    {
      items: [
        {
          width: 320,
          height: 24
        }
      ],
      rowGap: 22
    },
    {
      items: [
        {
          width: 56,
          height: 56,
          isCircled: true
        },
        {
          width: 120,
          height: 52,
          padStart: 16
        },
        {
          width: 56,
          height: 56,
          isCircled: true,
          padStart: 100
        },
        {
          width: 120,
          height: 52,
          padStart: 16
        }
      ],
      rowGap: 6
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 32
    },
    {
      items: [
        {
          width: 230,
          height: 24
        }
      ],
      rowGap: 22
    },
    {
      items: [
        {
          width: 56,
          height: 56,
          isCircled: true
        },
        {
          width: 120,
          height: 52,
          padStart: 16
        },
        {
          width: 56,
          height: 56,
          isCircled: true,
          padStart: 100
        },
        {
          width: 120,
          height: 52,
          padStart: 16
        }
      ],
      rowGap: 6
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 130,
          height: 24,
          padStart: 70
        },
        {
          width: 130,
          height: 24,
          padStart: 165
        }
      ],
      rowGap: 4
    }
  ],
  contact: [
    {
      items: [
        {
          width: 80,
          height: 24
        },
        {
          width: 20,
          height: 20,
          isCircled: true
        }
      ],
      rowGap: 16,
      between: true
    },
    {
      items: [
        {
          width: 200,
          height: 24
        }
      ],
      rowGap: 16
    },
    {
      items: [
        {
          width: 120,
          height: 24
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 200,
          height: 24
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 70,
          height: 24
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 100,
          height: 24
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 200,
          height: 24
        }
      ],
      rowGap: 4
    },
    {
      items: [
        {
          width: 100,
          height: 24
        }
      ],
      padStart: 25
    }
  ],
  plan: [
    {
      items: [
        {
          width: 333,
          height: 337
        }
      ]
    }
  ]
};
