import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail, Icons } from 'utils/types';
import { ContactData } from '../types';
import { useStore } from 'storesProvider/storeContext';
import { getDataFromConfig, getContactsPhone } from 'helpers/getDataForTable';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/makers.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

interface Props {
  contact: ContactData;
  onContact?: () => void;
}

const ContactItem: FC<Props> = ({ contact, onContact }) => {
  const { configStore, alertStore } = useStore();
  const { t } = useTranslation();

  const label = useMemo((): string => {
    return configStore.enums && contact.role
      ? getDataFromConfig(configStore.enums.projectRoles, contact.role).toUpperCase()
      : '';
  }, [contact]);

  const phone = useMemo(() => {
    return configStore.enums ? getContactsPhone(configStore.enums, contact.details) : '';
  }, [contact]);

  const getICon = (detail: Detail): ReactNode => {
    const icons = {
      [Icons.PHONE]: () => <></>,
      [Icons.EMAIL]: () => (
        <a href={`mailto:${detail.value}`} target="_blank" rel="noreferrer">
          <EmailIcon className="cursor-pointer hover:stroke-[#0087FF]" />
        </a>
      ),
      [Icons.LINKEDIN]: () => (
        <a href={detail.value} target="_blank" rel="noreferrer">
          <LinkedinIcon className="cursor-pointer stroke-[#BABCC1] hover:stroke-[#0087FF]" />
        </a>
      ),
      [Icons.WEB]: () => (
        <a href={detail.value} target="_blank" rel="noreferrer">
          <WebsiteIcon className="cursor-pointer hover:stroke-[#0087FF]" />
        </a>
      ),
      [Icons.GROUP]: () => (
        <GroupIcon
          className="cursor-pointer hover:stroke-[#0087FF]"
          onClick={() => copy(detail.value)}
        />
      ),
      [Icons.USER]: () => (
        <UserIcon
          className="cursor-pointer stroke-[#BABCC1] hover:stroke-[#0087FF]"
          onClick={() => copy(detail.value)}
        />
      )
    };
    return icons[detail.type as Icons]();
  };

  const copy = (value: string): void => {
    navigator.clipboard
      .writeText(value)
      .then(() => alertStore.successAlert(t('savedSearch.copied')))
      .catch(() => alertStore.errorAlert('savedSearch.errorCopy'));
  };

  return (
    <div className="w-full xl:w-1/2 2xl:w-1/3 mb-4 relative">
      {label && (
        <span className="absolute font-kraftig text-xxs text-grey-300 -top-[8px]">{label}</span>
      )}
      <h6 className="m-0 cursor-pointer" onClick={() => onContact && onContact()}>
        {contact.name}
      </h6>
      {phone && <span className="text-sm">{phone}</span>}
      <div className="flex">
        {contact.details.map((detail, idx) => (
          <div
            className="relative group flex flex-col items-center justify-center"
            key={`icon-contact-${idx}`}>
            {getICon(detail)}
            <div className="absolute top-6 mb-6 mr-1 rounded text-dark z-1 hidden group-hover:block">
              <div className="flex max-w-xs flex-col items-center">
                <div className="clip-bottom h-2 w-4 bg-white"></div>
                <div className="w-max rounded bg-white shadow-overlay px-6 py-4 text-base">
                  <p className="select-text">{detail.value}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/*TODO: remove after testing*/}
      {/*{!hasSubscription && onHandleUnlock && (*/}
      {/*  <div*/}
      {/*    role="button"*/}
      {/*    onClick={onHandleUnlock}*/}
      {/*    className={clsx(*/}
      {/*      'position-absolute',*/}
      {/*      'top-0',*/}
      {/*      'start-0',*/}
      {/*      'bottom-0',*/}
      {/*      'end-0',*/}
      {/*      'd-flex',*/}
      {/*      'justify-content-center',*/}
      {/*      'align-items-center',*/}
      {/*      classes.lockedWrapper*/}
      {/*    )}>*/}
      {/*    <div className={classes.lockIcon}>*/}
      {/*      <LockIcon className="w-100 h-auto" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default ContactItem;
