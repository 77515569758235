import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export const useListWidth = (listRef: MutableRefObject<HTMLDivElement>): number => {
  const [listWidth, setListWidth] = useState<number>(0);
  const onResize = useCallback(() => setListWidth(listRef.current?.clientWidth || 0), []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      setListWidth(listRef.current.clientWidth);
    }
  }, [listRef.current]);
  return listWidth;
};
