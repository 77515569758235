import React, { FC } from 'react';
import classes from '../CancelSubscriptionModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface Props {
  fullWidth: boolean;
}

const List: FC<Props> = ({ fullWidth }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(classes.wrapperBlock, 'flex', 'flex-col', fullWidth ? 'w-full' : 'w-[48%]')}>
      <div className="px-4 py-5">
        <span className="block mb-3 text-dark font-kraftig">
          {t('cancelSubscription.whatLose')}
        </span>
        <ul className={clsx(classes.list, 'list-none', 'mt-2')}>
          <li className="flex items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.allSaved')}
          </li>
          <li className="flex items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.notifications')}
          </li>
          <li className="flex items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.unlimitedProject')}
          </li>
          <li className="flex items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.advantage')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default List;
