import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/ProgressBar';
import { Pagination, Table } from 'components';
import AddToFavoritesModal from 'view/Favorites/components/AddToFavoritesModal';
import { useProgress } from 'hooks/useProgress';
import { IAction, IColumn } from 'components/Table/types';
import { orderProcessing } from 'helpers/orderProcessing';
import { IFavoriteState, IFavoriteEntity } from 'view/Favorites/types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { useIntersectionObserverEffect } from 'hooks/useIntersectionObserverEffect';
import { ClickEvent, Location, AllRoles, AllEntityTypes } from 'utils/types';
import { getLocationForSubscription } from './helper';
import c from './ListView.module.scss';
import { valueFormatter } from 'helpers/valueFormatter';
import { NA } from 'utils/constants';
import { createProjectCountString } from 'utils/createProjectCountString';
import { SearchProjectView } from '../SubHeader/types';
import ViewSwitcher from '../SubHeader/components/ViewSwitcher';
import { getContactFromProjectById } from 'helpers/getContactsFromProjectById';

interface Props {
  onHandleUnlock: (locations: Location[] | null) => void;
  onSelectView: (vies: SearchProjectView) => void;
}

const ListView: FC<Props> = observer(({ onHandleUnlock, onSelectView }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [, setParams] = useSearchParams();
  const { projectSearchStore, searchStore, filtersStore, userStore } = useStore();
  const searchParams = filtersStore.searchParams;
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [isMoreProjectsLoading, setIsMoreProjectsLoading] = useState<boolean>(false);
  const observableDiv = useRef<HTMLDivElement | null>(null);
  const {
    progress: projectsProgress,
    visible: projectsVisible,
    reload: projectsReloadProgress
  } = useProgress(isProjectsLoading);
  const [favoriteState, setFavoriteState] = useState<IFavoriteState>({
    entity: null,
    showAddToFavorite: false,
    favoriteId: null
  });
  const controller = new AbortController();

  useLayoutEffect(() => {
    if (searchParams.page !== 1) {
      const currentPage = Math.ceil(((searchParams.page - 1) * 250) / 20);
      filtersStore.setSearchParams('page', currentPage);
    }
    filtersStore.setSearchParams('pageSize', 20);
  }, []);

  useEffect(() => {
    projectsReloadProgress();
    (async () => await loadListProjects(controller))();
    return () => {
      controller.abort();
    };
  }, [searchParams]);

  const loadListProjects = async (controller?: AbortController, page?: number): Promise<void> => {
    setIsProjectsLoading(true);
    try {
      await projectSearchStore.getProjectByListView(filtersStore.searchParams, controller, page);
    } finally {
      setIsProjectsLoading(false);
    }
  };

  const columns = useMemo((): IColumn[] => {
    return [
      {
        field: 'property',
        headerName: t('savedSearch.table.details')
      },
      {
        field: 'effectiveDate',
        headerName: t('savedSearch.table.date'),
        sort: true
      },
      {
        field: 'status',
        headerName: t('savedSearch.table.status')
      },
      {
        field: 'location',
        headerName: t('savedSearch.table.location')
      },
      {
        field: 'contacts',
        headerName: t('savedSearch.table.contacts')
      },
      {
        field: 'value',
        headerName: t('savedSearch.table.value'),
        sort: true
      },
      {
        field: 'actions',
        headerName: t('savedSearch.table.actions')
      }
    ];
  }, []);

  const tableAction = useMemo((): IAction[] => {
    return [
      {
        icon: 'favorite',
        callback: (id) => {
          if (!userStore.user) {
            setParams('auth=sign-in&extended=true');
            return;
          }
          const coordinates: DOMRect | null =
            document.getElementById(`table-action-favorite-${id}`)?.getBoundingClientRect() || null;
          if (!coordinates) return;
          if (checkSubscription()) {
            handleUnlock();
            return;
          }
          const entity: IFavoriteEntity = {
            id,
            coordinates,
            type: AllEntityTypes.Project,
            role: AllRoles.Project,
            key: 'table',
            name: 'Project'
          };
          setFavoriteState((prev) => ({ ...prev, entity, favoriteId: id }));
          showAddFavoriteModal(entity);
        }
      }
    ];
  }, []);

  const checkSubscription = (): boolean => {
    return !!(userStore.user && !projectSearchStore.projectListData.hasSubscription);
  };

  const handleOrder = (field: string): void => {
    projectsReloadProgress();
    filtersStore.setSearchParams(
      'order',
      orderProcessing(field, filtersStore.searchParams.order || [])
    );
  };

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    const thisModal = document.getElementById(
      `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
    );
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: !thisModal }));
  };

  const handleTableScroll = useCallback((): void => {
    closeAddFavoriteModal();
  }, []);

  const handleOpenProjectInfo = useCallback(
    (id: number) => {
      if (checkSubscription()) {
        handleUnlock();
        return;
      }
      setParams(`details=${id}`);
    },
    [pathname]
  );

  const handleUnlock = (): void => {
    if (searchStore.locationForMap.length) {
      onHandleUnlock(getLocationForSubscription(searchStore.locationForMap));
    }
  };

  const closeAddFavoriteModal = (): void => {
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: false }));
  };

  const favoriteCallback = useCallback(
    (isActive: boolean) => {
      if (favoriteState.favoriteId) {
        projectSearchStore.updateFavoriteListRow(favoriteState.favoriteId, isActive);
      }
    },
    [favoriteState.favoriteId]
  );

  useIntersectionObserverEffect(
    observableDiv,
    async (entry: IntersectionObserverEntry) => {
      if (
        entry.isIntersecting &&
        projectSearchStore.projectListData.hasMore &&
        !isMoreProjectsLoading
      ) {
        setIsMoreProjectsLoading(true);
        await loadListProjects(controller, projectSearchStore.projectListData.currentPage + 1);
        setIsMoreProjectsLoading(false);
      }
    },
    [
      observableDiv.current,
      projectSearchStore.projectListData.currentPage,
      projectSearchStore.projectListData.hasMore
    ]
  );

  const getProjectCount = useMemo(
    (): string =>
      createProjectCountString(projectSearchStore.projectListData, t('searchProject.projects')),
    [projectSearchStore.projectListData]
  );

  const getTitle = useCallback(() => {
    return searchStore.locationForMap.map((location) => location.title).join(' | ');
  }, [searchStore.locationForMap]);

  const handlePageClick = useCallback((event: ClickEvent) => {
    filtersStore.setSearchParams('page', event.selected + 1);
  }, []);

  const handleContact = (contactId: number, projectId: number): void => {
    const contact = getContactFromProjectById(
      projectSearchStore.projectListData.data,
      projectId,
      contactId
    );
    setParams(
      `contacts=${contact.id}&type=${contact.type}&role=${contact.role}&name=${contact.name}&favourite=${contact.favourite}&project=${projectId}`
    );
  };

  return (
    <div className="pt-3">
      <div className="px-6 relative">
        <span className="font-halbfett text-xl text-dark block select-text w-80 overflow-hidden text-ellipsis whitespace-nowrap">
          {getTitle()}
        </span>
        <div className="flex items-center text-nowrap text-sm">
          {getProjectCount} | {t('searchProject.projectValue')}:
          {projectSearchStore.isValueLoading ? (
            <div className={clsx(c.loader, 'ms-2')}>
              <div></div>
            </div>
          ) : (
            <span>
              &nbsp;
              {projectSearchStore.projectListData.value > 0
                ? `$${valueFormatter(projectSearchStore.projectListData.value)}`
                : NA}
            </span>
          )}
        </div>
        {searchStore.locationForMap.length === 1 && (
          <div className="absolute right-4 top-1">
            <ViewSwitcher isView={SearchProjectView.LIST} onSelectView={onSelectView} />
          </div>
        )}
      </div>
      <div className="rounded-sm my-3 w-full mt-5">
        {!projectsVisible ? (
          <ProgressBar progress={projectsProgress} thin />
        ) : (
          <>
            {projectSearchStore.projectListData.data.length > 0 && !isProjectsLoading ? (
              <div className="mx-6">
                <Table
                  columns={columns}
                  rows={projectSearchStore.projectListData.dataTable}
                  actions={tableAction}
                  orders={filtersStore.searchParams.order}
                  orderAction={handleOrder}
                  onScroll={handleTableScroll}
                  onTitleClick={handleOpenProjectInfo}
                  hasSubscription={projectSearchStore.projectListData.hasSubscription}
                  onHandleUnlock={handleUnlock}
                  onContact={handleContact}
                  tableClass={
                    projectSearchStore.projectListData.hasSubscription
                      ? c.table
                      : c.tableWithoutSubscription
                  }
                />
                {projectSearchStore.projectListData.hasSubscription && (
                  <div className="flex">
                    <Pagination
                      onPageClick={handlePageClick}
                      currentPage={projectSearchStore.projectListData.currentPage}
                      totalPages={projectSearchStore.projectListData.totalPages}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="p-4 bg-white">
                <h4 className="text-center text-dark">{t('searchProject.notFoundProjects')}</h4>
              </div>
            )}
          </>
        )}
      </div>
      {favoriteState.showAddToFavorite && favoriteState.entity && (
        <AddToFavoritesModal
          entity={favoriteState.entity}
          closeModal={closeAddFavoriteModal}
          toggleCallback={favoriteCallback}
        />
      )}
    </div>
  );
});

export default ListView;
