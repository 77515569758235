import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import {
  ChecksumType,
  CONFIG_SERVICE,
  ConfigKey,
  IAppConfig,
  IConfigs,
  IConfigService
} from 'store/ConfigStore/types';
import { APP_VERSION, CHECKSUM, ENUMS } from 'utils/constants';
import { getLocal, removeLocal, setLocal } from 'utils/localStorageOperation';

export class ConfigStore {
  private _configService: IConfigService = injector.get<IConfigService>(CONFIG_SERVICE);

  constructor() {
    makeAutoObservable(this);
  }

  appConfig: IAppConfig | null = null;
  checksums: ChecksumType | null = null;
  enums: IConfigs | null = null;

  setAppConfigs(config: IAppConfig): void {
    this.appConfig = config;
  }

  setChecksum(checksums: ChecksumType): void {
    this.checksums = checksums;
  }

  setEnums(enums: IConfigs): void {
    this.enums = enums;
  }

  async getAppConfig(): Promise<void> {
    this.setAppConfigs(await this._configService.getAppConfig());
  }
  async getNewChecksum(): Promise<void> {
    this.setChecksum(await this._configService.getNewChecksum());
  }

  async getAppResource<T>(url: string) {
    return await this._configService.getAppResource<T>(url);
  }

  async updateConfigs() {
    await this.getNewChecksum();
    const savedAppVersion = getLocal<string>(APP_VERSION);
    await this.getAppConfig();

    if (String(this.appConfig?.version) !== savedAppVersion) {
      removeLocal(CHECKSUM);
      removeLocal(ENUMS);
      setLocal(APP_VERSION, String(this.appConfig?.version));
    }

    const checksum = getLocal<ChecksumType | null>(CHECKSUM);

    if (checksum) {
      this.setChecksum(checksum);
      await this.updateEnums(checksum, this.checksums, this.appConfig?.configs || null);
    }

    const enums =
      getLocal<IConfigs | null>(ENUMS) ||
      (await this.updateEnums({} as ChecksumType, this.checksums, this.appConfig?.configs || null));
    this.setEnums(enums);
  }

  async updateEnums(
    currentChecksum: ChecksumType,
    newChecksum: ChecksumType | null,
    enumUrlsObj: ChecksumType | null
  ): Promise<IConfigs> {
    if (!newChecksum || !enumUrlsObj) return {} as IConfigs;
    const enumsToUpdate = Object.keys(newChecksum).filter(
      (currentEnum) =>
        newChecksum[currentEnum as ConfigKey] !== currentChecksum[currentEnum as ConfigKey]
    );

    const promiseArr = enumsToUpdate.map((currentEnum) =>
      this.getAppResource<IConfigs>(
        `${enumUrlsObj[currentEnum as ConfigKey]}?v=${newChecksum[currentEnum as ConfigKey]}`
      )
    );

    const enumsValues: Awaited<IConfigs>[] = await Promise.all(promiseArr);

    const updatedEnums = enumsToUpdate.reduce((accum, enumKey, index) => {
      return { ...accum, [enumKey]: enumsValues[index] };
    }, {} as IConfigs);

    const enums = getLocal<IConfigs>(ENUMS);
    const newEnums = { ...enums, ...updatedEnums };

    if (Object.keys(newEnums).length) {
      setLocal(ENUMS, newEnums);
    }
    return updatedEnums;
  }
}
