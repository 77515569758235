import { SurveyList } from './types';

export const surveyList: SurveyList[] = [
  {
    id: 1,
    label: 'It is too expensive'
  },
  {
    id: 2,
    label: 'The product is difficult to use'
  },
  {
    id: 3,
    label: 'I completed my research'
  },
  {
    id: 4,
    label: 'I found a better solution'
  },
  {
    id: 5,
    label: 'The data is insufficient'
  },
  {
    id: 6,
    label: 'My market is not well defined'
  },
  {
    id: 7,
    label: 'Other'
  }
];

export const requiredQuestion =
  'First, please let us know why you would like to cancel your Project Radar subscription. This allows us to listen to our subscriber’s feedback and provide a world-class service. We look forward to your response.';

export const otherQuestion = 'What could we improve to make Project Radar better for you?';

export const questionAndAnswer = [
  {
    question: requiredQuestion,
    answer: '',
    custom: ''
  },
  {
    question: otherQuestion,
    custom: ''
  }
];
