import {
  EXPORT_DATA_SERVICE,
  ExportData,
  IExportDataService,
  IExportDataStore,
  Status
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';

export class ExportDataStore implements IExportDataStore {
  private _exportDataService: IExportDataService =
    injector.get<IExportDataService>(EXPORT_DATA_SERVICE);

  exportData: ExportData = {
    link: null,
    status: Status.PENDING
  };

  constructor() {
    makeAutoObservable<ExportDataStore>(this);
  }

  setExportData(data: ExportData): void {
    this.exportData = data;
  }

  async runExport(userId: number, searchId: number): Promise<void> {
    await this._exportDataService.runExport(userId, searchId);
  }

  async getExportFile(userId: number, searchId: number): Promise<void> {
    this.setExportData(await this._exportDataService.getExportFile(userId, searchId));
  }
}
