import { IAxiosResponse, ITableData } from 'utils/types';
import { IRow } from 'components/Table/types';
import { ObjectValues, ISort } from 'utils/types';

export type ITypes = {
  [key in keys]: JSX.Element;
};
type keys = 'remodel' | 'hvac' | 'plumbing';

export type FavoriteAnalytics = {
  name: string;
  value: number;
};

export type DataAnalyticsDiagram = {
  labels: string[];
  values: number[];
  colors: string[];
};

export type Params = {
  lists: number[];
  order: ISort[];
  page: number;
};

export type ParamsForPost = {
  lists: number[];
  order: ObjectValues<string>;
  page: number;
};

export type AnalyticsDataRes = {
  propertySubType: string;
  propertyType: string;
  buildingPermitType: string;
  value: number;
};

export const ANALYTICS_REPO = 'ANALYTICS_REPO';
export interface IAnalyticsRepo {
  getFavouriteAnalytics(
    userId: number,
    lists: number[]
  ): Promise<IAxiosResponse<FavoriteAnalytics[]>>;
  getFavoriteAnalyticsData(
    userId: number,
    params: ParamsForPost | Params,
    lists: ObjectValues<number>
  ): Promise<IAxiosResponse<ITableData<AnalyticsDataRes>>>;
}

export const ANALYTICS_SERVICE = 'ANALYTICS_SERVICE';
export interface IAnalyticsService {
  getFavouriteAnalytics(userId: number, lists: number[]): Promise<DataAnalyticsDiagram>;
  getFavoriteAnalyticsData(
    userId: number,
    params: Params,
    colorTypes: ObjectValues<string>
  ): Promise<ITableData<IRow>>;
}

export interface IAnalyticsStore {
  getFavouriteAnalytics(userId: number, lists: number[]): Promise<void>;
  getFavoriteAnalyticsData(userId: number, params: Params): Promise<void>;
}
