import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'components';
import { clsx } from 'utils/clsx';
import { ENTER } from 'components/SearchList/constants';
import closeIcon from 'assets/icons/close.svg';
import classes from '../Filter.module.scss';

interface IProps {
  onSaveClick: () => void;
  saveSearchVisible: boolean;
  hideSaveSearch: () => void;
  handleSaveSearch: () => void;
  value: string;
  onInput: (name: string, value: string) => void;
}

const SaveSearchButton: FC<IProps> = ({
  onSaveClick,
  saveSearchVisible,
  hideSaveSearch,
  handleSaveSearch,
  value,
  onInput
}) => {
  const { t } = useTranslation();

  const modalRef = useRef<null | HTMLDivElement>(null);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(modalRef.current as EventTarget)) {
      hideSaveSearch();
    }
  }, []);

  const keyPress = (e: KeyboardEvent): void => {
    if (e.code === ENTER && document.activeElement === e.target) {
      e.stopPropagation();
      handleSaveSearch();
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={modalRef}>
      <Button
        tabIndex={0}
        type="primary"
        onClick={onSaveClick}
        className="flex items-center h-8 py-0 px-[15px] text-xs z-[1] whitespace-nowrap"
        data-test-element="search-project-save-button">
        <span className="font-kraftig">{t('searchProject.saveSearch')}</span>
      </Button>
      <div
        className={clsx(
          'absolute',
          'top-full',
          'right-2',
          '2xl:left-0',
          'pt-2',
          saveSearchVisible ? 'block' : 'hidden'
        )}>
        <div className={clsx(classes.triangle, 'bg-white', 'relative')} />
        <div className={clsx(classes.saveWindow, 'bg-white')}>
          <div className="flex justify-between items-start p-3">
            <h2 className="text-dark text-lg m-0">{t('searchProject.saveSearch')}</h2>
            <img
              onClick={hideSaveSearch}
              src={closeIcon}
              alt="close"
              className="relative"
              role="button"
            />
          </div>
          <div className="p-3">
            <Input
              type="text"
              value={value}
              onInput={onInput}
              name="searchName"
              label="Name your search"
              data-test-element="search-project-save-search-input"
              onKeyDown={keyPress}
            />
          </div>
          <div className="bg-light-400 h-px" />
          <div className="flex items-center justify-end p-3">
            <Button
              type="secondary"
              className={classes.btn}
              onClick={hideSaveSearch}
              data-test-element="search-project-cancel-save-button">
              <span className="font-kraftig">{t('savedSearch.cancel')}</span>
            </Button>
            <Button
              type="primary"
              className={classes.btn}
              onClick={handleSaveSearch}
              data-test-element="search-project-submit-save-button">
              <span className="font-kraftig">{t('savedSearch.save')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveSearchButton;
