import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Input, Select } from 'components';
import { FavoriteFormValue, FavoritesOptions, IFavoriteFilters } from '../types';
import { DATE, LOCATION, PROJECT_COUNT, PROJECT_VALUE, TOTAL_AMOUNT, TYPE } from 'utils/constants';
import TreeDropdown from 'modules/Filter/components/TreeDropdown';
import DateDropdown from 'modules/Filter/components/DateDropdown';
import { clsx } from 'utils/clsx';
import classes from '../Favorites.module.scss';
import ValuationDropdown from 'modules/Filter/components/ValuationDropdown';
import CountDropdown from 'modules/Filter/components/CountDropdown';
import searchIcon from 'assets/icons/search-dark.svg';
import { useStore } from 'storesProvider/storeContext';
import {
  ICustomDate,
  IDropdownEvent,
  IInputChangeItem
} from 'view/SearchProjects/components/SubHeader/types';
import { IOption } from 'components/Select/types';
import { getDate } from 'helpers/getDate';
import { dateOptions } from 'modules/Filter/mock';

interface Props {
  dropdownSelect: string;
  dropdownOptions: FavoritesOptions[];
  onDropdownChange: (option: FavoritesOptions) => void;
  onCreateList: () => void;
  activeFilters: (keyof IFavoriteFilters)[];
  locations: IOption[];
  favoriteFilters: IFavoriteFilters;
  onSearchInput: (name: string, value: string) => void;
  searchValue: string;
  onDropdownSave(name: keyof IFavoriteFilters, value: FavoriteFormValue): void;
  onDropdownReset(name: keyof IFavoriteFilters): void;
  onCategoryChange: (event: IDropdownEvent) => void;
}

export const SubHeader: FC<Props> = ({
  dropdownSelect,
  dropdownOptions,
  onDropdownChange,
  onCreateList,
  activeFilters,
  locations,
  favoriteFilters,
  onSearchInput,
  searchValue,
  onDropdownSave,
  onDropdownReset,
  onCategoryChange
}) => {
  const { favoritesStore } = useStore();
  const [customDate, setCustomDate] = useState<ICustomDate | null>(null);
  const [selectedDate, setSelectedDate] = useState<IOption | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { t } = useTranslation();

  const selectedLocation = useMemo<IOption | null>(() => {
    const [id] = favoriteFilters.location || [];
    return locations.find((location) => location.id === id) || null;
  }, [favoriteFilters.location]);

  const handleLocationChange = useCallback((option: IOption) => {
    onDropdownSave('location', [option.id]);
  }, []);

  const handleLocationReset = useCallback(() => {
    onDropdownSave('location', []);
  }, []);

  const handleDateChange = useCallback((option: IOption) => {
    if (option.value !== 'custom') {
      setSelectedDate(option);
      const dayInMs = 1000 * 60 * 60 * 24;
      const now = new Date(Date.now());
      const from = new Date(Date.now() - dayInMs * Number(option.value));
      const dateFrom = getDate(from);
      const dateTo = getDate(now);
      onDropdownSave('date', { min: dateFrom, max: dateTo });
    }
  }, []);

  const handleCustomDateChange = useCallback((date: ICustomDate) => {
    setCustomDate(date);
    setSelectedDate(dateOptions.find((option) => option.value === 'custom') || null);
    const dateFrom = getDate(new Date(date.from));
    const dateTo = getDate(new Date(date.to));
    onDropdownSave('date', { min: dateFrom, max: dateTo });
  }, []);

  const handleDateReset = useCallback(() => {
    onDropdownSave('date', null);
    setCustomDate(null);
    setSelectedDate(null);
  }, []);

  const handleCountChange = useCallback((value: IInputChangeItem) => {
    onDropdownSave('projectCount', value);
  }, []);

  const handleCountReset = useCallback(() => {
    onDropdownSave('projectCount', null);
  }, []);

  useEffect(() => {
    if (!favoriteFilters.date?.min && !favoriteFilters.date?.min) {
      setCustomDate(null);
      setSelectedDate(null);
    }
  }, [favoriteFilters.date]);

  const onSearch = (): void => {
    setShowSearch(!showSearch);
  };

  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center">
        <div className="w-3/12 mr-5">
          <Dropdown
            title={dropdownSelect}
            options={dropdownOptions}
            onSelect={onDropdownChange}
            float="start"
            activeClass="text-primary"
            titleClass="text-lg"
            extendedPosition="start">
            <Button
              type="light"
              color="#0087FF"
              onClick={onCreateList}
              className="w-full shadow-default py-2 mt-2">
              <span>{t('favorites.createPersonalList')}</span>
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="flex justify-center items-center">
        {showSearch && (
          <div className={clsx(classes.searchWrapper, 'mr-2')}>
            <Input
              name=""
              type="text"
              value={searchValue}
              onInput={onSearchInput}
              inputClass={classes.searchWrapper}
            />
          </div>
        )}
        <Button
          type="light"
          onClick={onSearch}
          className={clsx('flex', 'items-center px-1 mr-4', showSearch ? 'ml-0' : 'ml-2')}>
          <img src={searchIcon} alt="" />
        </Button>
        {activeFilters.includes(TYPE) && (
          <TreeDropdown
            data={favoritesStore.categories}
            onChange={onCategoryChange}
            placeholder={t('components.dropdown.placeholder')}
            divClass="property"
          />
        )}
        {activeFilters.includes(DATE) && (
          <DateDropdown
            customValue={customDate}
            onChange={handleDateChange}
            onCustomChange={handleCustomDateChange}
            onReset={handleDateReset}
            value={selectedDate}
          />
        )}
        {activeFilters.includes(LOCATION) && (
          <Select
            className={clsx(classes.select, 'mr-2', !!selectedLocation && classes.active)}
            options={locations}
            value={selectedLocation}
            onChange={handleLocationChange}
            name="Location"
            placeholder="Location"
            optionWidth={250}
            onReset={handleLocationReset}
            data-test-element="favorite-location-select"
          />
        )}
        {activeFilters.includes(PROJECT_VALUE) && (
          <ValuationDropdown
            placeholder={t('searchProject.valuation')}
            value={favoriteFilters.projectValue || null}
            onReset={() => onDropdownReset('projectValue')}
            onSave={(value) => onDropdownSave('projectValue', value)}
            attr="favorite-project-value-dropdown"
            optionClass="-left-[232px]"
          />
        )}
        {activeFilters.includes(PROJECT_COUNT) && (
          <CountDropdown
            value={favoriteFilters.projectCount || null}
            onReset={handleCountReset}
            onSave={handleCountChange}
          />
        )}
        {activeFilters.includes(TOTAL_AMOUNT) && (
          <ValuationDropdown
            placeholder="Total Amount"
            value={favoriteFilters.totalAmount || null}
            width={140}
            onReset={() => onDropdownReset('totalAmount')}
            onSave={(value) => onDropdownSave('totalAmount', value)}
            attr="favorite-total-amount-dropdown"
            optionClass="-left-[232px]"
          />
        )}
      </div>
    </div>
  );
};
