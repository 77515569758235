import moment from 'moment';
import {
  ISubscriptionRow,
  Plans,
  SubscriptionStatus,
  UserSubscription
} from 'view/SubscriptionsAndPlans/types';
import { useStore } from 'storesProvider/storeContext';
import { Status } from 'components/Table/types';
import { ALL_COUNTRIES, ERROR_COLOR, GREEN_COLOR, GREY_COLOR, ORANGE_COLOR } from 'utils/constants';
import { conversionPrice } from 'view/SubscriptionsAndPlans/helper';

const getDate = (startDate: string, endDate: string): string => {
  const a = moment(endDate).startOf('day');
  // TODO: remove after testing
  // const b = moment(startDate);
  const currentDate = moment().utc().startOf('day');
  // TODO: remove after testing
  // const endTime = moment(endDate).utc().format('X');
  // const currentTime = moment(moment().format('X'));
  const days = a.diff(currentDate, 'days');
  const hours = a.diff(currentDate, 'hours');
  return `${moment(startDate).format('MMMM DD, YYYY')} (in ${
    days > 0 ? days : hours > 0 ? '<1' : 0
  } days)`;
};

const getStatus = (status: number): Status => {
  const { configStore } = useStore();
  const subscriptionStatuses = configStore.enums?.subscriptionStatuses;
  if (subscriptionStatuses && subscriptionStatuses.length) {
    return {
      id: 1,
      name:
        subscriptionStatuses.find((subStatus) => subStatus.id === status)?.name || 'Unknown status',
      color: getStatusColor(status)
    };
  }
  return {
    id: 1,
    name: 'Unknown status',
    color: GREY_COLOR
  };
};

const getStatusColor = (status: SubscriptionStatus): string => {
  const colors = {
    [SubscriptionStatus.UNDEFINED]: GREY_COLOR,
    [SubscriptionStatus.ACTIVE]: GREEN_COLOR,
    [SubscriptionStatus.PAST_DUE]: ORANGE_COLOR,
    [SubscriptionStatus.UNPAID]: ERROR_COLOR,
    [SubscriptionStatus.CANCELED]: ERROR_COLOR,
    [SubscriptionStatus.INCOMPLETE]: ORANGE_COLOR,
    [SubscriptionStatus.INCOMPLETE_EXPIRED]: ERROR_COLOR,
    [SubscriptionStatus.TRIALING]: ORANGE_COLOR,
    [SubscriptionStatus.RENEW_INCOMPLETE]: ORANGE_COLOR
  };
  return colors[status];
};

export const tableRowGeneration = (data: UserSubscription[]): ISubscriptionRow[] => {
  return data.map((item) => {
    return {
      id: item.id,
      newItem: false,
      status: item.status,
      fields: [
        {
          field: 'status',
          status: getStatus(item.status)
        },
        {
          field: 'location',
          label: item.name,
          value: item.unlimited ? ALL_COUNTRIES : item.location.title
        },
        {
          field: 'subscription',
          value: getDate(item.startAt, item.endsAt)
        },
        {
          field: 'amount',
          value: `$${conversionPrice(item.price.amount)}/${
            item.period === Plans.MONTH ? 'mo' : 'yr'
          }`
        }
      ]
    };
  });
};
