import { IAxiosResponse } from 'utils/types';

export enum Status {
  PENDING = 'Pending',
  DONE = 'Done'
}

export type ExportData = {
  link: string | null;
  status: Status.PENDING | Status.DONE | null;
};

export interface IExportDataStore {
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<void>;
}

export const EXPORT_DATA_SERVICE = 'EXPORT_DATA_SERVICE';
export interface IExportDataService {
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<ExportData>;
}

export const EXPORT_DATA_REPO = 'EXPORT_DATA_REPO';
export interface IExportDataRepo {
  runExport(userId: number, searchId: number): Promise<void>;
  getExportFile(userId: number, searchId: number): Promise<IAxiosResponse<ExportData>>;
}
