import React, { FC, useCallback, useEffect, useState } from 'react';
import Header from 'view/Header';
import FileUploader from '../components/FileUploader';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MatchModal from '../components/MatchModal';
import { UploadStatusCode } from '../types';
import ImportHistory from '../ImportHistory';
import classes from '../ListUpload.module.scss';
import ViewResultsModal from '../components/ViewResultsModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clsx } from 'utils/clsx';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';

const NewImport: FC = observer(() => {
  const { t } = useTranslation();
  const { listUploadStore } = useStore();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  // const [matchModalShow, setMatchModalShow] = useState<boolean>(false);
  const [isUploadDataLoading, setIsUploadDataLoading] = useState<boolean>(false);
  const { progress: uploadDataProgress } = useProgress(isUploadDataLoading);
  const [viewResultsModalShow, setViewResultsModalShowModalShow] = useState<boolean>(false);

  const matchId = params.get('matchId');
  const searchId = params.get('searchId');

  useEffect(() => {
    (async () => {
      setIsUploadDataLoading(true);
      await listUploadStore.getStatusLastUploadedFile();
      // listUploadStore.setImportHistoryData({
      //   ...listUploadStore.importHistoryData,
      //   orders: [{ field: 'createdAt', order: 'desc' }],
      //   limit: 10
      // });
      setIsUploadDataLoading(false);
    })();
    return () => {
      if (listUploadStore.timer) clearTimeout(listUploadStore.timer);
    };
  }, []);

  useEffect(() => {
    if (
      listUploadStore.uploadData &&
      listUploadStore.uploadData.status !== UploadStatusCode.START &&
      isUploadDataLoading
    ) {
      setIsUploadDataLoading(false);
    }
  }, [listUploadStore.uploadData]);

  const handleImport = async (file: File) => {
    await listUploadStore.uploadFile(file);
  };

  const handleResetUploadToDefault = () => listUploadStore.resetUploadToDefault();

  const handleOpenSavedSearch = useCallback(() => {
    if (!listUploadStore.uploadData) return;
    navigate(`/saved-search/${listUploadStore.uploadData.savedSearchId}`);
  }, [listUploadStore.uploadData]);

  const handleAbortImport = () => {
    if (
      listUploadStore.uploadData?.status === UploadStatusCode.NEW ||
      listUploadStore.uploadData?.status === UploadStatusCode.PENDING ||
      listUploadStore.uploadData?.status === UploadStatusCode.IN_PROGRESS
    ) {
      void listUploadStore.abortUploadedFileProcess();
      if (listUploadStore.timer) clearTimeout(listUploadStore.timer);
    }
  };

  const showMatchModal = useCallback(() => {
    if (!listUploadStore.uploadData) return;
    setParams(
      `matchId=${listUploadStore.uploadData.crossUploadId}&searchId=${listUploadStore.uploadData.savedSearchId}`
    );
  }, [listUploadStore.uploadData]);

  return (
    <div>
      <Header>
        <h2 className="m-0 text-lg leading-[3.5rem]">{t('listUpload.import')}</h2>
      </Header>
      <div
        style={{ maxHeight: `calc(100vh - 56px)` }}
        className={clsx(classes.listUploadWrapper, 'h-full overflow-y-scroll')}>
        <div className="m-4 px-6 py-7 bg-white rounded-sm">
          <h4 className="text-dark text-lg leading-8">{t('listUpload.importListTitle')}</h4>
          <p className="text-grey-500">{t('listUpload.importListText')}</p>
          <div className="mt-6">
            {isUploadDataLoading && <ProgressBar progress={uploadDataProgress} thin />}
            {!isUploadDataLoading && listUploadStore.uploadData && (
              <FileUploader
                onImport={handleImport}
                uploadData={listUploadStore.uploadData}
                onResetUploadToDefault={handleResetUploadToDefault}
                onShowMatchModal={showMatchModal}
                onShowViewResultsModal={() => setViewResultsModalShowModalShow(true)}
                onOpenSavedSearch={handleOpenSavedSearch}
                onAbortImport={handleAbortImport}
              />
            )}
          </div>
          {matchId && searchId && <MatchModal />}
          {viewResultsModalShow && (
            <ViewResultsModal
              onReviewNow={showMatchModal}
              onReviewLater={handleOpenSavedSearch}
              onCloseModal={() => setViewResultsModalShowModalShow(false)}
            />
          )}
        </div>
        <h3 className="m-4 mt-7">{t('listUpload.importHistory')}</h3>
        <ImportHistory isNewImportPage />
      </div>
    </div>
  );
});

export default NewImport;
