import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import 'react-loading-skeleton/dist/skeleton.css';
import './style/main.scss';
import './locales/config';
import ReactLinks from './router/ReactLinks';
import { BrowserRouter } from 'react-router-dom';
import { useStore } from './storesProvider/storeContext';
import { useAppInit } from './hooks/useAppInit';
import Alerts from 'view/Alerts';
import ProgressBar from './components/ProgressBar';
import { useProgress } from 'hooks/useProgress';

const App = observer(() => {
  const { userStore } = useStore();

  const [isFontsLoading, setIsFontsLoading] = useState<boolean>(true);
  const { progress, visible } = useProgress(isFontsLoading && userStore.isUserLoading);

  useEffect(() => {
    (async () => await useAppInit())();
    const isSafariUser = navigator.userAgent.match(/safari/i);
    isSafariUser
      ? document.fonts.ready.then(() => setIsFontsLoading(false))
      : document.fonts.addEventListener('loadingdone', () => setIsFontsLoading(false));
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Alerts />
        {!visible ? <ProgressBar progress={progress} /> : <ReactLinks />}
      </BrowserRouter>
    </div>
  );
});

export default App;
