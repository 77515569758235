import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import { AnimatedInput, Button } from 'components';
import { useTranslation } from 'react-i18next';
import backIcon from 'assets/icons/back-arrow.svg';
import { useFormik } from 'formik';
import { IResetFormValues } from '../types';
import { ResetConfirmSchema } from 'utils/Formik/validation';
import InfoModal from './InfoModal';

const ResetConfirm = () => {
  const { t } = useTranslation();
  const { alertStore, authStore } = useStore();
  const [searchParams, setParams] = useSearchParams();

  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const [success, setSuccess] = useState<boolean>(false);

  const sendCode = useCallback(async (values: IResetFormValues) => {
    if (!token || !email) return;
    await authStore.resetPassword({
      email,
      token: token.replaceAll(' ', '+'),
      value: values.password
    });
    alertStore.successAlert(t('auth.successReset'));
    setSuccess(true);
  }, []);

  const formik = useFormik<IResetFormValues>({
    onSubmit: sendCode,
    initialValues: {
      password: '',
      repeat: ''
    },
    validationSchema: ResetConfirmSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.handleChange({ target: { value, name } });
  }, []);

  const openLogin = useCallback(() => setParams('auth=sign-in'), []);

  useEffect(() => {
    if (!token || !email) {
      alertStore.errorAlert(t('auth.incorrectLink'));
      openLogin();
    }
  }, []);

  if (success) {
    return (
      <InfoModal
        info={t('auth.setNewPasswordSuccessSubTitle')}
        buttonText={t('auth.continue')}
        onBtnClick={openLogin}
        title={t('auth.setNewPasswordSuccessTitle')}
      />
    );
  }

  return (
    <form
      action="#"
      className="rounded-sm shadow-form max-w-146 bg-white py-7.5"
      onSubmit={formik.handleSubmit}>
      <div className="flex items-center flex-col">
        <h1 className="text-dark text-2xl m-0">{t('auth.setNewPasswordTitle')}</h1>
        <p className="text-base text-grey-500 font-normal w-71 mb-4 text-center">
          {t('auth.setNewPasswordSubTitle')}
        </p>
        <div className="px-10 w-full mb-2">
          <div>
            <AnimatedInput
              type="password"
              name="password"
              value={formik.values.password}
              onInput={onInputChange}
              isError={!!formik.errors.password}
              isSuccess={!formik.errors.password && formik.values.password.length > 0}
              errorText={formik.errors.password}
              dataTestElement="set-new-password-input">
              {t('form.password')}
            </AnimatedInput>
            <div className={clsx('mb-4', classes.label)}>Must be at least 8 characters</div>
          </div>
          <AnimatedInput
            type="password"
            name="repeat"
            value={formik.values.repeat}
            onInput={onInputChange}
            isError={!!formik.errors.repeat}
            isSuccess={!formik.errors.repeat && formik.values.repeat.length > 0}
            errorText={formik.errors.repeat}
            dataTestElement="set-new-password-input">
            {t('form.password')}
          </AnimatedInput>
        </div>

        <div className="my-4 w-full h-px bg-light-400" />
        <div className="px-10 w-full">
          <Button
            type="primary"
            className="w-full font-kraftig px-2 py-[11px]"
            data-test-element="reset-password-button">
            {t('auth.reset')}
          </Button>
        </div>
        <div className="d-flex justify-center pt-4">
          <Button
            type="light"
            onClick={openLogin}
            className={clsx(
              '!text-primary',
              classes.forgot,
              'py-0',
              'm-0',
              'flex',
              'items-center'
            )}>
            <div className="mr-2 flex items-center">
              <img src={backIcon} alt="" />
            </div>
            <span className="font-kraftig">{t('auth.backLogin')}</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ResetConfirm;
