import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useStore } from 'storesProvider/storeContext';
import classes from './LeftSidebar.module.scss';
import ProjectInfo from '../ProjectInfo';
import ContactList from '../ContactList';
import { observer } from 'mobx-react';
import { FixedSizeList as List } from 'react-window';
import { IListItem } from './types';
import { skeletonsToShow } from './mock';
import { Skeleton } from 'components';
import { useListWidth } from 'hooks/useListWidth';
import LeftSidebarHeader from './components/LeftSidebarHeader';
import { useListHeight } from 'hooks/useListHeight';
import { FavoriteSubTypes } from 'view/Favorites/types';
import { IListRef } from '../../types';
import { ClickEvent, IContact, Location } from 'utils/types';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { numberWithSpaces } from 'helpers/numberWithComma';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  isProjectsLoading: boolean;
  handleUnlock: (location: Location | null) => void;
}

const LeftSidebar: FC<IProps> = observer(({ isProjectsLoading, handleUnlock }) => {
  const { projectSearchStore, filtersStore, searchStore } = useStore();
  const [, setParams] = useSearchParams();

  const listWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const projectList = useRef() as MutableRefObject<IListRef>;

  const [activeId, setActiveId] = useState<null | number>(null);

  const listWidth = useListWidth(listWrapperRef);
  const listHeight = useListHeight(listWrapperRef, [
    projectSearchStore.projectsData.hasSubscription
  ]);

  const onSelectItem = useCallback((id: number) => {
    setActiveId((prev) => {
      if (prev === id) {
        return null;
      }
      projectSearchStore.setSelectedProjectOnTheMap(null);
      return id;
    });
  }, []);

  const closeContacts = useCallback(() => setActiveId(null), []);

  useEffect(() => {
    if (projectSearchStore.selectedProjectOnTheMap !== null && projectList.current) {
      projectList.current.scrollToItem(projectSearchStore.selectedProjectOnTheMap, 'start');
    }
  }, [projectSearchStore.selectedProjectOnTheMap]);

  const onUnlock = useCallback(
    (location: Location | null) => {
      handleUnlock(location);
      closeContacts();
    },
    [handleUnlock]
  );

  const replacePagesWithCommas = useCallback(() => {
    const pages = document.querySelectorAll('.page-link');
    pages.forEach((pageEl) => {
      const content = numberWithSpaces(pageEl.textContent || '');
      if (Number(pageEl.textContent) && !Number.isNaN(Number(pageEl.textContent))) {
        pageEl.textContent = content;
      }
    });
  }, []);

  const isContactsLocked = useMemo<boolean>(() => {
    if (!activeId) return true;
    return !projectSearchStore.projectsData.hasSubscription;
  }, [activeId]);

  const contactsLocation = useMemo<Location | null>(() => {
    return (
      projectSearchStore.projectsData.data.find((project) => project.id === activeId)?.locations
        .county || null
    );
  }, [activeId]);

  useEffect(() => {
    closeContacts();
    replacePagesWithCommas();
  }, [filtersStore.searchParams, projectSearchStore.projectsData]);

  useEffect(() => {
    const btns = document.querySelectorAll('.page-item');
    const handleClick = (e: Event) => {
      if (projectSearchStore.projectsData.hasSubscription) return;
      e.stopPropagation();
      onUnlock(searchStore.locationForMap as unknown as Location);
    };
    btns.forEach((btn) => btn.addEventListener('click', handleClick));
    return () => {
      btns.forEach((btn) => btn.removeEventListener('click', handleClick));
    };
  }, [projectSearchStore.projectsData]);

  const handlePageClick = useCallback((event: ClickEvent) => {
    filtersStore.setSearchParams('page', event.selected + 1);
  }, []);

  const handleShowMore = useCallback(
    (contact: IContact) => {
      const isFavorite = projectSearchStore.checkContactIsFavorite(contact);
      setParams(
        `contacts=${contact.id}&type=${contact.type}&role=${contact.role}&name=${contact.name}&favourite=${isFavorite}&project=${activeId}`
      );
    },
    [activeId]
  );

  return (
    <div className="relative border-r border-r-light-600">
      <LeftSidebarHeader />
      <div
        className={
          projectSearchStore.projectsData.hasSubscription
            ? classes.projectsWrapper
            : classes.projectsWrapperWithoutPagination
        }
        ref={listWrapperRef}>
        {isProjectsLoading ? (
          <Skeleton type="project" itemsToShow={skeletonsToShow} />
        ) : projectSearchStore.projectsData.data.length ? (
          <List
            className={classes.projectsList}
            height={listHeight}
            itemCount={projectSearchStore.projectsData.data.length}
            itemSize={185}
            width={listWidth || 0}
            ref={projectList}>
            {({ index, style }: IListItem) => (
              <ProjectInfo
                style={style}
                key={projectSearchStore.projectsData.data[index].id}
                project={projectSearchStore.projectsData.data[index]}
                active={activeId === projectSearchStore.projectsData.data[index].id}
                onSelectItem={onSelectItem}
                index={index}
                listRef={projectList}
                isLocked={projectSearchStore.projectsData.data[index].isLocked}
                handleUnlock={!projectSearchStore.projectsData.hasSubscription ? onUnlock : null}
                type="projects"
              />
            )}
          </List>
        ) : (
          <div className="p-2 text-center bg-white border-t border-t-light-600">
            There isn&apos;t any project matches the specified filter
          </div>
        )}
      </div>
      {projectSearchStore.projectsData.hasSubscription && (
        <div className="fixed bottom-0 bg-white z-[9]" style={{ width: listWidth }}>
          <div className="flex p-2 pagination-block justify-center border-t border-light-600">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<ArrowIcon data-direction="left" />}
              previousLabel={<ArrowIcon data-direction="right" />}
              onPageChange={handlePageClick}
              forcePage={projectSearchStore.projectsData.currentPage - 1}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              pageCount={projectSearchStore.projectsData.totalPages}
              containerClassName="pagination flex mb-2 justify-center"
              activeClassName="active"
              activeLinkClassName="active"
              breakClassName="page-item"
              breakLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
              pageClassName="page-item mx-1"
              pageLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
              previousClassName="page-item mx-1"
              previousLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
              nextClassName="page-item mx-1"
              nextLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
            />
          </div>
        </div>
      )}
      {activeId && (
        <ContactList
          projectId={activeId}
          position="right"
          closeList={closeContacts}
          type={FavoriteSubTypes.regular}
          handleUnlock={() => handleUnlock(contactsLocation)}
          isLocked={isContactsLocked}
          onShowMore={handleShowMore}
        />
      )}
    </div>
  );
});

export default LeftSidebar;
