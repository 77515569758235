import { useStore } from 'storesProvider/storeContext';
import { IMoreValues } from 'modules/Filter/types';
import { maxOptions, minOptions } from 'modules/Filter/mock';
import { IOptionsConfig } from 'modules/Filter/types';
import { getConfigs } from './getConfigs';

export const getInitialMoreValues = () => {
  const { filtersStore } = useStore();
  const configs: IOptionsConfig = getConfigs();

  const values: IMoreValues = {
    units: {
      min: filtersStore.searchParams.units?.min
        ? minOptions.find((option) => option.value == filtersStore.searchParams.units.min) || null
        : null,
      max: filtersStore.searchParams.units?.max
        ? maxOptions.find((option) => option.value == filtersStore.searchParams.units.max) || null
        : null
    },
    levels: {
      min: filtersStore.searchParams.levels?.min
        ? minOptions.find((option) => option.value == filtersStore.searchParams.levels.min) || null
        : null,
      max: filtersStore.searchParams.levels?.max
        ? maxOptions.find((option) => option.value == filtersStore.searchParams.levels.max) || null
        : null
    },
    status:
      configs.projectStatuses.find((status) => {
        return filtersStore.searchParams.statuses?.length > 0
          ? status.id === filtersStore.searchParams.statuses[0]
          : false;
      }) || null,
    keywords: filtersStore.searchParams.keywords || [],
    excludes: {
      address: false,
      companyName: false,
      description: false
    },
    companyName: filtersStore.searchParams.companyName || [],
    companyAddress: filtersStore.searchParams.companyAddress || [],
    companyType: filtersStore.searchParams.companyType
      ? {
          id: 0,
          value: filtersStore.searchParams.companyType,
          text:
            filtersStore.searchParams.companyType.charAt(0).toUpperCase() +
            filtersStore.searchParams.companyType.slice(1)
        }
      : null
  };
  return values;
};
