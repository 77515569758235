import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { FilterState, IDropCategories, IDropdownEvent, IInputChangeItem } from './types';
import { useDebounce } from 'hooks/useDebounce';
import { DROPDOWN_DEBOUNCE_DELAY } from 'utils/constants';
import { IOption } from 'components/Select/types';
import { observer } from 'mobx-react';
import FilterForSearch from './components/FilterForSearch';
import FilterForSavedSearch from './components/FilterForSavedSearch';
import FilterForCompanySearch from './components/FilterForCompanySearch';

interface Props {
  type: FilterState;
  savedSearchId?: number | null;
  onSaveSearch: (name: string) => void;
}

const Filter: FC<Props> = observer(({ type, savedSearchId, onSaveSearch }) => {
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const { pathname } = useLocation();

  const { alertStore, filtersStore, userStore } = useStore();
  const [saveSearchVisible, setSaveSearchVisible] = useState<boolean>(false);
  const [categories, setCategories] = useState<IDropCategories>({
    classes: filtersStore.searchParams.classes,
    types: filtersStore.searchParams.types,
    subTypes: filtersStore.searchParams.subTypes
  });
  const [permitTypes, setPermitTypes] = useState<number[]>(filtersStore.searchParams.permitTypes);
  const debouncedCategories = useDebounce(categories, DROPDOWN_DEBOUNCE_DELAY);
  const debouncedPermitTypes = useDebounce(permitTypes, DROPDOWN_DEBOUNCE_DELAY);
  const [newSearchName, setNewSearchName] = useState<string>('');

  const handleSearchNameInput = useCallback(
    (name: string, value: string) => setNewSearchName(value),
    []
  );

  const handleSaveClick = useCallback(() => {
    if (!userStore.user) {
      navigate('?auth=sign-up&extended=true');
      return;
    }
    setSaveSearchVisible((prev) => !prev);
  }, []);

  const hideSaveSearch = useCallback(() => {
    setSaveSearchVisible(false);
    setNewSearchName('');
  }, []);

  const handleSaveSearch = useCallback(() => {
    if (newSearchName.length < 3) {
      alertStore.errorAlert('This name is too short. It should have 3 characters or more.');
      return;
    }
    onSaveSearch(newSearchName);
    hideSaveSearch();
  }, [newSearchName]);

  const handleSaveValue = useCallback(
    (value: IInputChangeItem) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        filtersStore.onInputChange({ min: null, max: null }, 'value');
        return;
      }
      filtersStore.onInputChange(value, 'value');
    },
    [pathname]
  );

  const handleResetValue = useCallback(() => {
    filtersStore.onInputChange({ min: null, max: null }, 'value');
  }, []);

  const handleResetDate = useCallback(() => {
    filtersStore.onSelectReset('date');
    filtersStore.setCustomDate(null);
  }, []);

  const handleCategoryChange = useCallback(
    ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      setCategories((prev) => ({
        classes: activeClasses || prev.classes,
        types: activeTypes || prev.types,
        subTypes: activeSubtypes || prev.subTypes
      }));
    },
    [pathname]
  );

  const handlePropertyChangeChange = useCallback(
    ({ activePermits }: IDropdownEvent) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      if (activePermits) {
        setPermitTypes([...activePermits]);
      }
    },
    [pathname]
  );

  const handleDateChange = useCallback(
    (option: IOption, field: string) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      filtersStore.onSelectChange(option, field);
    },
    [pathname]
  );

  useEffect(() => {
    filtersStore.onCategoryChange(categories.classes, categories.types, categories.subTypes);
  }, [debouncedCategories]);

  useEffect(() => {
    filtersStore.onPermitsChange(permitTypes);
  }, [debouncedPermitTypes]);

  const handleReset = useCallback(() => {
    filtersStore.handleReset();
    const treeFilters = document.querySelectorAll('.dropdown-trigger.arrow');
    treeFilters.forEach((filter) => filter.removeAttribute('aria-labelledby'));
  }, []);

  return (
    <div className="flex items-center">
      {type === FilterState.SEARCH && (
        <FilterForSearch
          onDateChange={handleDateChange}
          onResetDate={handleResetDate}
          onCategoryChange={handleCategoryChange}
          onPropertyChangeChange={handlePropertyChangeChange}
          onResetValue={handleResetValue}
          onSaveValue={handleSaveValue}
          onSaveSearch={handleSaveSearch}
          onHideSaveSearch={hideSaveSearch}
          onSearchNameInput={handleSearchNameInput}
          onSaveClick={handleSaveClick}
          saveSearchVisible={saveSearchVisible}
          newSearchName={newSearchName}
          onReset={handleReset}
        />
      )}
      {type === FilterState.SAVED_SEARCH && (
        <FilterForSavedSearch
          onDateChange={handleDateChange}
          onResetDate={handleResetDate}
          onCategoryChange={handleCategoryChange}
          onPropertyChangeChange={handlePropertyChangeChange}
          onResetValue={handleResetValue}
          onSaveValue={handleSaveValue}
        />
      )}
      {(type === FilterState.COMPANY_SEARCH || type === FilterState.SAVED_SEARCH_COMPANY) && (
        <FilterForCompanySearch
          type={type}
          onDateChange={handleDateChange}
          onResetDate={handleResetDate}
          onCategoryChange={handleCategoryChange}
          onPropertyChangeChange={handlePropertyChangeChange}
          onResetValue={handleResetValue}
          onSaveValue={handleSaveValue}
          onSaveSearch={handleSaveSearch}
          onHideSaveSearch={hideSaveSearch}
          onSearchNameInput={handleSearchNameInput}
          onSaveClick={handleSaveClick}
          saveSearchVisible={saveSearchVisible}
          newSearchName={newSearchName}
          onReset={handleReset}
          savedSearchId={savedSearchId}
        />
      )}
    </div>
  );
});

export default Filter;
