import { IShareService, IShareStore, SHARE_SERVICE, SharedEntityType } from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';

export class ShareStore implements IShareStore {
  private _shareService: IShareService = injector.get<IShareService>(SHARE_SERVICE);

  shareTokens = {
    [SharedEntityType.SAVED_SEARCH]: '',
    [SharedEntityType.SINGLE_PERMIT]: '',
    [SharedEntityType.FAVORITE_LIST]: ''
  };

  constructor() {
    makeAutoObservable<ShareStore>(this);
  }

  setShareToken(token: string, entityType: SharedEntityType): void {
    this.shareTokens[entityType] = token;
  }

  async createSavedSearchToken(
    userId: number,
    searchId: number,
    email: string | null
  ): Promise<void> {
    this.setShareToken(
      (await this._shareService.createSavedSearchToken(userId, searchId, email)).token,
      SharedEntityType.SAVED_SEARCH
    );
  }
  async createSinglePermitToken(searchId: number, email: string | null): Promise<void> {
    this.setShareToken(
      (await this._shareService.createSinglePermitToken(searchId, email)).token,
      SharedEntityType.SINGLE_PERMIT
    );
  }

  async createFavoriteListToken(
    userId: number,
    listId: number,
    email: string | null
  ): Promise<void> {
    this.setShareToken(
      (await this._shareService.createFavoriteListToken(userId, listId, email)).token,
      SharedEntityType.FAVORITE_LIST
    );
  }
}
