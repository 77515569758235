import { INote, INoteRepo } from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';
import { FavoriteTabSystemType } from '../Favorites/types';

export class NoteRepo implements INoteRepo {
  async getNote(projectId: number, type: FavoriteTabSystemType): Promise<IAxiosResponse<INote>> {
    return await axiosInstance.get(`${type}/${projectId}/notes`);
  }

  async addNote(
    projectId: number,
    type: FavoriteTabSystemType,
    content: string
  ): Promise<IAxiosResponse<INote>> {
    return await axiosInstance.post(`${type}/${projectId}/notes`, { content });
  }
}
