import React, { FC } from 'react';
import Skelet from 'react-loading-skeleton';
import { SkeletonType } from './types';
import { clsx } from 'utils/clsx';
import { skeletonBase } from './base';

interface IProps {
  type: SkeletonType;
  itemsToShow: number;
}

const Skeleton: FC<IProps> = ({ type, itemsToShow }) => {
  const rows = skeletonBase[type];
  return (
    <>
      {Array(itemsToShow)
        .fill(null)
        .map((_, idx) => (
          <div key={_ + idx}>
            <div className={type === 'plan' ? 'p-0' : 'p-4'}>
              <div>
                {rows.map((skeleton, idx) => (
                  <div
                    key={idx}
                    className={clsx('flex', skeleton.between && 'justify-between')}
                    style={{ marginBottom: skeleton.rowGap, paddingLeft: skeleton.padStart }}>
                    {skeleton.items.map((item) => (
                      <div style={{ paddingLeft: item.padStart }} key={`${Math.random()}-${idx}`}>
                        <Skelet circle={item.isCircled} height={item.height} width={item.width} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {idx !== itemsToShow - 1 && (
              <div style={{ height: '1px', backgroundColor: '#DDE2ED' }} />
            )}
          </div>
        ))}
    </>
  );
};

export default Skeleton;
