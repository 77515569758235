import React, { FC } from 'react';
import { SwitchItem } from 'components';
import { NotificationItem, NotificationSettingsKeys, NotificationSettingType } from '../../types';

interface Props {
  setting: NotificationItem;
  onEnabled: (key: NotificationSettingsKeys, value: boolean) => void;
}

const SettingItem: FC<Props> = ({ setting, onEnabled }) => {
  const handleEnabled = (enabled: boolean, type: string) => {
    onEnabled(`${setting.key}${type}` as NotificationSettingsKeys, enabled);
  };

  return (
    <div className="flex p-6">
      <div className="max-w-[402px]">
        <h3 className="text-base mb-0">{setting.title}</h3>
        <p className="font-buch text-sm">{setting.content}</p>
      </div>
      <div className="px-6">
        <div className="mb-4">
          <SwitchItem
            enabled={setting.enabledApp}
            onEnabled={(enabled) => handleEnabled(enabled, NotificationSettingType.APP)}
            label="In-app"
          />
        </div>
        <SwitchItem
          enabled={setting.enabledEmail}
          onEnabled={(enabled) => handleEnabled(enabled, NotificationSettingType.EMAIL)}
          label="Email"
        />
      </div>
    </div>
  );
};

export default SettingItem;
