import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { orderProcessing, singleSortProcessing } from 'helpers/orderProcessing';
import Header from 'view/Header';
import HistoryTable from '../components/HistoryTable';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isNewImportPage?: boolean;
}

const ImportHistory: FC<IProps> = observer(({ isNewImportPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { listUploadStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleOrder = useCallback(
    (field: string) => {
      listUploadStore.setImportHistoryData({
        ...listUploadStore.importHistoryData,
        orders: singleSortProcessing(field, listUploadStore.importHistoryData.orders),
        // TODO: remove after test
        // orders: isNewImportPage
        //   ? singleSortProcessing(field, listUploadStore.importHistoryData.orders)
        //   : orderProcessing(field, listUploadStore.importHistoryData.orders),
        currentPage: 1
      });
      if (isNewImportPage) {
        listUploadStore.localSortHistoryData();
      }
    },
    [listUploadStore.importHistoryData]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      listUploadStore.setImportHistoryData({
        ...listUploadStore.importHistoryData,
        currentPage: page
      });
    },
    [listUploadStore.importHistoryData]
  );

  useEffect(() => {
    if (!isNewImportPage) {
      setIsLoading(true);
      listUploadStore.setImportHistoryData({
        ...listUploadStore.importHistoryData,
        limit: 20
      });
      listUploadStore.getImportHistoryData().finally(() => setIsLoading(false));
    }
  }, [listUploadStore.importHistoryData.orders, listUploadStore.importHistoryData.currentPage]);

  useEffect(() => {
    listUploadStore.setImportHistoryData({
      ...listUploadStore.importHistoryData,
      orders: [{ field: 'createdAt', order: 'desc' }],
      limit: 10
    });
    if (isNewImportPage) {
      setIsLoading(true);
      listUploadStore.getImportHistoryData().finally(() => {
        listUploadStore.setImportHistoryData({
          ...listUploadStore.importHistoryData,
          orders: [{ field: 'createdAtFull', order: 'desc' }]
        });
      });
      setIsLoading(false);
    }
    return () => listUploadStore.resetImportHistoryData();
  }, []);

  return (
    <div className="max-h-screen overflow-hidden flex flex-col pb-6">
      {!isNewImportPage && (
        <div className="flex-1">
          <Header>
            <h2 className="m-0 text-lg leading-[3.5rem]">{t('listUpload.importHistory')}</h2>
          </Header>
        </div>
      )}
      {listUploadStore.importHistoryData.data.length ? (
        <div className="mt-4 mx-4" style={{ maxWidth: `calc(100vw - 232px)` }}>
          <HistoryTable
            data={listUploadStore.importHistoryData.data}
            orders={listUploadStore.importHistoryData.orders}
            orderAction={handleOrder}
            currentPage={listUploadStore.importHistoryData.currentPage}
            handlePageClick={handlePageChange}
            totalPages={listUploadStore.importHistoryData.totalPages}
            isNewImportPage={Boolean(isNewImportPage)}
          />
        </div>
      ) : (
        <>
          {!isLoading && (
            <div className="p-4">
              <h4 className="text-center text-dark">{t('listUpload.emptyHistory')}</h4>
              {!isNewImportPage && (
                <Button
                  type="primary"
                  className="px-4 m-auto mt-3 font-kraftig"
                  onClick={() => navigate('/new-import')}>
                  {t('listUpload.goToImport')}
                </Button>
              )}
            </div>
          )}
        </>
      )}
      {isNewImportPage && (
        <div className="pl-4 mt-6">
          <Button type="primary" className="px-9" onClick={() => navigate('/import-history')}>
            {t('listUpload.seeHistory')}
          </Button>
        </div>
      )}
    </div>
  );
});

export default ImportHistory;
