import { FoundLocation, ISearchRepo, SearchProjects } from './types';
import { axiosInstance } from 'utils/API';
import { IAxiosResponse } from 'utils/types';

export class SearchRepo implements ISearchRepo {
  async searchProjects(
    value: string,
    controller: AbortController,
    searchId?: number
  ): Promise<IAxiosResponse<SearchProjects>> {
    return await axiosInstance.get(`/locations/autocomplete`, {
      params: {
        query: value,
        searchId
      },
      signal: controller.signal
    });
  }

  async lookupLocation(
    states: string[],
    titles: string[]
  ): Promise<IAxiosResponse<FoundLocation[]>> {
    return await axiosInstance.get(`locations/lookup`, { params: { states, titles } });
  }
}
