import { injector } from 'utils/injector';
import { FoundLocation, ISearchRepo, ISearchService, SEARCH_REPO, SearchProjects } from './types';

export class SearchService implements ISearchService {
  private _searchRepo: ISearchRepo = injector.get<ISearchRepo>(SEARCH_REPO);

  async searchProjects(
    value: string,
    controller: AbortController,
    searchId?: number
  ): Promise<SearchProjects> {
    return (await this._searchRepo.searchProjects(value, controller, searchId)).data.result;
  }

  async lookupLocation(states: string[], titles: string[]): Promise<FoundLocation[]> {
    return (await this._searchRepo.lookupLocation(states, titles)).data.result;
  }
}
