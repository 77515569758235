import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classes from 'components/Select/Select.module.scss';
import styles from '../Filter.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useStore } from 'storesProvider/storeContext';
import { clsx } from 'utils/clsx';
import { Button, Input } from 'components';
import { maxValues, minValues } from '../mock';
import { valueFormatter } from 'helpers/valueFormatter';
import { useTranslation } from 'react-i18next';
import { IInputChangeItem } from '../types';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helpers/childOf';

interface IProps {
  placeholder: string;
  width?: number;
  value: {
    min: number | null;
    max: number | null;
  } | null;
  onSave: (value: IInputChangeItem) => void;
  onReset: () => void;
  attr?: string;
  optionClass?: string;
}
const ValuationDropdown: FC<IProps> = observer(
  ({ attr, placeholder, width, value, onSave, onReset, optionClass }) => {
    const { alertStore, filtersStore } = useStore();
    const { t } = useTranslation();
    const [minInputValue, setMinInputValue] = useState<string>(String(value?.min) ?? '');
    const [maxInputValue, setMaxInputValue] = useState<string>(String(value?.max) ?? '');
    const [isMinPriceSelected, setIsMinPriceSelected] = useState<boolean>(true);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const formRef = useRef<HTMLDivElement | null>(null);
    const selectRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (minInputValue !== String(filtersStore.searchParams.value?.min)) {
        setMinInputValue(String(filtersStore.searchParams.value?.min));
      }
      if (maxInputValue !== String(filtersStore.searchParams.value?.max)) {
        setMaxInputValue(String(filtersStore.searchParams.value?.max));
      }
    }, []);

    const toggleSelect = useCallback((e: React.MouseEvent): void => {
      if (e.nativeEvent.composedPath().includes(formRef.current as EventTarget)) return;
      setIsOpened((prev) => !prev);
    }, []);

    const setMinValue = useCallback((value: string) => {
      setMinInputValue(value);
      setIsMinPriceSelected(false);
    }, []);
    const setMaxValue = useCallback((value: string | null) => setMaxInputValue(value || ''), []);

    const handleMinInputFocus = useCallback(() => setIsMinPriceSelected(true), []);
    const handleMaxInputFocus = useCallback(() => setIsMinPriceSelected(false), []);

    const clickOutside = useCallback((e: MouseEvent): void => {
      if (!e.composedPath().includes(selectRef.current as EventTarget)) {
        setIsOpened(false);
      }
    }, []);

    const onInput = useCallback((name: string, value: string) => {
      if (name === 'valueMin') setMinInputValue(value.replace(/\D/g, ''));
      if (name === 'valueMax') setMaxInputValue(value.replace(/\D/g, ''));
    }, []);

    const handleCancel = useCallback(() => {
      setIsOpened(false);
    }, []);

    const handleSave = useCallback(() => {
      if (maxInputValue && Number(minInputValue) > Number(maxInputValue)) {
        alertStore.errorAlert(t('components.dropdown.valueError'));
        return;
      }
      onSave({
        min: minInputValue ? Number(minInputValue) : null,
        max: maxInputValue ? Number(maxInputValue) : null
      });
      setIsOpened(false);
      setIsMinPriceSelected(true);
    }, [minInputValue, maxInputValue]);

    const handleReset = (e: React.MouseEvent) => {
      e.stopPropagation();
      setMinInputValue('');
      setMaxInputValue('');
      setIsMinPriceSelected(true);
      setIsOpened(false);
      onReset();
    };

    const keyPress = useCallback((e: KeyboardEvent): void => {
      if (e.code === ENTER) {
        if (
          !childOf(document.activeElement, selectRef.current) &&
          document.activeElement !== selectRef.current
        )
          setIsOpened(false);
        if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
      }
    }, []);

    const handleMinPressEnter = useCallback((e: React.KeyboardEvent, value: string) => {
      if (e.code === ENTER && document.activeElement === e.target) {
        setMinValue(value);
      }
    }, []);

    const handleMaxPressEnter = useCallback((e: React.KeyboardEvent, value: string) => {
      if (e.code === ENTER && document.activeElement === e.target) {
        setMaxValue(value);
      }
    }, []);

    const handleResetPressEnter = useCallback((e: React.KeyboardEvent) => {
      if (e.code === ENTER && document.activeElement === e.target) {
        setMinInputValue('');
        setMaxInputValue('');
        setIsMinPriceSelected(true);
        setIsOpened(false);
        onReset();
      }
    }, []);

    const isActive = useMemo<boolean>(() => {
      return typeof value?.min === 'number' || typeof value?.max === 'number';
    }, [value]);

    const selectValue = useMemo<string | null>(() => {
      const { min, max } = value || {};
      if ((min || min === 0) && !max) return `${valueFormatter(min, 2)}+`;
      if (!min && max) return `${t('searchProject.upTo')} ${valueFormatter(max, 2)}`;
      if (min && max) return `${valueFormatter(min, 2)} - ${valueFormatter(max, 2)}`;
      return null;
    }, [value]);

    const textToShow = useMemo<string>(() => {
      return selectValue || placeholder;
    }, [selectValue]);

    useEffect(() => {
      window.addEventListener('keydown', keyPress);
      if (!isOpened)
        return () => {
          window.removeEventListener('keydown', keyPress);
        };
      document.body.addEventListener('click', clickOutside);
      return () => {
        document.body.removeEventListener('click', clickOutside);
        window.removeEventListener('keydown', keyPress);
      };
    }, [isOpened]);

    useEffect(() => {
      setMinInputValue(typeof value?.min === 'number' ? String(value.min) : '');
      setMaxInputValue(typeof value?.max === 'number' ? String(value.max) : '');
    }, [value]);

    return (
      <>
        <div
          role="button"
          className={clsx(
            classes.select,
            'relative',
            'flex',
            'items-center',
            'justify-between',
            'border-2',
            'border-grey-200',
            'rounded-sm',
            'text-sm',
            !isOpened && 'bg-white',
            isOpened && 'bg-primary text-white border-primary hover:bg-primary',
            'h-8',
            selectValue ? 'pr-2' : 'pr-8',
            'pl-4',
            'mr-2',
            'select-none',
            'hover:bg-grey-100',
            isActive && 'border-2 border-primary'
          )}
          style={{ width }}
          tabIndex={0}
          data-test-element={attr || 'search-projects-valuation-dropdown'}
          onClick={toggleSelect}
          ref={selectRef}>
          <>
            <span role="button" className="block 2xl:hidden w-full whitespace-nowrap font-kraftig">
              {placeholder}
            </span>
            <span
              role="button"
              className="hidden 2xl:inline-block w-full whitespace-nowrap font-kraftig">
              {textToShow}
            </span>
            &nbsp;
            {isOpened && (
              <div
                className={clsx(
                  classes.optionWrapper,
                  optionClass ? optionClass : 'left-0',
                  'w-[370px]',
                  'absolute',
                  'bg-white',
                  'z-40',
                  'overflow-x-hidden',
                  'overflow-y-scroll'
                )}
                ref={formRef}>
                <div>
                  <h2 className="text-dark text-lg m-0 py-3 px-4">
                    {t('searchProject.priceRange')}
                  </h2>
                  <div className="bg-light-400 h-px" />
                  <div className="px-4 text-dark py-3">
                    <div className="flex">
                      <div>
                        <Input
                          tabIndex={0}
                          type="text"
                          value={minInputValue}
                          onInput={onInput}
                          name="valueMin"
                          placeholder="No Min"
                          data-test-element="search-project-min-value-input"
                          onFocus={handleMinInputFocus}
                          autoComplete="off"
                          inputClass="mb-3"
                        />
                        <div className={clsx('grow', !isMinPriceSelected && 'invisible')}>
                          {minValues.map((value) => (
                            <div key={value.id} className="mb-2">
                              <span
                                tabIndex={0}
                                role="button"
                                onClick={() => setMinValue(value.value as string)}
                                onKeyDown={(e) => handleMinPressEnter(e, value.value as string)}
                                className={styles.valuationItem}>
                                ${value.text}+
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex h-10">
                        <div className="bg-dark h-0.5 w-3 my-0 mx-2.5 self-center" />
                      </div>
                      <div>
                        <Input
                          tabIndex={0}
                          type="text"
                          value={maxInputValue}
                          onInput={onInput}
                          name="valueMax"
                          placeholder="No Max"
                          data-test-element="search-project-max-value-input"
                          onFocus={handleMaxInputFocus}
                          autoComplete="off"
                          autoFocus={!isMinPriceSelected}
                          inputClass="mb-3"
                        />
                        <div className={clsx('grow', isMinPriceSelected && 'invisible')}>
                          {maxValues.map((value) => (
                            <div key={value.id} className="mb-2">
                              <span
                                tabIndex={0}
                                role="button"
                                onClick={() => setMaxValue(value.value)}
                                onKeyDown={(e) => handleMaxPressEnter(e, value.value as string)}
                                className={clsx(
                                  styles.valuationItem,
                                  !!value.value &&
                                    Number(minInputValue) > Number(value.value) &&
                                    styles.disabled
                                )}>
                                {value.value && '$'}
                                {value.text}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-light-400 h-px" />
                  <div className="p-4 flex justify-end">
                    <Button
                      type="secondary"
                      className={clsx('px-4', styles.valuationBtn)}
                      onClick={handleCancel}>
                      <span className="font-kraftig">{t('searchProject.cancel')}</span>
                    </Button>
                    <Button
                      type="primary"
                      className="px-4 ml-3 text-sm py-[5px] px-0"
                      onClick={handleSave}>
                      <span className="font-kraftig">{t('searchProject.save')}</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
          {selectValue ? (
            <div role="button" onClick={handleReset} className="flex items-center">
              <CloseIcon
                className={clsx(classes.resetIcon, isOpened && classes.resetOpened)}
                tabIndex={0}
                onKeyDown={handleResetPressEnter}
              />
            </div>
          ) : (
            <div
              className={clsx(
                classes.icon,
                'absolute',
                'top-1/2',
                'flex',
                'items-center',
                '-translate-y-[45%]',
                'right-3',
                isOpened && classes.active
              )}
              role="button">
              <ArrowIcon />
            </div>
          )}
        </div>
      </>
    );
  }
);

export default ValuationDropdown;
