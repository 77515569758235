import React, { FC, ReactNode } from 'react';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import classes from 'modules/ProjectInfoModal/ProjectInfoModal.module.scss';
import { PersonItem } from 'components';
import { IFullProject } from 'modules/ProjectInfoModal/types';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { NA } from 'utils/constants';
import { IContact, PersonDetail } from 'utils/types';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

interface IProps {
  project: IFullProject;
  onScroll: () => void;
  onContacts?: (contact: IContact<PersonDetail>, projectId: number) => void;
  onUnlock: () => void;
  children: ReactNode;
}

const Details: FC<IProps> = ({ project, onScroll, onContacts, onUnlock, children }) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.generalInfo, 'p-4')} onScroll={onScroll}>
      <div className="flex justify-between items-center mb-3">
        <div
          className="py-0.5 px-2.5 uppercase text-xs bg-white border border-grey-200 rounded-sm"
          style={{ color: project.status.color }}>
          {project.status.name}
        </div>
        <span style={{ marginRight: '3px' }}>{children}</span>
      </div>
      <span className="flex justify-between items-center mb-1 text-dark text-lg font-kraftig">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden max-w-full text-dark pr-2">
          {project.propertyClass.name} - {project.propertyType.name}
        </span>
        <div className="relative">
          <span>{project.value ? `$${numberWithSpaces(project.value)}` : NA}</span>
          {project.estimatedValue && (
            <span className="absolute bottom-full right-0 translate-y-[3px] text-dark text-xxs">
              EST.
            </span>
          )}
        </div>
      </span>
      <div className="flex justify-between items-center mb-5 text-grey-500 font-normal text-base">
        <span className="pr-2 whitespace-nowrap text-ellipsis overflow-hidden max-w-full text-grey-500 text-base">
          {project.permitType?.name}
        </span>
        <span>{project.effectiveDate}</span>
      </div>
      {project.description && (
        <div className="mb-5">
          <h6>{t('searchProject.description')}</h6>
          <p className="select-text">{project.description}</p>
        </div>
      )}
      {project.contacts.length > 0 && (
        <div>
          <h6 className="mb-4">{t('searchProject.projectContacts')}</h6>
          <div className="flex-wrap flex relative">
            {project.contacts.map((person, idx) => (
              <PersonItem
                key={idx}
                person={person}
                onContacts={(person) => onContacts && onContacts(person, project.id)}
              />
            ))}
            {!project.hasSubscription && (
              <div
                role="button"
                onClick={onUnlock}
                className="absolute inset-0 flex justify-center items-center backdrop-blur-sm">
                <div className={classes.lockIcon}>
                  <LockIcon className="w-full h-auto" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
