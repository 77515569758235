import { Location } from 'utils/types';
import { FoundLocation } from 'view/Search/types';

export const getLocationForSubscription = (locations: FoundLocation[]): Location[] => {
  return locations.map((location) => {
    return {
      id: location.id,
      title: location.title,
      type: 30,
      stateCode: location.stateCode,
      coordinates: [0, 0]
    };
  });
};
