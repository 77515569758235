import React, { FC } from 'react';
import { IIncreaseCard } from '../types';
import classes from '../IncreasePipeline.module.scss';
import { clsx } from 'utils/clsx';

const IncreaseCard: FC<IIncreaseCard> = ({ title, subTitle, Icon, color }) => {
  return (
    <div
      className="bg-white flex flex-col items-center p-[30px] shadow-card rounded-sm border-b-2 border-b-transparent mr-10 flex-1 last:mr-0"
      style={{ borderBottomColor: color }}>
      <div className="mb-3 pb-2">
        <Icon />
      </div>
      <h4 className="text-center mb-3 text-dark text-2xl">{title}</h4>
      <p className="text-center m-0 mt-auto text-grey-500 text-lg">{subTitle}</p>
    </div>
  );
};

export default IncreaseCard;
