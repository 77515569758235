import { ISearchParams } from 'modules/Filter/types';
import { SearchType } from 'utils/types';

const filterForRequest = (filters: ISearchParams, searchType?: SearchType): ISearchParams => {
  let copy: ISearchParams = {} as ISearchParams;
  for (const key in filters) {
    const k = key as keyof ISearchParams;
    if (
      !(searchType === SearchType.COMPANY && key === 'area') &&
      key !== 'date' &&
      key !== 'page' &&
      key !== 'pageSize' &&
      !(key === 'keywords' && !filters.keywords.length)
    )
      copy = { ...copy, [k]: filters[k] };
  }
  return { ...copy };
};

export default filterForRequest;
