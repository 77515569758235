import { IAxiosResponse, ISort, ObjectValues, UsableLocation } from 'utils/types';
import { ISearchParams } from 'modules/Filter/types';

export interface ICompanySearchStore {
  companies: ICompaniesListView | null;
  getCompaniesListView(
    params: ISearchParams,
    // orders: ISort[],
    controller?: AbortController
  ): Promise<void>;
}

export const COMPANY_SEARCH_SERVICE = 'COMPANY_SEARCH_SERVICE';
export interface ICompanySearchService {
  getCompaniesListView(
    params: ISearchParams,
    // orders: ISort[],
    controller?: AbortController
  ): Promise<ICompaniesListView | null>;
}

export const COMPANY_SEARCH_REPO = 'COMPANY_SEARCH_REPO';
export interface ICompanySearchRepo {
  getCompaniesListView(
    params: ISearchParams,
    // orderParams: ObjectValues<string>,
    controller?: AbortController
  ): Promise<IAxiosResponse<ICompaniesListView>>;
}

export interface ICompaniesListView {
  currentPage: number;
  totalPages: number;
  limit: number;
  hasMore: boolean;
  hasSubscription: boolean;
  showCount: number;
  showTotal: number;
  emptyCounties: number[];
  locations: UsableLocation[];
  data: CompanyData[];
}

export type CompanyData = {
  hasNotes: boolean;
  id: number;
  name: string;
  locationTitle: string;
  projectsCount: number;
  projectsProgress: number;
  projectsValue: number;
  roleType: string;
  favorite: boolean;
};
