import React, { FC, ReactNode, useCallback } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table as RTable } from '@tanstack/table-core/build/lib/types';
import { Button, NewTable, Pagination } from 'components';
import { ClickEvent, ISort } from 'utils/types';
import { ImportData, UploadStatusCode } from '../types';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import MatchModal from './MatchModal';
import classes from '../ListUpload.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { clsx } from 'utils/clsx';

interface IProps {
  data: ImportData[];
  orders: ISort[];
  orderAction: (field: string) => void;
  currentPage: number;
  totalPages: number;
  handlePageClick: (page: number) => void;
  isNewImportPage: boolean;
}
const HistoryTable: FC<IProps> = ({
  data,
  orders,
  orderAction,
  currentPage,
  totalPages,
  handlePageClick,
  isNewImportPage
}) => {
  const { alertStore } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();

  const matchId = params.get('matchId');
  const searchId = params.get('searchId');

  const columnHelper = createColumnHelper<ImportData>();

  const handleOpenMatch = useCallback((matchId: number, searchId: number) => {
    if (!searchId) {
      alertStore.errorAlert(t('listUpload.thisListDeleted'));
      return;
    }
    setParams(`matchId=${matchId}&searchId=${searchId}`);
  }, []);

  // const handleCloseMatch = useCallback(() => {
  //   setParams('');
  //   // setMatchId(null);
  //   // setMatchSearchId(null);
  // }, []);

  const viewSearch = (savedSearchId: number) => {
    if (!savedSearchId) {
      alertStore.errorAlert(t('listUpload.thisListDeleted'));
      return;
    }
    navigate(`/saved-search/${savedSearchId}`);
  };

  const tooltip = (rowId: number): ReactNode => {
    const last = data.length - 1 === rowId;
    return (
      <div
        className={clsx(
          last ? '-top-[68px]' : 'top-8',
          'absolute right-3 mb-6 mr-1 rounded text-dark z-1 hidden group-hover:block'
        )}>
        <div className="flex w-36 flex-col items-center">
          {!last && <div className="clip-bottom h-2 w-4 bg-white"></div>}
          <div className="w-max rounded bg-white shadow-overlay px-6 py-4 text-center text-xs">
            <p className="select-text">
              Data processing is in progress. <br /> You may need to reload the page.
            </p>
          </div>
          {last && <div className="clip-top h-2 w-4 bg-white"></div>}
        </div>
      </div>
    );
  };

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => {
        return <span className="block max-w-md cursor-pointer">{info.getValue()}</span>;
      },
      footer: (info) => info.column.id,
      enableSorting: true
    }),
    columnHelper.accessor('uploaded', {
      header: () => 'Objects Uploaded',
      cell: (info) => {
        return <span className="block min-w-[130px]">{info.renderValue()}</span>;
      },
      footer: (info) => info.column.id,
      enableSorting: true
    }),
    columnHelper.accessor('found', {
      header: () => 'Results Found',
      cell: (info) => {
        return <span className="block min-w-[130px]">{info.getValue()}</span>;
      },
      footer: (info) => info.column.id,
      enableSorting: true
    }),
    columnHelper.accessor(isNewImportPage ? 'createdAtFull' : 'processedAt', {
      header: () => 'Created',
      cell: (info) => {
        return (
          <div className="w-fit">
            <span className="block w-fit text-right">{info.row.original.processedAt}</span>
            <span className="block text-grey-300 text-sm text-right">
              {info.row.original.processedAtTime}
            </span>
          </div>
        );
      },
      footer: (info) => info.column.id,
      enableSorting: true
    }),
    columnHelper.accessor('savedSearchId', {
      header: () => '',
      cell: (info) => {
        return (
          <div className="flex justify-end">
            <div className="relative group flex flex-col items-center justify-center">
              <Button
                disabled={info.row.original.status === UploadStatusCode.POST_PROCESSING}
                type="light"
                className="underline text-sm mr-5 whitespace-nowrap">
                <span onClick={() => viewSearch(info.getValue())}>
                  {t('listUpload.viewSearch')}
                </span>
              </Button>
              {info.row.original.status === UploadStatusCode.POST_PROCESSING &&
                tooltip(Number(info.row.id))}
            </div>
            <div className="relative group flex flex-col items-center justify-center">
              <Button
                disabled={
                  info.row.original.isTotallyProcessed ||
                  info.row.original.status === UploadStatusCode.POST_PROCESSING
                }
                type="secondary"
                className="text-sm leading-4 px-2 whitespace-nowrap"
                onClick={() =>
                  handleOpenMatch(info.row.original.id, info.row.original.savedSearchId)
                }>
                {t('listUpload.approveMatches')}
              </Button>
              {info.row.original.status === UploadStatusCode.POST_PROCESSING &&
                tooltip(Number(info.row.id))}
            </div>
          </div>
        );
      },
      enableSorting: false,
      footer: (info) => info.column.id
    })
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const onPageClick = useCallback(
    (event: ClickEvent) => {
      handlePageClick(event.selected + 1);
    },
    [handlePageClick]
  );

  return (
    <div>
      <NewTable
        tableClass={classes.listUploadTable}
        table={table as RTable<unknown>}
        orders={orders}
        orderAction={orderAction}
      />
      {matchId && searchId && <MatchModal />}
      {!isNewImportPage && (
        <div className="flex">
          <Pagination onPageClick={onPageClick} currentPage={currentPage} totalPages={totalPages} />
        </div>
      )}
    </div>
  );
};

export default HistoryTable;
