import React, { FC, useCallback } from 'react';
import { Button, Modal } from 'components';
import { ReactComponent as TickIcon } from 'assets/icons/circle-tick.svg';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  modal: 'w-full max-w-[611px] max-h-[611px] pb-10',
  title: 'pt-4 !text-3xl text-center'
};

interface IProps {
  onCloseModal: () => void;
  onReviewNow: () => void;
  onReviewLater: () => void;
}
const ViewResultsModal: FC<IProps> = ({ onCloseModal, onReviewNow, onReviewLater }) => {
  const { listUploadStore } = useStore();
  const { t } = useTranslation();

  const handleReviewNow = useCallback(() => {
    onCloseModal();
    onReviewNow();
  }, [onCloseModal, onReviewNow]);

  const handleReviewLater = useCallback(() => {
    onCloseModal();
    onReviewLater();
  }, [onReviewLater, onCloseModal]);

  return (
    <Modal
      show={true}
      closeModal={onCloseModal}
      title={`New List Created From Upload`}
      classModal={modalStyle}
      hideFooter={true}>
      <div className="flex flex-col items-center">
        <TickIcon className="my-6" />
        <h3 className="mb-3 text-dark text-lg">{t('listUpload.listUploadedSuccessfully')}</h3>
        <p className="mb-1.5 text-grey-500 text-base">
          {listUploadStore.uploadData?.found} {t('listUpload.resultsFound')}.
        </p>
        <p
          className="mb-13 text-primary text-base underline cursor-pointer"
          onClick={handleReviewNow}>
          {listUploadStore.uploadData?.needToMerge} {t('listUpload.needReview')}
        </p>
        <div className="flex justify-between">
          <Button
            type="secondary"
            className="mr-7.5 px-8 border-primary !text-primary"
            onClick={handleReviewLater}>
            {t('listUpload.reviewLater')}
          </Button>
          <Button type="primary" className="px-9" onClick={handleReviewNow}>
            {t('listUpload.reviewNow')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewResultsModal;
