import {
  IContact,
  IAxiosResponse,
  EntityType,
  Detail,
  entityTypesValues,
  PersonDetail,
  Location,
  AllRoles
} from 'utils/types';

export type ContactById = {
  id: number;
  type: EntityType;
  name: string;
  details: Detail[];
  favourite: boolean;
};

export const CONTACT_INFO_REPO = 'CONTACT_INFO_REPO';
export interface IContactInfoRepo {
  getContactById(id: number, type: entityTypesValues): Promise<IAxiosResponse<ContactById>>;
  getCompany(
    projectId: number,
    role: string,
    contactId: number
  ): Promise<IAxiosResponse<ICompanyResponse>>;
  getCompanyContactDetailsById(contactId: number): Promise<IAxiosResponse<ICompanyResponse>>;
}

export const CONTACT_INFO_SERVICE = 'CONTACT_INFO_SERVICE';
export interface IContactInfoService {
  getContactById(id: number, type: entityTypesValues): Promise<ContactById>;
  getCompany(projectId: number, role: string, contactId: number): Promise<ICompanyResponse>;
  getCompanyContactDetailsById(contactId: number): Promise<ICompanyResponse>;
}

export interface IContactInfoStore {
  contact: IContact | null;
  company: ICompanyResponse | null;
  getContactById(id: number, type: entityTypesValues, role: AllRoles): Promise<void>;
  getCompany(projectId: number, role: string, contactId: number): Promise<void>;
  getCompanyContactDetailsById(contactId: number): Promise<void>;
}

export interface ICompanyResponse {
  id: number;
  name: string;
  description: string;
  details: PersonDetail[];
  location: Location;
  projectPersons: IContact<PersonDetail>[];
  otherPersons: IContact<PersonDetail>[];
  favorite: boolean;
}
