import React, { FC, useEffect } from 'react';
import { Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { ACCESS_TOKEN, SHARE, SHARE_TOKEN } from 'utils/constants';
import { SharedEntityType } from '../modules/ShareModal/types';
import { setLocal } from 'utils/localStorageOperation';

export interface IProps {
  children: JSX.Element;
}

const Private: FC<IProps> = observer(({ children }) => {
  const { authStore, shareStore, userStore } = useStore();
  // const [shareToken, setShareToken] = useState<string | null>(null);
  const [, setParams] = useSearchParams();
  const location = useLocation();

  const pathAvailableToTheGuest = 'search-projects';

  useEffect(() => {
    if (authStore.isRedirected) {
      setParams('auth=sign-in');
      authStore.setIsRedirected(false);
    }
  }, [authStore.isRedirected]);

  const urlContaining = location.pathname.split('/');

  if (urlContaining.includes('saved-search') && urlContaining.includes('share')) {
    shareStore.setShareToken(urlContaining[3], SharedEntityType.SAVED_SEARCH);
  }

  if (!userStore.user && !urlContaining.includes(pathAvailableToTheGuest)) {
    if (urlContaining[2] === SHARE) {
      setLocal(SHARE_TOKEN, urlContaining[4]);
    }
    localStorage.removeItem(ACCESS_TOKEN);
    return <Navigate to="/?auth=sign-in" replace />;
  }

  if (urlContaining.includes(pathAvailableToTheGuest)) {
    if (urlContaining.length === 4 && (!urlContaining[2].length || !urlContaining[3].length)) {
      return <Navigate to="/saved-search" replace />;
    }
  }

  return children;
});

export default Private;
