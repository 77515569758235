import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import { FoundLocation } from 'view/Search/types';
import SearchItem from './SearchItem';
import { ShortLocation } from 'modules/SubscriptionLocationDropdown/types';

interface Props {
  subscriptionCount: number;
  subscriptionLocation: FoundLocation[] | ShortLocation[];
  selectedLocation: FoundLocation[] | ShortLocation[];
  styleTop?: string;
  wrap?: boolean;
  onChange: (item: FoundLocation | ShortLocation, replace?: boolean) => void;
}

const SubscriptionList: FC<Props> = ({
  subscriptionCount,
  subscriptionLocation,
  selectedLocation,
  styleTop,
  wrap,
  onChange
}) => {
  return (
    <ul
      className={clsx('w-full', 'p-0', 'pe-0', 'm-0', 'list-none', subscriptionCount > 0 && 'pb-2')}
      style={{ top: `${styleTop}px` }}
      data-test-element="search-list">
      {subscriptionLocation.map((item: FoundLocation | ShortLocation, idx) => (
        <SearchItem
          item={item}
          key={`search-item-${idx}-${item.id}-${item.targetTitle
            ?.toLowerCase()
            .split(' ')
            .join('')}`}
          handleClick={onChange}
          selectedLocationIds={selectedLocation.map((location) => location.id)}
          subscription={true}
          wrap={wrap}
        />
      ))}
    </ul>
  );
};

export default SubscriptionList;
