import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classes from './ChoosePlanModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from 'components';
import PlanCard from './components/PlanCard';
import closeIcon from 'assets/icons/close.svg';
import { useStore } from 'storesProvider/storeContext';
import { PaymentType, Plans, Product, ShortLocationForPayment } from '../types';
import { observer } from 'mobx-react';
import Payment from 'view/PaymentForm';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  show: boolean;
  closeModal: () => void;
  locations: ShortLocationForPayment[] | null;
  onUpdate?: () => void;
}

const ChoosePlanModal: FC<IProps> = observer(({ show, closeModal, locations, onUpdate }) => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const { subscriptionAndPlansStore, userStore } = useStore();
  const [loadPlans, setLoadPlans] = useState<boolean>(false);
  const [activePlan, setActivePlan] = useState<Plans | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const windowHeight = window.innerHeight;

  const clickOutside = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (e.target === e.currentTarget && !subscriptionAndPlansStore.paymentProcessing) closeModal();
  }, []);

  const toggleType = useCallback((product: Product) => {
    setSelectedProduct(product);
  }, []);

  useEffect(() => {
    if (locations && locations.length && show) {
      (async () => {
        setLoadPlans(true);
        if (!userStore.user) {
          params.set('auth', 'sign-in');
          setParams(params);
          return;
        }
        const hasSubscription = await subscriptionAndPlansStore.checkHasSubscriptionToLocation(
          locations
        );
        if (hasSubscription) {
          closeModal();
          return;
        }
        await subscriptionAndPlansStore.getProductsByLocation(locations);
        initSelectedPlan();
        setLoadPlans(false);
      })();
      return;
    }
    if (show) {
      closeModal();
    }
  }, [show, locations, userStore.user]);

  const initSelectedPlan = useCallback((): void => {
    setActivePlan(subscriptionAndPlansStore.monthProducts.length ? Plans.MONTH : Plans.YEAR);
    setSelectedProduct(getProductByActivePlan[0]);
  }, [subscriptionAndPlansStore.monthProducts, subscriptionAndPlansStore.yearProducts]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      if (prev) {
        const newTabPlan = getProductByActivePlan.find((plan) => plan.unlimited === prev.unlimited);
        return newTabPlan ? newTabPlan : getProductByActivePlan[0];
      }
      return getProductByActivePlan[0];
    });
  }, [activePlan]);

  useEffect(() => {
    if (selectedProduct) {
      subscriptionAndPlansStore.createPaymentData(selectedProduct, locations);
    }
  }, [selectedProduct, locations]);

  const getProductByActivePlan = useMemo((): Product[] => {
    if (activePlan === Plans.MONTH) {
      return subscriptionAndPlansStore.monthProducts;
    }
    if (activePlan === Plans.YEAR) {
      return subscriptionAndPlansStore.yearProducts;
    }
    return [];
  }, [activePlan, subscriptionAndPlansStore.monthProducts, subscriptionAndPlansStore.yearProducts]);

  useEffect(() => {
    setSelectedProduct(getProductByActivePlan[0]);
  }, [getProductByActivePlan]);

  const onClose = (): void => {
    if (!subscriptionAndPlansStore.paymentProcessing) {
      closeModal();
    }
  };

  const handlePurchase = (): void => {
    if (onUpdate) {
      onUpdate();
    }

    closeModal();
  };

  const planCardNames = useCallback((): string => {
    if (locations && locations.length) {
      return locations.map((location) => location.title).join(', ');
    }
    return '';
  }, [locations]);

  return (
    <div
      className={clsx(
        'fixed',
        'inset-0',
        'flex',
        'items-center',
        'justify-center',
        show ? 'block' : 'hidden',
        classes.modal
      )}
      onClick={clickOutside}>
      <div
        className={clsx(
          classes.body,
          'py-7.5',
          'px-10',
          'relative',
          windowHeight < 768 && classes.bodyScroll
        )}
        style={{ maxHeight: windowHeight }}>
        <div className={clsx(classes.close, 'absolute')} role="button" onClick={onClose}>
          <img src={closeIcon} alt="" />
        </div>
        <h2 className="mb-2 text-dark text-3xl text-center">{t('choosePlan.title')}</h2>
        <p className="mb-2 text-grey-500 text-base text-center">{t('choosePlan.subTitle')}</p>
        <div className="flex items-center justify-center mb-4">
          {!!subscriptionAndPlansStore.monthProducts.length && (
            <Button
              type={activePlan === Plans.MONTH ? 'primary' : 'secondary'}
              onClick={() => setActivePlan(Plans.MONTH)}
              className={clsx(
                activePlan === Plans.MONTH ? 'text-white' : 'bg-white !text-grey-500',
                'text-sm',
                'shadow-paymentNavBtn',
                'rounded-sm',
                'px-3'
              )}>
              {t('choosePlan.monthly')}
            </Button>
          )}
          {!!subscriptionAndPlansStore.yearProducts.length && (
            <Button
              type={activePlan === Plans.YEAR ? 'primary' : 'secondary'}
              onClick={() => setActivePlan(Plans.YEAR)}
              className={clsx(
                activePlan === Plans.YEAR ? 'text-white' : 'bg-white !text-grey-500',
                'text-sm',
                'shadow-paymentNavBtn',
                'rounded-sm',
                'px-3'
              )}>
              {t('choosePlan.annually')}
            </Button>
          )}
          <span className={clsx('ml-2', classes.save)}>{t('choosePlan.save')}</span>
        </div>
        <div className="flex justify-between">
          {loadPlans && <Skeleton type="plan" itemsToShow={2} />}
          {!loadPlans &&
            selectedProduct &&
            getProductByActivePlan.map((product) => (
              <PlanCard
                key={product.id}
                active={selectedProduct.id === product.id}
                product={product}
                onToggle={toggleType}
                locationName={planCardNames()}
              />
            ))}
        </div>
        <div className="mt-3">
          {selectedProduct && subscriptionAndPlansStore.paymentData ? (
            <Payment direction="row" type={PaymentType.NEW} onPurchase={handlePurchase} />
          ) : (
            <div className="w-full flex justify-center">
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ChoosePlanModal;
