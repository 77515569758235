import React, { FC, ReactNode, useCallback, MouseEvent } from 'react';
import classes from './Tab.module.scss';
import { ITabsItems } from './types';
import { clsx } from 'utils/clsx';
import { ENTER } from '../SearchList/constants';

interface Props {
  items: ITabsItems[];
  mode: string;
  navClass?: string;
  action: (tabId: number) => void;
  stopPropagation?: boolean;
  children?: ReactNode;
}

const Tab: FC<Props> = ({ items, mode, navClass, action, stopPropagation, children }) => {
  const showBadge = (badge: number): ReactNode => {
    return (
      !!badge && (
        <span className="bg-primary min-w-[16px] h-4 rounded-3xl p-[3px] text-xs ml-2 px-1 flex items-center justify-center text-white">
          {badge}
        </span>
      )
    );
  };

  const handleTabClick = useCallback(
    (e: MouseEvent, id: number) => {
      if (stopPropagation) e.stopPropagation();
      action(id);
    },
    [action]
  );

  const handlePressEnter = useCallback((e: React.KeyboardEvent, id: number) => {
    if (e.code === ENTER && document.activeElement === e.target)
      handleTabClick(e as unknown as MouseEvent, id);
  }, []);

  const listItem = (items: ITabsItems[]) => {
    return items.map((item: ITabsItems, index: number) => {
      return (
        <li
          className={clsx(
            'nav-item',
            'text-center',
            'nav-link',
            'flex',
            'items-center',
            'justify-center',
            'mr-4',
            'cursor-pointer',
            'py-2',
            'px-2.5',
            'text-xs',
            'leading-7',
            'uppercase',
            'last:m-0',
            item.active && classes.active,
            classes[mode + 'NavLink']
          )}
          tabIndex={0}
          onKeyDown={(e) => handlePressEnter(e, item.tabId)}
          data-test-element={item.attr}
          onClick={(e) => handleTabClick(e, item.tabId)}
          key={`tab-` + index}>
          <span className="font-halbfett">{item.name}</span>
          {showBadge(item.badge || 0)}
        </li>
      );
    });
  };
  return (
    <div className={clsx('flex w-full justify-between', navClass)}>
      <ul className={clsx('flex')}>{items.length && listItem(items)}</ul>
      {children}
    </div>
  );
};
export default Tab;
