import { AllEntityTypes, IAxiosResponse, ObjectValues } from 'utils/types';
import { ISort } from 'utils/types';

export const LIST_UPLOAD_REPO = 'LIST_UPLOAD_REPO';
export const LIST_UPLOAD_SERVICE = 'LIST_UPLOAD_SERVICE';

export interface IListUploadRepo {
  uploadFile(file: FormData): Promise<IAxiosResponse<UploadResult>>;
  getStatusLastUploadedFile(): Promise<IAxiosResponse<UploadResult>>;
  abortUploadedFileProcess(): Promise<IAxiosResponse<UploadResult>>;
  retrieveContactsListByImportId(
    importFileId: number,
    page: number
  ): Promise<IAxiosResponse<MatchesResponseData>>;
  mergeContactFromFileImport(data: MatchOperationData[]): Promise<IAxiosResponse<void>>;
  getImportHistoryData(
    page: number,
    orderParams: ObjectValues<string>,
    perPage?: number
  ): Promise<IAxiosResponse<IImportHistory>>;
}

export interface IListUploadService {
  uploadFile(file: File): Promise<UploadResult>;
  getStatusLastUploadedFile(): Promise<UploadResult>;
  abortUploadedFileProcess(): Promise<UploadResult>;
  retrieveContactsListByImportId(importFileId: number, page: number): Promise<MatchesResponseData>;
  mergeContactFromFileImport(data: MatchOperationData[]): Promise<void>;
  getImportHistoryData(page: number, orders: ISort[], perPage?: number): Promise<IImportHistory>;
}

export interface IListUploadStore {
  uploadFile(file: File): Promise<void>;
  getStatusLastUploadedFile(): Promise<void>;
  abortUploadedFileProcess(): Promise<void>;
  retrieveContactsListByImportId(importFileId: number, page: number): Promise<void>;
  mergeContactFromFileImport(): Promise<void>;
  getImportHistoryData(): Promise<void>;
}

export type UploadResult = {
  status: UploadStatusCode;
  process: number;
  info: string;
  crossUploadId: number;
  isTotallyProcessed: boolean;
  found: number;
  uploaded: number;
  needToMerge: number;
  savedSearchId: number;
};

export enum UploadStatusCode {
  NEW = 10,
  PENDING = 20,
  IN_PROGRESS = 30,
  DONE = 40,
  FAIL = 50,
  ABORT = 60,
  START = 70,
  POST_PROCESSING = 80
}

export type MatchesResponseData = {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: MatchesData[];
};

export type MatchesData = {
  userContactId: number;
  merged: boolean;
  name: string;
  locationTitle: string;
  data: MatchesDataResult[];
};

export type MatchesDataResult = {
  locationTitle: string;
  contactId: number;
  name: string;
  type: AllEntityTypes;
};

export type ImportData = {
  id: number;
  status: UploadStatusCode;
  createdAt: string;
  createdAtTime: string;
  processedAt: string;
  processedAtTime: string;
  createdAtFull: string;
  found: number;
  uploaded: number;
  name: string;
  isTotallyProcessed: boolean;
  savedSearchId: number;
};

export type ImportDataKeys = keyof ImportData;

export interface IImportHistory {
  orders: ISort[];
  currentPage: number;
  totalPages: number;
  limit: number;
  data: ImportData[];
}

export type MatchOperationData = {
  userContactId: number;
  contactId: number;
  operation: MatchOperation;
  type: AllEntityTypes;
};

export type MatchOperation = 'accept' | 'decline';

export const SPECIAL_DELAY = 1500;
export const PRIMARY_DELAY = 1500;
export const MAXIMUM_DELAY = 10000;
export const FILE_SIZE_MAX = 4000000;

export const UNRECOGNIZED_STATE = 'unrecognisedState';
