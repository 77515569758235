import { ISubscriptionLocationDropdownRepo, SubscribedLocationsResponse } from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class SubscriptionLocationDropdownRepo implements ISubscriptionLocationDropdownRepo {
  getSubscribedLocations(
    page: number,
    pageSize: number,
    searchId?: number | null
  ): Promise<IAxiosResponse<SubscribedLocationsResponse>> {
    return axiosInstance.get('/locations/subscribed-locations', {
      params: { page, pageSize, searchId }
    });
  }
}
