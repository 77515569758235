import React, { FC } from 'react';
import Filter from 'modules/Filter';
import { FilterState } from 'modules/Filter/types';

interface Props {
  onSaveSearch: (name: string) => void;
}

const SubHeader: FC<Props> = ({ onSaveSearch }) => {
  return (
    <div className="text-dark relative z-[5]">
      <div className="py-1">
        <Filter type={FilterState.COMPANY_SEARCH} onSaveSearch={onSaveSearch} />
      </div>
    </div>
  );
};

export default SubHeader;
