import React, { FC } from 'react';
import { clsx } from 'utils/clsx';

interface IProps {
  progress: number;
  thin?: boolean;
}

const ProgressBar: FC<IProps> = ({ progress, thin }) => {
  return (
    <div className={clsx('progress bg-transparent relative', thin && 'thin')}>
      <div
        className={'progress-bar h-1 bg-primary flex items-center flex-row relative'}
        role="progressbar"
        style={{ width: `${progress}%` }}>
        <span className="font-kraftig">&nbsp;</span>
        <span className="font-halbfett">&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

export default ProgressBar;
