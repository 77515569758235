import {
  ExportData,
  HasSubscriptionLocation,
  ISavedSearch,
  ISavedSearchService,
  ISavedSearchStore,
  SAVED_SEARCH_SERVICE,
  Status
} from './types';
import { ISearchParams } from 'modules/Filter/types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { FoundLocation } from 'view/Search/types';
import { defaultExportData } from './mock';
import { PRIMARY_DELAY, MAXIMUM_DELAY } from './constants';
import { SearchType } from 'utils/types';

export class SavedSearchManageStore implements ISavedSearchStore {
  private _savedSearchService: ISavedSearchService =
    injector.get<ISavedSearchService>(SAVED_SEARCH_SERVICE);

  savedSearches: ISavedSearch[] = [];
  newProjectsCount = 0;
  exportData: ExportData = defaultExportData;
  subscribeLocations: HasSubscriptionLocation[] = [];

  constructor() {
    makeAutoObservable<SavedSearchManageStore>(this);
  }

  setSavedSearch(searches: ISavedSearch[] | ISavedSearch): void {
    if (Array.isArray(searches)) {
      this.savedSearches = searches;
      return;
    }
    if (this.getSavedSearchById(searches.id)) {
      return;
    }
    this.savedSearches = [searches].concat(this.savedSearches);
  }

  setSharedSavedSearches(savedSearch: ISavedSearch): void {
    if (this.getSavedSearchById(savedSearch.id)) {
      return;
    }
    this.savedSearches.push(savedSearch);
  }

  setNewProjectsCount(count: number): void {
    this.newProjectsCount = count;
  }

  setExportData(data: ExportData): void {
    this.exportData = data;
  }

  setSubscribeLocations(subscribeLocations: HasSubscriptionLocation[]): void {
    this.subscribeLocations = subscribeLocations;
  }

  getSavedSearchById(id: number | null): ISavedSearch | null {
    if (!id) return null;
    if (this.savedSearches.length) {
      return this.savedSearches.find((item: ISavedSearch) => item.id === id) as ISavedSearch;
    }
    return null;
  }

  async delete(userId: number, searchId: number): Promise<void> {
    await this._savedSearchService.delete(userId, searchId);
  }

  async deleteShareSavedSearch(userId: number, searchId: number): Promise<void> {
    await this._savedSearchService.deleteShareSavedSearch(userId, searchId);
  }

  async updateTitle(userId: number, searchId: number, name: string): Promise<void> {
    const editedSearch = await this._savedSearchService.updateTitle(userId, searchId, name);
    this.setSavedSearch([
      ...this.savedSearches.filter((item) => item.id !== editedSearch.id),
      editedSearch
    ]);
  }

  async clone(userId: number, searchId: number): Promise<void> {
    this.setSavedSearch(await this._savedSearchService.clone(userId, searchId));
    // try {
    //   this.setSavedSearch(await this._savedSearchService.clone(userId, searchId));
    // } catch (e) {
    //   console.log('Error clone: ', e);
    // }
  }

  async hasSubscription(locations: number[]): Promise<void> {
    this.setSubscribeLocations(await this._savedSearchService.hasSubscription(locations));
  }

  async getSavedSearches(id: number): Promise<void> {
    this.setSavedSearch(await this._savedSearchService.getSavedSearches(id));
  }

  async saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<ISavedSearch> {
    return await this._savedSearchService.saveSearch(id, name, filters, searchType);
  }

  async getNewProjectCount(userId: number, searchType: SearchType): Promise<void> {
    this.setNewProjectsCount(await this._savedSearchService.getNewProjectCount(userId, searchType));
  }

  async getLocationsInfo(locationIds: number[]): Promise<FoundLocation[]> {
    return await this._savedSearchService.getLocationsInfo(locationIds);
  }

  async runExport(userId: number, searchId: number): Promise<void> {
    await this._savedSearchService.runExport(userId, searchId);
  }

  async getExportFile(userId: number, searchId: number): Promise<void> {
    let attempt = 0;
    let delay = 0;
    let result = defaultExportData;

    const hasExportData = async (): Promise<ExportData> => {
      if (attempt <= 45) {
        attempt += 1;
        await this.wait(delay);
        if (attempt === 1) {
          delay = PRIMARY_DELAY;
        }
        if (attempt > 1 && delay < MAXIMUM_DELAY) {
          delay *= 2;
        }
        result = await this._savedSearchService.getExportFile(userId, searchId);

        if (result.status === Status.DONE || result.status === null) {
          return result;
        }

        if (result.status === Status.PENDING) {
          await hasExportData();
        }
      }
      return result;
    };
    this.setExportData(await hasExportData());
  }

  async wait(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }
}
