import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { IFullProject, IPageClickEvent } from './types';
import { useStore } from 'storesProvider/storeContext';
import logoDark from 'assets/icons/logo-light.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { IFavoriteEntity } from 'view/Favorites/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';
import { ITabsItems } from 'components/Tab/types';
import { ProjectList, Skeleton, Tab } from 'components';
import Details from './components/Details';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { RelatedProjectType } from 'view/SearchProjects/types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Map from './components/Map';
import arrowIcon from 'assets/icons/arrow.svg';
import { AllEntityTypes, AllRoles, IContact, Location, PersonDetail } from 'utils/types';
import ShareModal from 'modules/ShareModal';
import { SharedEntityType } from 'modules/ShareModal/types';
import { mockUsers } from 'view/SearchProjects/mock';

interface IProps {
  onFavorite?: (id: number, isActive: boolean) => void;
  onHandleUnlock?: (locations: Location[] | null) => void;
}

const ProjectInfoModal: FC<IProps> = observer(({ onFavorite, onHandleUnlock }) => {
  const { projectSearchStore, configStore, userStore } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const id = Number(params.get('details'));
  const { projectId } = useParams();

  const [showFavoriteHeader, setShowFavoriteHeader] = useState<boolean>(false);
  const [showFavoriteDetails, setShowFavoriteDetails] = useState<boolean>(false);
  const [isInfoLoading, setIsInfoLoading] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [project, setProject] = useState<IFullProject | null>(null);
  const [shareId, setShareId] = useState<number | null>(null);
  const [tabs, setTabs] = useState<ITabsItems[]>([
    {
      name: t('components.projectInfo.details'),
      active: true,
      tabId: 1
    },
    {
      name: t('components.projectInfo.open'),
      active: false,
      tabId: 2
    },
    {
      name: t('components.projectInfo.closed'),
      active: false,
      tabId: 3
    }
  ]);

  const entity = useMemo<IFavoriteEntity | null>(() => {
    if (!project) return null;
    const projectClass = configStore.enums?.propertyClasses.find(
      (cl) => cl.id === project.propertyClass.id
    );
    const projectType = configStore.enums?.propertySubTypes.find(
      (type) => type.id === project.propertySubType.id
    );
    const entity: IFavoriteEntity = {
      id: project.id,
      coordinates: {} as DOMRect,
      type: AllEntityTypes.Project,
      role: AllRoles.Project,
      key: 'projects',
      name: `${projectClass?.name} - ${projectType?.name}`
    };
    return entity;
  }, [project]);

  const activeTab = useMemo<number | null>(() => {
    return tabs.find((tab) => tab.active)?.tabId || null;
  }, [tabs]);

  const clickOutside = (e: React.MouseEvent): void => {
    const favoriteModals = document.querySelector('#modal-root')?.children.length;
    if (!favoriteModals) e.stopPropagation();
    if (e.target === e.currentTarget) {
      e.stopPropagation();
      setShowFavoriteHeader(false);
      setShowFavoriteDetails(false);
      setParams('');
    }
  };

  const closeFavorite = useCallback(() => {
    setShowFavoriteHeader(false);
    setShowFavoriteDetails(false);
  }, []);

  const closeFavoriteInList = useCallback(() => {
    projectSearchStore.setActiveFavorite(null);
  }, []);

  const openShareModal = useCallback(() => {
    closeFavorite();
    setShareId(id);
  }, []);

  const closeShareModal = useCallback(() => {
    setShareId(null);
  }, []);

  const unlockLocation = useCallback((): void => {
    if (!userStore.user) {
      return setParams(`details=${id}&auth=sign-up&extended=true`);
    }
    if (project && onHandleUnlock) {
      onHandleUnlock([project.location.county]);
    }
  }, [onHandleUnlock, project, userStore.user]);

  const openFavoriteModal = useCallback(
    (e: React.MouseEvent, type: 'header' | 'details'): void => {
      if (!project?.hasSubscription) return unlockLocation();
      const thisModal = document.getElementById(
        `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
      );
      type === 'header' ? setShowFavoriteHeader(!thisModal) : setShowFavoriteDetails(!thisModal);
      if (thisModal) {
        e.stopPropagation();
      }
      if (!entity) return;
      projectSearchStore.setActiveFavorite(
        thisModal ? null : { id: entity.id, type: 'fullInfoModal' }
      );
    },
    [entity]
  );

  const handleFavorite = useCallback(
    (isActive: boolean, id: number): void => {
      if (onFavorite) {
        onFavorite(id, isActive);
      }
      projectSearchStore.updateFavoriteProjects(isActive, id);
      setIsFavorite(isActive);
    },
    [project]
  );

  const isModalHeaderShown = useMemo<boolean>(() => {
    return (
      showFavoriteHeader &&
      projectSearchStore.activeFavorite?.id === id &&
      projectSearchStore.activeFavorite?.type === 'fullInfoModal'
    );
  }, [entity, projectSearchStore.activeFavorite, showFavoriteHeader]);

  const isModalDetailsShown = useMemo<boolean>(() => {
    return (
      showFavoriteDetails &&
      projectSearchStore.activeFavorite?.id === id &&
      projectSearchStore.activeFavorite?.type === 'fullInfoModal'
    );
  }, [entity, projectSearchStore.activeFavorite, showFavoriteDetails]);

  const closeProjectFavoriteModal = useCallback(() => {
    setShowFavoriteHeader(false);
    setShowFavoriteDetails(false);
  }, []);

  const handleTabChange = useCallback(
    (tabId: number) => {
      if (!project?.hasSubscription) return unlockLocation();
      setTabs((prev) => prev.map((tab) => ({ ...tab, active: tab.tabId === tabId })));
    },
    [project]
  );

  const handleTitleClick = useCallback(() => {
    setTabs((prev) => prev.map((tab) => ({ ...tab, active: tab.tabId === 1 })));
  }, []);

  const handlePageClick = useCallback(async ({ id, type, page }: IPageClickEvent) => {
    if (!id) return;
    await projectSearchStore.loadMore(id, type as RelatedProjectType, page);
  }, []);

  useLayoutEffect(() => {
    (async () => {
      try {
        setIsInfoLoading(true);
        const sharedProject: IFullProject | null = projectSearchStore.sharedProjectData
          ? {
              ...projectSearchStore.sharedProjectData,
              contacts: projectSearchStore.sharedProjectData?.hasSubscription
                ? [...projectSearchStore.sharedProjectData.contacts]
                : mockUsers
            }
          : null;
        const project =
          id === Number(projectId) ? sharedProject : await projectSearchStore.getAllProjectInfo(id);
        setProject(project);
        setIsFavorite(project?.favourite ?? false);
        if (project?.hasSubscription) {
          await projectSearchStore.getRelatedProjects(id);
        }
      } catch (e) {
        setParams('');
      } finally {
        setIsInfoLoading(false);
      }
    })();
  }, [id, projectSearchStore.sharedProjectData, projectId]);

  useLayoutEffect(() => {
    setTabs((prev) =>
      prev.map((tab) => {
        if (tab.tabId === 2) return { ...tab, badge: projectSearchStore.relatedOpenProjects.count };
        if (tab.tabId === 3)
          return { ...tab, badge: projectSearchStore.relatedClosedProjects.count };
        return tab;
      })
    );
  }, [projectSearchStore.relatedOpenProjects, projectSearchStore.relatedClosedProjects]);

  useEffect(() => {
    return () => projectSearchStore.resetRelatedProjects();
  }, []);
  const back = () => navigate(-1);

  const handleContact = (contact: IContact<PersonDetail>, projectId: number): void => {
    setParams(
      `contacts=${contact.id}&type=${contact.type}&role=${contact.role}&name=${contact.name}&favourite=${contact.favourite}&project=${projectId}`
    );
  };

  return (
    <div
      className="absolute inset-0 flex justify-end bg-shadesBlue-400 bg-opacity-[.21] z-10"
      onClick={clickOutside}>
      <div className="w-[656px] h-screen bg-white">
        <div className="flex p-3 justify-between">
          <img src={logoDark} alt="" />
          <div className="flex items-center">
            <div className="flex items-center mr-4" role="button">
              {entity && (
                <span className="mr-1">
                  <AddToFavorites
                    onHeartClick={(e) => openFavoriteModal(e, 'header')}
                    closeModal={closeFavorite}
                    entity={entity}
                    toggleCallback={handleFavorite}
                    isFavorite={isFavorite}
                    isModalShown={isModalHeaderShown}
                  />
                </span>
              )}
              <h4
                onClick={(e) => openFavoriteModal(e, 'header')}
                className="mb-0 text-dark uppercase text-xs">
                {t('components.projectInfo.save')}
              </h4>
            </div>
            <div className="flex items-center" role="button" onClick={openShareModal}>
              <ShareIcon
                className="mr-1 -translate-y-[3px]"
                data-test-element="share-icon"
                role="button"
              />
              <h4 className="mb-0 text-dark uppercase text-xs">
                {t('components.projectInfo.share')}
              </h4>
            </div>
            <div className="leading-none ml-[29px] -translate-y-[2px]">
              <svg
                onClick={() => setParams('')}
                role="button"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11" stroke="#2F3139" strokeWidth="1.4" strokeLinejoin="round" />
                <path d="M11 1L1 11" stroke="#2F3139" strokeWidth="1.4" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div className="bg-light-400 h-px" />
        {project && !isInfoLoading ? (
          <>
            <div className="p-3">
              <div className="flex">
                <img
                  role="button"
                  src={arrowIcon}
                  alt=""
                  className="-rotate-90 mr-2"
                  onClick={back}
                />
                <h2 className="text-xl text-dark select-text whitespace-nowrap text-ellipsis overflow-hidden">
                  {project.location.address.title || ''}
                  {project.location.city?.title && `, ${project.location.city.title}`}
                  {project.location.state ? `, ${project.location.state.title}` : ''}
                  {project.location.zip ? `, ${project.location.zip.title}` : ''}
                </h2>
              </div>
              <p className="text-grey-500 text-sm m-0">
                {projectSearchStore.relatedOpenProjects.count}{' '}
                {t('components.projectInfo.openPermits')}
              </p>
              <div className="mt-3">
                <Map location={project.location.address} />
              </div>
            </div>
            <div className="bg-light-400 h-px" />
            <Tab items={tabs} mode="light" action={handleTabChange} navClass="px-3" />
            <div className="bg-light-400 h-px" />
            {activeTab === 1 && (
              <Details
                project={project}
                onScroll={closeProjectFavoriteModal}
                onContacts={handleContact}
                onUnlock={unlockLocation}>
                {entity && (
                  <AddToFavorites
                    onHeartClick={(e) => openFavoriteModal(e, 'details')}
                    closeModal={closeFavorite}
                    entity={entity}
                    toggleCallback={handleFavorite}
                    isFavorite={isFavorite}
                    isModalShown={isModalDetailsShown}
                  />
                )}
              </Details>
            )}
            {activeTab === 2 && (
              <div>
                <ProjectList
                  type="openProjects"
                  onTitleClick={handleTitleClick}
                  projectId={id}
                  isLoading={false}
                  itemSize={155}
                  hideContacts
                  onPageClick={handlePageClick}
                  projectsData={projectSearchStore.relatedOpenProjects}
                  onListScroll={closeFavoriteInList}
                  listWithMap={true}
                />
              </div>
            )}
            {activeTab === 3 && (
              <div>
                <ProjectList
                  type="closedProjects"
                  onTitleClick={handleTitleClick}
                  projectId={id}
                  isLoading={false}
                  itemSize={155}
                  hideContacts
                  onPageClick={handlePageClick}
                  projectsData={projectSearchStore.relatedClosedProjects}
                  onListScroll={closeFavoriteInList}
                  listWithMap={true}
                />
              </div>
            )}
          </>
        ) : (
          <Skeleton type="company" itemsToShow={1} />
        )}
      </div>
      <ShareModal
        entityType={SharedEntityType.SINGLE_PERMIT}
        isShowWithId={shareId}
        closeModal={closeShareModal}
        location={project?.location}
      />
    </div>
  );
});

export default ProjectInfoModal;
