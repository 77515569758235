import {
  ICompanySearchService,
  ICompanySearchRepo,
  COMPANY_SEARCH_REPO,
  ICompaniesListView
} from './types';
import { injector } from 'utils/injector';
import { ISearchParams } from 'modules/Filter/types';

const copyParams = (params: ISearchParams): ISearchParams => {
  let copy: ISearchParams = {} as ISearchParams;
  for (const key in params) {
    const k = key as keyof ISearchParams;
    if (!(key === 'keywords' && !params.keywords.length)) copy = { ...copy, [k]: params[k] };
  }
  if (copy.companyName && !copy.companyName.length) {
    delete copy.companyName;
  }
  if (copy.companyAddress && !copy.companyAddress.length) {
    delete copy.companyAddress;
  }
  return copy;
};

export class CompanySearchService implements ICompanySearchService {
  private _companySearchRepo: ICompanySearchRepo =
    injector.get<ICompanySearchRepo>(COMPANY_SEARCH_REPO);

  async getCompaniesListView(
    params: ISearchParams,
    // orders: ISort[],
    controller?: AbortController
  ): Promise<ICompaniesListView | null> {
    const res = await this._companySearchRepo.getCompaniesListView(copyParams(params), controller);
    if (!res) return null;
    return res.data.result;
  }
}
