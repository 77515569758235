import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import classes from './Favorites.module.scss';
import { Button, ConfirmModal, Input, Modal, Pagination, UnderConstructionModal } from 'components';
import { SubHeader } from './components/SubHeader';
import { IOption } from 'components/Select/types';
import {
  IFavoriteDataBody,
  FavoritesOptions,
  IFavoriteFilters,
  ModalType,
  FavoriteFormValue
} from './types';
import TableHeader from './components/TableHeader';
import { IDropdownOption } from 'components/Dropdown/types';
import { isNaN } from 'formik';
import { IModalActions } from 'components/Modal/types';
import {
  TYPE,
  DATE,
  LOCATION,
  ALL,
  COMPANY,
  DEBOUNCE_DELAY,
  PERSON,
  PROJECT,
  PROJECT_VALUE,
  PROJECT_COUNT,
  TOTAL_AMOUNT
} from 'utils/constants';
import { Location } from 'utils/types';
import { IFavoriteTabsItems } from 'components/Tab/types';
import { orderProcessing } from 'helpers/orderProcessing';
import { initialFavoriteFilters } from './mock';
import { useDebounce } from 'hooks/useDebounce';
import { usePrevious } from 'hooks/usePrevious';
import { IDropCategories, IDropdownEvent } from '../SearchProjects/components/SubHeader/types';
import ProjectInfoModal from 'modules/ProjectInfoModal/Modal';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';
import { ClickEvent, ISort, AllEntityTypes, EntityType, AllRoles } from 'utils/types';
import Header from 'view/Header';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import ContactInfo from 'modules/ContactInfoModal';
import ProjectsTable from './components/ProjectsTable';
import CompaniesTable from './components/CompaniesTable';
import PersonsTable from './components/PersonsTable';
import { SharedEntityType } from 'modules/ShareModal/types';
import ShareModal from 'modules/ShareModal';
import { ShortLocationForPayment } from '../SubscriptionsAndPlans/types';

const Favorites: FC = observer(() => {
  const { id, pathTab } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchId = Number(id);
  const { pathname } = useLocation();
  const [params, setParams] = useSearchParams();
  const details = params.get('details');
  const [shareId, setShareId] = useState<number | null>(null);
  const { favoritesStore, userStore, alertStore } = useStore();
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [unlockLocation, setUnlockLocation] = useState<ShortLocationForPayment | null>(null);
  const [showUnderConstruction, setShowUnderConstruction] = useState<boolean>(false);
  const [locationOptions, setLocationOptions] = useState<IOption[]>([]);
  const [favoriteFilters, setFavoriteFilters] = useState<IFavoriteFilters>(initialFavoriteFilters);
  const [activeFilters, setActiveFilters] = useState<(keyof IFavoriteFilters)[]>([
    TYPE,
    DATE,
    PROJECT_VALUE,
    LOCATION
  ]);
  const [currentFavorite, setCurrentFavorite] = useState<IDropdownOption | null>(null);
  const [show, setShowModal] = useState<boolean>(false);
  const [confirmModalShow, setConfirmModalShow] = useState<boolean>(false);
  const [favoriteListName, setFavoriteListName] = useState<string>('');
  const [editableFavorite, setEditableFavorite] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalActions, setModalActions] = useState<IModalActions>({
    negative: t('favorites.cancel'),
    positive: t('favorites.save')
  });
  const [modalDeleteBtn, setModalDeleteBtn] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.CREATE);
  const [orders, setOrders] = useState<ISort[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [categories, setCategories] = useState<IDropCategories>({
    classes: [],
    types: [],
    subTypes: []
  });
  const modalRef = useRef<HTMLDivElement | null>(null);

  const debouncedCategories = useDebounce(categories, DEBOUNCE_DELAY * 3);
  const debouncedSearchTerm = useDebounce<string>(searchValue, DEBOUNCE_DELAY);
  const prevSearchValue: string = usePrevious<string>(debouncedSearchTerm);
  const { progress, visible, reload: reloadProgress } = useProgress(isLoading);
  const contacts = params.get('contacts');

  const tabItems = useMemo((): IFavoriteTabsItems[] => {
    return [
      {
        name: 'Projects',
        active: pathTab?.toLowerCase() === PROJECT,
        tabId: 1,
        systemType: PROJECT,
        type: AllEntityTypes.Project,
        attr: 'favorite-tab-projects'
      },
      {
        name: 'Companies',
        active: pathTab?.toLowerCase() === COMPANY,
        tabId: 2,
        systemType: COMPANY,
        type: AllEntityTypes.Company,
        attr: 'favorite-tab-companies'
      },
      {
        name: 'Persons',
        active: pathTab?.toLowerCase() === PERSON,
        tabId: 3,
        systemType: PERSON,
        type: AllEntityTypes.Person,
        attr: 'favorite-tab-persons'
      }
    ];
  }, [pathTab]);

  useLayoutEffect(() => {
    (async () => {
      if (!userStore.user) return;
      await favoritesStore.getFavoritesList(userStore.user.id);
    })();
    if (!pathTab) navigate(`/favorites/${id}/${PROJECT}`, { replace: true });
  }, []);

  useEffect(() => {
    if (isNaN(Number(id)) && id !== ALL) {
      navigate(`/favorites/all/${PROJECT}`, { replace: true });
    }
    return () => {
      favoritesStore.resetCategories();
    };
  }, [pathTab, id]);

  useEffect(() => {
    if (id !== ALL) {
      setCurrentFavorite(favoriteOptions.find((option) => option.id === Number(id)) || null);
    }
  }, [favoritesStore.list, id]);

  useEffect(() => {
    (async () => await getTableData())();
  }, [searchId, pathTab, orders, favoriteFilters, page]);

  useEffect(() => {
    (async () => {
      if (
        (debouncedSearchTerm.length >= 3 && debouncedSearchTerm) ||
        (prevSearchValue && prevSearchValue.length === 3 && debouncedSearchTerm.length < 3) ||
        (debouncedSearchTerm.length < 3 && prevSearchValue.length >= 3)
      ) {
        await getTableData();
      }
    })();
  }, [debouncedSearchTerm]);

  const favoriteBody = useMemo<IFavoriteDataBody>(
    () => ({
      ...favoriteFilters,
      lists: searchId ? [searchId] : [],
      order: orders,
      ...(searchValue.length >= 3 && { search: searchValue }),
      page
    }),
    [favoriteFilters, searchId, orders, searchValue, page]
  );

  const handleOrder = useCallback(
    (field: string): void => {
      setPage(1);
      setOrders(orderProcessing(field, orders));
    },
    [orders]
  );

  useEffect(() => {
    favoritesStore.resetCategories();
    setFavoriteFilters(initialFavoriteFilters);
    if (tabItems.length) {
      (async () => {
        if (!userStore.user) return;
        await favoritesStore.getFavoritesProjectsTotalValue(
          userStore.user.id,
          getCurrentTab.systemType,
          favoriteBody.lists
        );
      })();
    }
  }, [searchId, pathTab]);

  const getCurrentTab = useMemo<IFavoriteTabsItems>(() => {
    return tabItems.find((item) => item.systemType === pathTab?.toLowerCase()) || tabItems[0];
  }, [pathTab]);

  const getTableData = useCallback(async (): Promise<void> => {
    if (!userStore.user) return;
    setIsLoading(true);
    reloadProgress();
    setEditableFavorite(!!searchId);
    const systemType = getCurrentTab.systemType;
    await favoritesStore.getFavoritesData(userStore.user.id, systemType, {
      ...favoriteBody,
      page
    });
    setIsLoading(false);
  }, [favoriteBody, page, searchId, pathTab]);

  // TODO: move this code to store
  const favoriteOptions = useMemo<FavoritesOptions[]>(
    () =>
      favoritesStore.list.map((search) => ({
        content: search.name,
        selected: searchId === search.id,
        id: search.id,
        shared: search.shared
      })),
    [searchId, favoritesStore.list]
  );
  const allFavoritesOption = useMemo<FavoritesOptions>(
    () => ({
      content: t('favorites.allFavorites'),
      selected: isNaN(searchId),
      id: 0,
      shared: false
    }),
    [searchId, favoritesStore.list]
  );
  const allFavoritesOptions = [allFavoritesOption, ...favoriteOptions];

  const showUnderConstructionModal = (): void => {
    setShowUnderConstruction(true);
  };

  const closeUnderConstructionModal = (): void => {
    setShowUnderConstruction(false);
  };

  const handleProjectDropdownChange = useCallback((selected: IDropdownOption) => {
    setCurrentFavorite(selected);
    setOrders([]);
    const path = !selected.id ? ALL : String(selected.id);
    setPage(1);
    navigate(`/favorites/${path}/${PROJECT}`, { replace: true });
  }, []);

  const getSelectedFavoriteOption = useCallback((): string => {
    const selectedFavorite = allFavoritesOptions.find((option) => option.selected);
    return selectedFavorite ? selectedFavorite.content : allFavoritesOptions[0].content;
  }, [searchId, favoritesStore.list]);

  const submitModal = async (): Promise<void> => {
    if (favoriteListName.length < 3) {
      alertStore.errorAlert(t('addToFavorites.errorCreateList'));
      return;
    }
    if (modalType === ModalType.CREATE) {
      await createFavoriteList();
      return;
    }
    if (modalType === ModalType.EDIT) {
      await editFavoriteList();
      return;
    }
  };

  const createFavoriteList = async (): Promise<void> => {
    if (!userStore.user) return;
    await favoritesStore.createFavoritesList(userStore.user.id, favoriteListName);
    setFavoriteListName('');
    setShowModal(false);
    if (favoritesStore.list.length === 1) {
      navigate(`/favorites/${favoritesStore.list[favoritesStore.list.length - 1].id}/${PROJECT}`, {
        replace: true
      });
    }
    alertStore.successAlert(t('addToFavorites.successCreateList'));
  };

  const editFavoriteList = async (): Promise<void> => {
    if (!userStore.user || !currentFavorite?.id) return;
    await favoritesStore.editFavoriteList(
      // TODO: move to separate function
      userStore.user.id,
      currentFavorite.id,
      favoriteListName
    );
    setFavoriteListName('');
    setShowModal(false);
    alertStore.successAlert(t('favorites.editSuccess'));
  };

  const onStrChange = (name: string, str: string): void => {
    setFavoriteListName(str);
  };

  const openModal = (): void => {
    setShowModal(true);
    const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
    setTimeout(() => target?.focus(), 0);
  };

  const openCreateModal = (): void => {
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.save')
    });
    setModalTitle(t('favorites.createFavoriteList'));
    setModalDeleteBtn(false);
    setModalType(ModalType.CREATE);
    openModal();
  };

  const closeModal = (): void => {
    setFavoriteListName('');
    setShowModal(false);
  };

  const handleShare = (): void => {
    setShareId(searchId);
  };

  const closeShareModal = (): void => {
    setShareId(null);
  };

  const handleEditList = (): void => {
    setFavoriteListName(currentFavorite?.content as string);
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.saveList')
    });
    setModalTitle(t('favorites.editList'));
    setModalDeleteBtn(true);
    setModalType(ModalType.EDIT);
    openModal();
  };

  const handleDeleteList = (): void => {
    closeModal();
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.delete')
    });
    setConfirmModalShow(true);
  };

  const deleteFavorite = useCallback(async (): Promise<void> => {
    try {
      if (!userStore.user || !currentFavorite?.id) return;
      await favoritesStore.deleteFavoriteList(userStore.user.id, currentFavorite.id);
      alertStore.successAlert(t('favorites.deleteSuccess'));
      favoritesStore.resetCategories();
      setFavoriteFilters(initialFavoriteFilters);
      navigate(`/favorites/all/${PROJECT}`, { replace: true });
    } finally {
      setConfirmModalShow(false);
    }
  }, [currentFavorite]);

  const handleTabAction = useCallback(
    (tabId: number): void => {
      const tabName = (tabItems.find((tab) => tab.tabId === tabId) || tabItems[0]).systemType;
      navigate(`/favorites/${id || ALL}/${tabName.toLowerCase()}`);
      setSearchValue('');
      setOrders([]);
      handleChangeFilters(tabId);
    },
    [id]
  );

  const handleChangeFilters = useCallback((tabId: number) => {
    setOrders([]);
    setPage(1);
    if (tabId !== 1) {
      favoritesStore.resetCategories();
      setCategories({
        classes: [],
        types: [],
        subTypes: []
      });
    }
    setActiveFilters(() => {
      switch (tabId) {
        case 1:
          return [TYPE, DATE, PROJECT_VALUE, LOCATION];
        case 2:
          return [PROJECT_COUNT, TOTAL_AMOUNT, LOCATION];
        case 3:
          return [TOTAL_AMOUNT, PROJECT_COUNT];
        default:
          return [];
      }
    });
    setFavoriteFilters(initialFavoriteFilters);
  }, []);

  const handleFiltersSave = useCallback(
    (name: keyof IFavoriteFilters, value: FavoriteFormValue): void => {
      setFavoriteFilters((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleFiltersReset = useCallback((name: keyof IFavoriteFilters): void => {
    setFavoriteFilters((prev) => ({ ...prev, [name]: null }));
  }, []);

  const handleCategoryChange = useCallback(
    ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => {
      setCategories((prev) => ({
        classes: activeClasses || prev.classes,
        types: activeTypes || prev.types,
        subTypes: activeSubtypes || prev.subTypes
      }));
    },
    []
  );

  useEffect(() => {
    favoritesStore.onCategoryChange(categories.classes, categories.types, categories.subTypes);
    if (getCurrentTab.systemType === PROJECT) {
      setFavoriteFilters((prev) => ({
        ...prev,
        classes: categories.classes,
        types: categories.types,
        subTypes: categories.subTypes
      }));
    }
  }, [debouncedCategories]);

  useEffect(() => {
    if (getCurrentTab.systemType === PERSON) return;
    setFavoriteFilters(initialFavoriteFilters);
    (async () => {
      if (!userStore.user) return;
      setLocationOptions(
        await favoritesStore.getFavoriteLocations(
          userStore.user.id,
          getCurrentTab.systemType,
          searchId,
          favoriteFilters
        )
      );
    })();
  }, [getCurrentTab.systemType, id]);

  const handlePageClick = (event: ClickEvent) => {
    setPage(event.selected + 1);
  };

  const handleSearch = (name: string, value: string): void => {
    setSearchValue(value);
  };

  const tableData = useMemo(() => {
    if (getCurrentTab.systemType === PROJECT) {
      return favoritesStore.projects;
    }
    if (getCurrentTab.systemType === COMPANY) {
      return favoritesStore.companies;
    }
    if (getCurrentTab.systemType === PERSON) {
      return favoritesStore.persons;
    }
    return null;
  }, [getCurrentTab, favoritesStore.projects, favoritesStore.companies, favoritesStore.persons]);

  const handleOpenProjectInfo = useCallback(
    (idProject: number) => {
      if (getCurrentTab.systemType !== 'project') return;
      setParams(`details=${idProject}`);
    },
    [pathname]
  );

  const handleAnalyticsAction = (): void => {
    navigate('/favorites/analytics', { state: searchId ? [searchId] : [] });
  };

  const handleUpdateNote = (id: number, hasNote: boolean) => {
    const systemType = getCurrentTab.systemType;
    favoritesStore.updateNoteStatus(id, hasNote, systemType);
  };

  const handleTitleContact = (id: number, type: EntityType, role: AllRoles) => {
    setParams(`contacts=${id}&type=${type}&role=${role}`);
  };

  const handleUpdateFavorite = async () => await getTableData();

  const handleUnlock = useCallback((location?: Location) => {
    if (location) {
      const { id, title } = location;
      setUnlockLocation({ id, title });
      setShowPlan(true);
    }
  }, []);

  return (
    <div>
      <Header>
        <SubHeader
          dropdownSelect={getSelectedFavoriteOption()}
          dropdownOptions={allFavoritesOptions}
          onDropdownChange={handleProjectDropdownChange}
          onCreateList={openCreateModal}
          favoriteFilters={favoriteFilters}
          activeFilters={activeFilters}
          onDropdownSave={handleFiltersSave}
          onDropdownReset={handleFiltersReset}
          locations={locationOptions}
          onSearchInput={handleSearch}
          onCategoryChange={handleCategoryChange}
          searchValue={searchValue}
        />
      </Header>
      <TableHeader
        tabItems={tabItems}
        totalProjectValue={favoritesStore.totalValue}
        onButtonClick={showUnderConstructionModal}
        onTabClick={handleTabAction}
        analyticsAction={handleAnalyticsAction}
        onShare={handleShare}
        disableShare={isNaN(searchId) || Boolean(currentFavorite?.shared)}
        onEditList={handleEditList}
        onDeleteList={handleDeleteList}
        editableFavorite={editableFavorite}
      />
      {!visible ? (
        <div className="m-4">
          <ProgressBar progress={progress} thin />
        </div>
      ) : (
        <div className="my-4 mx-6">
          {!!favoritesStore.projects?.data.length && getCurrentTab.systemType === PROJECT && (
            <ProjectsTable
              data={favoritesStore.projects.data}
              onUpdateFavorite={handleUpdateFavorite}
              onDetails={handleOpenProjectInfo}
              onContact={handleTitleContact}
              onUpdateNode={handleUpdateNote}
              orders={orders}
              orderAction={handleOrder}
              shared={currentFavorite?.shared}
              onHandleUnlock={handleUnlock}
            />
          )}
          {!!favoritesStore.companies?.data.length && getCurrentTab.systemType === COMPANY && (
            <CompaniesTable
              data={favoritesStore.companies.data}
              onUpdateFavorite={handleUpdateFavorite}
              onContact={handleTitleContact}
              onUpdateNode={handleUpdateNote}
              orders={orders}
              orderAction={handleOrder}
              shared={currentFavorite?.shared}
            />
          )}
          {!!favoritesStore.persons?.data.length && getCurrentTab.systemType === PERSON && (
            <PersonsTable
              data={favoritesStore.persons.data}
              onUpdateFavorite={handleUpdateFavorite}
              onContact={handleTitleContact}
              onUpdateNode={handleUpdateNote}
              orders={orders}
              orderAction={handleOrder}
              shared={currentFavorite?.shared}
            />
          )}
          {!!tableData && (
            <div className="flex">
              <Pagination
                onPageClick={handlePageClick}
                currentPage={page}
                totalPages={tableData.totalPages}
              />
            </div>
          )}
          {!!favoritesStore.list.length && !tableData?.data.length && (
            <div className="p-4">
              <h4 className="text-center text-dark">{t('favorites.notFavoritesInList')}</h4>
            </div>
          )}
          {!favoritesStore.list.length && (
            <div className="p-4">
              <h4 className="text-center text-dark">{t('favorites.listStillEmpty')}</h4>
              <Button
                type="primary"
                className="px-4 m-auto mt-3 font-kraftig"
                onClick={openCreateModal}>
                {t('favorites.createList')}
              </Button>
            </div>
          )}
        </div>
      )}
      {unlockLocation && (
        <ChoosePlanModal
          show={showPlan}
          locations={[unlockLocation]}
          closeModal={() => setShowPlan(false)}
          onUpdate={handleUpdateFavorite}
        />
      )}
      <div ref={modalRef}>
        <Modal
          show={show}
          title={modalTitle}
          actions={modalActions}
          closeModal={closeModal}
          onDelete={handleDeleteList}
          onSubmit={submitModal}
          deleteBtn={modalDeleteBtn}>
          <Input
            name=""
            type="text"
            label={t('favorites.listName')}
            value={favoriteListName}
            onInput={onStrChange}
          />
        </Modal>
      </div>
      <ConfirmModal
        title={t('favorites.deleteList')}
        subTitle={t('favorites.deleteListInfo')}
        actions={modalActions}
        show={confirmModalShow}
        closeModal={() => setConfirmModalShow(false)}
        onSubmit={deleteFavorite}
        modalClass={classes.confirmModal}
        bodyClass="w-[300px]"
      />
      <UnderConstructionModal
        title="Action"
        subTitle=""
        closeModal={closeUnderConstructionModal}
        show={showUnderConstruction}
      />
      <ShareModal
        entityType={SharedEntityType.FAVORITE_LIST}
        isShowWithId={shareId}
        closeModal={closeShareModal}
      />
      {details && <ProjectInfoModal onFavorite={handleUpdateFavorite} />}
      {contacts && <ContactInfo onUpdateFavorite={handleUpdateFavorite} />}
    </div>
  );
});

export default Favorites;
