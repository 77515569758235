import { MutableRefObject, useCallback, useEffect } from 'react';

export const useIntersectionObserverEffect = (
  ref: MutableRefObject<HTMLDivElement | null>,
  callBack: (entry: IntersectionObserverEntry) => void,
  deps: unknown[]
) => {
  const cb = useCallback((entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    callBack(entry);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(cb, {
      root: null,
      rootMargin: '20px'
    });

    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [...deps]);
};
