import React, { FC, useCallback, MouseEvent } from 'react';
import { clsx } from 'utils/clsx';
import classes from 'view/Auth/Auth.module.scss';
import LinkedInAuth from './LinkedInAuth';
import GoogleAuth from './GoogleAuth';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import {
  IGoogleResponse,
  ILinkedinResponse,
  IOpenIdLogin,
  PreRegistrationUserData
} from '../types';
import { LOGIN_PROVIDER_GOOGLE } from 'utils/constants';
import { Button } from 'components';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';

interface IProps {
  step: 'sign-in' | 'sign-up';
  light?: boolean;
}

const AuthFooter: FC<IProps> = ({ step, light }) => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const { authStore, alertStore, userStore } = useStore();
  const { navigateAfterLogin } = useNavigateAfterLogin();

  const googleAuth = useCallback(async (user: IGoogleResponse) => {
    const response = await authStore.openIdLogin(user.credential, LOGIN_PROVIDER_GOOGLE);
    await handleUserAction(response);
  }, []);

  const linkedInAuth = useCallback(async (response: ILinkedinResponse) => {
    const res: IOpenIdLogin = {
      token: response.type === 'token' ? (response.data as unknown as string) : '',
      result: response.type === 'token' ? null : response.data
    };
    await handleUserAction(res);
  }, []);

  const handleUserAction = useCallback(async (response: IOpenIdLogin) => {
    if (response.result) {
      if (!response.result.firstName || !response.result.lastName) return;
      const preRegistrationUser: PreRegistrationUserData = {
        fullName: `${response.result.firstName} ${response.result.lastName}`,
        email: response.result.email,
        token: response.result.token
      };
      setParams('auth=sign-up', { state: preRegistrationUser });
    } else {
      authStore.setToken(response.token);
      await userStore.getCurrentUser();
      alertStore.successAlert(t('auth.successLogin'));
      navigateAfterLogin();
    }
  }, []);

  const openLogin = useCallback((e: MouseEvent) => {
    e.preventDefault();
    params.set('auth', 'sign-in');
    setParams(params);
  }, []);
  const openRegister = useCallback((e: MouseEvent) => {
    e.preventDefault();
    params.set('auth', 'sign-up');
    setParams(params);
  }, []);

  return (
    <div className={clsx(!light && 'px-4')}>
      <div className="py-2.5 my-9.5">
        <div className="relative h-px bg-light-400">
          <span
            className={clsx(
              'text-dark',
              '-translate-y-1/2',
              '-translate-x-1/2',
              'text-sm top-1/2',
              'left-1/2',
              'uppercase',
              'text-center',
              'mb-4',
              'absolute',
              'after:absolute',
              'after:block',
              'after:w-full',
              'after:h-px',
              'after:bg-light-600',
              'after:top-1/2',
              'after:left-0',
              'after:-translate-y-1/2',
              light && '!text-white'
            )}>
            <b
              className={clsx(
                'px-3',
                light ? 'bg-shadesBlue-300' : 'bg-white',
                'font-kraftig',
                'relative',
                'z-[99]'
              )}>
              {t('auth.orUse')}
            </b>
          </span>
        </div>
      </div>

      <div className="flex justify-between mb-6">
        <div className="w-[48%]">
          <LinkedInAuth step={step} linkedinAction={linkedInAuth} light={!!light} />
        </div>
        <div className="w-[48%]">
          <GoogleAuth step={step} googleAction={googleAuth} light={!!light} />
        </div>
      </div>
      {step === 'sign-in' ? (
        <span
          className={clsx(
            'text-center',
            'text-dark',
            'flex',
            'items-center',
            'justify-center',
            '-mb-px',
            light && 'text-white'
          )}>
          {t('auth.toRegister')}
          <Button
            type="light"
            className={clsx('!text-primary bg-white border-0 ml-2 py-0', classes.link)}
            onClick={openRegister}>
            {t('auth.signUp')}
          </Button>
        </span>
      ) : (
        <span
          className={clsx(
            'text-center',
            'text-dark',
            'flex',
            'items-center',
            'justify-center',
            '-mb-px',
            light && 'text-white'
          )}>
          {t('auth.toLogin')}
          <Button
            type="light"
            className={clsx(
              '!text-primary border-0 ml-2 py-0 h-10',
              classes.link,
              !light && 'bg-white'
            )}
            onClick={openLogin}>
            {t('auth.login')}
          </Button>
        </span>
      )}
    </div>
  );
};

export default AuthFooter;
