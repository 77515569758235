import { IFullProject } from 'modules/ProjectInfoModal/types';
import { IFavoriteKey } from 'view/Favorites/types';
import { FunctionComponent } from 'react';
import { IContact, IAxiosResponse, ILocation, Status, AllRole, EntityType } from 'utils/types';
import { IRow } from 'components/Table/types';
import { ISearchParams } from 'modules/Filter/types';

export interface IKeyword {
  title?: string;
  type: number;
  value: number;
}

export interface IRecentProjectsData {
  count: number;
  currentPage: number;
  data: IProject[];
  limit: number;
  totalPages: number;
  value: number;
  hasSubscription: boolean;
}

export interface IProjectsData {
  currentPage: number;
  data: IProject[];
  limit: number;
  hasMore: boolean;
  hasSubscription: boolean;
  showCount: number;
  showTotal: number;
  value: number;
  totalPages: number;
  emptyCounties: number[];
}

export interface IProjectListResults {
  id: number;
  class: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  subType: {
    id: number;
    name: string;
  };
  permitType: {
    id: number;
    name: string;
  };
  description: string;
  status: Status;
  value: number;
  estimatedValue: boolean;
  // TODO: need to be changed to location instead locations on the BE
  locations: ILocation;
  effectiveDate: string;
  createdAt: string;
  updatedAt: string;
  favourite: boolean;
  hasFullData: boolean;
  contacts: IContact[];
}

export interface IProjectListResponse<T> {
  currentPage: number;
  totalPages: number;
  limit: number;
  hasMore: boolean;
  hasSubscription: boolean;
  showCount: number;
  showTotal: number;
  value: number;
  emptyCounties: number[];
  data: IProjectListResults[];
  dataTable: T;
}

export interface IProject {
  id: number;
  class: IProjectField;
  subType: IProjectField;
  type: IProjectField;
  permitType: IProjectField;
  effectiveDate: string;
  description: string;
  status: {
    id: number;
    name: string;
    color: string;
  };
  createdAt: string;
  value: number;
  // TODO: need to be changed to location instead locations on the BE
  locations: ILocation;
  locationToShow: string;
  favourite: boolean;
  hasFullData: boolean;
  estimatedValue: boolean;
  isLocked: boolean;
}

interface IProjectField {
  id: number;
  name: string;
}

export interface IDetail {
  type: number;
  value: string;
  icon: FunctionComponent;
}

export interface IProjectContact {
  id: number;
  type: EntityType;
  role: EntityType;
  name: string;
  favourite: boolean;
  projectId: number;
}

export const SEARCH_PROJECTS_REPO = 'SEARCH_PROJECTS_REPO';
export interface ISearchProjectsRepo {
  getKeywords(keyword: string): Promise<IAxiosResponse<IKeyword[]>>;
  getProjects(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectsData>>;
  getContacts(id: number): Promise<IAxiosResponse<IContact[]>>;
  getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>>;
  getAllProjectInfo(projectId: number): Promise<IAxiosResponse<IFullProject>>;
  getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>>;
  getProjectByListView(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectListResponse<IProjectListResults[]>>>;
  validateSharedToken(token: string, projectId: number): Promise<IAxiosResponse<IFullProject>>;
}
export const SEARCH_PROJECTS_SERVICE = 'SEARCH_PROJECTS_SERVICE';
export interface ISearchProjectService {
  getKeywords(keyword: string): Promise<IKeyword[]>;
  getProjects(params: ISearchParams, controller?: AbortController): Promise<IProjectsData | null>;
  getContacts(id: number): Promise<IContact<IDetail>[]>;
  getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IRecentProjectsData>;
  getAllProjectInfo(projectId: number): Promise<IFullProject>;
  getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IRecentProjectsData>;
  getProjectByListView(
    params: ISearchParams,
    controller?: AbortController,
    page?: number
  ): Promise<IProjectListResponse<IRow[]>>;
  validateSharedToken(token: string, projectId: number): Promise<IFullProject>;
}

export interface ISearchStore {
  getKeywords(keyword: string): Promise<IKeyword[]>;
  getProjects(params: ISearchParams, controller?: AbortController): Promise<void>;
  getContacts(id: number): Promise<void>;
  setProjects(projectsData: IProjectsData): void;
  projectsData: IProjectsData;
  isValueLoading: boolean;
  contacts: IContact[];
  recentContacts: IContact[];
  activeFavorite: IActiveFavorite | null;
  getRecentProjects(contactType: string, contactId: number, page: number): Promise<void>;
  getAllProjectInfo(projectId: number): Promise<IFullProject>;
  getRelatedProjects(projectId: number): Promise<void>;
  getProjectByListView(
    params: ISearchParams,
    controller?: AbortController,
    page?: number
  ): Promise<void>;
  validateSharedToken(token: string, projectId: number): Promise<void>;
}

// export interface IOptionsConfig {
//   projectClasses: IOption[];
//   projectStatuses: IOption[];
//   projectTypes: IOption[];
// }

export type FavoriteContacts = {
  id: number;
  type: EntityType;
  role: AllRole;
};

export interface IActiveFavorite {
  id: number;
  type: IFavoriteKey;
  role?: number;
}

export type RelatedProjectType = 'openProjects' | 'closedProjects';
export type RelatedProjectKey = 'open' | 'closed';
export type ProjectType = 'recentProjects' | 'openProjects' | 'closedProjects' | 'projects';

export interface IListRef {
  _outerRef: HTMLDivElement;
  scrollToItem: (id: number | null, type: string) => void;
}
