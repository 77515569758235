import 'utils/injectAllDeps';
import { AuthStore } from 'view/Auth/Auth.store';
import { AlertStore } from 'view/Alerts/Alert.store';
import { ProjectStore } from 'view/SearchProjects/SearchProjects.store';
import { ConfigStore } from 'store/ConfigStore/Config.store';
import { SavedSearchManageStore } from 'view/SavedSearches/SavedSearchManage.store';
import { FilterStore } from 'modules/Filter/Filter.store';
import { MapStore } from 'view/SearchProjects/components/Map/Map.store';
import { SavedSearchResultsStore } from 'view/SavedSearches/components/SavedSearchResults/SavedSearchResults.store';
import { SearchStore } from 'view/Search/Search.store';
import { FavoritesStore } from 'view/Favorites/Favorites.store';
import { UserStore } from 'view/Profile/User.store';
import { AnalyticsStore } from 'view/Favorites/Analytics/Analytics.store';
import { NoteStore } from 'view/AddNoteModal/Note.store';
import { SubscriptionAndPlansStore } from 'view/SubscriptionsAndPlans/SubscriptionAndPlans.store';
import { NotificationStore } from 'modules/Notification/Notification.store';
import { ExportDataStore } from 'modules/ExportData/ExportData.store';
import { ShareStore } from 'modules/ShareModal/Share.store';
import { SubscriptionLocationDropdownStore } from 'modules/SubscriptionLocationDropdown/SubscriptionLocationDropdown.store';
import { ContactInfoStore } from 'modules/ContactInfoModal/ContactInfo.store';
import { CompanySearchStore } from 'view/CompanySearch/CompanySearch.store';
import { ListUploadStore } from 'view/ListUpload/ListUpload.store';

export const stores = {
  authStore: new AuthStore(),
  alertStore: new AlertStore(),
  projectSearchStore: new ProjectStore(),
  configStore: new ConfigStore(),
  savedSearchesStore: new SavedSearchManageStore(),
  filtersStore: new FilterStore(),
  mapStore: new MapStore(),
  savedSearchResultsStore: new SavedSearchResultsStore(),
  searchStore: new SearchStore(),
  userStore: new UserStore(),
  favoritesStore: new FavoritesStore(),
  analyticsStore: new AnalyticsStore(),
  noteStore: new NoteStore(),
  subscriptionAndPlansStore: new SubscriptionAndPlansStore(),
  notificationStore: new NotificationStore(),
  exportDataStore: new ExportDataStore(),
  shareStore: new ShareStore(),
  subscriptionLocationDropdownStore: new SubscriptionLocationDropdownStore(),
  contactInfoStore: new ContactInfoStore(),
  companySearchStore: new CompanySearchStore(),
  listUploadStore: new ListUploadStore()
};
