import {
  IAuthRepo,
  IConfirmData,
  IConfirmResponse,
  ILoginData,
  ILoginResponse,
  IOpenIdLogin,
  IRegisterResponse,
  IDataResponse,
  IResetPasswordData,
  IRegisterReducedData
} from './types';
import { axiosInstance } from 'utils/API';

export class AuthRepo implements IAuthRepo {
  async signIn(data: ILoginData): Promise<ILoginResponse> {
    return axiosInstance.post('/auth/login', data);
  }
  async signUp(data: IRegisterReducedData): Promise<IRegisterResponse> {
    return axiosInstance.post('/auth/register', data);
  }
  async openIdLogin(token: string, provider: string): Promise<IDataResponse<IOpenIdLogin>> {
    return axiosInstance.post('/openid/login', { token, provider });
  }
  async confirmAccount(data: IConfirmData): Promise<IConfirmResponse> {
    return axiosInstance.post('/auth/email/verify', data);
  }

  async sendResetCode(email: string): Promise<IConfirmResponse> {
    return axiosInstance.post('/auth/reset/password/send', { email });
  }

  async resetPassword(data: IResetPasswordData): Promise<IConfirmResponse> {
    return axiosInstance.post('auth/reset/password/complete', data);
  }
}
