import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from './ContactList.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { IDetail } from '../../types';
import { observer } from 'mobx-react';
import { Skeleton } from 'components';
import { mockContacts, skeletonsToShow } from './mock';
import { FavoriteSubTypes } from 'view/Favorites/types';
import Contact from './components/Contact';
import { IContact } from 'utils/types';

interface IProps {
  projectId: number;
  position: 'left' | 'right';
  closeList: () => void;
  type: keyof typeof FavoriteSubTypes;
  handleUnlock: () => void;
  isLocked: boolean;
  onShowMore: (contact: IContact) => void;
}

const ContactList: FC<IProps> = observer(
  ({ projectId, position, closeList, type, handleUnlock, isLocked, onShowMore }) => {
    const { projectSearchStore } = useStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement | null>(null);

    // const showMore = useCallback(
    //   (contact: IContact) => {
    //     // projectSearchStore.setCurrentContact({
    //     //   ...contact,
    //     //   projectId
    //     // });
    //     const isFavorite = projectSearchStore.checkContactIsFavorite(contact);
    //     setParams(
    //       `contacts=${contact.id}&type=${contact.type}&role=${contact.role}&name=${contact.name}&favourite=${isFavorite}&project=${projectId}`
    //     );
    //     // closeList();
    //   },
    //   [projectId]
    // );

    const positionList = useCallback((): string => {
      return position === 'left' ? `right-full ${classes.leftWrapper}` : 'left-full';
    }, [position]);

    const loadContacts = useCallback(async () => {
      try {
        setIsLoading(true);
        type === 'regular'
          ? await projectSearchStore.getContacts(projectId)
          : await projectSearchStore.getRecentContacts(projectId);
      } finally {
        setIsLoading(false);
      }
    }, [projectId]);

    const arrayToShow = useMemo<IContact<IDetail>[]>(() => {
      if (isLocked) return mockContacts;
      return type === FavoriteSubTypes.regular
        ? projectSearchStore.contacts
        : projectSearchStore.recentContacts;
    }, [projectSearchStore.contacts, projectSearchStore.recentContacts]);

    const clickOutside = (e: MouseEvent): void => {
      const favoriteModals = document.querySelector('#modal-root')?.children.length;
      if (favoriteModals) return;
      if (
        listRef.current &&
        !e.composedPath().includes(listRef.current) &&
        (e.target as HTMLDivElement).dataset.testElement !== 'search-project-contacts-button'
      ) {
        closeList();
      }
    };

    useEffect(() => {
      if (!closeList) return;
      window.addEventListener('click', clickOutside);
      return () => {
        window.removeEventListener('click', clickOutside);
      };
    }, []);

    useEffect(() => {
      loadContacts().then();
    }, [projectId]);

    return (
      <>
        <div
          ref={listRef}
          className={clsx('absolute', positionList(), 'top-0', 'bg-white', classes.wrapper)}
          data-test-element="contacts-list">
          {isLoading ? (
            <Skeleton type="contact" itemsToShow={skeletonsToShow} />
          ) : (
            <>
              {arrayToShow.map((contact) => (
                <Contact
                  type={type}
                  listRef={listRef}
                  key={`${contact.id}-${contact.type}-${contact.role}`}
                  contact={contact}
                  showMore={onShowMore}
                  isLocked={isLocked}
                  handleUnlock={handleUnlock}
                />
              ))}
            </>
          )}
        </div>
      </>
    );
  }
);

export default ContactList;
