import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { Alert } from './components/Alert';

const Alerts: FC = observer(() => {
  const { alertStore } = useStore();

  return (
    <div className="top-2 z-[999] fixed right-2/4 translate-x-2/4">
      {alertStore.alerts.map((alert, i) => (
        <Alert
          key={`alert-${i}`}
          text={alert.text}
          type={alert.type}
          closeAlert={() => alertStore.closeAlert(i)}
        />
      ))}
    </div>
  );
});

export default Alerts;
