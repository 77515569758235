import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SharedEntityType } from 'modules/ShareModal/types';
import { useStore } from 'storesProvider/storeContext';

const ShareFavorite = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const { favoritesStore, userStore, shareStore, alertStore } = useStore();

  const loadSharedList = useCallback(async () => {
    if (!userStore.user) {
      params.set('auth', 'sign-up');
      params.set('extended', 'true-up');
      setParams(params);
    }
    if (!token || !userStore.user?.id) return;
    try {
      await favoritesStore.validateSharedToken(token);
      shareStore.setShareToken(token, SharedEntityType.SINGLE_PERMIT);
      alertStore.successAlert(t('share.listAccepted'));
    } finally {
      navigate('/favorites/all/project', { replace: true });
    }
  }, [userStore.user, token]);

  useEffect(() => {
    void loadSharedList();
  }, [loadSharedList]);

  return (
    <div className="flex items-center w-full h-full justify-center">
      <div className="loader p-0">
        <div />
      </div>
    </div>
  );
};

export default ShareFavorite;
