import React, { FC, useId } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/icons/logo-header.svg';
import { Button, Input, SearchList } from 'components';
import searchIcon from 'assets/icons/search.svg';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { SIGN_IN, SIGN_UP } from 'utils/constants';

interface Props {
  authAction: (type: string) => void;
}

const GuestBody: FC<Props> = observer(({ authAction }) => {
  const { t } = useTranslation();
  const id = useId();
  const [, setParams] = useSearchParams();

  return (
    <div className="flex h-full px-3">
      {/*TODO: remove after test*/}
      {/*<div className="flex items-center grow">*/}
      {/*  <Link className="focus:outline-0 mr-4" to="/" tabIndex={-1}>*/}
      {/*    <img src={Logo} alt="close" className="relative" role="button" />*/}
      {/*  </Link>*/}
      {/*  <div className="grow flex">*/}
      {/*    <div className="flex relative max-w-[553px] w-full">*/}
      {/*      <Input*/}
      {/*        tabIndex={0}*/}
      {/*        data-input-id={id}*/}
      {/*        autoComplete="off"*/}
      {/*        name="search"*/}
      {/*        type="text"*/}
      {/*        value={inputValue}*/}
      {/*        onInput={inputChange}*/}
      {/*        onFocus={onInputFocus}*/}
      {/*        onBlur={onInputBlur}*/}
      {/*        data-test-element="header-search-input"*/}
      {/*        inputClass="text-white bg-white bg-opacity-[.21] focus:text-dark focus:bg-white focus:bg-opacity-1"*/}
      {/*        searchIcon>*/}
      {/*        <div*/}
      {/*          data-input-id={id}*/}
      {/*          className="flex w-full items-center justify-center text-white text-opacity-80 h-full ease-linear duration-200 hover:bg-white hover:bg-opacity-[.21]">*/}
      {/*          <img src={searchIcon} alt="" />*/}
      {/*          &nbsp;{t('components.header.search')}*/}
      {/*        </div>*/}
      {/*      </Input>*/}
      {/*      <SearchList*/}
      {/*        listResponse={searchResults}*/}
      {/*        selectedLocation={selectedLocation}*/}
      {/*        onChange={searchListChange}*/}
      {/*        styleTop="42"*/}
      {/*        show={showSearchList}*/}
      {/*        closeList={closeList}*/}
      {/*        inputId={id}*/}
      {/*        onKeyUp={onKeyUp}*/}
      {/*        onKeyDown={onKeyDown}*/}
      {/*        onKeyEnter={onKeyEnter}*/}
      {/*        onKeyEscape={onKeyEscape}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <Button*/}
      {/*      type="primary"*/}
      {/*      data-test-element="header-purchase-btn"*/}
      {/*      background={'#00C47D'}*/}
      {/*      className="text-xs uppercase px-5 ml-2 py-3 hover:bg-green-400 hover:bg-opacity-10"*/}
      {/*      onClick={() => setParams('?auth=sign-up&extended=true')}>*/}
      {/*      <span className="font-halbfett">{t('components.header.purchase')}</span>*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="flex w-[171px] -mr-[13px]">
        <Button
          type="light"
          color="#8E94BC"
          data-test-element="header-login-btn"
          className="text-xs uppercase px-4 mr-2"
          onClick={() => authAction(SIGN_IN)}>
          <span className="font-halbfett">{t('components.header.login')}</span>
        </Button>
        <Button
          type="primary"
          data-test-element="header-sign-up-btn"
          className="text-xs uppercase px-3"
          onClick={() => authAction(SIGN_UP)}>
          <span className="font-halbfett">{t('components.header.signUp')}</span>
        </Button>
      </div>
    </div>
  );
});

export default GuestBody;
