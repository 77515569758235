import {
  ISavedSearchData,
  ISavedSearchResultsRepo,
  ISavedSearchResultsService,
  IShareSavedSearchResponse,
  SAVED_SEARCH_RESULTS_REPO
} from './types';
import { injector } from 'utils/injector';
import { ISort, ObjectValues, SearchStringType, SearchType } from 'utils/types';
import { ISearchParams } from 'modules/Filter/types';
import filterForRequest from 'helpers/filtersForRequest';
import { ICompaniesListView } from 'view/CompanySearch/types';
import getOrdersParams from 'helpers/getOrdersParams';

export class SavedSearchResultsService implements ISavedSearchResultsService {
  private _savedSearchResultRepo: ISavedSearchResultsRepo =
    injector.get<ISavedSearchResultsRepo>(SAVED_SEARCH_RESULTS_REPO);

  async getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orders: ISort[],
    type: SearchStringType
  ): Promise<ISavedSearchData | ICompaniesListView> {
    return (
      await this._savedSearchResultRepo.getCrossReferencingSavedSearches(
        userId,
        id,
        page,
        getOrdersParams(orders),
        type
      )
    ).data.result;
  }
  async getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<ISavedSearchData> {
    const searchIds: ObjectValues<number> = {};
    ids.forEach((id, index) => {
      const key = `searchId[${index}]`;
      searchIds[key] = id;
    });
    return (
      await this._savedSearchResultRepo.getSavedSearchesProjectsBySearchType(
        userId,
        searchIds,
        page,
        getOrdersParams(orders)
      )
    ).data.result;
  }

  async getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<ICompaniesListView> {
    const searchIds: ObjectValues<number> = {};
    ids.forEach((id, index) => {
      const key = `searchId[${index}]`;
      searchIds[key] = id;
    });
    return (
      await this._savedSearchResultRepo.getSavedSearchesCompaniesBySearchType(
        userId,
        searchIds,
        page,
        getOrdersParams(orders)
      )
    ).data.result;
  }

  async getShareProjectSavedSearch(
    token: string,
    page: number,
    orders: ISort[]
  ): Promise<IShareSavedSearchResponse<ISavedSearchData>> {
    return (
      await this._savedSearchResultRepo.getShareSavedSearch<ISavedSearchData>(
        'project',
        token,
        page,
        getOrdersParams(orders)
      )
    ).data.result;
  }

  async getShareCompanySavedSearch(
    token: string,
    page: number,
    orders: ISort[]
  ): Promise<IShareSavedSearchResponse<ICompaniesListView>> {
    return (
      await this._savedSearchResultRepo.getShareSavedSearch<ICompaniesListView>(
        'company',
        token,
        page,
        getOrdersParams(orders)
      )
    ).data.result;
  }

  async updateFilters<T>(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<T | null> {
    if (searchType === SearchType.PROJECT || searchType === SearchType.CROSS_REFERENCING_PROJECT) {
      return (
        await this._savedSearchResultRepo.updateFilters<Promise<T>>(
          userId,
          searchId,
          filterForRequest(filters, searchType),
          searchType,
          controller
        )
      ).data.result;
    }
    if (
      searchType === SearchType.COMPANY ||
      searchType === SearchType.CROSS_REFERENCING_COMPANY ||
      searchType === SearchType.CROSS_REFERENCING_BOTH
    ) {
      return (
        await this._savedSearchResultRepo.updateFilters<T>(
          userId,
          searchId,
          filterForRequest(filters, searchType),
          searchType,
          controller
        )
      ).data.result;
    }
    return null;
  }
}
