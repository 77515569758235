export interface IAuthStore {
  accessToken: string;
  isRedirected: boolean;
  signIn(data: ILoginData): Promise<void>;
  signUp(data: IRegisterData): Promise<void>;
  logout(): void;
  setToken(accessToken: string): void;
  storeAccessToken(): void;
  openIdLogin(token: string, provider: string): Promise<IOpenIdLogin>;
  sendResetCode(email: string): Promise<void>;
  resetPassword(data: IResetPasswordData): Promise<void>;
}

export const AUTH_SERVICE = 'AUTH_SERVICE';
export interface IAuthService {
  signIn(data: ILoginData): Promise<string>;
  signUp(data: IRegisterData): Promise<IRegisterResponse>;
  openIdLogin(token: string, provider: string): Promise<IOpenIdLogin>;
  confirmAccount(data: IConfirmData): void;
  sendResetCode(email: string): Promise<boolean>;
  resetPassword(data: IResetPasswordData): Promise<boolean>;
}

export const AUTH_REPO = 'AUTH_REPO';
export interface IAuthRepo {
  signIn(data: ILoginData): Promise<ILoginResponse>;
  signUp(data: IRegisterReducedData): Promise<IRegisterResponse>;
  openIdLogin(token: string, provider: string): Promise<IDataResponse<IOpenIdLogin>>;
  confirmAccount(data: IConfirmData): void;
  sendResetCode(email: string): Promise<IConfirmResponse>;
  resetPassword(data: IResetPasswordData): Promise<IConfirmResponse>;
}

export interface IRegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  type: string;
  agree: boolean;
  socialUserToken?: string;
}

export interface IPreRegisterData {
  fullName: string;
  email: string;
}

//TODO: delete after back-end fixes
export interface IRegisterReducedData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  socialUserToken?: string;
}

export interface IResetData {
  email: string;
}

export interface IResetPasswordData {
  email: string;
  value: string;
  token: string;
}

export interface IRegisterResponse {
  data: {
    register: boolean;
  };
}

export interface IConfirmResponse {
  data: {
    result: boolean;
  };
}

export interface IErrorResponse {
  code: number;
  message: string;
}
export interface IValidationErrorResponse extends IErrorResponse {
  validation?: IValidation;
}

export type IValidation = {
  [key in keyof IRegisterData]: string;
};

export interface ILoginData {
  email: string;
  password: string;
}

export interface IConfirmData {
  email: string;
  token: string;
}

export interface ILoginResponse {
  data: {
    token: string;
  };
}

export interface IDataResponse<T> {
  data: T;
}

export interface IOpenIdLogin {
  result: {
    email: string;
    firstName: string;
    lastName: string;
    token: string;
  } | null;
  token: string;
}

export interface IGoogleResponse {
  clientId: string;
  credential: string;
  select_by: string;
}

export interface ILinkedinResponse {
  type: string;
  data: {
    email: string;
    firstName: string;
    lastName: string;
    token: string;
  };
}

export type PreRegistrationUserData = {
  fullName: string;
  email: string;
  token: string;
};

export interface IResetFormValues {
  password: string;
  repeat: string;
}

export interface IGoogle {
  accounts: {
    id: {
      initialize: (params: IGoogleInitParams) => void;
      renderButton: (el: HTMLElement | null, settings: { [key: string]: string | number }) => void;
      prompt: () => void;
    };
  };
}

export interface IGoogleInitParams {
  client_id: string;
  callback: (user: IGoogleResponse) => void;
}

export enum EmailType {
  Current = 'current',
  New = 'new'
}

export enum AuthStep {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  Reset = 'reset',
  ResetPassword = 'reset-password',
  VerifyEmail = 'verify-email',
  ConfirmCurrentEmail = 'confirm-current-email',
  ConfirmNewEmail = 'confirm-new-email'
}
