import {
  FAVORITES_REPO,
  IFavoriteData,
  IFavoritesRepo,
  FavoritesList,
  IFavoritesService,
  IFavoriteDataBody,
  IFavoriteFilters,
  IFavoriteDataRes
} from './types';
import { injector } from 'utils/injector';
import { IOption } from 'components/Select/types';

export class FavoritesService implements IFavoritesService {
  private _favoritesRepo: IFavoritesRepo = injector.get<IFavoritesRepo>(FAVORITES_REPO);

  async createFavoritesList(id: number, name: string): Promise<FavoritesList> {
    return (await this._favoritesRepo.createFavoritesList(id, name)).data.result;
  }

  async addToFavorites(id: number, data: IFavoriteData): Promise<void> {
    return await this._favoritesRepo.addToFavorites(id, data);
  }
  async deleteFromFavorites(id: number, data: IFavoriteData): Promise<void> {
    return await this._favoritesRepo.deleteFromFavorites(id, data);
  }

  async getListsByEntity(
    id: number,
    type: string,
    entityId: number,
    role: string
  ): Promise<number[]> {
    return (await this._favoritesRepo.getListsByEntity(id, type, entityId, role)).data.result.map(
      (list) => list.id
    );
  }

  async getFavoritesList(userId: number): Promise<FavoritesList[]> {
    return (await this._favoritesRepo.getFavoritesList(userId)).data.result;
  }

  async getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<number> {
    return (await this._favoritesRepo.getFavoritesProjectsTotalValue(userId, systemType, lists))
      .data.result;
  }

  async deleteFavoriteList(userId: number, listId: number): Promise<void> {
    await this._favoritesRepo.deleteFavoriteList(userId, listId);
  }

  async editFavoriteList(userId: number, listId: number, name: string): Promise<FavoritesList> {
    return (await this._favoritesRepo.editFavoriteList(userId, listId, name)).data.result;
  }

  async getFavoritesData<T>(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IFavoriteDataRes<T>> {
    // TODO: need an update on the BE part (change property to class)
    const newBody = {
      ...body,
      order: body.order.map((item) => {
        if (item.field === 'class') {
          return {
            ...item,
            field: 'property'
          };
        }
        // if (item.field === 'updatedAt') {
        //   return {
        //     ...item,
        //     field: 'createdAt'
        //   };
        // }
        return item;
      })
    };
    return (await this._favoritesRepo.getFavoritesData<T>(userId, systemType, newBody)).data.result;
  }
  async getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]> {
    const { totalAmount, date, projectValue, projectCount, classes, types, subTypes } =
      favoriteFilters;
    return (
      await this._favoritesRepo.getFavoriteLocations(userId, systemType, {
        lists: listId ? [listId] : [],
        totalAmount,
        date,
        projectValue,
        projectCount,
        classes,
        types,
        subTypes
      })
    ).data.result.map(({ id, title }) => ({ value: String(id), id: id, text: title }));
  }

  async validateSharedToken(token: string): Promise<FavoritesList> {
    return (await this._favoritesRepo.validateSharedToken(token)).data.result;
  }
}
