import React, { FC, useCallback, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoModal from './InfoModal';
import { useStore } from 'storesProvider/storeContext';
import { EmailType } from '../types';

interface IProps {
  emailType: EmailType.Current | EmailType.New;
}

const ConfirmEmailChange: FC<IProps> = ({ emailType }) => {
  const { t } = useTranslation();
  const { alertStore, userStore, authStore } = useStore();
  const [params, setParams] = useSearchParams();
  const token = params.get('token');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitChanging = useCallback(() => setParams(''), [setParams]);

  if (!token) {
    alertStore.errorAlert(t('auth.incorrectLink'));
    return <Navigate to="/profile/settings" />;
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await userStore.confirmEmailToken(token.replaceAll(' ', '+'));
        setIsLoading(false);
        if (response?.token && emailType === EmailType.New) {
          authStore.setToken(response.token);
          await userStore.getCurrentUser();
        }
      } catch (e) {
        handleSubmitChanging();
      }
    })();
  }, []);

  return (
    <InfoModal
      info={
        emailType === EmailType.Current
          ? t('auth.changeCurrentEmailSubtitle')
          : t('auth.changeEmailSuccess')
      }
      isLoading={isLoading}
      buttonText={t('auth.continue')}
      onBtnClick={handleSubmitChanging}
      title={t('auth.changeEmailTitle')}
    />
  );
};

export default ConfirmEmailChange;
