import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DateDropdown from './DateDropdown';
import TreeDropdown from './TreeDropdown';
import ValuationDropdown from './ValuationDropdown';
import MoreDropdown from './MoreDropdown';
import { useStore } from 'storesProvider/storeContext';
import { IOption } from 'components/Select/types';
import { useTranslation } from 'react-i18next';
import { FilterState, IDropdownEvent, IInputChangeItem } from '../types';
import SubscriptionLocationDropdown from 'modules/SubscriptionLocationDropdown';

interface Props {
  onDateChange: (option: IOption, field: string) => void;
  onResetDate: () => void;
  onCategoryChange: ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => void;
  onPropertyChangeChange: ({ activePermits }: IDropdownEvent) => void;
  onResetValue: () => void;
  onSaveValue: (value: IInputChangeItem) => void;
}

const FilterForSavedSearch: FC<Props> = observer(
  ({
    onDateChange,
    onResetDate,
    onCategoryChange,
    onPropertyChangeChange,
    onResetValue,
    onSaveValue
  }) => {
    const { t } = useTranslation();
    const { filtersStore } = useStore();

    return (
      <div className="flex items-center">
        <div>
          <SubscriptionLocationDropdown />
        </div>
        <DateDropdown
          customValue={filtersStore.customDate}
          onChange={onDateChange}
          onCustomChange={filtersStore.onCustomDateChange}
          onReset={onResetDate}
          value={filtersStore.selectedDate}
        />
        <TreeDropdown
          data={filtersStore.categories}
          onChange={onCategoryChange}
          divClass="property"
          placeholder={t('components.dropdown.placeholder')}
        />
        <TreeDropdown
          data={filtersStore.permitTypes}
          placeholder={t('components.dropdown.placeholderPermitType')}
          onChange={onPropertyChangeChange}
          dropdownClass="permit-types"
        />
        <ValuationDropdown
          onReset={onResetValue}
          onSave={onSaveValue}
          value={filtersStore.searchParams.value}
          placeholder={t('searchProject.valuation')}
        />
        <MoreDropdown type={FilterState.SAVED_SEARCH} />
      </div>
    );
  }
);

export default FilterForSavedSearch;
