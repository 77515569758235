import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import MapWithMarkerStore from './MapWithMarker';
import { Location } from 'utils/types';

interface Props {
  location: Location;
}

const MapProjectInfo: FC<Props> = observer(({ location }) => {
  const map = new MapWithMarkerStore();

  useEffect(() => {
    const mapElement: HTMLElement | null = document.getElementById('mapProjectInfo');
    if (mapElement) {
      mapElement.innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
      const m = document.getElementById('map');
      if (m) {
        map.leafletMap.initMapForProjectInfo(m, location.coordinates);
        map.addMarker(location.coordinates);
      }
    }
  }, []);

  return <div id="mapProjectInfo"></div>;
});

export default MapProjectInfo;
