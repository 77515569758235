import React, { FC, ReactNode, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from '../Button';
import { IModalActions, ModalClasses } from './types';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';

interface Props {
  show: boolean;
  title?: string;
  subTitle?: string;
  deleteBtn?: boolean;
  closeModal: () => void;
  onSubmit?: (e?: React.MouseEvent) => void;
  onDelete?: () => void;
  hideFooter?: boolean;
  actions?: IModalActions;
  children: ReactNode;
  classModal?: ModalClasses;
  [key: string]: unknown;
}

const Modal: FC<Props> = ({
  show,
  title,
  subTitle,
  deleteBtn,
  actions,
  closeModal,
  onSubmit,
  children,
  onDelete,
  hideFooter = false,
  classModal,
  ...rest
}) => {
  const { t } = useTranslation();

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        initialFocus={cancelButtonRef}
        onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-y-auto bg-shadesBlue-400 bg-opacity-20"
          {...rest}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={clsx(
                  classModal?.modal,
                  'relative transform overflow-hidden rounded-sm bg-white w-112 text-left shadow-overlay transition-all sm:my-8'
                )}>
                <div className="text-dark">
                  <div className="pt-4 px-4">
                    <div className="flex w-full justify-end">
                      <CloseIcon
                        className="relative -translate-y-0.5"
                        onClick={closeModal}
                        role="button"
                      />
                    </div>
                    <div className="-mt-4 w-11/12">
                      {title && (
                        <Dialog.Title
                          as="h3"
                          className={clsx(
                            classModal?.title,
                            'font-kraftig text-lg leading-6 overflow-hidden text-ellipsis'
                          )}>
                          {title}
                        </Dialog.Title>
                      )}
                      {subTitle && (
                        <p className={clsx(classModal?.subTitle, 'm-0 text-sm')}>{subTitle}</p>
                      )}
                    </div>
                    <div>{children && <div className="pt-7 pb-4">{children}</div>}</div>
                  </div>
                </div>
                {!hideFooter && (
                  <div className="border-t border-light-400 p-4 flex">
                    {deleteBtn && (
                      <Button type="light" color="red" onClick={onDelete}>
                        <span className="font-kraftig">{t('components.modal.delete')}</span>
                      </Button>
                    )}
                    <div className="flex w-full justify-end m-0">
                      {actions && (
                        <>
                          <Button type="secondary" className="px-6 mx-3" onClick={closeModal}>
                            <span className="font-kraftig">{actions.negative}</span>
                          </Button>
                          <Button type="primary" className="px-6" onClick={onSubmit}>
                            <span className="font-kraftig">{actions.positive}</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
