import React, { Children, FC, ReactNode, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import UserBody from './components/UserBody';
import GuestBody from './components/GuestBody';
import { SIGN_IN, SIGN_UP } from 'utils/constants';
import { clsx } from 'utils/clsx';

interface Props {
  children?: ReactNode;
}

const Header: FC<Props> = observer(({ children }) => {
  const { userStore } = useStore();
  const [, setParams] = useSearchParams();
  const { pathname } = useLocation();

  const flattenedChildren = Children.toArray(children);
  const modifiedChildren = Children.map(flattenedChildren, (child) => child);

  const handleAuthAction = useCallback(
    (type: string) => {
      if (type === SIGN_IN) {
        setParams('auth=sign-in');
        return;
      }
      if (type === SIGN_UP) {
        setParams('auth=sign-up');
        return;
      }
    },
    [pathname]
  );

  return (
    <div className="relative h-14">
      <div
        className={clsx(
          'absolute shadow-default text-dark px-4 bg-white min-h-14 left-0 right-0 z-10'
        )}>
        <div className="flex justify-between items-center w-full h-14">
          <div className="w-full">{modifiedChildren[0]}</div>
          <div>{userStore.user ? <UserBody /> : <GuestBody authAction={handleAuthAction} />}</div>
        </div>
        <div>{modifiedChildren[1]}</div>
      </div>
    </div>
  );
});
export default Header;
