import { detailIcons } from 'utils/icons';
import { IDetail } from '../../types';
import { IContact } from 'utils/types';

export const skeletonsToShow = 3;

export const mockContacts: IContact<IDetail>[] = [
  {
    id: 1,
    type: 10,
    role: 20,
    name: 'Mock Name',
    details: [
      { type: 10, value: '123123123', icon: detailIcons[10] },
      { type: 20, value: 'testemail@gmail.com', icon: detailIcons[20] },
      { type: 30, value: 'https://linkedin.com/some-test-acc', icon: detailIcons[30] },
      { type: 40, value: 'https://some-web.com/', icon: detailIcons[40] },
      { type: 50, value: 'Some mail address', icon: detailIcons[50] },
      { type: 60, value: 'Mock name', icon: detailIcons[60] }
    ],
    favourite: false
  },
  {
    id: 2,
    type: 10,
    role: 10,
    name: 'Mock Name',
    details: [
      { type: 10, value: '123123123', icon: detailIcons[10] },
      { type: 20, value: 'testemail@gmail.com', icon: detailIcons[20] },
      { type: 30, value: 'https://linkedin.com/some-test-acc', icon: detailIcons[30] },
      { type: 40, value: 'https://some-web.com/', icon: detailIcons[40] },
      { type: 50, value: 'Some mail address', icon: detailIcons[50] },
      { type: 60, value: 'Mock name', icon: detailIcons[60] }
    ],
    favourite: false
  },
  {
    id: 3,
    type: 10,
    role: 30,
    name: 'Mock Name',
    details: [
      { type: 10, value: '123123123', icon: detailIcons[10] },
      { type: 20, value: 'testemail@gmail.com', icon: detailIcons[20] },
      { type: 30, value: 'https://linkedin.com/some-test-acc', icon: detailIcons[30] },
      { type: 40, value: 'https://some-web.com/', icon: detailIcons[40] },
      { type: 50, value: 'Some mail address', icon: detailIcons[50] },
      { type: 60, value: 'Mock name', icon: detailIcons[60] }
    ],
    favourite: false
  }
];
