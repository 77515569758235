import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, Modal } from 'components';
import { RemoveType } from './enums';
import { Error, IModalData, ISavedSearch } from './types';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { Search } from '../Search';
import ProgressBar from 'components/ProgressBar';
import { useProgress } from 'hooks/useProgress';
import { ESCAPE } from 'components/SearchList/constants';
import ShareModal from 'modules/ShareModal';
import Header from 'view/Header';
import { SharedEntityType } from 'modules/ShareModal/types';
import { IAxiosError, IAxiosErrorResponse, SearchType } from 'utils/types';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import WarningModal from './components/WarningModal';
import { useNavigate } from 'react-router-dom';
import ErrorModal from './components/ErrorModal';
import { use } from 'i18next';

const SavedSearches: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { savedSearchesStore, alertStore, userStore } = useStore();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showShareWithId, setShowShareWithId] = useState<{
    id: number;
    type: SearchType;
  } | null>(null);
  const [isSearchesLoading, setIsSearchesLoading] = useState<boolean>(false);
  // const [currentName, setCurrentName] = useState<string>('');
  const [currentSavedSearch, setCurrentSavedSearch] = useState<ISavedSearch | null>(null);
  const [itemToEdit, setItemToEdit] = useState<null | number>(null);
  const [itemToDelete, setItemToDelete] = useState<null | number>(null);
  const [errorType, setErrorType] = useState<Error | null>(null);
  const [modalData, setModalData] = useState<IModalData>({
    title: '',
    subtitle: '',
    negativeAction: t('savedSearch.cancel'),
    positiveAction: t('savedSearch.update')
  });
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { progress, visible } = useProgress(isSearchesLoading);

  // const [actionType, setActionType] = useState<ActionType>(ActionType.EDIT);
  const [removeType, setRemoveType] = useState<RemoveType>(RemoveType.PERSONAL_SAVED_SEARCH);

  const onModalDataChange = useCallback((name: string, value: string): void => {
    setModalData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const savedSearches = savedSearchesStore.savedSearches;

  const handleDelete = (currentItem: ISavedSearch, type: RemoveType): void => {
    if (!currentItem) return;
    onModalDataChange('title', `${t('savedSearch.wantDelete')} ${currentItem.name}`);
    onModalDataChange('subtitle', t('savedSearch.cantUndo'));
    onModalDataChange('positiveAction', t('savedSearch.delete'));
    // setActionType(ActionType.DELETE);
    setRemoveType(type);
    setShowModal(true);
    setItemToDelete(currentItem.id);
    const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
    setTimeout(() => target?.focus(), 0);
  };

  // TODO: remove after test
  // const handleEdit = (currentItem: ISavedSearch): void => {
  //   if (!currentItem) return;
  //   onModalDataChange('title', t('savedSearch.edit'));
  //   onModalDataChange('subtitle', '');
  //   onModalDataChange('positiveAction', t('savedSearch.update'));
  //   setCurrentName(currentItem.name);
  //   setActionType(ActionType.EDIT);
  //   setShowModal(true);
  //   setItemToEdit(currentItem.id);
  //   const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
  //   setTimeout(() => target?.focus(), 0);
  // };

  const handleEdit = (id: number) => {
    navigate(`/saved-search/${id}`, { state: { edit: true }, replace: true });
  };

  const handleClone = useCallback(async (currentItem: ISavedSearch) => {
    savedSearchesStore.setSubscribeLocations([]);
    setCurrentSavedSearch(currentItem);
    if (
      currentItem.searchType === SearchType.PROJECT ||
      currentItem.searchType === SearchType.COMPANY
    ) {
      await savedSearchesStore.hasSubscription(currentItem.filters.locations);
    }
    if (!savedSearchesStore.subscribeLocations.length) {
      await cloneSavedSearch(currentItem);
      return;
    }
    if (savedSearchesStore.subscribeLocations.length) {
      setShowWarning(true);
      return;
    }
  }, []);

  const handleRefuse = useCallback(async () => {
    setShowWarning(false);
    if (currentSavedSearch && partialSubscription()) {
      await cloneSavedSearch(currentSavedSearch);
    }
    // TODO: remove after test
    // if (currentSavedSearch) {
    //   await cloneSavedSearch(currentSavedSearch);
    // }
    // savedSearchesStore.setSubscribeLocations([]);
  }, [currentSavedSearch, savedSearchesStore.subscribeLocations]);

  const partialSubscription = useCallback((): boolean => {
    return !!(
      currentSavedSearch &&
      savedSearchesStore.subscribeLocations.length > 0 &&
      currentSavedSearch.filters.locations.length > savedSearchesStore.subscribeLocations.length
    );
  }, [currentSavedSearch, savedSearchesStore.subscribeLocations]);

  const handleAccept = () => {
    setShowWarning(false);
    setShowPlan(true);
  };

  const cloneAfterPayment = useCallback(async () => {
    setShowPlan(false);
    if (currentSavedSearch) {
      await cloneSavedSearch(currentSavedSearch);
    }
    savedSearchesStore.setSubscribeLocations([]);
  }, [currentSavedSearch]);

  const closePlanModal = () => {
    savedSearchesStore.setSubscribeLocations([]);
    setShowPlan(false);
  };

  const cloneSavedSearch = useCallback(async (currentSearch: ISavedSearch) => {
    if (!userStore.user || !currentSearch) return;
    try {
      await savedSearchesStore.clone(userStore.user.id, currentSearch.id);
      alertStore.successAlert(
        `${t('savedSearch.cloneSuccessStart')} ${currentSearch.name} ${t(
          'savedSearch.cloneSuccessEnd'
        )}`
      );
    } catch (e) {
      const errorMessage: string = (e as IAxiosError<IAxiosErrorResponse>).response.data.message;
      alertStore.errorAlert(errorMessage);
    }
  }, []);

  const closeModal = (): void => {
    setItemToEdit(null);
    setItemToDelete(null);
    setShowModal(false);
  };

  //TODO: need remove if the future
  // const onTitleSavedChange = (name: string, str: string): void => {
  //   setCurrentName(str);
  // };

  //TODO: need remove if the future
  // const handleEditSearch = useCallback(async () => {
  //   try {
  //     if (!userStore.user || !itemToEdit) return;
  //     await savedSearchesStore.edit(userStore.user.id, itemToEdit, currentName);
  //     alertStore.successAlert(t('savedSearch.editSuccess'));
  //   } finally {
  //     closeModal();
  //   }
  // }, [currentName, itemToEdit]);

  const handleDeleteSearch = useCallback(async () => {
    try {
      if (!userStore.user || !itemToDelete) return;
      if (removeType === RemoveType.PERSONAL_SAVED_SEARCH) {
        await savedSearchesStore.delete(userStore.user.id, itemToDelete);
      }
      if (removeType === RemoveType.SHARE_SAVED_SEARCH) {
        await savedSearchesStore.deleteShareSavedSearch(userStore.user.id, itemToDelete);
      }
      alertStore.successAlert(t('savedSearch.deleteSuccess'));
      await loadSearches();
      // TODO: remove after test
      // await savedSearchesStore.getNewProjectCount(userStore.user.id);
    } finally {
      closeModal();
    }
  }, [itemToDelete]);

  const loadSearches = useCallback(async () => {
    try {
      if (!userStore.user) return;
      setIsSearchesLoading(true);
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    } finally {
      setIsSearchesLoading(false);
    }
  }, []);

  const keyPress = (e: KeyboardEvent) => {
    if (e.code === ESCAPE && showModal) setShowModal(false);
  };

  const handleShare = (id: number, type: SearchType): void => {
    setShowShareWithId({ id, type });
  };

  const errorMessage = useCallback((): string => {
    if (errorType === Error.EDIT) {
      return t('savedSearch.modal.editError');
    }
    if (errorType === Error.SHARE) {
      return t('savedSearch.modal.shareError');
    }
    return '';
  }, [errorType]);

  useLayoutEffect(() => {
    loadSearches().then();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    return () => {
      window.removeEventListener('keydown', keyPress);
      savedSearchesStore.setSubscribeLocations([]);
    };
  }, []);

  if (!visible)
    return (
      <div className="relative -top-1">
        <ProgressBar progress={progress} />
      </div>
    );
  if (!savedSearchesStore.savedSearches.length) {
    return <Search />;
  }

  return (
    <div className="h-screen overflow-y-scroll">
      <Header>
        <h2 className="m-0 text-lg leading-[3.5rem]">{t('savedSearchManage.savedSearches')}</h2>
      </Header>
      <div className="mx-4 my-3">
        {savedSearches.map((item: ISavedSearch, idx: number) => (
          <ListItem
            data={item}
            onShare={handleShare}
            onDelete={(item) => handleDelete(item, RemoveType.PERSONAL_SAVED_SEARCH)}
            isEdit={!item.isShared}
            isShare={!item.isShared}
            onClone={handleClone}
            onEdit={handleEdit}
            key={`item-list-${item.id}`}
            dataTestId={idx + 1}
          />
        ))}
      </div>
      <WarningModal
        show={showWarning}
        partialSubscription={partialSubscription()}
        locations={currentSavedSearch ? currentSavedSearch.filters.locations : null}
        hasSubscriptionLocations={savedSearchesStore.subscribeLocations}
        onClose={() => setShowWarning(false)}
        onRefuse={handleRefuse}
        onAccept={handleAccept}
      />
      <ErrorModal show={!!errorType} message={errorMessage()} onClose={() => setErrorType(null)} />
      <ChoosePlanModal
        show={showPlan}
        locations={savedSearchesStore.subscribeLocations}
        closeModal={closePlanModal}
        onUpdate={cloneAfterPayment}
      />
      <div ref={modalRef}>
        <Modal
          show={showModal}
          title={modalData.title}
          subTitle={modalData.subtitle}
          deleteBtn={false}
          actions={{
            negative: modalData.negativeAction,
            positive: modalData.positiveAction
          }}
          // onSubmit={itemToEdit ? handleEditSearch : handleDeleteSearch}
          onSubmit={handleDeleteSearch}
          data-test-element="saved-search-modal"
          closeModal={closeModal}>
          {/*{actionType === ActionType.EDIT && (*/}
          {/*  <Input*/}
          {/*    name=""*/}
          {/*    type="text"*/}
          {/*    label={t('savedSearchManage.editLabel')}*/}
          {/*    placeholder={t('savedSearchManage.editPlaceholder')}*/}
          {/*    value={currentName}*/}
          {/*    onInput={onTitleSavedChange}*/}
          {/*    data-test-element="saved-search-edit-input"*/}
          {/*  />*/}
          {/*)}*/}
        </Modal>
        <ShareModal
          entityType={SharedEntityType.SAVED_SEARCH}
          isShowWithId={showShareWithId?.id ?? null}
          closeModal={() => setShowShareWithId(null)}
          searchType={showShareWithId?.type}
        />
      </div>
    </div>
  );
});

export default SavedSearches;
