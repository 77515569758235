import React, { FC, useCallback } from 'react';
import {
  INotification,
  NotificationTypes,
  ProjectUpdateNotification,
  SavedSearchNotification
} from '../types';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';
import classes from '../Notification.module.scss';
import moment from 'moment';

interface Props {
  notification: INotification;
}

const NotificationItem: FC<Props> = ({ notification }) => {
  const { t } = useTranslation();

  const notificationBody = useCallback(() => {
    // TODO: need refactoring in the future (condition by type)
    if ((notification.data as SavedSearchNotification).savedSearchName) {
      return (
        <>
          <p className="mb-1 text-sm">
            <span>{(notification.data as SavedSearchNotification).savedSearchName}</span>{' '}
            {t('notification.savedSearch')}
          </p>
          <h6 className="m-0 text-dark">
            {(notification.data as SavedSearchNotification).newProjectsCount}{' '}
            {t('notification.newProjects')}
          </h6>
        </>
      );
    }
    if (notification.type === NotificationTypes.CROSS_IMPORT_PROCESSED) {
      return (
        <>
          <p className="mb-1 text-sm">{t('notification.listUploaded')}</p>
          <h6 className="m-0 text-dark">
            {(notification.data as ProjectUpdateNotification).message}
          </h6>
        </>
      );
    }
    if (
      notification.type === NotificationTypes.FAV_PROJECT_VALUE_UPDATED ||
      notification.type === NotificationTypes.FAV_PROJECT_STATUS_UPDATED ||
      notification.type === NotificationTypes.FAV_PROJECT_SHARED
    ) {
      return (
        <>
          <p className="mb-1 text-sm">{t('notification.favoriteList')}</p>
          <h6 className="m-0 text-dark">
            {(notification.data as ProjectUpdateNotification).message}
          </h6>
        </>
      );
    }
    return (
      <>
        <p className="mb-1 text-sm">{t('notification.projectUpdated')}</p>
        <h6 className="m-0 text-dark">
          {(notification.data as ProjectUpdateNotification).message}
        </h6>
      </>
    );
  }, [notification]);

  return (
    <div
      className={clsx(classes.notification, 'py-3 px-6 flex justify-between relative')}
      data-test-element="notification-item">
      <div>
        {!notification.seenAt && <div className="new-notification rounded-full absolute"></div>}
        {notificationBody()}
      </div>
      <div className={clsx(classes.notificationDate, 'text-sm')}>
        {moment(notification.createdAt).fromNow()}
      </div>
    </div>
  );
};

export default NotificationItem;
