import React, { FC, MouseEvent, ReactNode } from 'react';
import { clsx } from 'utils/clsx';
import { ButtonType } from './types';
import { buttonTypeToClasses } from './constants';

interface IProps {
  children: ReactNode;
  type: ButtonType;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  color?: string;
  background?: string;
  className?: string;
  isLoading?: boolean;
  disabledClass?: string;
  [key: string]: unknown;
}

const Button: FC<IProps> = ({
  children,
  type,
  disabled = false,
  color,
  className,
  onClick,
  isLoading,
  background,
  disabledClass,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        buttonTypeToClasses[type],
        'block',
        className,
        'relative',
        'rounded-sm',
        'py-2',
        'font-medium',
        disabled && 'opacity-50 pointer-events-none',
        disabled && disabledClass,
        'hover:shadow-none',
        'focus:shadow-none'
      )}
      style={{ color, ...{ [type === 'primary' ? 'backgroundColor' : 'borderColor']: background } }}
      onClick={onClick}
      {...rest}>
      {children}
      {isLoading && (
        <div
          className={clsx(
            'bg-inherit',
            'absolute',
            'inset-0',
            'after:border-t-4',
            'after:border-t-white',
            'after:rounded-full',
            'after:animate-spin',
            'after:absolute',
            'after:w-5',
            'after:h-5',
            'after:inset-0',
            'after:m-auto',
            'after:border-4',
            'after:border-t-white',
            'after:rounded-full',
            'after:border-transparent'
          )}
        />
      )}
    </button>
  );
};

export default Button;
