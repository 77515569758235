import React, { FC, useCallback } from 'react';
import classes from '../SearchList.module.scss';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { FoundLocation } from 'view/Search/types';
import { clsx } from 'utils/clsx';
import { Checkbox } from 'components';
import { ShortLocation } from 'modules/SubscriptionLocationDropdown/types';

interface IProps {
  item: FoundLocation | ShortLocation;
  handleClick: (item: FoundLocation | ShortLocation) => void;
  selectedLocationIds: number[];
  subscription?: boolean;
  disabled?: boolean;
  wrap?: boolean;
}

const SearchItem: FC<IProps> = ({
  item,
  handleClick,
  selectedLocationIds,
  subscription = false,
  disabled,
  wrap
}) => {
  const isSelectedLocation = useCallback(
    (locationId: number): string => {
      return selectedLocationIds.includes(locationId)
        ? 'text-blue-100 border border-primary bg-light-500'
        : '';
    },
    [selectedLocationIds]
  );
  return (
    // TODO: remove after test
    // onMouseDown={() => handleClick(item)}
    // onMouseUp={() => handleClick(item)}
    <li
      className={clsx(
        'relative',
        'mx-2',
        'px-2',
        'py-3',
        'flex',
        'justify-between',
        'items-center',
        'cursor-pointer',
        'text-sm',
        'border-0',
        'rounded-sm',
        'mb-1',
        'hover:text-blue-100',
        'hover:bg-light-500',
        'last:mb-0',
        isSelectedLocation(item.id),
        wrap && item.targetTitle && 'pb-4'
      )}>
      {disabled && <div className="absolute inset-0 bg-light-400 opacity-40 z-10"></div>}
      <div className="relative flex text-dark font-halbfett min-w-[228px]">
        {subscription && (
          <Checkbox
            id={item.id}
            checked={!!isSelectedLocation(item.id)}
            onChange={() => handleClick(item)}>
            {item.title}
            <span
              className={clsx(
                wrap && 'absolute top-4 left-[26px]',
                'pt-0.5 text-grey-500 text-xs font-buch'
              )}>
              {!wrap && <span className="px-1 text-grey-200">•</span>}
              {item.targetTitle}
            </span>
          </Checkbox>
        )}
        {!subscription && (
          <div onClick={() => handleClick(item)}>
            {item.title}{' '}
            <span
              className={clsx(
                wrap && 'absolute top-4 left-0',
                'pt-0.5 text-grey-500 text-xs font-buch'
              )}>
              {!wrap && <span className="px-1 text-grey-200">•</span>}
              {item.targetTitle}
            </span>
          </div>
        )}
      </div>
      {!!isSelectedLocation(item.id) && !subscription && <TickIcon className={classes.tickIcon} />}
    </li>
  );
};

export default SearchItem;
