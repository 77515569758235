import {
  IListRef,
  IProjectsData,
  IRecentProjectsData,
  ProjectType
} from 'view/SearchProjects/types';
import React, { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useListWidth } from 'hooks/useListWidth';
import { ShortLocationForPayment } from 'view/SubscriptionsAndPlans/types';
import { ClickEvent } from 'utils/types';
import { FixedSizeList as List } from 'react-window';
import { IListItem } from 'view/SearchProjects/components/LeftSidebar/types';
import ProjectInfo from 'view/SearchProjects/components/ProjectInfo';
import ReactPaginate from 'react-paginate';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { skeletonsToShow } from 'view/SearchProjects/components/LeftSidebar/mock';
import { Pagination, Skeleton } from '../index';
import { clsx } from 'utils/clsx';
import { IPageClickEvent } from 'modules/ProjectInfoModal/types';
import { useStore } from 'storesProvider/storeContext';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import classes from './ProjectList.module.scss';

interface IProps {
  projectId?: number;
  isLoading: boolean;
  type: ProjectType;
  itemSize: number;
  onTitleClick?: () => void;
  projectsData: IProjectsData | IRecentProjectsData;
  onPageClick: (pageEvent: IPageClickEvent) => void;
  listWithMap?: boolean;
  onListScroll?: () => void;
  hideContacts?: boolean;
  followMap?: boolean;
  activeId?: number | null;
  onContactsClick?: (id: number) => void;
  onUpdateProjects?: () => void;
}

const ProjectList: FC<IProps> = observer(
  ({
    projectId,
    hideContacts,
    isLoading,
    type,
    onTitleClick,
    itemSize,
    projectsData,
    onPageClick,
    listWithMap,
    onListScroll,
    onContactsClick,
    activeId,
    followMap,
    onUpdateProjects
  }) => {
    const { t } = useTranslation();
    const { projectSearchStore } = useStore();

    const listWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const projectList = useRef() as MutableRefObject<IListRef>;

    const listWidth = useListWidth(listWrapperRef);
    const listHeight = itemSize * projectsData.data.length + 45;

    const [showPlan, setShowPlan] = useState<boolean>(false);
    const [planLocation, setPlanLocation] = useState<ShortLocationForPayment | null>(null);

    const handleUnlock = useCallback((location: ShortLocationForPayment | null) => {
      setPlanLocation(location);
      setShowPlan(true);
    }, []);

    const handlePageClick = useCallback(
      async (event: ClickEvent) => {
        onPageClick({
          page: event.selected + 1,
          type: type,
          id: projectId || null
        });
      },
      [onPageClick]
    );

    useEffect(() => {
      if (!followMap) return;
      if (projectSearchStore.selectedProjectOnTheMap !== null && projectList.current) {
        projectList.current.scrollToItem(projectSearchStore.selectedProjectOnTheMap, 'start');
      }
    }, [projectSearchStore.selectedProjectOnTheMap]);

    if (isLoading) {
      return <Skeleton type="project" itemsToShow={skeletonsToShow} />;
    }

    return (
      <>
        {/*TODO: with tailwind*/}
        {/*<div*/}
        {/*  ref={listWrapperRef}*/}
        {/*  onScroll={onListScroll}*/}
        {/*  className={clsx(*/}
        {/*    classes.projectsWrapper,*/}
        {/*    'relative',*/}
        {/*    'bg-white',*/}
        {/*    'overflow-x-scroll',*/}
        {/*    listClassName*/}
        {/*  )}>*/}
        <div
          ref={listWrapperRef}
          onScroll={onListScroll}
          className={clsx(
            listWithMap ? classes.projectsWrapperWithMap : classes.projectsWrapper,
            classes.projectsWrapperScroll,
            'relative'
          )}>
          {projectsData.data.length > 0 ? (
            <List
              className="list"
              height={listHeight}
              itemCount={projectsData.data.length}
              itemSize={itemSize}
              width={listWidth || 652}
              ref={projectList}>
              {({ index, style }: IListItem) => (
                <ProjectInfo
                  style={style}
                  type={type}
                  key={projectsData.data[index].id}
                  project={projectsData.data[index]}
                  active={activeId === projectsData.data[index].id}
                  hideContacts={hideContacts}
                  onSelectItem={onContactsClick}
                  onTitleClick={onTitleClick}
                  index={index}
                  listRef={projectList}
                  isLocked={!projectsData.data[index].hasFullData}
                  handleUnlock={!projectsData.data[index].hasFullData ? handleUnlock : null}
                />
              )}
            </List>
          ) : (
            <div className="p-4 text-center">{t('components.projectInfo.emptyProjects')}</div>
          )}
          {type === 'recentProjects' && (projectsData as IRecentProjectsData).totalPages > 1 && (
            <div
              style={{ width: listWidth || 652 }}
              className="fixed bottom-0 bg-white z-10 border-t border-light-600">
              <Pagination
                onPageClick={handlePageClick}
                currentPage={projectsData.currentPage}
                totalPages={(projectsData as IRecentProjectsData).totalPages}
              />
            </div>
            // TODO: Remove after test
            // <div className="fixed bottom-0 inset-x-0 bg-white z-10 border-t border-light-600">
            //   <ReactPaginate
            //     breakLabel="..."
            //     nextLabel={<ArrowIcon data-direction="left" />}
            //     previousLabel={<ArrowIcon data-direction="right" />}
            //     onPageChange={handlePageClick}
            //     forcePage={projectsData.currentPage - 1}
            //     pageRangeDisplayed={1}
            //     marginPagesDisplayed={1}
            //     pageCount={(projectsData as IRecentProjectsData).totalPages}
            //     containerClassName="pagination mb-2 justify-center"
            //     activeClassName="active"
            //     activeLinkClassName="active"
            //     breakClassName="page-item"
            //     breakLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
            //     pageClassName="page-item mx-1"
            //     pageLinkClassName="page-link m-0 p-0 flex justify-center items-center pagination-item"
            //     previousClassName="page-item mx-1"
            //     previousLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
            //     nextClassName="page-item mx-1"
            //     nextLinkClassName="page-link m-0 px-2 h-6 flex justify-center items-center"
            //   />
            // </div>
          )}
        </div>
        <ChoosePlanModal
          show={showPlan}
          locations={planLocation ? [planLocation] : planLocation}
          closeModal={() => setShowPlan(false)}
          onUpdate={onUpdateProjects}
        />
      </>
    );
  }
);

export default ProjectList;
