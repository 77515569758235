import React, { FC, useEffect, Fragment, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { Listbox, Transition } from '@headlessui/react';
import { clsx } from 'utils/clsx';
import SearchItem from 'components/SearchList/components/SearchItem';
import { ShortLocation } from './types';
import classes from './SubscriptionLocationDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';

interface Props {
  savedSearchId?: number | null;
}

const SubscriptionLocationDropdown: FC<Props> = observer(({ savedSearchId }) => {
  const { subscriptionLocationDropdownStore, filtersStore } = useStore();
  const { t } = useTranslation();
  const [dropdownReady, setDropdownReady] = useState<boolean>(false);
  // const [currentPage, setCurrentPage] = useState<number>(1);

  // const observableDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDropdownReady(false);
    subscriptionLocationDropdownStore.getSubscribedLocations(savedSearchId).then(() => {
      subscriptionLocationDropdownStore.initSelectedLocations(
        filtersStore.originalFilters?.locations || []
      );
      setDropdownReady(true);
    });
    return () => {
      subscriptionLocationDropdownStore.selected = [];
    };
  }, []);

  // useIntersectionObserverEffect(
  //   observableDiv,
  //   (entry: IntersectionObserverEntry) => {
  //     console.log('useIntersectionObserverEffect', entry);
  //     if (entry.isIntersecting && subscriptionLocationDropdownStore.data.hasMore) {
  //       subscriptionLocationDropdownStore.setNextPage();
  //     }
  //   },
  //   [observableDiv.current, subscriptionLocationDropdownStore.data.hasMore]
  // );

  const handleChange = useCallback((item: ShortLocation) => {
    subscriptionLocationDropdownStore.setSelected(item);
    filtersStore.setSearchParams(
      'locations',
      subscriptionLocationDropdownStore.selected.map((location) => location.id)
    );
  }, []);

  const locationList = useCallback((): ShortLocation[] => {
    const selectedIds = subscriptionLocationDropdownStore.selected.map((location) => location.id);
    return subscriptionLocationDropdownStore.data.data.filter(
      (location) => !selectedIds.includes(location.id)
    );
  }, []);

  const selectedTitles = useCallback((): string => {
    if (!subscriptionLocationDropdownStore.selected.length) {
      return 'Select Location';
    }
    return subscriptionLocationDropdownStore.selected.map((location) => location.title).join(', ');
  }, []);

  if (!dropdownReady) {
    return (
      <div className="loader">
        <div />
      </div>
    );
  }

  return (
    <div>
      <Listbox value={subscriptionLocationDropdownStore.selected} multiple>
        {({ open }) => (
          <>
            <div className="relative mr-2">
              <Listbox.Button
                className={clsx(
                  subscriptionLocationDropdownStore.selected.length
                    ? 'border-primary'
                    : 'border-grey-200',
                  'relative w-[335px] cursor-pointer py-0.5 pl-3 pr-14 text-left bg-white text-dark border-2 rounded-sm duration-200 focus:outline-none sm:text-sm sm:leading-6'
                )}>
                <span className="block truncate font-kraftig">{selectedTitles()}</span>
                {subscriptionLocationDropdownStore.selected.length > 2 && (
                  <span className="absolute inset-y-1 right-7.5 bg-primary rounded-sm text-white flex justify-center items-center px-1">
                    +{subscriptionLocationDropdownStore.selected.length - 2}
                  </span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ArrowIcon className={clsx(open ? 'rotate-180' : 'rotate-0', 'duration-200')} />
                </span>
              </Listbox.Button>

              <Transition show={open} as={Fragment}>
                <Listbox.Options
                  className={clsx(
                    classes.scroll,
                    'absolute z-10 mt-1 max-h-64 w-[335px] overflow-auto bg-white text-base shadow-options focus:outline-none sm:text-sm'
                  )}>
                  <div
                    className={clsx(
                      'uppercase',
                      'px-2',
                      'py-2',
                      'text-grey-500',
                      'bg-light-300',
                      'text-xxs',
                      subscriptionLocationDropdownStore.data.total > 0 && 'mb-2'
                    )}>
                    <span className="font-kraftig">
                      {!savedSearchId && t('savedSearch.searchSubscriptionsResults')}
                      {savedSearchId && t('savedSearch.yourLocations')}
                      <span className="ml-2 text-primary border border-light-600 py-0.5 px-3 text-xs rounded-3xl">
                        {subscriptionLocationDropdownStore.data.total}
                      </span>
                    </span>
                  </div>
                  {subscriptionLocationDropdownStore.selected.map((location, idx) => (
                    <SearchItem
                      item={location}
                      key={`search-item-${idx}-${location.id}-${location.targetTitle
                        ?.toLowerCase()
                        .split(' ')
                        .join('')}`}
                      handleClick={(item) => handleChange(item as ShortLocation)}
                      selectedLocationIds={subscriptionLocationDropdownStore.selected.map(
                        (location) => location.id
                      )}
                      subscription={true}
                      disabled={subscriptionLocationDropdownStore.selected.length === 1}
                    />
                  ))}
                  {locationList().map((location, idx) => (
                    <SearchItem
                      item={location}
                      key={`search-item-${idx}-${location.id}-${location.targetTitle
                        ?.toLowerCase()
                        .split(' ')
                        .join('')}`}
                      handleClick={(item) => handleChange(item as ShortLocation)}
                      selectedLocationIds={[]}
                      subscription={true}
                    />
                  ))}
                  {/*<div ref={observableDiv} className="bg-dark h-1"></div>*/}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
});

export default SubscriptionLocationDropdown;
