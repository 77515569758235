import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from 'components/Select/Select.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { IInputChangeItem } from '../types';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helpers/childOf';

interface IProps {
  value: {
    min: number | null;
    max: number | null;
  } | null;
  onSave(value: IInputChangeItem): void;
  onReset(): void;
}

const CountDropdown: FC<IProps> = ({ value, onSave, onReset }) => {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [form, setForm] = useState<{ min: string; max: string }>({
    min: value?.min ? String(value.min) : '',
    max: value?.max ? String(value.max) : ''
  });

  const selectRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  const toggleSelect = useCallback((e: React.MouseEvent): void => {
    if (e.nativeEvent.composedPath().includes(formRef.current as EventTarget)) return;
    setIsOpened((prev) => !prev);
  }, []);

  const handleInput = useCallback((name: string, val: string) => {
    setForm((prev) => ({ ...prev, [name]: val.replace(/\D/g, '') }));
  }, []);

  const handleReset = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpened(false);
    onReset();
    setForm({ max: '', min: '' });
  }, []);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(selectRef.current as EventTarget)) {
      setIsOpened(false);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    onSave({ min: form.min ? Number(form.min) : null, max: form.max ? Number(form.max) : null });
    setIsOpened(false);
  }, [form]);

  const keyPress = useCallback((e: KeyboardEvent): void => {
    if (e.code === ENTER) {
      if (
        !childOf(document.activeElement, selectRef.current) &&
        document.activeElement !== selectRef.current
      )
        setIsOpened(false);
      if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
    }
  }, []);

  const handleResetPressEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.code === ENTER && document.activeElement === e.target) {
      setIsOpened(false);
      onReset();
      setForm({ max: '', min: '' });
    }
  }, []);

  const isActive = useMemo<boolean>(() => {
    return !!value?.max || !!value?.min;
  }, [value]);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    if (!isOpened)
      return () => {
        window.removeEventListener('keydown', keyPress);
      };
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    setForm({ min: value?.min ? String(value.min) : '', max: value?.max ? String(value.max) : '' });
  }, [value]);

  return (
    <div
      role="button"
      className={clsx(
        classes.select,
        'relative',
        'flex',
        'items-center',
        'justify-between',
        'border-2',
        'border-grey-200',
        'rounded-sm',
        'text-sm',
        'h-8',
        'py-0.5',
        'pr-8',
        'pl-4',
        'mr-2',
        !isOpened && 'bg-white',
        isOpened && '!bg-primary text-white border-2 border-primary',
        'select-none',
        'hover:bg-grey-100',
        isActive && 'border-2 border-primary'
      )}
      tabIndex={0}
      data-test-element="favorite-project-count-dropdown"
      onClick={toggleSelect}
      ref={selectRef}>
      <>
        <span role="button" className="block w-full font-kraftig whitespace-nowrap">
          {t('favorites.projectCount')}
        </span>
        {isOpened && (
          <div
            className={clsx(
              classes.optionWrapper,
              'w-[370px]',
              'absolute',
              'bg-white',
              'z-40',
              'overflow-x-hidden',
              'overflow-y-scroll',
              'left-0'
            )}
            ref={formRef}>
            <div className="py-4">
              <div className="flex px-4 items-center">
                <h3 className="text-dark text-sm whitespace-nowrap grow m-0">
                  {t('favorites.projectCount')}
                </h3>
                <div className="h-8 max-w-25">
                  <Input
                    type="text"
                    value={form.min}
                    onInput={handleInput}
                    name="min"
                    placeholder="No Min"
                    data-test-element="favorite-min-count"
                    inputClass="text-dark ml-auto h-8 max-w-25"
                  />
                </div>

                <div className="bg-dark h-0.5 w-3 my-0 mx-2.5 self-center" />
                <div className="h-8 max-w-25">
                  <Input
                    type="text"
                    value={form.max}
                    onInput={handleInput}
                    name="max"
                    placeholder="No Max"
                    data-test-element="favorite-max-count"
                    inputClass="text-dark ml-auto h-8 max-w-25"
                  />
                </div>
              </div>
            </div>
            <div className="bg-light-400 h-px" />
            <div className="flex justify-end p-4">
              <Button type="primary" className="px-4 text-sm py-[5px] px-0" onClick={handleSubmit}>
                {t('searchProject.done')}
              </Button>
            </div>
          </div>
        )}
      </>
      {value?.min || value?.max ? (
        <div onClick={handleReset} className="flex items-center" role="button">
          <CloseIcon
            className={clsx(classes.resetIcon, isOpened && classes.resetOpened)}
            tabIndex={0}
            onKeyDown={handleResetPressEnter}
          />
        </div>
      ) : (
        <div
          className={clsx(
            classes.icon,
            'absolute',
            'top-1/2',
            'flex',
            'items-center',
            '-translate-y-[45%]',
            'right-3',
            isOpened && classes.active
          )}
          role="button">
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};

export default CountDropdown;
