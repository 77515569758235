import React, { FC, useCallback, useEffect, useId, useState } from 'react';
import { observer } from 'mobx-react';
import { clsx } from 'utils/clsx';
import classes from './SearchLocationInput.module.scss';
import { Input, SearchList } from 'components';
import { ReactComponent as SearchIcon } from 'assets/icons/search-input.svg';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { useKeyboardCallbacks } from 'hooks/useKeyboardCallbacks';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_DELAY } from 'utils/constants';
import { usePrevious } from 'hooks/usePrevious';

interface Props {
  savedSearchId?: number | null;
}

const SearchLocationInput: FC<Props> = observer(({ savedSearchId }) => {
  const id = useId();
  const { t } = useTranslation();
  const { searchStore, userStore } = useStore();
  const [inputValue, setInputValue] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const [canLoadLocations, setCanLoadLocations] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<SearchProjects | null>(null);
  const [lastInputValue, setLastInputValue] = useState<string>('');

  const debouncedSearchTerm = useDebounce<string>(searchString, DEBOUNCE_DELAY);
  const prevTerm = usePrevious(debouncedSearchTerm);

  const controller = new AbortController();

  useEffect(() => {
    if (!searchStore.selectedLocationsInSearchList.length && searchStore.locationForMap.length) {
      searchStore.setSelectedLocation(searchStore.locationForMap);
    }
  }, [searchStore.locationForMap]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setCanLoadLocations(false);
    if (!userStore.user && searchStore.locationForMap.length) {
      setInputValue(searchStore.locationForMap[0].title);
      setSearchString(searchStore.locationForMap[0].title);
      setLastInputValue(searchStore.locationForMap[0].title);
    } else {
      setInputValue('');
      setSearchString('');
      setLastInputValue('');
    }
  }, [searchStore.locationForMap, userStore.user]);

  useEffect(() => {
    (async () => await loadLocations())();
  }, [debouncedSearchTerm]);

  const loadLocations = useCallback(
    async (loadAnyway = false) => {
      if (!prevTerm.length && !debouncedSearchTerm.length && !loadAnyway) return;
      try {
        await searchStore.searchProjects(
          debouncedSearchTerm,
          controller,
          savedSearchId || undefined
        );
        setSearchResults(searchStore.foundLocations);
        setShowSearchList(true);
      } catch (error) {
        console.log(error);
      }
      if (!canLoadLocations && !loadAnyway) {
        setCanLoadLocations(true);
        setShowSearchList(false);
      }
    },
    [debouncedSearchTerm, canLoadLocations, prevTerm]
  );

  const onSearchWrapp = async () => {
    const input = document.getElementById('header-input-search');
    input?.focus();
    await handleInputFocus();
  };

  const handleInputFocus = useCallback(async () => {
    if (showSearchList) return;
    setCanLoadLocations(true);
    await loadLocations(true);
  }, [loadLocations, showSearchList]);

  const showSearchLocations = (): string => {
    const locations = searchStore.locationForMap.length
      ? searchStore.locationForMap
      : searchStore.selectedLocationsInSearchList;
    const locationStrings = locations.map((location) => {
      if (location.title.split(',')[1]) return location.title;
      return `${location.title}, ${location.stateCode}`;
    });
    return locationStrings.join(' | ');
  };

  const setValueWithoutFetching = useCallback((value: string) => {
    setCanLoadLocations(false);
    setInputValue(value);
  }, []);

  const { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape } = useKeyboardCallbacks({
    searchResults,
    setSearchResults,
    lastInputValue,
    setLastInputValue,
    setValueWithoutFetching,
    setShowSearchList
  });

  const onKeyEnter = useCallback(() => {
    handleKeyEnter();
    const input: HTMLInputElement | null = document.querySelector(`[data-input-id="${id}"]`);
    input?.blur();
  }, [handleKeyEnter]);

  const handleInput = useCallback((name: string, value: string) => {
    setCanLoadLocations(true);
    setSearchString(value);
    setInputValue(value);
    setLastInputValue(value);
  }, []);

  const handleInputBlur = useCallback(() => {
    setTimeout(() => setShowSearchList(false), 150);
  }, []);

  const handleSearchListChange = useCallback(
    (item: FoundLocation, replace?: boolean): void => {
      // filtersStore.setSearchParams('area', []);
      if (replace) {
        searchStore.setSelectedLocation([item]);
      } else {
        searchStore.setSelectedLocation(item);
      }
      // TODO: remove after test
      // navigate(
      //   `/search-projects/${getLocationParamsFromSelectedLocation(
      //     searchStore.selectedLocationsInSearchList
      //   )}`
      // );
      setShowSearchList(false);
      setLastInputValue(item.title);
    },
    [searchStore.selectedLocationsInSearchList]
  );

  const handleCloseList = (): void => {
    setShowSearchList(false);
    setSearchResults(null);
  };

  return (
    <div
      className={clsx(
        'flex relative w-[288px] text-white bg-white bg-opacity-[21] rounded-sm',
        classes.inputWrapp
      )}>
      <div className="flex w-full text-white bg-white bg-opacity-[21] rounded-sm">
        <Input
          id="header-input-search"
          tabIndex={0}
          data-input-id={id}
          autoComplete="off"
          name="search"
          type="text"
          value={inputValue}
          onInput={handleInput}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          data-test-element="header-search-input"
          searchIcon={true}>
          {/*TODO: maybe needed in the future*/}
          {/*onKeyDown={handleKeyDown}*/}
          {!!showSearchLocations().length && (
            <div className={clsx('px-3', classes.searchTagsWrapp)} onClick={onSearchWrapp}>
              <SearchIcon />
              <span className={clsx(classes.locationTags, 'ml-2')}>{showSearchLocations()}</span>
            </div>
          )}
          {!showSearchLocations().length && (
            <div
              data-input-id={id}
              className={
                'flex w-full items-center justify-center text-dark text-opacity-80 h-full ease-linear duration-200 hover:bg-white hover:bg-opacity-[.21]'
              }>
              <SearchIcon />
              &nbsp;{t('components.header.search')}
            </div>
          )}
        </Input>
      </div>
      <SearchList
        listResponse={searchResults}
        selectedLocation={searchStore.selectedLocationsInSearchList}
        onChange={handleSearchListChange}
        styleTop="42"
        wrap={true}
        show={showSearchList}
        closeList={handleCloseList}
        inputId={id}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        onKeyEnter={onKeyEnter}
        onKeyEscape={handleKeyEscape}
      />
    </div>
  );
});

export default SearchLocationInput;
