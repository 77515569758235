import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../SubHeader.module.scss';
import { SearchProjectView } from '../types';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';

interface Props {
  isView: SearchProjectView;
  onSelectView: (vies: SearchProjectView) => void;
}

const ViewSwitcher: FC<Props> = ({ isView, onSelectView }) => {
  return (
    <div
      className={clsx(
        classes.viewSwitcher,
        'bg-white',
        'shadow-default',
        'flex',
        'text-sm',
        'text-dark',
        'font-kraftig'
      )}>
      <div className="flex items-center">
        <input
          type="radio"
          id="radio-1"
          name="radio"
          checked={isView === SearchProjectView.MAP}
          onChange={() => onSelectView(SearchProjectView.MAP)}
        />
        <label htmlFor="radio-1" className="flex cursor-pointer items-center">
          <LocationIcon />
          <span className="pl-2">Map</span>
        </label>
      </div>
      <div className="flex items-center">
        <input
          type="radio"
          id="radio-2"
          name="radio"
          checked={isView === SearchProjectView.LIST}
          onChange={() => onSelectView(SearchProjectView.LIST)}
        />
        <label htmlFor="radio-2" className="flex cursor-pointer items-center">
          <ListIcon />
          <span className="block pl-2">List</span>
        </label>
      </div>
    </div>
  );
};

export default ViewSwitcher;
