import { IExportDataService, IExportDataRepo, EXPORT_DATA_REPO, ExportData } from './types';
import { injector } from 'utils/injector';

export class ExportDataService implements IExportDataService {
  private _exportDataRepo: IExportDataRepo = injector.get<IExportDataRepo>(EXPORT_DATA_REPO);

  async runExport(userId: number, searchId: number): Promise<void> {
    await this._exportDataRepo.runExport(userId, searchId);
  }

  async getExportFile(userId: number, searchId: number): Promise<ExportData> {
    return (await this._exportDataRepo.getExportFile(userId, searchId)).data.result;
  }
}
