import {
  IAxiosResponse,
  ObjectValues,
  ILocation,
  Status,
  IContact,
  ISort,
  UsableLocation,
  SearchType,
  SearchStringType
} from 'utils/types';
import { ISavedSearch } from 'view/SavedSearches/types';
import { ISearchParams } from 'modules/Filter/types';
import { ICompaniesListView } from 'view/CompanySearch/types';
import { ShortLocationForPayment } from 'view/SubscriptionsAndPlans/types';

export const SAVED_SEARCH_RESULTS_REPO = 'SAVED_SEARCH_RESULTS_REPO';
export interface ISavedSearchResultsRepo {
  getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orderParams: ObjectValues<string>,
    type: SearchStringType
  ): Promise<IAxiosResponse<ISavedSearchData | ICompaniesListView>>;
  getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ISavedSearchData>>;
  getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ICompaniesListView>>;
  getShareSavedSearch<T>(
    searchType: SearchStringType,
    token: string,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<IShareSavedSearchResponse<T>>>;
  updateFilters<T>(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<IAxiosResponse<T>>;
}

export const SAVED_SEARCH_RESULTS_SERVICE = 'SAVED_SEARCH_RESULTS_SERVICE';
export interface ISavedSearchResultsService {
  // getSavedSearchesProjectsBySearchType(
  //   userId: number,
  //   ids: number[],
  //   page: number,
  //   orders: ISort[]
  // ): Promise<ISavedSearchResponse<IRow[]>>;
  getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orders: ISort[],
    type: SearchStringType
  ): Promise<ISavedSearchData | ICompaniesListView>;
  getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<ISavedSearchData>;
  getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): Promise<ICompaniesListView>;
  getShareProjectSavedSearch(
    token: string,
    page: number,
    orders: ISort[]
  ): Promise<IShareSavedSearchResponse<ISavedSearchData>>;
  getShareCompanySavedSearch(
    token: string,
    page: number,
    orders: ISort[]
  ): Promise<IShareSavedSearchResponse<ICompaniesListView>>;
  updateFilters<T>(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<T | null>;
  // updateCompanyFilters(
  //   userId: number,
  //   searchId: number,
  //   filters: ISearchParams,
  //   searchType: SearchType,
  //   controller?: AbortController
  // ): Promise<ICompaniesListView>;
}

export interface ISavedSearchResultsStore {
  projects: ISavedSearchData | null;
  // totalPages: number;
  // tableRows: IRow[];
  unsubscribedLocations: ShortLocationForPayment[];
  getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orders: ISort[],
    type: SearchStringType
  ): Promise<void>;
  getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): void;
  getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: number[],
    page: number,
    orders: ISort[]
  ): void;
  getShareProjectSavedSearch(token: string, page: number, orders: ISort[]): Promise<void>;
  getShareCompanySavedSearch(token: string, page: number, orders: ISort[]): Promise<void>;
  updateFilters(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<void>;
}

export interface ISavedSearchData {
  locations: UsableLocation[];
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: ISavedSearchResultsProject[];
}

// export interface IShareSavedSearchData {
//   count: number;
//   currentPage: number;
//   limit: number;
//   totalPages: number;
//   data: ISavedSearchResultsProject[];
//   // dataTable: T;
// }

export interface ISavedSearchResultsProject {
  id: number;
  hasNotes: boolean;
  class: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  description: string;
  subType: {
    id: number;
    name: string;
  };
  status: Status;
  createdAt: string;
  updatedAt: string;
  effectiveDate: string;
  value: number;
  locations: ILocation;
  contacts: IContact[];
  new: boolean;
  favourite: boolean;
}

export interface IShareSavedSearchResponse<T> {
  locations: UsableLocation[];
  data: T;
  savedSearchData: ISavedSearch;
  searchType: SearchType;
}
