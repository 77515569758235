import React, { FC, useCallback } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  Table as RTable
} from '@tanstack/react-table';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/close.svg';
import { Button } from 'components';
import { NewTable } from 'components';
import { MatchesData, MatchOperation, MatchOperationData, UNRECOGNIZED_STATE } from '../types';
import { AllEntityTypes } from 'utils/types';
import { clsx } from 'utils/clsx';
import { ReactComponent as LocationIcon } from 'assets/icons/location-grey.svg';

interface Props {
  data: MatchesData[];
  onMatchOperation: (data: MatchOperationData) => void;
  matchData: MatchOperationData[];
}

const Table: FC<Props> = ({ data, onMatchOperation, matchData }) => {
  const columnHelper = createColumnHelper<MatchesData>();

  const matchOperation = (
    userContactId: number,
    contactId: number,
    type: AllEntityTypes,
    operation: MatchOperation
  ) => {
    onMatchOperation({
      userContactId,
      contactId,
      type,
      operation
    });
  };

  const findMatch = useCallback(
    (userContactId: number): MatchOperationData | null => {
      return matchData.find((item) => item.userContactId === userContactId) || null;
    },
    [matchData]
  );

  const getColor = (
    userContactId: number,
    contactId: number,
    operation: MatchOperation
  ): string => {
    const currentBtn = findMatch(userContactId);

    if (!!currentBtn && currentBtn?.contactId !== contactId) {
      return '!text-[#acadb0]';
    }
    if (!!currentBtn && currentBtn?.contactId === contactId) {
      return currentBtn.operation === 'accept' && operation === 'accept'
        ? '!text-green-300'
        : currentBtn.operation === 'decline' && operation === 'decline'
        ? '!text-red-100'
        : '!text-[#acadb0]';
    }
    return '!text-dark';
  };

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Imported Entity',
      cell: (info) => {
        return (
          <div className="w-64">
            <div>{info.getValue()}</div>
            {info.row.original.locationTitle &&
              info.row.original.locationTitle !== UNRECOGNIZED_STATE && (
                <div className="flex items-center -ml-1.5">
                  <LocationIcon />
                  <span>{info.row.original.locationTitle}</span>
                </div>
              )}
          </div>
        );
      },
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('merged', {
      header: '',
      cell: () => (
        <div className="w-5 flex justify-center items-center">
          <ArrowIcon className="rotate-90 mt-2" />
        </div>
      ),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('data', {
      header: () => 'Project Radar Result',
      cell: (info) =>
        info.getValue().map((item, idx) => (
          <div className="block mb-4 w-64" key={idx}>
            <div>{item.name}</div>
            {item.locationTitle && item.locationTitle !== UNRECOGNIZED_STATE && (
              <div className="flex items-center -ml-1.5">
                <LocationIcon />
                <span>{item.locationTitle}</span>
              </div>
            )}
          </div>
        )),
      enableSorting: false,
      footer: (info) => info.column.id
    }),
    columnHelper.accessor('userContactId', {
      header: '',
      cell: (info) => {
        return info.row.original.data.map((item) => {
          return (
            <div
              className={clsx(
                'flex align-top justify-end relative',
                item.locationTitle && item.locationTitle !== UNRECOGNIZED_STATE ? 'mb-10' : 'mb-4'
              )}
              key={item.contactId}>
              <Button
                type="light"
                className={clsx(
                  'text-sm',
                  'flex',
                  'items-center',
                  'mr-4',
                  '!py-0',
                  getColor(info.row.original.userContactId, item.contactId, 'accept')
                )}
                onClick={() =>
                  matchOperation(
                    info.row.original.userContactId,
                    item.contactId,
                    item.type,
                    'accept'
                  )
                }>
                <TickIcon
                  stroke={
                    findMatch(info.row.original.userContactId)?.contactId === item.contactId &&
                    findMatch(info.row.original.userContactId)?.operation === 'accept'
                      ? '#00C47D'
                      : findMatch(info.row.original.userContactId)
                      ? '#acadb0'
                      : '#2F3139'
                  }
                  className="mr-1"
                />
                Merge
              </Button>
              <Button
                type="light"
                className={clsx(
                  'text-sm',
                  'flex',
                  'items-center',
                  '!py-0',
                  getColor(info.row.original.userContactId, item.contactId, 'decline')
                )}
                onClick={() =>
                  matchOperation(
                    info.row.original.userContactId,
                    item.contactId,
                    item.type,
                    'decline'
                  )
                }>
                <RejectIcon
                  stroke={
                    findMatch(info.row.original.userContactId)?.contactId === item.contactId &&
                    findMatch(info.row.original.userContactId)?.operation === 'decline'
                      ? '#E35656'
                      : findMatch(info.row.original.userContactId)
                      ? '#acadb0'
                      : '#2F3139'
                  }
                />
                Reject
              </Button>
            </div>
          );
        });
      },
      enableSorting: false,
      footer: (info) => info.column.id
    })
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return <NewTable table={table as RTable<unknown>} tableClass="!max-h-[446px] matchTable" />;
};

export default Table;
