import { DetailInfo, IMapRepo } from './types';
import { axiosInstance } from 'utils/API';
import { IAxiosResponse } from 'utils/types';

export class MapRepo implements IMapRepo {
  async getLocationInfo(locationIds: number): Promise<IAxiosResponse<DetailInfo[]>> {
    return axiosInstance.get(`locations/info`, { params: { locationIds: [locationIds] } });
  }
  async getNeighbourLocations(locationId: number): Promise<IAxiosResponse<DetailInfo[]>> {
    return axiosInstance.get(`/locations/${locationId}/neighbours`);
  }
}
