import { useCallback, useEffect, useState } from 'react';

export const useProgress = (loading: boolean) => {
  const [progress, setProgress] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);

  const runTimer = useCallback(() => {
    setVisible(false);
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = Math.random() * 10;
        return Number(Math.min(oldProgress + diff, 99).toFixed(0));
      });
    }, 20);
  }, [loading]);

  useEffect(() => runTimer(), []);

  useEffect(() => {
    if (!loading && progress === 99) {
      setProgress(100);
      setTimeout(() => {
        setVisible(true);
      }, 600);
    }
  }, [loading, progress]);

  return { progress, visible, reload: runTimer };
};
