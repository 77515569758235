import React, { FC, useCallback, useEffect, useId, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Logo from 'assets/icons/logo-header.svg';
import { Button, Dropdown, Input, Nav, SearchList } from 'components';
import classes from '../Header.module.scss';
import searchIcon from 'assets/icons/search.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { INavItems } from 'components/Nav/types';
import { ReactComponent as UserDarkIcon } from 'assets/icons/user-dark.svg';
import { ReactComponent as SavedSearchIcon } from 'assets/icons/saved-search.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { IUserDropdown, UserDropdownType } from '../types';
import Notification from 'modules/Notification/Notification';
import { axiosInstance } from 'utils/API';
import { clsx } from 'utils/clsx';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { IDropdownOption } from 'components/Dropdown/types';

const UserBody: FC = observer(() => {
  const { t } = useTranslation();
  const { authStore, userStore, savedSearchesStore, projectSearchStore, searchStore } = useStore();
  // const [showPlan, setShowPlan] = useState<boolean>(false);

  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const location = useLocation();
  const id = useId();

  const badgeFormatter = (count: number): string => {
    if (!count) return '';
    return count > 1000 ? '1k+' : `${count}`;
  };

  // const navItems: INavItems[] = useMemo(() => {
  //   return [
  //     {
  //       name: t('components.header.nav.savedSearches'),
  //       link: '/saved-search',
  //       active: location.pathname.includes('/saved-search'),
  //       badge: badgeFormatter(savedSearchesStore.newProjectsCount)
  //     },
  //     {
  //       name: t('components.header.nav.favorites'),
  //       link: '/favorites',
  //       active: location.pathname.includes('/favorites')
  //     }
  //   ];
  // }, [savedSearchesStore.newProjectsCount, location.pathname]);

  // TODO: remove after test
  // useEffect(() => {
  //   (async () => {
  //     if (userStore.user) {
  //       await savedSearchesStore.getNewProjectCount(userStore.user.id);
  //     }
  //   })();
  // }, [userStore.user]);

  // TODO: remove import, remove, clear after testing
  const userDropdownOptions = useMemo<IDropdownOption[]>(
    () => [
      {
        content: t('components.header.menu.profile'),
        value: 'profile',
        id: 1,
        icon: UserDarkIcon
      },
      {
        content: t('components.header.menu.searches'),
        value: 'searches',
        id: 2,
        icon: SavedSearchIcon
      },
      {
        content: t('components.header.menu.logout'),
        value: 'logout',
        id: 3,
        icon: LogoutIcon
      }
    ],
    []
  );

  // TODO: remove import, remove, clear after testing
  const handleDropdownChange = (selected: IDropdownOption): void => {
    const currentTarget = selected.value;
    const targets: IUserDropdown = {
      profile: () => navigate('/profile/settings', { replace: true }),
      searches: () => navigate('/saved-search', { replace: true }),
      logout: () => logout(),
      auth: () => setParams('auth=sign-in'),
      components: () => navigate('/components', { replace: true }),
      subscription: () => navigate('/profile/subscription', { replace: true }),
      favorites: () => navigate('/favorites', { replace: true }),
      analytics: () => navigate('/favorites/analytics', { replace: true }),
      import: () => importData(),
      remove: () => removeData(),
      clear: () => removeSub()
    };
    targets[currentTarget as UserDropdownType]();
  };

  // TODO: remove this function after testing
  const removeSub = async (): Promise<void> => {
    await axiosInstance.get(`subscriptions/remove/data`);
  };
  const importData = async (): Promise<void> => {
    await axiosInstance.get(`subscriptions/import/data`);
  };
  const removeData = async (): Promise<void> => {
    await axiosInstance.get(`subscriptions/remove/all-data`);
  };

  const logout = (): void => {
    navigate('/');
    authStore.logout();
    userStore.setUser(null);
  };
  //
  // const showSubscribeModal = useCallback(() => setShowPlan(true), []);
  // const hideSubscribeModal = useCallback(() => setShowPlan(false), []);
  //
  // const handleLoadProjects = useCallback(() => {
  //   projectSearchStore.setProjectsLoadingHeader(true);
  // }, []);
  //
  // const handleKeyEnter = useCallback(() => {
  //   onKeyEnter();
  //   const input: HTMLInputElement | null = document.querySelector(`[data-input-id="${id}"]`);
  //   input?.blur();
  // }, [onKeyEnter]);
  //
  // const showPurchase = useMemo<boolean>(() => {
  //   return (
  //     searchStore.locationForMap.length === 1 && !projectSearchStore.projectsData.hasSubscription
  //   );
  // }, [searchStore.locationForMap, projectSearchStore.projectsData]);
  //
  // const onSearchWrapp = () => {
  //   const input = document.getElementById('header-input-search');
  //   input?.focus();
  //   onInputFocus();
  // };
  //
  // const showSearchLocations = (): string => {
  //   return searchStore.locationForMap.map((location) => location.title).join(' | ');
  // };

  // TODO: maybe needed in the future
  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (event.key === 'Backspace' && !inputValue.length) {
  //     searchStore.removeLastSelectedLocation();
  //   }
  // };

  return (
    <div className="flex w-full h-full pl-3">
      {/*<div className="flex items-center">*/}
      {/*  <Link className="focus:outline-0 mr-4" to="/saved-search" tabIndex={-1}>*/}
      {/*    <img src={Logo} alt="close" className="relative" role="button" />*/}
      {/*  </Link>*/}
      {/*  <Nav items={navItems} mode="dark" navClass="mx-4" />*/}
      {/*</div>*/}
      {/*<div className="grow flex items-center">*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'flex relative h-10',*/}
      {/*      classes.inputWrapp,*/}
      {/*      showPurchase ? 'max-w-[553px] w-full' : 'w-full'*/}
      {/*    )}>*/}
      {/*    <div className={clsx('flex', classes.inputWrapp)}>*/}
      {/*      <Input*/}
      {/*        id="header-input-search"*/}
      {/*        tabIndex={0}*/}
      {/*        data-input-id={id}*/}
      {/*        autoComplete="off"*/}
      {/*        name="search"*/}
      {/*        type="text"*/}
      {/*        value={inputValue}*/}
      {/*        onInput={inputChange}*/}
      {/*        onFocus={onInputFocus}*/}
      {/*        onBlur={onInputBlur}*/}
      {/*        data-test-element="header-search-input"*/}
      {/*        inputClass={classes.searchInput}*/}
      {/*        searchIcon={false}>*/}
      {/*        /!*TODO: maybe needed in the future*!/*/}
      {/*        /!*onKeyDown={handleKeyDown}*!/*/}
      {/*        {!!searchStore.locationForMap.length && (*/}
      {/*          <div className={clsx('px-3', classes.searchTagsWrapp)} onClick={onSearchWrapp}>*/}
      {/*            <span className={clsx(classes.locationTags)}>{showSearchLocations()}</span>*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        {!searchStore.locationForMap.length && (*/}
      {/*          <div*/}
      {/*            data-input-id={id}*/}
      {/*            className={`flex w-full items-center justify-center ${classes.inputSearchOverlayText}`}>*/}
      {/*            <img src={searchIcon} alt="" />*/}
      {/*            &nbsp;{t('components.header.search')}*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*      </Input>*/}
      {/*    </div>*/}
      {/*    <SearchList*/}
      {/*      listResponse={searchResults}*/}
      {/*      selectedLocation={selectedLocation}*/}
      {/*      onChange={searchListChange}*/}
      {/*      styleTop="42"*/}
      {/*      show={showSearchList}*/}
      {/*      closeList={closeList}*/}
      {/*      inputId={id}*/}
      {/*      onKeyUp={onKeyUp}*/}
      {/*      onKeyDown={onKeyDown}*/}
      {/*      onKeyEnter={handleKeyEnter}*/}
      {/*      onKeyEscape={onKeyEscape}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  {showPurchase && (*/}
      {/*    <Button*/}
      {/*      type="primary"*/}
      {/*      data-test-element="header-purchase-btn"*/}
      {/*      background={'#00C47D'}*/}
      {/*      className="text-xs uppercase px-5 ml-2 py-3 hover:bg-green-400 hover:bg-opacity-10"*/}
      {/*      disabled={projectSearchStore.isValueLoading}*/}
      {/*      onClick={showSubscribeModal}>*/}
      {/*      <span className="font-halbfett">{t('components.header.purchase')}</span>*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className="flex items-center">
        <Notification />
        <Dropdown
          activeClass={classes.activeDropdown}
          changeIconColor
          title={<UserIcon />}
          titleClass={classes.dropdownTitle}
          options={userDropdownOptions}
          onSelect={handleDropdownChange}
          arrowIconColor="#171717"
          isFiltering={false}
          width={216}
        />
      </div>
      {/*{!!searchStore.locationForMap.length && (*/}
      {/*  <ChoosePlanModal*/}
      {/*    show={showPlan}*/}
      {/*    locations={searchStore.locationForMap}*/}
      {/*    closeModal={hideSubscribeModal}*/}
      {/*    onUpdate={handleLoadProjects}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
});

export default UserBody;
