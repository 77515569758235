import { IShareRepo, ShareToken } from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class ShareRepo implements IShareRepo {
  async createSavedSearchToken(
    userId: number,
    searchId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>> {
    return await axiosInstance.post(`users/${userId}/saved-searches/${searchId}/share`, {
      email
    });
  }
  async createSinglePermitToken(
    projectId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>> {
    return await axiosInstance.post(`projects/${projectId}/share`, {
      email
    });
  }
  async createFavoriteListToken(
    userId: number,
    listId: number,
    email: string | null
  ): Promise<IAxiosResponse<ShareToken>> {
    return await axiosInstance.post(`users/${userId}/favourites/lists/${listId}/share`, {
      email
    });
  }
}
