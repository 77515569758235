import { ConfigType, IConfigs } from 'store/ConfigStore/types';
import { ILocation, SourceLink, Detail } from 'utils/types';

export const getDataFromConfig = (config: ConfigType[], configType: number): string => {
  if (config && config.length) {
    return config.find((item) => item.id === configType)?.name || '';
  }
  return '';
};

export const getLocations = (location: ILocation): string => {
  return location
    ? `${location.city ? location.city.title : ''} ${
        location.state ? location.state.stateCode : ''
      }`
    : '';
};

export const getContactsPhone = (configs: IConfigs, details: Detail[]): string => {
  const phone = details.find((detail) => detail.type === SourceLink.PHONE);
  if (phone) {
    return phone.value;
  }
  return '';
};
