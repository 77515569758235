import React, { FC, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Filter.module.scss';
import { Input } from 'components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface Props {
  keywords: string[];
  onSetKeyword: (val: string) => void;
  onRemoveKeyword: (idx: number) => void;
}

const CompanyAddressKeywords: FC<Props> = ({ keywords, onSetKeyword, onRemoveKeyword }) => {
  const [keywordValue, setKeywordValue] = useState<string>('');

  const handleKeywordChange = (name: string, str: string): void => {
    setKeywordValue(str);
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      if (keywordValue.length) {
        onSetKeyword(keywordValue);
        setKeywordValue('');
      }
    }
  };

  return (
    <div className="w-58">
      <Input
        type="text"
        value={keywordValue}
        onInput={handleKeywordChange}
        onKeyDown={handleKeyDown}
        name="company-address-keywords"
        inputClass="text-dark h-12 bg-light-200 border-0 w-58 focus:shadow-field ml-auto"
        placeholder="Company, INC"
      />
      <div className={clsx(classes.keywordList, 'flex', 'flex-wrap', 'mb-2', 'max-h-14')}>
        {keywords.map((keyword: string, index) => (
          <div
            key={index}
            className={clsx(
              classes.keywordItem,
              'text-dark',
              'p-2',
              'mt-2',
              'mr-2',
              'flex',
              'items-center',
              'cursor-default'
            )}>
            <span>{keyword}</span>
            <CloseIcon onClick={() => onRemoveKeyword(index)} className="ml-1 cursor-pointer" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyAddressKeywords;
