import React, { FC, useCallback, MouseEvent } from 'react';
import classes from '../Auth.module.scss';
import { clsx } from 'utils/clsx';
import closeIcon from 'assets/icons/close.svg';

interface IProps {
  children: JSX.Element;
  show: boolean;
  onClose: () => void;
}

const AuthModal: FC<IProps> = ({ children, show, onClose }) => {
  const handleModalClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <div
      className={clsx(
        classes.modalWrapper,
        'fixed',
        'top-0',
        'bottom-0',
        'right-0',
        'left-0',
        'flex',
        'items-center',
        'justify-center',
        'overflow-hidden',
        'h-screen',
        show && classes.active
      )}
      onMouseDown={handleModalClick}>
      <div className="relative min-w-[536px]">
        <img
          src={closeIcon}
          alt="close"
          className={clsx('absolute', classes.closeIcon)}
          onClick={onClose}
          role="button"
        />
        {children}
      </div>
    </div>
  );
};

export default AuthModal;
