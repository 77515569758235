import {
  ISubscriptionLocationDropdownService,
  ISubscriptionLocationDropdownStore,
  ShortLocation,
  SubscribedLocationsResponse,
  SUBSCRIPTION_LOCATION_DROPDOWN_SERVICE
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';

export class SubscriptionLocationDropdownStore implements ISubscriptionLocationDropdownStore {
  private _subscriptionLocationsDropdownService: ISubscriptionLocationDropdownService =
    injector.get<ISubscriptionLocationDropdownService>(SUBSCRIPTION_LOCATION_DROPDOWN_SERVICE);

  currentPage = 1;
  pageSize = 10000;
  data: SubscribedLocationsResponse = {
    limit: 0,
    page: 1,
    hasMore: false,
    total: 0,
    data: []
  };
  selected: ShortLocation[] = [];

  constructor() {
    makeAutoObservable<SubscriptionLocationDropdownStore>(this);
  }

  initSelectedLocations(locationIds: number[]): void {
    if (locationIds.length) {
      this.selected = this.data.data.filter((location) => locationIds.includes(location.id));
    }
  }

  setData(data: SubscribedLocationsResponse): void {
    this.data = data;
  }

  setSelected(location: ShortLocation): void {
    const findSelectedLocation = this.selected.find((item) => item.id === location.id);
    if (!findSelectedLocation) {
      this.selected = this.selected.concat([location]);
      return;
    }
    this.selected = this.selected.filter((item) => item.id !== location.id);
  }

  async getSubscribedLocations(searchId?: number | null): Promise<void> {
    this.setData(
      await this._subscriptionLocationsDropdownService.getSubscribedLocations(
        this.currentPage,
        this.pageSize,
        searchId
      )
    );
  }
}
