import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import classes from './SubHeader.module.scss';
import { IDropdownOption } from 'components/Dropdown/types';
import { Button, Dropdown, Input } from 'components';
// import { getLocationParamsFromSelectedLocation } from 'helpers/createLocationParams';
import { ISavedSearch, Status } from 'view/SavedSearches/types';
import { defaultExportData } from 'view/SavedSearches/mock';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { observer } from 'mobx-react';
import { usePrevious } from 'hooks/usePrevious';
import { SearchStringType, SearchType } from 'utils/types';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as PermitIcon } from 'assets/icons/permit.svg';

// import { useFiltersActions } from 'hooks/useFiltersActions';

interface IProps {
  // onSelectSearch: (id: number) => void;
  currentSavedSearches: ISavedSearch;
  searchId: number | null;
  all: boolean;
  token: boolean;
  edit: boolean;
  owner: boolean;
  activeType: SearchStringType;
  onEditFilters: () => void;
  onLoadSearch: () => void;
  onMoveToMap: () => void;
  onToggleType: () => void;
  onSaveTitle: (val: string) => void;
  isSearchCrossReferencing: boolean;
}

const SubHeader: FC<IProps> = observer(
  ({
    currentSavedSearches,
    searchId,
    all,
    token,
    edit,
    owner,
    activeType,
    onEditFilters,
    onLoadSearch,
    onMoveToMap,
    onSaveTitle,
    onToggleType,
    isSearchCrossReferencing
  }) => {
    const { savedSearchesStore, userStore, savedSearchResultsStore, configStore } = useStore();
    const { t } = useTranslation();
    // const navigate = useNavigate();
    // const [currentSavedSearches, setCurrentSavedSearches] = useState<ISavedSearch | null>(null);
    const [titleEdit, setTitleEdit] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const prevTitle = usePrevious<string>(currentSavedSearches.name);
    // TODO: remove after test
    // const { setFilters, removeFilters } = useFiltersActions();

    // const options = useMemo<IDropdownOption[]>(
    //   () =>
    //     savedSearchesStore.savedSearches.map((search) => ({
    //       content: search.name,
    //       selected: searchId === search.id,
    //       id: search.id
    //     })),
    //   [searchId, token, savedSearchesStore.savedSearches]
    // );

    // const allSearchesOption = useMemo<IDropdownOption>(
    //   () => ({
    //     content: t('savedSearch.allSearches'),
    //     selected: !token && isNaN(searchId || 0),
    //     id: 0
    //   }),
    //   [searchId]
    // );
    //
    // const sharedOption = useMemo<IDropdownOption | null>(() => {
    //   if (token && savedSearchResultsStore.shareSearchFilter) {
    //     return {
    //       content: savedSearchResultsStore.shareSearchFilter.name,
    //       selected: token && !!savedSearchResultsStore.shareSearchFilter,
    //       id: savedSearchResultsStore.shareSearchFilter.id
    //     };
    //   }
    //   return null;
    // }, [token, savedSearchResultsStore.shareSearchFilter]);

    // const dropdownOptions = useMemo(
    //   (): IDropdownOption[] => [
    //     allSearchesOption,
    //     ...options,
    //     ...(sharedOption ? [sharedOption] : [])
    //   ],
    //   [options, sharedOption]
    // );

    // const selected = useMemo<IDropdownOption | null>(
    //   () =>
    //     dropdownOptions.find((option) => {
    //       return option.selected;
    //     }) || null,
    //   [dropdownOptions]
    // );

    useEffect(() => {
      // if (!savedSearchesStore.savedSearches.length) {
      //   (async () => await loadSearches())();
      // }
      // onLoadSearch();
      (async () => await getExportFile())();
      return () => {
        savedSearchesStore.setExportData(defaultExportData);
      };
    }, [token, searchId]);

    // useEffect(() => {
    //   if (token) {
    //     setCurrentSavedSearches(savedSearchResultsStore.shareSearchFilter);
    //     return;
    //   }
    //   if (selected) {
    //     setCurrentSavedSearches(
    //       savedSearchesStore.getSavedSearchByIdFromAllSavedSearches(selected.id)
    //     );
    //   }
    // }, [token, selected, savedSearchResultsStore.shareSearchFilter]);

    // const loadSearches = useCallback(async () => {
    //   try {
    //     if (!userStore.user) return;
    //     // setIsSearchesLoading(true);
    //     await savedSearchesStore.getSavedSearches(userStore.user.id);
    //   } finally {
    //     // setIsSearchesLoading(false);
    //   }
    // }, []);

    const locationsInSavedSearch = useMemo<number>(() => {
      return currentSavedSearches.filters.locations.length;
    }, [currentSavedSearches]);

    // const onSelect = useCallback((option: IDropdownOption) => {
    //   onSelectSearch(option.id ? option.id : NaN);
    // }, []);

    // const openMap = async (): Promise<void> => {
    //   if (all) return;
    //   if (currentSavedSearches) {
    //     // removeFilters();
    //     const locations = await savedSearchesStore.getLocationsInfo(
    //       currentSavedSearches.filters.locations
    //     );
    //     // searchStore.setSelectedLocation(locations);
    //     // setFilters(currentSavedSearches.filters);
    //     navigate(`/search-projects/${getLocationParamsFromSelectedLocation(locations)}`, {
    //       replace: true
    //     });
    //   }
    // };

    const exportData = async (): Promise<void> => {
      if (userStore.user && searchId) {
        try {
          savedSearchesStore.setExportData({ ...defaultExportData, status: Status.PENDING });
          await savedSearchesStore.runExport(userStore.user.id, searchId);
          await getExportFile();
        } catch (e) {
          savedSearchesStore.setExportData({ ...defaultExportData, status: null });
          console.log('Export: ', e);
        }
      }
    };

    const getExportFile = async (): Promise<void> => {
      if (userStore.user && searchId) {
        try {
          await savedSearchesStore.getExportFile(userStore.user.id, searchId);
        } catch (e) {
          console.log('Export: ', e);
        }
      }
    };

    const pathToExportFile = useMemo((): string => {
      if (!savedSearchesStore.exportData.media) return '';
      return (
        configStore.appConfig?.media.csv.original
          .replace('{userId}', String(savedSearchesStore.exportData.media.userId))
          .replace('{mediaType}', savedSearchesStore.exportData.media.type)
          .replace('{hash}', savedSearchesStore.exportData.media.hash)
          .replace('{extension}', savedSearchesStore.exportData.media.extension) || ''
      );
    }, [savedSearchesStore.exportData]);

    const exportDone = useMemo<boolean>(() => {
      return !!(
        savedSearchesStore.exportData.status === Status.DONE && savedSearchesStore.exportData.media
      );
    }, [savedSearchesStore.exportData]);

    const getTitle = useCallback((): string => {
      if (all) {
        return 'ALL';
      }
      return currentSavedSearches.name;
    }, [currentSavedSearches, all]);

    const onInputChange = (name: string, value: string): void => {
      setTitle(value);
    };

    const onTitleEdit = () => {
      setTitle(getTitle());
      setTitleEdit(true);
    };

    const onInputBlur = () => {
      if (prevTitle !== title) {
        onSaveTitle(title);
      }
      setTitleEdit(false);
    };

    // TODO: remove after testing
    // if (!selected) {
    //   return <></>;
    // }

    return (
      <div className="w-full">
        <div className="flex items-center justify-between h-14">
          <div className="mr-4 py-3">
            {!titleEdit && (
              <span
                className="flex w-fit overflow-hidden ml-2 px-5 py-2 text-lg text-dark cursor-pointer hover:bg-light-200"
                onClick={owner ? onTitleEdit : undefined}>
                <span
                  className={clsx(
                    getTitle().length < 30 ? 'w-fit' : 'w-52',
                    'whitespace-nowrap text-ellipsis overflow-hidden'
                  )}>
                  {getTitle()}
                </span>
                {owner && <PenIcon width={15} height={15} className="ml-3.5 mt-1.5" />}
              </span>
            )}
            {titleEdit && (
              <form onSubmit={onInputBlur}>
                <Input
                  type="text"
                  value={title}
                  onInput={onInputChange}
                  onBlur={onInputBlur}
                  name="title"
                  data-test-element="edit-search-name-input"
                  inputClass="ml-2"
                  focus={true}
                />
              </form>
            )}
            {/*<Dropdown*/}
            {/*  title={selected.content}*/}
            {/*  options={dropdownOptions}*/}
            {/*  onSelect={onSelect}*/}
            {/*  float="start"*/}
            {/*  activeClass="text-primary"*/}
            {/*  titleClass={classes.dropdownTitle}*/}
            {/*  extendedPosition="start"*/}
            {/*/>*/}
          </div>
          <div className="flex">
            {owner && (
              <Button
                type="secondary"
                className="py-0.5 px-0 h-8 text-sm px-3 mr-2 font-kraftig flex items-center !text-primary"
                onClick={onEditFilters}>
                {edit ? 'Close Filter' : 'Edit Filter'}
              </Button>
            )}
            {/*TODO: move to ExportData component in the future*/}
            {!!searchId && savedSearchesStore.exportData.status !== Status.DONE && (
              <Button
                type={exportDone ? 'primary' : 'secondary'}
                className="py-0.5 px-0 h-8 text-sm px-3 mr-2 flex items-center"
                onClick={exportData}
                disabled={savedSearchesStore.exportData.status === Status.PENDING}>
                {!savedSearchesStore.exportData.status && (
                  <span className="font-kraftig flex">
                    {t('savedSearch.exportResults')}
                    <ExportIcon className={clsx('ml-2', classes.exportIcon)} />
                  </span>
                )}
                {savedSearchesStore.exportData.status === Status.PENDING && (
                  <div className="flex items-center">
                    <div className="loader p-0 mt-1">
                      <div />
                    </div>
                    <span className="ml-1">Pending...</span>
                  </div>
                )}
              </Button>
            )}

            {/*TODO: move to ExportData component in the future*/}
            {exportDone &&
              !!pathToExportFile.length &&
              savedSearchesStore.exportData.status === Status.DONE && (
                <a
                  href={pathToExportFile}
                  download
                  className={clsx(
                    classes.subHeaderButton,
                    'px-3',
                    'mr-2',
                    'flex',
                    'items-center',
                    classes.subHeaderButtonDone
                  )}>
                  Download file
                </a>
              )}

            {!!locationsInSavedSearch && currentSavedSearches.searchType === SearchType.PROJECT && (
              <Button
                type="secondary"
                className="py-0.5 px-0 h-8 text-sm px-3 font-kraftig flex items-center"
                onClick={onMoveToMap}>
                {locationsInSavedSearch > 1
                  ? t('savedSearch.showOnTheList')
                  : t('savedSearch.showOnTheMap')}
              </Button>
            )}

            {isSearchCrossReferencing && (
              <div className="flex">
                <Button
                  type="secondary"
                  className={clsx(
                    'py-0.5 h-8 text-sm px-3 font-kraftig flex items-center border-r-0 rounded-r-none',
                    activeType === 'project' && 'pointer-events-none'
                  )}
                  onClick={onToggleType}>
                  <div
                    className={clsx('flex items-center', activeType === 'company' && 'opacity-50')}>
                    <PermitIcon className="mr-1.5" />
                    Project
                  </div>
                </Button>
                <Button
                  type="secondary"
                  className={clsx(
                    'py-0.5 h-8 text-sm px-3 font-kraftig flex items-center border-l-0 rounded-l-none',
                    activeType === 'company' && 'pointer-events-none'
                  )}
                  onClick={onToggleType}>
                  <div
                    className={clsx('flex items-center', activeType === 'project' && 'opacity-50')}>
                    <CompanyIcon stroke="#000000" className="mr-1.5" />
                    Company
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default SubHeader;
