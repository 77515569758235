import { ISort } from 'utils/types';
import { IOption } from 'components/Select/types';

export enum FilterState {
  SEARCH,
  SAVED_SEARCH,
  COMPANY_SEARCH,
  SAVED_SEARCH_COMPANY
}

export interface ISearchParams {
  permitTypes: number[];
  statuses: number[];
  classes: number[];
  types: number[];
  subTypes: number[];
  locations: number[];
  value: {
    min: number | null;
    max: number | null;
  };
  levels: {
    min: number | null;
    max: number | null;
  };
  units: {
    min: number | null;
    max: number | null;
  };
  date: {
    min: string | null;
    max: string | null;
  };
  onlyWithContacts?: boolean;
  order?: ISort[];
  page: number;
  area?: [number, number][];
  keywords: string[];
  excludes: Excludes;
  companyName?: string[];
  companyAddress?: string[];
  companyType?: CompanyType | null;
  pageSize?: number;
}

export type CompanyType = 'owner' | 'applicant';

export type Excludes = {
  address: boolean;
  companyName: boolean;
  description: boolean;
};

export type ExcludesKeys = keyof Excludes;

export type KeywordExcludes = {
  id: number;
  key: ExcludesKeys;
  name: string;
};

type Omit<T, K extends keyof never> = Pick<T, Exclude<keyof T, K>>;

export type ISearchParamKey = keyof Omit<ISearchParams, 'onlyWithContacts'>;

export type keywordKeys = 'keywords' | 'companyName' | 'companyAddress';

export interface IInputChangeItem {
  min: number | null;
  max: number | null;
}

export type ISearchParamValue =
  | number[]
  | string
  | { min: string | number | null; max: string | number | null }
  | ISort[]
  | null
  | number
  | string[]
  | [number, number][]
  | Excludes;

export interface ICustomDate {
  from: number;
  to: number;
}

export interface IDateChangeEvent {
  selection: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

export interface IFilterStore {
  searchParams: ISearchParams;
  selectedDate: IOption | null;
  customDate: ICustomDate | null;
  setSearchParams(name: string, param: ISearchParamValue): void;
  resetSearchParams(): void;
  setSelectedDate(date: IOption | null): void;
  onSelectChange(option: IOption, field: string): void;
  onInputChange(inputChange: IInputChangeItem, field: string): void;
  onSelectReset(field: string): void;
  handleReset(): void;
}

export interface IMoreValues {
  units: IMinMax;
  levels: IMinMax;
  status: IOption | null;
  keywords: string[];
  excludes: Excludes;
  companyName: string[];
  companyAddress: string[];
  companyType: IOption | null;
}

export interface IMinMax {
  min: IOption | null;
  max: IOption | null;
}

export interface ITreeOption {
  label: string;
  value: number;
  children?: ITreeOption[];
  checked?: boolean;
  type: string;
}

export enum TreeOptionType {
  Class = 'class',
  Type = 'type',
  Subtype = 'subtype',
  PermitType = 'permitType'
}

export interface IDropCategories {
  classes: number[];
  types: number[];
  subTypes: number[];
}

export interface IDropdownEvent {
  activeClasses?: number[];
  activeTypes?: number[];
  activeSubtypes?: number[];
  activePermits?: number[];
}

// export enum SearchProjectView {
//   MAP,
//   LIST
// }

export type formInputChangeEventType = 'levels' | 'units' | 'value';

export interface IOptionsConfig {
  projectClasses: IOption[];
  projectStatuses: IOption[];
  projectTypes: IOption[];
}
