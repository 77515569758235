import { IShareRepo, IShareService, SHARE_REPO, ShareToken } from './types';
import { injector } from 'utils/injector';
// import { tableRowGeneration } from 'view/SavedSearches/components/SavedSearchResults/TableRowGeneration';

export class ShareService implements IShareService {
  private _shareRepo: IShareRepo = injector.get<IShareRepo>(SHARE_REPO);

  async createSavedSearchToken(
    userId: number,
    searchId: number,
    email: string | null
  ): Promise<ShareToken> {
    return (await this._shareRepo.createSavedSearchToken(userId, searchId, email)).data.result;
  }

  async createSinglePermitToken(searchId: number, email: string | null): Promise<ShareToken> {
    return (await this._shareRepo.createSinglePermitToken(searchId, email)).data.result;
  }

  async createFavoriteListToken(
    userId: number,
    listId: number,
    email: string | null
  ): Promise<ShareToken> {
    return (await this._shareRepo.createFavoriteListToken(userId, listId, email)).data.result;
  }
}
