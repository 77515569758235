import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import LeftSidebar from '../LeftSidebar';
import MapProjects from '../Map';
import { Location } from 'utils/types';
import MapProjectStatistics from './components/MapProjectStatistics';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import ViewSwitcher from '../SubHeader/components/ViewSwitcher';
import { SearchProjectView } from '../SubHeader/types';

interface Props {
  onHandleUnlock: (locations: Location[] | null) => void;
  onSelectView: (vies: SearchProjectView) => void;
}

const MapView: FC<Props> = observer(({ onHandleUnlock, onSelectView }) => {
  const { projectSearchStore, filtersStore, searchStore } = useStore();
  const searchParams = filtersStore.searchParams;
  const controller = new AbortController();
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  // const [showRightSidebar, setShowRightSidebar] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (searchParams.page !== 1) {
      const currentPage = Math.ceil((searchParams.page * 20) / 250);
      filtersStore.setSearchParams('page', currentPage);
    }
    filtersStore.setSearchParams('pageSize', 250);
  }, []);

  useEffect(() => {
    if (searchParams.locations.length && searchParams.area) {
      loadProjects(controller).then();
      return () => {
        controller.abort();
      };
    }
  }, [searchParams]);

  const loadProjects = useCallback(
    async (controller?: AbortController) => {
      try {
        setIsProjectsLoading(true);
        await projectSearchStore.getProjects(searchParams, controller);
      } finally {
        setIsProjectsLoading(false);
      }
    },
    [searchParams]
  );

  useEffect(() => {
    if (!projectSearchStore.isProjectsLoadingHeader) return;
    (async () => await loadProjects())();
  }, [projectSearchStore.isProjectsLoadingHeader]);

  const onUnlock = useCallback(
    (location: Location | null) => {
      onHandleUnlock(location ? [location] : location);
    },
    [onHandleUnlock]
  );

  return (
    <div className="flex h-full-page">
      <div className="w-3/12 pr-0">
        <LeftSidebar isProjectsLoading={isProjectsLoading} handleUnlock={onUnlock} />
      </div>
      <div className="w-9/12 p-0 relative z-0">
        <MapProjects />
        <MapProjectStatistics />
        {searchStore.locationForMap.length === 1 && (
          <div className="absolute right-4 top-4 z-[1]">
            <ViewSwitcher isView={SearchProjectView.MAP} onSelectView={onSelectView} />
          </div>
        )}
      </div>
    </div>
  );
});

export default MapView;
