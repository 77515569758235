import React, { FC, useCallback, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  Table as RTable,
  VisibilityState
} from '@tanstack/react-table';
import classes from 'components/NewTable/Table.module.scss';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { NA } from 'utils/constants';
import { CompanyData, IFavoriteEntity } from 'view/Favorites/types';
import { AllEntityTypes, AllRoles, EntityType, ISort } from 'utils/types';
import AddToFavorites from 'view/Favorites/components/AddtoFavorites';
import { NewTable } from 'components';
import { useStore } from 'storesProvider/storeContext';
import { getDataFromConfig } from 'helpers/getDataForTable';
import { clsx } from 'utils/clsx';
import ContactItem from 'components/NewTable/components/ContactItem';
import { INoteEntity, INoteState } from 'view/AddNoteModal/types';
import AddNoteModal from 'view/AddNoteModal';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import favoriteClasses from '../Favorites.module.scss';

interface Props {
  data: CompanyData[];
  onUpdateFavorite: (id: number, isActive: boolean, listId: number) => void;
  onContact: (id: number, type: EntityType, role: AllRoles) => void;
  onUpdateNode: (id: number, hasNote: boolean) => void;
  orders: ISort[];
  orderAction: (field: string) => void;
  hasSubscription?: boolean;
  shared?: boolean;
  onHandleUnlock?: () => void;
}

const CompaniesTable: FC<Props> = ({
  data,
  onUpdateFavorite,
  onContact,
  onUpdateNode,
  orders,
  orderAction,
  hasSubscription = true,
  shared,
  onHandleUnlock
}) => {
  const { configStore } = useStore();
  const [favoriteShowId, setFavoriteShowId] = useState<number | null>(null);
  const columnHelper = createColumnHelper<CompanyData>();
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    id: !shared
  });
  const [noteState, setNoteState] = useState<INoteState>({
    entity: {
      coordinates: null,
      name: null,
      description: null,
      id: null,
      type: null
    },
    showAddNote: false
  });

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Details',
      cell: (info) => {
        return (
          <div className="relative">
            <span className="absolute font-kraftig text-xxs text-grey-300 -top-[8px]">
              {configStore.enums
                ? getDataFromConfig(
                    configStore.enums.projectRoles,
                    info.row.original.role
                  ).toUpperCase()
                : ''}
            </span>
            <span
              onClick={
                info.row.original.hasSubscription
                  ? () => onContact(info.row.original.id, 20, info.row.original.role)
                  : undefined
              }
              className="cursor-pointer">
              {info.getValue()}
            </span>
            <p className={clsx('text-sm', 'text-grey-500', 'mb-4', classes.description)}>
              {info.row.original.description}
            </p>
          </div>
        );
      }
    }),
    columnHelper.accessor('location', {
      header: () => 'Location',
      cell: (info) => (info.getValue() && info.getValue().title ? info.getValue().title : ''),
      enableSorting: false
    }),
    columnHelper.accessor('persons', {
      header: () => 'Contacts',
      enableSorting: false,
      cell: (info) => {
        return (
          <div className="flex flex-wrap -mb-6">
            {info.getValue().map((person, idx) => (
              <ContactItem key={`contact-${idx}`} contact={person} />
            ))}
          </div>
        );
      }
    }),
    columnHelper.accessor('projectsCount', {
      header: () => 'Project Count',
      cell: (info) => {
        return info.getValue() ? `${numberWithSpaces(info.getValue())}` : NA;
      }
    }),
    columnHelper.accessor('projectsValue', {
      header: () => 'Total Amount',
      cell: (info) => {
        return info.getValue() ? `${numberWithSpaces(info.getValue())}` : NA;
      }
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      enableSorting: false,
      cell: (info) => {
        return (
          <div className={`flex align-top justify-end text-right`}>
            <DocumentIcon
              id={`document-icon-${info.row.original.id}`}
              className="stroke-[#BABCC1] cursor-pointer mr-4"
              onClick={() => noteEntity(info.row.original)}
            />
            <AddToFavorites
              onHeartClick={() => openFavoriteModal(info.row.original)}
              closeModal={closeAddFavoriteModal}
              entity={getEntity(info.row.original)}
              toggleCallback={favoriteCallback}
              isFavorite={true}
              isModalShown={favoriteShowId === info.row.original.id}
            />
          </div>
        );
      }
    })
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility
  });

  // Work with actions
  const openFavoriteModal = (row: CompanyData) => {
    showAddFavoriteModal(getEntity(row));
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  const getEntity = useCallback((row: CompanyData): IFavoriteEntity => {
    return {
      id: row.id,
      coordinates: {} as DOMRect,
      type: AllEntityTypes.Company,
      role: row.role,
      key: 'table',
      name: row.name
    };
  }, []);

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    setFavoriteShowId((prev) => (prev === entity.id ? null : entity.id));
  };

  const favoriteCallback = useCallback((isActive: boolean, id: number, listId: number) => {
    onUpdateFavorite(id, isActive, listId);
  }, []);

  const closeAddFavoriteModal = (): void => {
    setFavoriteShowId(null);
  };

  const noteEntity = (row: CompanyData) => {
    const coordinates: DOMRect | null =
      document.getElementById(`document-icon-${row.id}`)?.getBoundingClientRect() || null;
    const entity: INoteEntity = {
      coordinates,
      name: row.name,
      description: row.description,
      id: row.id,
      type: 'project'
    };
    setNoteState((prev) => ({ ...prev, entity }));
    showAddNoteModal(row.id);
  };

  const showAddNoteModal = useCallback((id: number) => {
    const thisModal = document.getElementById(`note-${id}`);
    setNoteState((prev) => ({ ...prev, showAddNote: !thisModal }));
    setFavoriteShowId(null);
  }, []);

  const closeNoteModal = useCallback(
    () => setNoteState((prev) => ({ ...prev, showAddNote: false })),
    []
  );
  // Work with actions end

  const handleTableScroll = () => {
    setFavoriteShowId(null);
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  return (
    <div>
      <NewTable
        table={table as RTable<unknown>}
        orders={orders}
        orderAction={orderAction}
        onTableScroll={handleTableScroll}
        hasSubscription={hasSubscription}
        onHandleUnlock={onHandleUnlock}
        tableClass={favoriteClasses.table}
      />
      {noteState.showAddNote && noteState.entity && (
        <AddNoteModal
          entity={noteState.entity}
          closeModal={closeNoteModal}
          onNoteChange={onUpdateNode}
        />
      )}
    </div>
  );
};

export default CompaniesTable;
