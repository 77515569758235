import React from 'react';
import { PreRegister } from 'view/Auth/components/PreRegister';
import logo from 'assets/icons/logo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-shadesBlue-300 overflow-hidden">
      <div className="container m-auto">
        <div className="flex justify-between items-end py-[85px] px-0">
          <div>
            <div className="flex">
              <div className="ml-3 relative pb-3">
                <img src={logo} alt="" />
                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full w-[490px] h-[490px] border-2 border-white/[.15] first-of-type:w-[257px] first-of-type:h-[257px] last-of-type:w-[720px] last-of-type:h-[720px]" />
                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full w-[490px] h-[490px] border-2 border-white/[.15] first-of-type:w-[257px] first-of-type:h-[257px] last-of-type:w-[720px] last-of-type:h-[720px]" />
                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full w-[490px] h-[490px] border-2 border-white/[.15] first-of-type:w-[257px] first-of-type:h-[257px] last-of-type:w-[720px] last-of-type:h-[720px]" />
              </div>
            </div>
            <div className="max-w-132">
              <h2 className="text-white text-5xl mb-4">{t('home.getStared')}</h2>
              <p className="text-white text-base leading-8 mb-[136px]">{t('home.footerText')}</p>
            </div>
            <p className="text-base text-white opacity-50 m-0">
              &copy;{new Date().getFullYear()} {t('home.rights')}
            </p>
          </div>
          <PreRegister />
        </div>
      </div>
    </div>
  );
};

export default Footer;
