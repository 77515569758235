import { ISavedSearchRepo, ISavedSearch, ExportData, HasSubscriptionLocation } from './types';
import { ISearchParams } from 'modules/Filter/types';
import { axiosInstance } from 'utils/API';
import { DetailInfo } from '../SearchProjects/components/Map/types';
import { IAxiosResponse, SearchType } from 'utils/types';
import { COMPANY, PROJECT } from 'utils/constants';

export class SavedSearchManageRepo implements ISavedSearchRepo {
  async saveSearch(
    id: number,
    name: string,
    filters: ISearchParams,
    searchType: SearchType
  ): Promise<IAxiosResponse<ISavedSearch>> {
    return await axiosInstance.post(`users/${id}/saved-searches`, {
      filters: { ...filters },
      name,
      searchType
    });
  }

  async getSavedSearches(id: number): Promise<IAxiosResponse<ISavedSearch[]>> {
    return await axiosInstance.get(`users/${id}/saved-searches`);
  }

  async updateTitle(
    userId: number,
    searchId: number,
    name: string
  ): Promise<IAxiosResponse<ISavedSearch>> {
    return await axiosInstance.patch(`users/${userId}/saved-searches/${searchId}/name`, { name });
  }

  async clone(userId: number, searchId: number): Promise<IAxiosResponse<ISavedSearch>> {
    return await axiosInstance.post(`users/${userId}/saved-searches/${searchId}/clone`);
  }

  async delete(userId: number, searchId: number): Promise<void> {
    return await axiosInstance.delete(`users/${userId}/saved-searches/${searchId}`);
  }

  async deleteShareSavedSearch(userId: number, searchId: number): Promise<void> {
    return await axiosInstance.delete(`users/${userId}/saved-searches-shared/${searchId}`);
  }

  async getNewProjectCount(
    userId: number,
    searchType: SearchType
  ): Promise<IAxiosResponse<number>> {
    const type = searchType === SearchType.PROJECT ? PROJECT : COMPANY;
    return await axiosInstance.get(`/users/${userId}/saved-search/${type}/new-items/count`);
  }

  async getLocationsInfo(locationIds: number[]): Promise<IAxiosResponse<DetailInfo[]>> {
    return axiosInstance.get(`locations/info`, { params: { locationIds } });
  }

  async runExport(userId: number, searchId: number): Promise<void> {
    await axiosInstance.get(`users/${userId}/saved-searches/${searchId}/export/run`);
  }
  async getExportFile(userId: number, searchId: number): Promise<IAxiosResponse<ExportData>> {
    return await axiosInstance.get(`users/${userId}/saved-searches/${searchId}/export`);
  }

  async hasSubscription(locations: number[]): Promise<IAxiosResponse<HasSubscriptionLocation[]>> {
    return axiosInstance.get(`subscriptions`, {
      params: {
        locations
      }
    });
  }
}
