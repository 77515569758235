import React, { ChangeEvent, FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { ReactComponent as FileImportIcon } from 'assets/icons/file-import.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/done-icon.svg';
import { useStore } from 'storesProvider/storeContext';
import { Button } from 'components';
import { clsx } from 'utils/clsx';
import { formatBytes } from 'helpers/formatBytes';
import { useTranslation } from 'react-i18next';
import { FILE_SIZE_MAX, UploadResult, UploadStatusCode } from '../types';
import { numberWithSpaces } from 'helpers/numberWithComma';

interface Props {
  onImport: (file: File) => void;
  uploadData: UploadResult;
  onResetUploadToDefault: () => void;
  onShowMatchModal: () => void;
  onShowViewResultsModal: () => void;
  onOpenSavedSearch: () => void;
  onAbortImport: () => void;
}

const FileUploader: FC<Props> = ({
  onImport,
  uploadData,
  onResetUploadToDefault,
  onShowMatchModal,
  onOpenSavedSearch,
  onAbortImport,
  onShowViewResultsModal
}) => {
  const { t } = useTranslation();
  const { alertStore } = useStore();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const chooseFile = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    if (!files) return;
    const file = files[0];
    if (!file) return;
    fileVerification(file);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      if (uploadData.status !== UploadStatusCode.START) return;
      const droppedFile = e.dataTransfer.files[0];
      fileVerification(droppedFile);
    },
    [uploadData]
  );

  const fileVerification = (file: File) => {
    if (file.type !== 'text/csv') {
      alertStore.errorAlert('Only csv');
      return;
    }
    if (file.size > FILE_SIZE_MAX) {
      alertStore.errorAlert('Your file > 4MB');
      return;
    }
    setFile(file);
  };

  const showFileInfo = useCallback((): string => {
    if (!file) return '';
    const fileSize = formatBytes(file.size);
    return `${file.name} (${fileSize})`;
  }, [file]);

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
  };

  const onDiscard = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
    onAbortImport();
    setFile(null);
  };

  const importFile = () => {
    if (!file) return;
    onImport(file);
  };

  const importAnotherList = () => {
    onDiscard();
    onResetUploadToDefault();
  };

  const fileIsUploading = useMemo<boolean>(() => {
    return (
      uploadData.status === UploadStatusCode.IN_PROGRESS ||
      uploadData.status === UploadStatusCode.NEW ||
      uploadData.status === UploadStatusCode.PENDING
    );
  }, [uploadData]);

  const getDoneInfo = useCallback(() => {
    return `${numberWithSpaces(uploadData.uploaded)} ${t(
      'listUpload.resultsFound'
    )}: ${numberWithSpaces(uploadData.found)} ${t('listUpload.exactMatches')} ${numberWithSpaces(
      uploadData.needToMerge
    )} ${t('listUpload.possibleMatches')}`;
  }, [uploadData]);

  const needToMergeOrFoundPresence = useCallback((): boolean => {
    return !!(uploadData.needToMerge || uploadData.found);
  }, [uploadData]);

  const tooltip = useMemo((): ReactNode => {
    return (
      <div className="absolute top-8 right-2 mb-6 mr-1 rounded text-dark z-1 hidden group-hover:block">
        <div className="flex w-36 flex-col items-center">
          <div className="clip-bottom h-2 w-4 bg-white"></div>
          <div className="w-max rounded bg-white shadow-overlay px-6 py-4 text-center text-xs">
            <p className="select-text font-kraftig">
              Data processing is in progress. <br /> You may need to reload the page.
            </p>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div>
      <input
        type="file"
        ref={inputFileRef}
        onChange={handleFileChange}
        className="hidden"
        accept=".csv"
      />
      <div
        className={clsx(
          'w-full',
          'h-[294px]',
          'rounded-2xl',
          'border-2',
          'border-grey-300',
          'flex',
          'justify-center',
          'items-center',
          uploadData.status === UploadStatusCode.START && 'cursor-pointer',
          uploadData.status === UploadStatusCode.DONE ? 'border-solid' : 'border-dashed'
        )}
        onClick={uploadData.status === UploadStatusCode.START ? chooseFile : () => null}
        onDrop={handleDrop}
        onDragOver={handleDragOver}>
        {/*Default state*/}
        {!file &&
          (uploadData.status === UploadStatusCode.START ||
            uploadData.status === UploadStatusCode.ABORT) && (
            <div className="text-center">
              <ImportIcon className="m-auto mb-4" />
              <h4 className="text-dark text-lg font-halbfett">{t('listUpload.dragCSV')}</h4>
              <p className="text-grey-500 opacity-70 text-sm pt-2.5">
                {t('listUpload.orClickHere')}
              </p>
            </div>
          )}
        {/*End Default state*/}

        {/*File ready to import*/}
        {file && uploadData.status === UploadStatusCode.START && (
          <div className="text-center">
            <FileImportIcon className="m-auto mb-4" />
            <h4 className="text-dark text-lg">{showFileInfo()}</h4>
          </div>
        )}
        {/*End File ready to import*/}

        {/*TODO: remove after test*/}
        {/*File is pending*/}
        {/*{uploadData.status === UploadStatusCode.PENDING && (*/}
        {/*  <div className="text-center">*/}
        {/*    <p className="text-grey-500 opacity-70 text-sm">*/}
        {/*      {t('listUpload.yourCSVHasBeenUploaded')}*/}
        {/*      <br />*/}
        {/*      {t('listUpload.sendYouAnEmail')}*/}
        {/*    </p>*/}
        {/*    <h4 className="text-dark text-lg mt-7">{uploadData.info}</h4>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*End File is pending*/}

        {/*File is uploading*/}
        {fileIsUploading && (
          <div className="text-center">
            <p className="text-grey-500 opacity-70 text-sm">
              {t('listUpload.yourCSVHasBeenUploaded')}
              <br />
              {t('listUpload.sendYouAnEmail')}
            </p>
            <h4 className="text-dark text-lg mt-7">
              {showFileInfo() ||
                (uploadData.status !== UploadStatusCode.PENDING && uploadData.info)}
            </h4>
            <div className="flex justify-center items-center mt-7">
              <div className="w-96 h-2 bg-light-600 rounded-full">
                <div
                  className={clsx('h-2', 'bg-primary', 'rounded-full', 'duration-300')}
                  style={{ width: `${uploadData.process}%` }}></div>
              </div>
              <span className="pl-4 font-kraftig">{uploadData.process}%</span>
            </div>
          </div>
        )}
        {/*End File is uploading*/}

        {/*File have been successfully uploaded with found matches*/}
        {(uploadData.status === UploadStatusCode.DONE ||
          uploadData.status === UploadStatusCode.POST_PROCESSING) &&
          needToMergeOrFoundPresence() && (
            <div className="text-center">
              <DoneIcon className="m-auto mb-4" />
              <h4 className="text-dark text-lg font-halbfett">
                {t('listUpload.listUploadedSuccessfully')}
              </h4>
              <p className="text-grey-500 opacity-70 text-sm">{getDoneInfo()}</p>
              <div className="flex justify-center items-center gap-6 mt-7">
                {!!uploadData.found && (
                  <div className="relative group flex flex-col items-center justify-center">
                    <Button
                      disabled={uploadData.status === UploadStatusCode.POST_PROCESSING}
                      type="light"
                      className="h-12 underline text-grey-500"
                      onClick={
                        uploadData.isTotallyProcessed ? onOpenSavedSearch : onShowViewResultsModal
                      }>
                      {t('listUpload.viewResults')}
                    </Button>
                    {uploadData.status === UploadStatusCode.POST_PROCESSING && tooltip}
                  </div>
                )}
                {!uploadData.isTotallyProcessed && (
                  <div className="relative group flex flex-col items-center justify-center">
                    <Button
                      disabled={uploadData.status === UploadStatusCode.POST_PROCESSING}
                      type="light"
                      className="h-12 underline text-primary font-kraftig"
                      onClick={onShowMatchModal}>
                      {t('listUpload.reviewPossibleMatches')}
                    </Button>
                    {uploadData.status === UploadStatusCode.POST_PROCESSING && tooltip}
                  </div>
                )}
              </div>
            </div>
          )}
        {/*End File have been successfully uploaded with found matches*/}

        {/*File have been successfully uploaded without found matches*/}
        {uploadData.status === UploadStatusCode.DONE &&
          !uploadData.needToMerge &&
          !uploadData.found && (
            <div className="text-center">
              <h4 className="text-dark text-lg font-halbfett">{t('listUpload.listUploaded')}</h4>
              <p className="text-grey-500 opacity-70 text-sm">{`${uploadData.found} ${t(
                'listUpload.resultsFound'
              )}`}</p>
            </div>
          )}
        {/*End File have been successfully uploaded with found matches*/}
      </div>
      <div className="mt-6 flex items-center justify-between">
        <p className="text-grey-500 opacity-70 text-sm">
          <a href="/SampleFile.csv" className="underline font-kraftig" download>
            {t('listUpload.downloadSampleSheet')}
          </a>{' '}
          {t('listUpload.toConfirmThatYourData')}
        </p>
        <div>
          {uploadData.status !== UploadStatusCode.DONE && (
            <div className="flex">
              {(file || fileIsUploading) && (
                <Button type="light" className="h-12 underline mr-[30px]" onClick={onDiscard}>
                  {t('listUpload.discard')}
                </Button>
              )}
              <Button
                type="primary"
                className="w-[181px] h-12"
                disabled={!file || uploadData.status !== UploadStatusCode.START}
                disabledClass="!bg-[#DFE5EB] text-white"
                onClick={importFile}>
                {t('listUpload.import')}
              </Button>
            </div>
          )}
          {uploadData.status === UploadStatusCode.DONE && (
            <Button type="primary" className="w-[181px] h-12" onClick={importAnotherList}>
              {t('listUpload.importAnotherList')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
