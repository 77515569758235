import { NA } from 'utils/constants';

export const valueFormatter = (num: number, count?: number): string => {
  if (num === 0) {
    return String(num);
  }
  if (!num) {
    return NA;
  }
  if (Math.abs(num) > 999 && Math.abs(num) <= 999999) {
    return Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(count ? count : 1)) + 'K';
  }
  if (Math.abs(num) > 999999 && Math.abs(num) <= 999999999) {
    return Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(count ? count : 1)) + 'M';
  }
  if (Math.abs(num) > 999999999) {
    return Math.sign(num) * Number((Math.abs(num) / 1000000000).toFixed(count ? count : 1)) + 'B';
  }
  return String(num);
};
