import { IImportHistory, UploadResult, UploadStatusCode } from './types';

export const defaultUploadData: UploadResult = {
  status: UploadStatusCode.START,
  process: 0,
  info: '',
  crossUploadId: 0,
  savedSearchId: 0,
  isTotallyProcessed: false,
  found: 0,
  uploaded: 0,
  needToMerge: 0
};

export const defaultImportHistoryData: IImportHistory = {
  orders: [],
  data: [],
  currentPage: 1,
  totalPages: 1,
  limit: 20
};

export const defaultMatchesData = {
  count: 0,
  currentPage: 1,
  limit: 20,
  totalPages: 1,
  data: []
};
