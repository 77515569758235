import {
  IListUploadRepo,
  IListUploadService,
  LIST_UPLOAD_REPO,
  MatchesResponseData,
  MatchOperationData,
  UploadResult,
  IImportHistory
} from './types';
import { injector } from 'utils/injector';
import { ISort } from 'utils/types';
import getOrdersParams from 'helpers/getOrdersParams';
import moment from 'moment';

export class ListUploadService implements IListUploadService {
  private _listUploadRepo: IListUploadRepo = injector.get<IListUploadRepo>(LIST_UPLOAD_REPO);

  async uploadFile(file: File): Promise<UploadResult> {
    const time = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'csv');
    formData.append('time', time);
    return (await this._listUploadRepo.uploadFile(formData)).data.result;
  }

  async getStatusLastUploadedFile(): Promise<UploadResult> {
    return (await this._listUploadRepo.getStatusLastUploadedFile()).data.result;
  }

  async abortUploadedFileProcess(): Promise<UploadResult> {
    return (await this._listUploadRepo.abortUploadedFileProcess()).data.result;
  }

  async retrieveContactsListByImportId(
    importFileId: number,
    page: number
  ): Promise<MatchesResponseData> {
    return (await this._listUploadRepo.retrieveContactsListByImportId(importFileId, page)).data
      .result;
  }

  async mergeContactFromFileImport(data: MatchOperationData[]): Promise<void> {
    return (await this._listUploadRepo.mergeContactFromFileImport(data)).data.result;
  }

  async getImportHistoryData(
    page: number,
    orders: ISort[],
    perPage?: number
  ): Promise<IImportHistory> {
    return (await this._listUploadRepo.getImportHistoryData(page, getOrdersParams(orders), perPage))
      .data.result;
  }
}
