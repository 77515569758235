import React, { FC } from 'react';
import Button from 'components/Button';
import { ReactComponent as PlaceIcon } from 'assets/icons/place.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as CloneIcon } from 'assets/icons/clone.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useTranslation } from 'react-i18next';
import { ISavedSearch } from 'view/SavedSearches/types';
import { Link } from 'react-router-dom';
import { clsx } from 'utils/clsx';
import { SearchType } from 'utils/types';

interface Props {
  data: ISavedSearch;
  isShare?: boolean;
  onShare: (id: number, type: SearchType) => void;
  onDelete: (data: ISavedSearch) => void;
  onEdit: (id: number) => void;
  onClone: (data: ISavedSearch) => void;
  isEdit: boolean;
  dataTestId: number;
}

const ListItem: FC<Props> = ({
  data,
  isShare = true,
  onShare,
  onDelete,
  onEdit,
  onClone,
  isEdit,
  dataTestId
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between w-full py-3 px-4 mb-2 bg-white border border-grey-200 rounded-sm">
      <div className="w-10.5/12">
        <div className="flex pb-3 items-center">
          <Link
            to={`/saved-search/${data.id}`}
            className="max-w-[420px] text-base text-dark m-0 no-underline whitespace-nowrap text-ellipsis overflow-hidden font-medium">
            <span className="font-kraftig">{data.name}</span>
          </Link>
          {data.newProjects > 0 && (
            <span className="font-halbfett bg-red-100 inline-flex items-center min-w-[16px] h-4 rounded-3xl p-[3px] text-xs font-medium text-white mx-1 justify-center px-1">
              {data.newProjects}
            </span>
          )}
        </div>
        <div className="location flex items-center">
          <div className="-mx-1.5 pr-3">
            <PlaceIcon />
          </div>
          <span className="text-sm text-grey-500 pr-3">{data.description}</span>
        </div>
      </div>

      <div className="actions flex text-sm">
        <Button
          type="light"
          className={clsx(!onShare && 'select-none cursor-default', 'mr-4 px-1 flex items-center')}
          data-test-element={`saved-search-edit-button-${dataTestId}`}
          onClick={() => onShare(data.id, data.searchType)}>
          {!isShare && <ShareIcon stroke="#C1C2C4" />}
          {isShare && <ShareIcon />}
          <span className={clsx(isShare ? 'text-dark' : 'text-grey-250', 'pl-2.5')}>
            {t('components.projectInfo.share')}
          </span>
        </Button>
        <Button
          type="light"
          onClick={() => onEdit(data.id)}
          className="cursor-pointer mr-4 px-1"
          data-test-element={`saved-search-edit-button-${dataTestId}`}>
          {isEdit && (
            // <Link
            //   to={`/saved-search/${data.id}`}
            //   state={{ edit: true }}
            //   replace={true}
            //   className="flex h-full items-center">
            //   <PenIcon />
            //   <span className="text-dark pl-2.5">{t('components.listItem.edit')}</span>
            // </Link>
            <div className="flex h-full items-center">
              <PenIcon />
              <span className="text-dark pl-2.5">{t('components.listItem.edit')}</span>
            </div>
          )}
          {!isEdit && (
            <div className="flex h-full items-center">
              <PenIcon fill="#C1C2C4" />
              <span className="text-grey-250 pl-2.5">{t('components.listItem.edit')}</span>
            </div>
          )}
        </Button>
        <Button
          type="light"
          className="px-1 mr-4 flex items-center"
          data-test-element={`saved-search-delete-button-${dataTestId}`}
          onClick={() => onClone(data)}>
          <CloneIcon />
          <span className="pl-2.5 text-dark">{t('components.listItem.clone')}</span>
        </Button>
        <Button
          type="light"
          className="pr-1 flex items-center"
          data-test-element={`saved-search-delete-button-${dataTestId}`}
          onClick={() => onDelete(data)}>
          <TrashIcon />
          <span className="pl-2.5 text-dark">{t('components.listItem.delete')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
