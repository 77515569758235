import React, { FC } from 'react';
import { Button } from 'components';

interface IProps {
  title: string;
  info: string;
  buttonText: string;
  onBtnClick: () => void;
  isLoading?: boolean;
}

const InfoModal: FC<IProps> = ({ title, info, onBtnClick, buttonText, isLoading }) => {
  return (
    <div className="rounded-sm shadow-form max-w-146 bg-white py-7.5">
      <div className="flex items-center flex-col">
        {isLoading ? (
          <div className="w-full flex justify-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <h1 className="text-3xl m-0 text-dark">{title}</h1>
            <p className="w-71 mb-4 text-center text-base text-grey-500 font-normal">{info}</p>
            <div className="px-4 w-full">
              <Button
                type="primary"
                onClick={onBtnClick}
                className="w-full font-kraftig px-2 py-[11px]"
                data-test-element="reset-password-continue-button">
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoModal;
