import { axiosInstance } from 'utils/API';
import { ISavedSearchData, ISavedSearchResultsRepo, IShareSavedSearchResponse } from './types';
import { IAxiosResponse, ObjectValues, SearchStringType, SearchType } from 'utils/types';
import { ISearchParams } from 'modules/Filter/types';
import { CompanyData, ICompaniesListView } from 'view/CompanySearch/types';

export class SavedSearchResultsRepo implements ISavedSearchResultsRepo {
  async getCrossReferencingSavedSearches(
    userId: number,
    id: number,
    page: number,
    orderParams: ObjectValues<string>,
    type: SearchStringType
  ): Promise<IAxiosResponse<ISavedSearchData>> {
    const path = type === 'project' ? 'projects' : 'company';
    return await axiosInstance.get(`/users/${userId}/cross-reference/cross-reference-${path}`, {
      params: {
        page,
        searchId: [id],
        ...orderParams,
        pageSize: 20
      }
    });
  }
  async getSavedSearchesProjectsBySearchType(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ISavedSearchData>> {
    return await axiosInstance.get(`/users/${userId}/saved-search/project`, {
      params: {
        //TODO: can be changed after be fixes
        ...ids,
        page,
        ...orderParams,
        pageSize: 20
      }
    });
  }

  async getSavedSearchesCompaniesBySearchType(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ICompaniesListView>> {
    return await axiosInstance.get(`/users/${userId}/saved-search/company`, {
      params: {
        //TODO: can be changed after be fixes
        ...ids,
        page,
        ...orderParams,
        pageSize: 20
      }
    });
  }

  async getShareSavedSearch<T>(
    searchType: SearchStringType,
    token: string,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<IShareSavedSearchResponse<T>>> {
    return await axiosInstance.get(`users/saved-searches/${searchType}/shared/${token}`, {
      params: {
        page,
        ...orderParams
      }
    });
  }

  async updateFilters<T>(
    userId: number,
    searchId: number,
    filters: ISearchParams,
    searchType: SearchType,
    controller?: AbortController
  ): Promise<IAxiosResponse<T>> {
    return await axiosInstance.patch(
      `users/${userId}/saved-searches/${searchId}/filters`,
      { searchType, filters, pageSize: '20' },
      controller
        ? {
            signal: controller.signal
          }
        : {}
    );
  }
}
