import {
  CONFIG_REPO,
  IConfigRepo,
  IAppConfig,
  IConfigService,
  ChecksumType
} from 'store/ConfigStore/types';
import { injector } from 'utils/injector';

import moment from 'moment';
import { setLocal } from 'utils/localStorageOperation';
import { APP_CONFIG, CHECKSUM } from 'utils/constants';

export class ConfigService implements IConfigService {
  private _configRepo: IConfigRepo = injector.get<IConfigRepo>(CONFIG_REPO);

  async getNewChecksum(): Promise<ChecksumType> {
    const checksum = (await this._configRepo.getNewChecksum(String(moment().unix()))).data;
    setLocal(CHECKSUM, checksum);
    return checksum;
  }

  async getAppConfig(): Promise<IAppConfig> {
    const config = (await this._configRepo.getAppConfig()).data;
    setLocal(APP_CONFIG, config);
    return config;
  }

  async getAppResource<T>(url: string): Promise<T> {
    return (await this._configRepo.getAppResource<T>(url)).data;
  }
}
