import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SettingItem from './components/SettingItem';
import { useStore } from 'storesProvider/storeContext';
import { NotificationItem, NotificationSettingsKeys } from '../types';
import { observer } from 'mobx-react';

const Notifications: FC = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  useEffect(() => {
    (async () => await userStore.getNotificationSettings())();
  }, []);

  const settings = useMemo<NotificationItem[]>(() => {
    if (!userStore.notificationSettings) return [];
    return [
      {
        key: 'sharedSavedSearch',
        title: 'Shared Saved Search and Favorites',
        content:
          'These notifications are sent when a user shares a saved search or their list of favorite projects with you.',
        enabledApp: userStore.notificationSettings.sharedSavedSearchApp,
        enabledEmail: userStore.notificationSettings.sharedSavedSearchEmail
      },
      {
        key: 'newProjectSavedSearch',
        title: 'New Projects Added to Saved Searches',
        content:
          'These notifications are sent when a new project that meets the filters of one of your saved searches has been found and added to the list.',
        enabledApp: userStore.notificationSettings.newProjectSavedSearchApp,
        enabledEmail: userStore.notificationSettings.newProjectSavedSearchEmail
      },
      {
        key: 'projectUpdates',
        title: 'Project Updates',
        content:
          'These notifications are sent when a project you’ve favorited or have in a saved search has new information added to it.',
        enabledApp: userStore.notificationSettings.projectUpdatesApp,
        enabledEmail: userStore.notificationSettings.projectUpdatesEmail
      },
      // TODO: will be need in the future
      // {
      //   title: 'Company Updates',
      //   content:
      //     'These notifications are sent when a new user is added to your team. You can see all of your team members in the “Company Settings” page.'
      // },
      {
        key: 'shareAccepted',
        title: 'Share Accepted',
        content:
          'These notifications are sent when a user accepts an invitation to view a saved search you shared with them.',
        enabledApp: userStore.notificationSettings.shareAcceptedApp,
        enabledEmail: userStore.notificationSettings.shareAcceptedEmail
      }
    ];
  }, [userStore.notificationSettings]);

  const handleToggle = async (key: NotificationSettingsKeys, value: boolean): Promise<void> => {
    userStore.setNotificationSettings(key, value);
    await userStore.updateNotificationSettings();
  };

  return (
    <div className="h-full px-6 text-dark font-kraftig">
      <h1 className="text-base pt-8 mb-4">{t('profile.notifications.title')}</h1>
      <p className="text-sm text-grey-500">{t('profile.notifications.content')}</p>
      <div className="bg-white shadow-default my-6">
        {settings.map((setting, idx) => (
          <SettingItem
            setting={setting}
            key={`setting-notification-${idx}`}
            onEnabled={handleToggle}
          />
        ))}
      </div>
    </div>
  );
});

export default Notifications;
