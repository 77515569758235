import React, { FC, useState } from 'react';
import { Button, Tab, Dropdown } from 'components';
import { clsx } from 'utils/clsx';
import classes from '../Favorites.module.scss';
import projectAnalyticsIcon from 'assets/icons/project-analytics.svg';
import planeIcon from 'assets/icons/plane.svg';
import { useTranslation } from 'react-i18next';
import { ITabsItems } from 'components/Tab/types';
import { numberWithSpaces } from 'helpers/numberWithComma';
import { observer } from 'mobx-react';
import { IDropdownOption } from 'components/Dropdown/types';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-grey.svg';

interface Props {
  tabItems: ITabsItems[];
  totalProjectValue: number;
  onButtonClick: () => void;
  onTabClick: (tabId: number) => void;
  analyticsAction: () => void;
  onShare: () => void;
  onEditList: () => void;
  onDeleteList: () => void;
  editableFavorite: boolean;
  disableShare: boolean;
}

const TableHeader: FC<Props> = observer(
  ({
    tabItems,
    totalProjectValue,
    onButtonClick,
    onTabClick,
    analyticsAction,
    onShare,
    onEditList,
    onDeleteList,
    editableFavorite,
    disableShare
  }) => {
    const { t } = useTranslation();
    const [crudListOptions] = useState<IDropdownOption[]>([
      {
        selected: false,
        content: 'Edit list details',
        icon: PenIcon,
        id: 1,
        attr: 'favorite-list-edit-button'
      },
      {
        selected: false,
        content: 'Delete this list',
        icon: TrashIcon,
        id: 2,
        attr: 'favorite-list-delete-button'
      }
    ]);

    // TODO: remove after test
    // const handleTabChange = useCallback(
    //   (id: number) => {
    //     setSelectedDate(null);
    //     onTabClick(id);
    //   },
    //   [setSelectedDate]
    // );

    const handleTabChange = (id: number) => {
      onTabClick(id);
    };

    const handleDropdownChange = (value: IDropdownOption): void => {
      if (value.id === 1) {
        onEditList();
        return;
      }
      if (value.id === 2) {
        onDeleteList();
        return;
      }
    };

    return (
      <div>
        <Tab
          items={tabItems}
          mode="light"
          navClass={clsx(classes.tab, 'mb-4 mt-3')}
          action={handleTabChange}>
          <div className="flex items-end pb-3">
            <Button
              type="secondary"
              className="py-0.5 text-sm h-8 whitespace-nowrap px-3 mr-2 no-underline text-dark flex items-center"
              onClick={analyticsAction}>
              <span className="font-kraftig">{t('favorites.projectsAnalytics')}</span>
              <img src={projectAnalyticsIcon} className="ml-2" alt="project-analytics" />
            </Button>
            <Button
              type="secondary"
              className="flex py-0.5 text-sm h-8 whitespace-nowrap px-3 items-center"
              onClick={onButtonClick}>
              <span className="font-kraftig">{t('favorites.marketToThisList')}</span>
              <img src={planeIcon} className="ml-2" alt="export" />
            </Button>
            {!disableShare && (
              <Button
                type="secondary"
                background="#DBDFEA"
                color="#2F3139"
                className="ml-2 py-0.5 text-sm h-8 whitespace-nowrap px-4 flex items-center"
                onClick={onShare}>
                <span className="font-kraftig">{t('favorites.shareThisList')}</span>
              </Button>
            )}
            <div className="flex items-center ml-2">
              {editableFavorite && (
                <div className="ml-2 h-8">
                  <Dropdown
                    title={<DotsIcon />}
                    options={crudListOptions}
                    onSelect={handleDropdownChange}
                    float="start"
                    activeClass={classes.dropdownActiveIcon}
                    showArrow={false}
                  />
                </div>
              )}
            </div>
          </div>
        </Tab>
        <div className="flex justify-between px-6 my-7">
          <div className="flex justify-center items-center">
            <span className={clsx('mr-3', 'font-kraftig', classes.value)}>
              {t('favorites.totalProjectValue')}: ${numberWithSpaces(totalProjectValue)}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default TableHeader;
