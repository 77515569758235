import { IRow } from 'components/Table/types';
import { ObjectValues } from 'utils/types';
import { BLUE_COLOR } from 'utils/constants';
import { AnalyticsDataRes } from './Analytics/types';
import { numberWithSpaces } from 'helpers/numberWithComma';

export const tableAnalyticRowGeneration = (
  data: AnalyticsDataRes[],
  colorTypes: ObjectValues<string>,
  currentPage: number
): IRow[] => {
  return data.map((item, idx) => {
    return {
      id: `${currentPage}-${idx + 1}`,
      newItem: false,
      fields: [
        {
          field: 'details',
          value: `${item.propertyType} - ${item.propertySubType}`
        },
        {
          field: 'type',
          colorType: colorTypes[item.buildingPermitType] || BLUE_COLOR,
          value: item.buildingPermitType
        },
        {
          field: 'value',
          value: `$${numberWithSpaces(item.value)}`
        }
      ]
    };
  });
};
