import { Shape, ShapeItem } from './types';

export const coordinateShift = (shape: Shape): Shape => {
  return shape.map((shapeItem) => {
    return shapeItem.map((points) => {
      const data = points as Array<ShapeItem>;
      if (!Array.isArray(data[0])) {
        return data.reverse();
      }
      return data.map((point) => point.reverse()) as Array<ShapeItem>;
    });
  });
};

// TODO: remove after testing
export const markerRadiusByPrice = (value: number): number => {
  if (value >= 20000 && value < 100000) {
    return 5;
  }
  if (value >= 100000 && value < 500000) {
    return 8;
  }
  if (value >= 500000) {
    return 13;
  }
  return 3;
};

export const markerSizeByPrice = (value: number): string => {
  if (value >= 20000 && value < 100000) {
    return 'medium-marker';
  }
  if (value >= 100000 && value < 500000) {
    return 'large-marker';
  }
  if (value >= 500000) {
    return 'very-large-marker';
  }
  return 'small-marker';
};

export const clusterSizeByCount = (count: number): string => {
  if (count >= 2 && count < 10) {
    return 'small-cluster';
  }
  if (count >= 10 && count < 50) {
    return 'medium-cluster';
  }
  if (count >= 50 && count < 100) {
    return 'large-cluster';
  }
  return 'very-large-cluster';
};

export const getMarkerColorByStatus = (statusName: string): string => {
  if (statusName === 'Issued') {
    return 'green-marker';
  }
  if (statusName === 'Applied') {
    return 'orange-marker';
  }
  if (statusName === 'Final') {
    return 'blue-marker';
  }
  if (statusName === 'Approved') {
    return 'green-marker';
  }
  return 'black-marker';
};

export const getTooltipPosition = (value: number) => {
  if (value >= 20000 && value < 100000) {
    return 'tooltip-for-medium-marker';
  }
  if (value >= 100000 && value < 500000) {
    return 'tooltip-for-large-marker';
  }
  if (value >= 500000) {
    return 'tooltip-for-very-large-marker';
  }
  return 'tooltip-for-small-marker';
};
