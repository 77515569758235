import { IContact } from 'utils/types';

interface RequiredValue {
  id: number;
  contacts: IContact[];
}

export const getContactFromProjectById = <T extends RequiredValue>(
  projects: T[],
  projectId: number,
  contactId: number
): IContact => {
  return projects
    .find((project) => project.id === projectId)!
    .contacts.find((contact) => contact.id === contactId)!;
};
