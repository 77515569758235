import {
  FoundLocation,
  ISearchService,
  ISearchStore,
  SEARCH_SERVICE,
  SearchProjects
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
// import { setLocal } from 'utils/localStorageOperation';
// import { CURRENT_LOCATION } from 'utils/constants';

export class SearchStore implements ISearchStore {
  _searchService: ISearchService = injector.get<ISearchService>(SEARCH_SERVICE);

  constructor() {
    makeAutoObservable<SearchStore>(this);
  }

  foundLocations: SearchProjects | null = null;
  selectedLocationsInSearchList: FoundLocation[] = [];
  // TODO: need to rename or refactoring
  locationForMap: FoundLocation[] = [];

  setFoundLocations(locations: SearchProjects | null): void {
    this.foundLocations = locations;
  }

  setSelectedLocation(location: FoundLocation | FoundLocation[]): void {
    // if (!Array.isArray(location) && this.selectedLocationsInSearchList.length === 1) {
    //   console.log('1');
    //   if (this.selectedLocationsInSearchList[0].subscription !== location.subscription) {
    //     this.selectedLocationsInSearchList = [location];
    //     // this.selectedLocationsInSearchList = [
    //     //   {
    //     //     ...location,
    //     //     subscription: this.isSubscriptionLocation(location)
    //     //   }
    //     // ];
    //     return;
    //   }
    //   if (this.selectedLocationsInSearchList[0].subscription && location.subscription) {
    //     console.log('2');
    //     this.selectedLocationsInSearchList = [...this.selectedLocationsInSearchList, location];
    //     return;
    //   }
    // }
    // if (
    //   !Array.isArray(location) &&
    //   this.selectedLocationsInSearchList.length > 1 &&
    //   !location.subscription
    // ) {
    //   this.selectedLocationsInSearchList = [location];
    //   return;
    // }
    if (!Array.isArray(location)) {
      if (this.selectedLocationsInSearchList.length) {
        if (location.subscription && this.selectedLocationsInSearchList[0].subscription) {
          this.updateSelectedLocations(location);
        } else {
          this.selectedLocationsInSearchList = [location];
        }
        return;
      }
      this.selectedLocationsInSearchList = [location];
      return;
    }
    if (Array.isArray(location)) {
      // this.selectedLocationsInSearchList = location.map((item) => ({
      //   ...item,
      //   subscription: true
      // }));
      this.selectedLocationsInSearchList = location;
      return;
    }
    // this.updateSelectedLocations(location);
  }

  setLocationForMap(locations: FoundLocation[]): void {
    this.locationForMap = locations;
  }

  async searchProjects(
    value: string,
    controller: AbortController,
    searchId?: number
  ): Promise<void> {
    this.setFoundLocations(await this._searchService.searchProjects(value, controller, searchId));
  }

  async lookupLocation(states: string[], titles: string[]): Promise<void> {
    const res = await this._searchService.lookupLocation(states, titles);
    this.setLocationForMap(res);
  }

  updateSelectedLocations(location: FoundLocation): void {
    const isLocation = !!this.selectedLocationsInSearchList.find(
      (locationItem) => locationItem.id === location.id
    );
    if (isLocation) {
      if (this.selectedLocationsInSearchList.length === 1) return;
      this.selectedLocationsInSearchList = this.selectedLocationsInSearchList.filter(
        (locationItem) => locationItem.id !== location.id
      );
      return;
    }
    this.selectedLocationsInSearchList = [...this.selectedLocationsInSearchList, location];
  }

  // isSubscriptionLocation(location: FoundLocation): boolean {
  //   if (this.foundLocations) {
  //     return this.foundLocations.subscriptionData.data.map((data) => data.id).includes(location.id);
  //   }
  //   return false;
  // }

  getLocationsForMapById(ids: number[]): FoundLocation[] {
    return this.locationForMap.filter((location) => ids.includes(location.id));
  }
}
