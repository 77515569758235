import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import classes from './SearchList.module.scss';
import { clsx } from 'utils/clsx';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { useTranslation } from 'react-i18next';
import SearchItem from './components/SearchItem';
import { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE } from './constants';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import SubscriptionList from './components/SubscriptionList';

interface Props {
  listResponse: SearchProjects | null;
  selectedLocation: FoundLocation[];
  show: boolean;
  styleTop?: string;
  wrap?: boolean;
  onChange: (item: FoundLocation, replace?: boolean) => void;
  closeList: () => void;
  inputId: string;
  onKeyUp: () => void;
  onKeyDown: () => void;
  onKeyEnter: () => void;
  onKeyEscape: () => void;
}

const SearchList: FC<Props> = observer(
  ({
    listResponse,
    selectedLocation,
    show,
    styleTop = '0',
    wrap,
    onChange,
    inputId,
    closeList,
    onKeyUp,
    onKeyDown,
    onKeyEnter,
    onKeyEscape
  }) => {
    const { t } = useTranslation();
    const searchList = useRef() as React.MutableRefObject<HTMLDivElement>;
    const { searchStore } = useStore();

    useEffect(() => {
      if (show) {
        window.addEventListener('click', clickOutside);
        window.addEventListener('keydown', keyPress);
      }
      return () => {
        window.removeEventListener('click', clickOutside);
        window.removeEventListener('keydown', keyPress);
      };
    }, [show]);

    const keyPress = useCallback(
      (e: KeyboardEvent): void => {
        if (e.code === ARROW_UP) {
          onKeyUp();
        } else if (e.code === ARROW_DOWN) {
          onKeyDown();
        } else if (e.code === ENTER) {
          onKeyEnter();
        } else if (e.code === ESCAPE) {
          onKeyEscape();
        }
      },
      [onKeyUp, onKeyDown, onKeyEnter, onKeyEscape]
    );

    const clickOutside = (e: MouseEvent): void => {
      const searchRefs = document.querySelectorAll(`[data-input-id="${inputId}"]`);
      if (e.target !== searchList.current && ![...searchRefs].includes(e.target as Element)) {
        closeList();
      }
    };

    const selectedSubscriptionLocation = useMemo<FoundLocation[]>(() => {
      return searchStore.selectedLocationsInSearchList.filter((location) => location.subscription);
    }, [searchStore.selectedLocationsInSearchList]);

    const nonSelectedSubscriptionLocation = useMemo<FoundLocation[]>(() => {
      if (!listResponse) return [];
      const selectedSubscriptionIdsLocation = selectedSubscriptionLocation.map(
        (location) => location.id
      );
      return listResponse.subscriptionData.data.filter(
        (location) => !selectedSubscriptionIdsLocation.includes(location.id)
      );
    }, [searchStore.selectedLocationsInSearchList, listResponse]);

    if (!show || !listResponse || (!listResponse.subscriptionCount && !listResponse.count)) {
      return <></>;
    }

    return (
      <div
        className={clsx(
          classes.wrapper,
          'absolute',
          'max-h-80',
          'z-30',
          'w-full',
          'bg-white',
          'overflow-y-scroll',
          'shadow-wrapper'
        )}
        style={{ top: `${styleTop}px` }}
        ref={searchList}>
        {listResponse.subscriptionCount > 0 && (
          <>
            <div
              className={clsx(
                'uppercase',
                'px-2',
                'py-2',
                'text-grey-500',
                'bg-light-300',
                'text-xxs',
                listResponse.subscriptionCount > 0 && 'mb-2'
              )}>
              <span className="font-kraftig">
                {t('savedSearch.searchSubscriptionsResults')}
                <span className="ml-2 text-primary border border-light-600 py-0.5 px-3 text-xs rounded-3xl">
                  {listResponse.subscriptionCount}
                </span>
              </span>
            </div>

            {!!selectedSubscriptionLocation.length && (
              <SubscriptionList
                subscriptionCount={listResponse.subscriptionCount}
                subscriptionLocation={selectedSubscriptionLocation}
                selectedLocation={selectedLocation}
                onChange={(data) => onChange(data as FoundLocation)}
                styleTop={styleTop}
                wrap={wrap}
              />
            )}

            <SubscriptionList
              subscriptionCount={listResponse.subscriptionCount}
              subscriptionLocation={nonSelectedSubscriptionLocation}
              selectedLocation={selectedLocation}
              onChange={(data) => onChange(data as FoundLocation)}
              styleTop={styleTop}
              wrap={wrap}
            />
          </>
        )}
        {listResponse.count > 0 && (
          <>
            <div className="uppercase px-2 py-2 mb-2 text-grey-500 bg-light-300 text-xxs">
              <span className="font-kraftig">
                {t('savedSearch.searchResults')}{' '}
                <span className="ml-2 text-primary border rounded-3xl border-light-600 py-0.5 px-3 text-xs">
                  {listResponse.count}
                </span>
              </span>
            </div>
            <ul
              className={clsx('w-full', 'p-0', 'pr-0', 'm-0', 'list-none', 'pb-2')}
              style={{ top: `${styleTop}px` }}
              data-test-element="search-list">
              {listResponse.data.map((item: FoundLocation) => (
                <SearchItem
                  item={item}
                  key={`search-item-${item.id}-${item.targetTitle
                    ?.toLowerCase()
                    .split(' ')
                    .join('')}`}
                  handleClick={() => onChange(item, true)}
                  selectedLocationIds={selectedLocation.map((location) => location.id)}
                  wrap={wrap}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
);

export default SearchList;
