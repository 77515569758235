import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { clsx } from 'utils/clsx';
import classes from './Notification.module.scss';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { Button } from 'components';
import { BLUE_COLOR } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { INotification } from './types';
import NotificationItem from './components/NotificationItem';

const Notification: FC = observer(() => {
  const { t } = useTranslation();
  const { notificationStore } = useStore();
  const [showNotificationList, setShowNotificationList] = useState<boolean>(false);

  const notificationList = useRef<HTMLDivElement | null>(null);
  const notificationBtn = useRef<SVGSVGElement | null>(null);

  const clickOutside = (e: MouseEvent): void => {
    const composed = e.composedPath();
    if (
      e.target !== notificationList.current &&
      e.target !== notificationBtn.current &&
      notificationList.current &&
      !composed.includes(notificationList.current)
    ) {
      setShowNotificationList(false);
      if (
        notificationStore.notifications.some((notification: INotification) => !notification.seenAt)
      )
        (async () => await notificationStore.markAsRead())();
    }
  };

  const toggleVisible = useCallback(() => {
    setShowNotificationList((prev) => {
      if (
        prev &&
        notificationStore.notifications.some((notification: INotification) => !notification.seenAt)
      )
        (async () => await notificationStore.markAsRead())();
      return !prev;
    });
  }, []);

  const handleClearNotifications = async () => {
    await notificationStore.clearNotifications();
    setShowNotificationList(false);
  };

  useEffect(() => {
    if (showNotificationList) {
      window.addEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [showNotificationList]);

  return (
    <>
      <div role="button" onClick={toggleVisible} className="ml-3 mr-6">
        <BellIcon
          className={clsx(
            classes.bellBtn,
            showNotificationList && classes.bellIcon,
            'relative',
            'cursor-pointer'
          )}
          ref={notificationBtn}
        />
      </div>
      {showNotificationList && (
        <div
          className="absolute w-124 top-14 right-20 rounded-sm bg-white shadow-overlay z-20"
          ref={notificationList}>
          <div className={clsx(classes.header, 'py-3 px-6 flex justify-between text-dark')}>
            <div>
              <h5 className="m-0 text-lg">{t('notification.title')}</h5>
            </div>
            <div>
              <Button
                type="light"
                color={BLUE_COLOR}
                className="p-0"
                data-test-element="clear-all-button text-sm"
                onClick={handleClearNotifications}>
                {t('notification.clearAll')}
              </Button>
            </div>
          </div>
          <div className={clsx(classes.body)} data-test-element="notification-list">
            {!!notificationStore.notifications.length &&
              notificationStore.notifications.map((notification: INotification) => (
                <NotificationItem
                  notification={notification}
                  key={`notification-${notification.id}`}
                />
              ))}
            {!notificationStore.notifications.length && (
              <div
                className={clsx('py-3', 'px-4', 'text-center', 'text-dark', classes.notification)}>
                {t('notification.emptyNotes')}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default Notification;
