import React, { PropsWithChildren, ReactNode } from 'react';
import { IExtendedOption, IOption } from 'components/Select/types';
import { clsx } from 'utils/clsx';
import tickIcon from 'assets/icons/tick.svg';

interface IProps<T> {
  list: IExtendedOption[];
  children: ReactNode;
  childrenAlignment: 'top' | 'bottom';
  onChange: (item: T, option: IOption) => void;
  value: T | null;
}
const FavoriteList = <T,>({
  list,
  children,
  childrenAlignment,
  onChange,
  value
}: PropsWithChildren<IProps<T>>) => {
  const isSelected = (item: IExtendedOption, option: IOption): boolean => {
    if (!value) return false;
    return (
      (value as unknown as IExtendedOption).category === item.category &&
      (value as unknown as IExtendedOption).selected === option.id
    );
  };

  return (
    <div
      className={clsx(
        'w-full',
        'top-full',
        'left-0',
        'absolute',
        'z-100',
        'bg-white',
        'border-t-0',
        'shadow-wrapper',
        'flex',
        'mt-2',
        childrenAlignment === 'top' ? 'flex-col-reverse' : 'flex-col'
      )}>
      <div>
        {list.map((item, idx) => (
          <div key={item.category}>
            <div className="bg-light-300 text-xxs text-grey-500 uppercase py-1.5 px-0">
              <span className="px-2">{item.category}</span>
            </div>
            {item.options.map((option, i) => (
              <div
                key={option.value}
                className="flex justify-between items-center border-0 bg-white py-2 px-3 text-xs text-dark cursor-pointer hover:bg-grey-100"
                data-test-element={`dropdown-option-${idx + 1}-${i + 1}`}
                onClick={() => onChange(item as unknown as T, option)}>
                <span className="flex items-center">
                  {option.icon && (
                    <div className="mr-2">
                      <option.icon />
                    </div>
                  )}
                  {option.text}
                </span>

                {isSelected(item, option) && <img src={tickIcon} alt="" />}
              </div>
            ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export default FavoriteList;
