import React, { FC, useCallback, useEffect, useId, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import searchIcon from 'assets/icons/search.svg';
import illustrationIcon from 'assets/icons/illustration.svg';
import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { FoundLocation, SearchProjects } from './types';
import { useDebounce } from 'hooks/useDebounce';
import { useStore } from 'storesProvider/storeContext';
import { SearchList } from 'components';
import { CURRENT_LOCATION, DEBOUNCE_DELAY } from 'utils/constants';
import { useKeyboardCallbacks } from 'hooks/useKeyboardCallbacks';
import { usePrevious } from 'hooks/usePrevious';
import Header from 'view/Header';
import { setLocal } from 'utils/localStorageOperation';

export const Search: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alertStore, searchStore, filtersStore } = useStore();
  const [searchString, setSearchString] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchProjects | null>(null);
  const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce<string>(searchString, DEBOUNCE_DELAY);
  const [lastInputValue, setLastInputValue] = useState<string>('');
  const [canLoadLocations, setCanLoadLocations] = useState<boolean>(true);

  const controller = new AbortController();

  const prevTerm = usePrevious(debouncedSearchTerm);

  const id = useId();

  const inputSearch = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleInput = useCallback((name: string, value: string) => {
    setCanLoadLocations(true);
    setSearchString(value);
    setLastInputValue(value);
  }, []);

  const setValueWithoutFetching = useCallback((value: string) => {
    setCanLoadLocations(false);
    setSearchString(value);
  }, []);

  const loadLocations = useCallback(
    async (loadAnyway = false) => {
      if (!prevTerm.length && !debouncedSearchTerm.length && !loadAnyway) return;
      try {
        await searchStore.searchProjects(debouncedSearchTerm, controller);
        setSearchResults(searchStore.foundLocations);
        setShowSearchList(true);
      } catch (err) {
        console.log(err);
      }
      if (!canLoadLocations && !loadAnyway) {
        setCanLoadLocations(true);
        setShowSearchList(false);
      }
    },
    [debouncedSearchTerm, canLoadLocations, prevTerm]
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [debouncedSearchTerm]);

  useEffect(() => {
    searchStore.setSelectedLocation([]);
    setLocal(CURRENT_LOCATION, []);
  }, []);

  const handleInputFocus = useCallback(async () => {
    if (showSearchList) return;
    setCanLoadLocations(true);
    await loadLocations(true);
  }, [loadLocations, showSearchList]);

  const onSearchListChange = (item: FoundLocation): void => {
    searchStore.setSelectedLocation([item]);
    setLocal(CURRENT_LOCATION, []);
    // TODO: remove after test
    // filtersStore.setSearchParams('area', []);
    navigate(`/search-projects/${item.stateCode.toLowerCase()}/${item.canonicalTitle}`);
    setShowSearchList(false);
    setLastInputValue(item.title);
  };

  const handleCloseList = (): void => {
    setShowSearchList(false);
    setSearchResults(null);
  };

  const searchProjectsAction = (): void => {
    alertStore.errorAlert(t('savedSearch.inputText'));
    const currentInput = inputSearch.current.querySelector('input');
    if (currentInput) {
      currentInput.focus();
    }
  };

  const onInputBlur = useCallback(() => {
    setTimeout(() => setShowSearchList(false), 150);
  }, []);

  const { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape } = useKeyboardCallbacks({
    searchResults,
    setSearchResults,
    lastInputValue,
    setLastInputValue,
    setValueWithoutFetching,
    setShowSearchList
  });

  useEffect(() => {
    if (!canLoadLocations) return;
    (async () => await loadLocations())();
  }, [debouncedSearchTerm, canLoadLocations]);

  return (
    <div className="h-full-page">
      <Header />
      <div className="h-full flex items-center bg-white pt-20 relative overflow-hidden">
        <div className="w-[820px] m-auto">
          <div className="relative z-1">
            <div className="flex justify-center">
              <div className="max-w-[564px]">
                <h1 className="text-dark text-3xl leading-none text-center mb-3">
                  {t('savedSearch.search')}
                </h1>
                <p className="text-center text-dark font-normal text-base mb-9">
                  {t('savedSearch.searchTitle')}
                </p>
              </div>
            </div>
            <div className="flex mb-5 h-14 md:mb-0">
              <div className="grow relative" ref={inputSearch}>
                <Input
                  data-input-id={id}
                  name="search"
                  type="text"
                  autoComplete="off"
                  value={searchString}
                  onInput={handleInput}
                  onFocus={handleInputFocus}
                  onBlur={onInputBlur}
                  inputClass="w-full px-4 py-3 h-14"
                  searchIcon={true}
                  data-test-element="dashboard-search-input">
                  <div
                    data-input-id={id}
                    className="flex w-full items-center h-full px-4 py-3 text-grey-500 hover:bg-[#e3f2ff] transition ease-in-out duration-200">
                    <img src={searchIcon} alt="" />
                    &nbsp;{t('savedSearch.inputText')}
                  </div>
                </Input>
                <SearchList
                  listResponse={searchResults}
                  selectedLocation={searchStore.selectedLocationsInSearchList}
                  onChange={onSearchListChange}
                  styleTop="58"
                  show={showSearchList}
                  closeList={handleCloseList}
                  inputId={id}
                  onKeyUp={handleKeyUp}
                  onKeyDown={handleKeyDown}
                  onKeyEnter={handleKeyEnter}
                  onKeyEscape={handleKeyEscape}
                />
              </div>
              <Button type="primary" className="px-4 py-3 ml-2" onClick={searchProjectsAction}>
                {t('savedSearch.searchForProjectsNow')}
              </Button>
            </div>
          </div>
          <div className="flex justify-center w-full mt-[34px]">
            <img src={illustrationIcon} alt="" />
          </div>
          {/*<div className="flex justify-center md:absolute md:top-[620px] md:left-1/2 md:-translate-y-1/2 md:-translate-x-1/2">*/}
          {/*  <img src={illustrationIcon} alt="" />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
