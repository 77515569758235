import React, { FC, useCallback, useEffect, useRef } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import classes from '../Filter.module.scss';
import { mapPermitTypesToOptions, mapPropertyTypesToOptions } from 'helpers/mapConfigsToOptions';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { IDropdownEvent, ITreeOption, TreeOptionType } from '../types';
import 'react-dropdown-tree-select/dist/styles.css';
import { ENTER } from 'components/SearchList/constants';
import { runInAction } from 'mobx';

interface IProps {
  placeholder: string;
  data: ITreeOption[];
  onChange: (event: IDropdownEvent) => void;
  dropdownClass?: string;
  divClass?: string;
}

const TreeDropdown: FC<IProps> = observer(
  ({ onChange, placeholder, data, dropdownClass, divClass }) => {
    const { filtersStore, userStore } = useStore();

    const dropdownRef = useRef<DropdownTreeSelect | null>(null);
    const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
      if (!userStore.user) {
        onChange({ activeClasses: [], activeTypes: [], activeSubtypes: [], activePermits: [] });
        filtersStore.setCategories(mapPropertyTypesToOptions());
        filtersStore.setPermitTypes(mapPermitTypesToOptions());
        return;
      }
      const activeClasses = selectedNodes
        .filter((node) => node.type === TreeOptionType.Class)
        .map((item) => Number(item.value));
      const activeTypes = selectedNodes
        .filter((node) => node.type === TreeOptionType.Type)
        .map((item) => Number(item.value));
      const activeSubtypes = selectedNodes
        .filter((node) => node.type === TreeOptionType.Subtype)
        .map((item) => Number(item.value));
      const activePermits = selectedNodes
        .filter((node) => node.type === TreeOptionType.PermitType)
        .map((item) => Number(item.value));
      onChange({ activeClasses, activeTypes, activeSubtypes, activePermits });
    };

    useEffect(() => {
      if (!filtersStore.categories.length) {
        runInAction(() => {
          filtersStore.setCategories(mapPropertyTypesToOptions());
        });
      }
      if (!filtersStore.permitTypes.length) filtersStore.setPermitTypes(mapPermitTypesToOptions());
      const input = document.querySelector(
        `.${classes.searchSelect} input[placeholder="${placeholder}"]`
      );
      input?.setAttribute('disabled', 'disabled');
    }, []);

    const handleFocus = useCallback(() => {
      let list: NodeListOf<HTMLInputElement> | null =
        dropdownRef.current?.node.querySelectorAll('.dropdown-content input[type="checkbox"]') ||
        null;
      const interval = setInterval(() => {
        list = dropdownRef.current?.node.querySelectorAll('.dropdown-content input') || null;
        if (list?.length) {
          clearInterval(interval);
          list.forEach((item: Element) => {
            item.setAttribute('tabindex', '0');
            item.addEventListener('keydown', (e: Event) => {
              if ((e as KeyboardEvent).code === ENTER && document.activeElement === e.target) {
                const target = e.target as HTMLInputElement;
                const isActive = target.checked;
                const tagLink = dropdownRef.current?.node.querySelector('a.dropdown-trigger');
                const tags = (tagLink?.getAttribute('aria-labelledby') || '').split(' ');
                isActive
                  ? tagLink?.setAttribute(
                      'aria-labelledby',
                      tags.filter((tag) => tag !== target.id).join(' ')
                    )
                  : tagLink?.setAttribute('aria-labelledby', [...tags, target.id].join(' '));
                (e.target as HTMLInputElement).checked = !isActive;
                onChange({
                  [dropdownClass ? 'activePermits' : 'activeClasses']: Array.from(
                    list as NodeListOf<HTMLInputElement>
                  )
                    .filter((item) => item.checked)
                    .map((item) => Number(item.value))
                });
              }
            });
          });
        }
      }, 50);
    }, []);

    return (
      <DropdownTreeSelect
        ref={dropdownRef}
        texts={{ placeholder }}
        onFocus={handleFocus}
        keepOpenOnSelect
        showPartiallySelected
        disablePoppingOnBackspace
        data={data}
        onChange={handleChange}
        className={clsx(
          'h-8',
          'py-0.5',
          'pr-8',
          'pl-4',
          'text-sm',
          'mr-2',
          divClass,
          dropdownClass,
          classes.treeSelect
        )}
      />
    );
  }
);

export default TreeDropdown;
