import {
  ISubscriptionLocationDropdownRepo,
  ISubscriptionLocationDropdownService,
  SUBSCRIPTION_LOCATION_DROPDOWN_REPO,
  SubscribedLocationsResponse
} from './types';
import { injector } from 'utils/injector';

export class SubscriptionLocationDropdownService implements ISubscriptionLocationDropdownService {
  private _subscriptionLocationsDropdownRepo: ISubscriptionLocationDropdownRepo =
    injector.get<ISubscriptionLocationDropdownRepo>(SUBSCRIPTION_LOCATION_DROPDOWN_REPO);

  async getSubscribedLocations(
    page: number,
    pageSize: number,
    searchId?: number | null
  ): Promise<SubscribedLocationsResponse> {
    return (
      await this._subscriptionLocationsDropdownRepo.getSubscribedLocations(page, pageSize, searchId)
    ).data.result;
  }
}
